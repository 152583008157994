import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { IBeacon, IDepartment, IEmployee, IFormEmployee } from '../interfaces';

export interface IEmployeeApiSaveModel {
    id?: number;
    surname: string;
    firstName: string;
    middleName: string;
    phone: string;
    additionalPhone: string;
    email: string | null;
    department?: number | string | null;
    beacon?: string | null;
    orderInDepartment?: number;
}

export interface IEmployeeApiModel {
    id: number;
    surname: string;
    firstName: string;
    middleName: string;
    phone: string;
    additionalPhone: string;
    email: string;
    department: IDepartment;
    beacon: IBeacon;
    orderInDepartment?: number;
}

/**
 * Service to work with Employee API resources
 *
 * @class EmployeeService
 */
export class EmployeeService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/employees(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of Employee
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<IEmployee[]> {

        // return trackPromise(
        return await this.http
            .get<IEmployeeApiModel[]>(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            })
            .then(this.getDataExtractor())
            .then(list => list.map(mapToEmployeeModel))
        // );
    }

    /**
     * Create Employee
     *
     * @param { IEmployee } employee
     */
    async getEmployee(employee: IEmployee): Promise<IEmployee> {

        return await this.http
            .get<IEmployee>(this.url({ id: employee.id }), {
                headers: this.headers,
            })
            .then(this.getDataExtractor())
            .then(mapToEmployeeModel);
    }

    /**
     * Create Employee
     *
     * @param { IEmployee } employee
     */
    store(employee: IFormEmployee): Promise<IEmployee> {

        const formData = mapToEmployeeApiSaveModel(employee);

        return trackPromise(
            this.http
                .post<IEmployeeApiModel>(this.url(), formData, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToEmployeeModel)
        );
    }

    /**
     * Update employee
     *
     * @param { IEmployee } employee
     */
    update(employee: IFormEmployee): Promise<IEmployee> {

        const formData = mapToEmployeeApiSaveModel(employee);

        return trackPromise(
            this.http
                .patch<IEmployeeApiModel>(this.url({ id: employee.id }), formData, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToEmployeeModel)
        );
    }

    /**
     * Delete Employee
     * @param { IEmployee } employee
     */
    delete(employee: IEmployee): Promise<boolean> {

        return trackPromise(
            this.http
                .delete<IEmployeeApiModel>(this.url({ id: employee.id }), {
                    headers: this.headers,
                })
                .then(Boolean)
        );
    }

}

export function mapToEmployeeModel(rawEmployee: IEmployeeApiModel): IEmployee {

    return {
        id: rawEmployee.id,
        surname: rawEmployee.surname,
        firstName: rawEmployee.firstName,
        middleName: rawEmployee.middleName,
        phone: rawEmployee.phone,
        additionalPhone: rawEmployee.additionalPhone,
        email: rawEmployee.email,
        department: rawEmployee.department,
        beacon: rawEmployee.beacon,
        orderInDepartment: rawEmployee.orderInDepartment ? rawEmployee.orderInDepartment : 0,
    };
}

export function mapToEmployeeApiSaveModel(rawEmployee: IFormEmployee): IEmployeeApiSaveModel {

    const model: IEmployeeApiSaveModel = {
        surname: rawEmployee.surname,
        firstName: rawEmployee.firstName,
        middleName: rawEmployee.middleName,
        phone: rawEmployee.phone,
        additionalPhone: rawEmployee.additionalPhone,
        email: rawEmployee.email,
        beacon: rawEmployee.beacon,
        department: rawEmployee.department,
        orderInDepartment: rawEmployee.orderInDepartment,
    };

    if (rawEmployee.department) {

        model['department'] = rawEmployee.department;
    }

    if (!rawEmployee.orderInDepartment) {

        model['orderInDepartment'] = 0;
    }

    if (rawEmployee.id) {

        model['id'] = rawEmployee.id;
    }

    if (rawEmployee.email?.trim().length === 0) {

        model['email'] = null;
    }

    if (rawEmployee.beacon?.length === 0) {

        model['beacon'] = null;
    }

    return model;
}
