import { createSelector } from 'reselect';
import { RootState } from '../../store';


const getActiveProductState = (state: RootState) => {

    return state.unitActiveProducts;
};

const getActiveProduct = (state: RootState) => {

    return state.unitActiveProducts.activeProductData;
};

const getUnitId = (state: RootState, unitId: number) => unitId;

export const selectActiveProductByUnitId = createSelector(
    getActiveProduct,
    getUnitId,
    (activeProductData, unitId) => activeProductData.find(value => value.id === unitId || value.unit === unitId),
);

export const selectActiveProductInCurrentTime = createSelector(
    getActiveProductState,
    unitActiveProducts => unitActiveProducts.activeProductInCurrentTime.filter(value => value.product),
);