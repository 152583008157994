import { IChartAlert, ISensor } from '../../core/interfaces';
import { useSelector } from 'react-redux';
import { selectHistogramHeight } from '../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../../core/selectors/graphBarHeight/graphBarHeightSelector';
import * as d3 from 'd3';
import { IChartDataWithColor, useDataHistogram } from './useDataHistogram';


/**
 * Update chart scales
 */
export const useUpdateScale = (sensor: ISensor, forceMix = false, hrMode: boolean, mixMode: boolean): [
    d3.ScaleLogarithmic<number, number>,
    d3.ScaleLogarithmic<number, number>,
    d3.ScaleLinear<number, number>,
    number,
    number,
    (number | null),
    (number | null),
    (string | ((value: number) => Object)),
    number,
    number,
    any[],
    IChartDataWithColor[],
    IChartAlert[],
] => {
    const histogramHeight = useSelector(selectHistogramHeight),
        stateHeight = useSelector(selectGraphBarHeight);
    const { graphPreferences } = sensor;
    const currentPreferences = graphPreferences && graphPreferences[0],
        minY = currentPreferences ? currentPreferences.min : null,
        maxY = currentPreferences ? currentPreferences.max : null,
        color = currentPreferences?.color || '#b3de8e',
        scale = currentPreferences?.scale || 'liner';
    const height = forceMix ? stateHeight : histogramHeight;
    const [
        alertPointArr,
        data,
        alertData,
    ] = useDataHistogram(sensor, hrMode, mixMode, color);

    const bottomY = minY === null ? d3.min(data, d => Number(d.value)) || 0 : minY;
    const topY = maxY === null ? d3.max(data, d => Number(d.value)) || 100 : maxY === 0 ? 0.000000001 : maxY; //TODO  0.000000001 Correction factor at 0 maximum value. Corrects the display with a solid line.

    let positiveValuesHeight = height;

    let negativeValuesHeight = height - positiveValuesHeight;

    const modeLinearMinLogic = (maxY || maxY === 0) && currentPreferences;

    const modeLinearMaxLogic = (minY || minY === 0) && currentPreferences;

    const scaleLogPositive = d3.scaleLog();
    const scaleLogNegative = d3.scaleLog();
    const scaleLinear = d3.scaleLinear();
    if (bottomY <= 0 && topY >= 0) {

        const percentage = Math.abs(topY) / (Math.abs(topY) + Math.abs(bottomY));

        positiveValuesHeight = Math.floor(percentage > 0 ? height * percentage : height / 2);
        negativeValuesHeight = height - positiveValuesHeight;

        scaleLogPositive
            .domain([1e-6, topY])
            .range([1e-6, positiveValuesHeight]);

        scaleLogNegative
            .domain([bottomY, -1e-6])
            .range([negativeValuesHeight, 1e-6]);

    } else if (topY < 0) {

        scaleLogPositive
            .domain([1e-6, 1000])
            .range([height, 1000]);

        scaleLogNegative
            .domain([-Math.abs(bottomY), -1e-6])
            .range([height, 0]);

        negativeValuesHeight = Math.ceil(height - positiveValuesHeight);

    } else {

        scaleLogPositive
            .domain([1e-6, topY])
            .range([0, height]);

        scaleLogNegative
            .domain([-1000, -1e-6])
            .range([1000, height]);
    }

    scaleLinear
        .domain(topY < 0 ? [bottomY, modeLinearMinLogic ? topY : 0] :
            [modeLinearMaxLogic ? bottomY : 0, topY])
        .range(topY < 0 ? [positiveValuesHeight, 0] :
            [bottomY < 0 ? minY && minY !== 0 ? -negativeValuesHeight : -0.1 : negativeValuesHeight,
                topY < 0 ? -positiveValuesHeight : positiveValuesHeight]);


    return [
        scaleLogPositive,
        scaleLogNegative,
        scaleLinear,
        positiveValuesHeight,
        negativeValuesHeight,
        minY,
        maxY,
        scale,
        bottomY,
        topY,
        alertPointArr,
        data,
        alertData,
    ];
};