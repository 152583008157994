import React from 'react';
import ConfirmDialog from '../Dialog/ConfirmDialog';
import TextInput from './InputText/TextInput';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
    DragDropContext,
    DraggableLocation,
    DropResult, DragUpdate, Droppable, DroppableProvided,
} from 'react-beautiful-dnd';
import {
    Input, ListItem,
} from '@material-ui/core';
import { ReactComponent as DropdownArrow } from '../../assets/images/icons/dropdown-arrow.svg';
import './DraggableList.scss';
import DroppableItemUnsorted from './DragDropTreeHr/DroppableItemUnsorted';
import DroppableItem from './DragDropTreeHr/DroppableItem';
import { IDepartment, IEmployee } from '../../../interfaces';
import { IPlaceholderProps } from '../../../../interfaces';
import { ReactComponent as ProductView } from '../../assets/images/icons/product-view.svg';


/**
 *  interface for component
 */

interface Item {
    id?: number;
    name: string;
    surname: string;
    firstName: string;
    middleName: string;
    color?: string;
}

interface IProps {
    draggable: IDepartment[];
    droppable: IDepartment[];
    draggableUnsorted: IEmployee[];
    addInput: boolean;
    addCategory?: boolean;
    option: any;
    onDragEnd?: (state: any, action: string, data?: any) => void;
    filterFunction: (data: any) => Array<any>;
    table: string;
    draggableName: string[];
    droppableName: string[];
    phone: boolean;
    beacon: boolean;
}

interface IState {
    draggableUnsorted: any[];
    draggable: any[];
    droppable: any[];
    edit: number[];
    editD: number[];
    editDU: number[];
    confirm: boolean;
    move: {
        source: Item[];
        destination: Item[];
        droppableSource: DraggableLocation;
        droppableDestination: DraggableLocation;
        sourceIbdex: string | number;
        Destination: string | number;
        destinationCategory: any[];
        moveCause: any;
    } | null;

    placeholderProps: IPlaceholderProps;
}


/**
 * Dragged items component
 *
 * @class DraggableList
 */
class DragDropTreeHr extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        this.state = {
            draggableUnsorted: this.props.draggableUnsorted,
            draggable: this.props.draggable,
            droppable: this.props.droppable,
            edit: [],
            editD: [],
            editDU: [],
            confirm: false,
            move: null,
            placeholderProps: {},
        };

        this.onDragEnd = this.onDragEnd.bind(this);

        this.onDragUpdate = this.onDragUpdate.bind(this);

        this.getItem = this.getItem.bind(this);

        this.handleClick = this.handleClick.bind(this);

        this.activeDroppable = this.activeDroppable.bind(this);

        this.activeDraggable = this.activeDraggable.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.addCategoryOnKeyPress = this.addCategoryOnKeyPress.bind(this);

        this.addCategoryOnChange = this.addCategoryOnChange.bind(this);

        this.addItemOnKeyPress = this.addItemOnKeyPress.bind(this);

        this.addItemOnChange = this.addItemOnChange.bind(this);

    }


    /**
     * Component props update handler
     *
     * @param {IProps} nextProps Updated component properties
     */
    componentDidUpdate(nextProps: IProps) {

        if (this.props.draggable !== nextProps.draggable || this.props.droppable !== nextProps.droppable || this.props.draggableUnsorted !== nextProps.draggableUnsorted) {
            this.setState({
                droppable: this.props.droppable,
                draggable: this.props.draggable,
                draggableUnsorted: this.props.draggableUnsorted,
            });
        }

    }

    /**
     *  a little function to help us with Reordering the result
     * @param list - Category Item[]
     * @param startIndex - index begin dragging
     * @param endIndex - index end dragging
     * @param index - droppable index (category )
     */
    reorder(list: Item[], startIndex: number, endIndex: number, index: string | number) {

        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        const { table } = this.props;

        result.splice(endIndex, 0, removed);

        const { draggable, draggableUnsorted } = this.state;

        let draggableNew = draggable,
            draggableUnsortedNew = draggableUnsorted;

        if (index !== 'unsorted') {

            draggableNew = draggable.map(value => {

                if (value.id === index) {

                    value[table] = result;

                    return value;

                } else {

                    return value;
                }
            });

        } else {

            draggableUnsortedNew = result;
        }

        this.setState({ draggableUnsorted: draggableUnsortedNew, draggable: draggableNew, placeholderProps: {} });

        if (this.props.onDragEnd) {

            const { draggableUnsorted, droppable, draggable } = this.state;

            const data = { draggable, draggableUnsorted, droppable, endIndex, moved: removed, index };

            this.props.onDragEnd(data, 'reorder', data);
        }

    }


    /**
     * Moves an item from one list to another list.
     * @param source - item dragging from Item[]
     * @param destination - item dragging to Item[]
     * @param droppableSource - index from Item[]
     * @param droppableDestination - index to Item[]
     * @param sourceIbdex - droppable index in state
     * @param Destination - droppable index in state
     */
    move(
        source: Item[],
        destination: Item[],
        droppableSource: DraggableLocation,
        droppableDestination: DraggableLocation,
        sourceIbdex: string | number,
        Destination: string | number
    ) {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [removed] = sourceClone.splice(droppableSource.index, 1);
        const { table } = this.props;

        const indexSource = droppableSource.droppableId !== 'unsorted' ? parseInt(droppableSource.droppableId.replace(/\D+/g, '')) : 'unsorted';
        const indexDestination = droppableDestination.droppableId !== 'unsorted' ? parseInt(droppableDestination.droppableId.replace(/\D+/g, '')) : 'unsorted';

        destClone.splice(droppableDestination.index, 0, removed);

        const { draggable, draggableUnsorted } = this.state;
        const draggableNew = draggable.map(value => {
            if (value.id === indexSource) {
                value[table] = sourceClone;
                return value;
            } else if (value.id === indexDestination) {
                value[table] = destClone;
                return value;
            } else {
                return value;
            }
        });

        function unsortedNew() {
            if (indexSource === 'unsorted') {
                return sourceClone;
            } else if (indexDestination === 'unsorted') {
                return destClone;
            } else {
                return draggableUnsorted;
            }
        }

        this.setState({ draggable: draggableNew, draggableUnsorted: unsortedNew(), placeholderProps: {} });

        if (this.props.onDragEnd) {
            const { droppable, draggable } = this.state, draggableUnsorted = unsortedNew();
            const data = { draggable, draggableUnsorted, droppable };

            this.props.onDragEnd(this.state.move, 'move', data);
        }

    }


    /**
     *  Get item from state
     *
     * @param id { number }
     */
    getItem(id: number) {

        const currentUnit = this.state.draggable.filter(value => value.id === id);

        return currentUnit[0];
    }

    /**
     *  Result after dragged element
     *
     * @param result DropResult
     */
    onDragEnd(result: DropResult) {
        const { source, destination } = result;
        const { table } = this.props;
        // dropped outside the list
        if (!destination) {
            this.setState({ placeholderProps: {} });
            return;
        }

        if (source.droppableId === destination.droppableId) {

            const index = parseInt(source.droppableId.replace(/\D+/g, ''));
            this.reorder(
                source.droppableId !== 'unsorted' ? this.getItem(index)[table] : this.state.draggableUnsorted,
                source.index,
                destination.index,
                source.droppableId !== 'unsorted' ? index : 'unsorted'
            );

        } else {
            const index = parseInt(source.droppableId.replace(/\D+/g, ''));
            const index2 = parseInt(destination.droppableId.replace(/\D+/g, ''));
            const moveCause = Array.from(source.droppableId !== 'unsorted' ? this.getItem(index)[table] : this.state.draggableUnsorted,);

            this.setState({
                confirm: true,
                move: {
                    source: source.droppableId !== 'unsorted' ? this.getItem(index)[table] : this.state.draggableUnsorted,
                    destination: destination.droppableId !== 'unsorted' ? this.getItem(index2)[table] : this.state.draggableUnsorted,
                    droppableSource: source,
                    droppableDestination: destination,
                    sourceIbdex: source.droppableId !== 'unsorted' ? index : 'unsorted',
                    Destination: source.droppableId !== 'unsorted' ? index : 'unsorted',
                    destinationCategory: this.state.droppable.filter(value => value.id === index2),
                    moveCause: source.droppableId !== 'unsorted' ? moveCause[source.index] : moveCause[source.index],
                },
                placeholderProps: {},
            });
        }
    }

    /**
     *  handle for open draggable element
     * @param index
     * @param isOpen
     */
    handleClick(index: number | string, isOpen: boolean | undefined) {
        const { draggable } = this.state;

        const draggableNew = draggable.map(value => {
            if (value.id === index) {
                value.isOpen = !isOpen;
                return value;
            } else {
                return value;
            }
        });

        if (isOpen === undefined) {
            draggableNew.push({
                id: parseInt(String(index)),
                isOpen: true,
                data: [],
            });
        }

        this.setState({ draggable: draggableNew });

    }

    /**
     *  Active status Droppable
     * @param id
     */
    activeDroppable(id: number) {

        const { edit } = this.state;

        if (edit.indexOf(id) < 0) {

            edit.push(id);

            this.setState({ edit: edit });

        } else if (edit.indexOf(id) >= 0) {

            edit.splice(this.state.edit.indexOf(id), 1);

            this.setState({ edit: edit });
        }
    }

    /**
     *  Active status for dragged item
     * @param id item
     * @param type draggable or unsorted
     */
    activeDraggable(id: number, type: string) {

        const { editD, editDU } = this.state;

        if (type === 'draggable') {

            if (editD.indexOf(id) < 0) {

                editD.push(id);

            } else if (editD.indexOf(id) >= 0) {

                editD.splice(editD.indexOf(id), 1);
            }
        } else if (type === 'unsorted') {

            if (editDU.indexOf(id) < 0) {

                editDU.push(id);

            } else if (editDU.indexOf(id) >= 0) {

                editDU.splice(editDU.indexOf(id), 1);
            }
        }
        this.setState({ editD: editD, editDU: editDU });
    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {
        const { move } = this.state;
        if (move) {
            this.move(
                move.source,
                move.destination,
                move.droppableSource,
                move.droppableDestination,
                move.sourceIbdex,
                move.Destination
            );
        }
    }

    /**
     * State item form cancel handler
     */
    handleCancel() {

        this.setState({
            confirm: false,
            move: null,
            placeholderProps: {},
        });
    }

    /**
     * Update placeholder when item is dragged
     * @param {DragUpdate} update
     * */
    onDragUpdate(update: DragUpdate) {

        if (!update.destination) {

            return;

        }

        const insertItem = (arr: any, item: any, index: any) => [
            ...arr.slice(0, index),
            item,
            ...arr.slice(index),
        ];

        const removeItem = (arr: any, index: any) => [
            ...arr.slice(0, index),
            ...arr.slice(index + 1),
        ];

        const swapElements = (arr: any, source: any, destination: any) =>
                insertItem(removeItem(arr, source), arr[source], destination),
            draggableAttr = 'data-rbd-drag-handle-draggable-id',
            droppableAttr = 'data-rbd-droppable-id',
            getAttr = (key: any, value: any) => `[${key}="${value}"]`,
            { source, destination } = update,
            draggableQuery = getAttr(draggableAttr, update.draggableId),
            droppableQuery = getAttr(droppableAttr, destination.droppableId),
            draggable = document.querySelector(draggableQuery),
            droppable = document.querySelector(droppableQuery);


        if (!draggable || !droppable) {

            return;
        }

        const { clientHeight, clientWidth } = draggable;

        const reorderedChildren = source.droppableId === destination.droppableId

            ? swapElements([...Array.from(droppable.getElementsByClassName('wrapper-draged-element')[0].children)], source.index, destination.index)
            : insertItem([...Array.from(droppable.getElementsByClassName('wrapper-draged-element')[0].children)], draggable, destination.index);

        const clientY =
            parseFloat(window.getComputedStyle(droppable).paddingTop) +
            [...reorderedChildren]
                .slice(0, destination.index)
                .reduce((total, curr) => {
                    const style = curr.currentStyle || window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);

                    return total + curr.clientHeight + marginBottom;
                }, 0);

        this.setState({
            placeholderProps: {
                id: destination.droppableId,
                clientHeight,
                clientWidth,
                clientY,
                clientX: parseFloat(window.getComputedStyle(droppable).paddingLeft),
            },
        });
    }

    /**
     *  add category key press function
     *
     * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event
     */
    addCategoryOnKeyPress(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {

        if (this.props.option.addCategoryEvent) {

            this.props.option.addCategoryEvent.inputHandle(event, this.state.droppable);

        }
    }

    /**
     * add category change function
     *
     * @param {React.KeyboardEvent<HTMLDivElement>} event
     */
    addCategoryOnChange(event: React.KeyboardEvent<HTMLDivElement>) {

        if (event.key === 'Enter' && this.props.option.addCategoryEvent) {

            this.props.option.addCategoryEvent.confirmCreate(event);
        }
    }

    /**
     *  add category key press function
     *
     * @param {React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>} event
     */
    addItemOnKeyPress(event: React.KeyboardEvent<HTMLDivElement>) {

        if (event.key === 'Enter' && this.props.option.addEvent) {

            this.props.option.addEvent.confirmCreate(event);
        }
    }

    /**
     * add category change function
     *
     * @param {React.KeyboardEvent<HTMLDivElement>} event
     */
    addItemOnChange(event:  React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {

        if (this.props.option.addEvent) {

            this.props.option.addEvent.inputHandle(event, this.state.draggableUnsorted);

        }
    }

    /**
     * Component render
     *
     * @returns {JSX.Element}
     */
    render(): React.ReactElement | string | number | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const { move, placeholderProps, draggableUnsorted } = this.state;
        const { option: { editItem }, table, draggableName, droppableName, phone = false, beacon = false, t, option, addInput } = this.props;
        const confirmHeading = t('ARE_YOU_SURE_WANT_TO_MOVE_THE_EMPLOYEE_TO_DEPARTMENT', {
            employee: move ? draggableName.map(value => `${move.moveCause[value]} `).join(' ').trim() : '',
            department: move ? move?.destinationCategory.length > 0 ? move?.destinationCategory[0].name : t('UNSORTED') : '',
        });

        return (
            <div className={'dragged-list'}>
                <TextInput
                    className={'form-field'}
                    value={this.props.option.search.value}
                    name="search"
                    type="text"
                    placeholder={this.props.t('SEARCH')}
                    icon={'search'}
                    onChange={this.props.option.search.handleSearch}
                />
                <DragDropContext onDragEnd={this.onDragEnd} onDragUpdate={this.onDragUpdate}>
                    <React.Fragment>
                        {this.state.droppable.map((droppableItem) =>
                            (
                               <DroppableItem
                                   key={droppableItem.id}
                                   droppableItem={droppableItem}
                                   editItem={editItem}
                                   draggableName={draggableName}
                                   droppableName={droppableName}
                                   phone={phone}
                                   beacon={beacon}
                                   placeholderProps={this.state.placeholderProps}
                                   activeDraggable={this.activeDraggable}
                                   getItem={this.getItem}
                                   option={this.props.option}
                                   editD={this.state.editD}
                                   activeItemWrap={this.state.edit.indexOf(droppableItem.id) < 0} 
                                   activeDroppable={this.activeDroppable}
                                   handleClick={this.handleClick}
                                   filterFunction={this.props.filterFunction}
                                   table={table}
                               />
                            )
                        )}
                        {this.props.addCategory ?
                            <div
                                className={'list-draggable__item-wrap wrap-add-category'}
                                style={{
                                    position: 'relative',
                                    backgroundColor: '#fff',
                                    width: '100%',
                                }}
                            >

                                <div
                                    className={'list-draggable__item MuiListItem-root MuiListItem-gutters'}
                                >
                                    <DropdownArrow />
                                    <Input
                                        autoFocus={this.props.addCategory}
                                        disableUnderline
                                        className={'add-category ' + (this.props.option.errorStatus ? 'error-field' : '')}
                                        inputProps={{
                                            'maxLength': 30,
                                        }}
                                        onChange={this.addCategoryOnKeyPress}
                                        onKeyPress={this.addCategoryOnChange}
                                    />
                                </div>
                            </div>
                            :
                            null
                        }
                        <Droppable droppableId={'unsorted'} key={'unsorted'}>
                            {(provided: DroppableProvided) => (
                                <React.Fragment>
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className={'list-draggable'}
                                        style={{ position: 'relative' }}
                                    >
                                        <div className={'wrapper-draged-element'}>
                                            {draggableUnsorted && draggableUnsorted.length > 0 ? draggableUnsorted.map((unsortedItem: any, unsortedItemIndex: number) =>
                                                (

                                                    <DroppableItemUnsorted
                                                        key={unsortedItem.id}
                                                        unsortedItem={unsortedItem}
                                                        index={unsortedItemIndex}
                                                        editDU={this.state.editDU}
                                                        editItem={editItem}
                                                        option={this.props.option}
                                                        draggableName={draggableName}
                                                        phone={phone}
                                                        beacon={beacon}
                                                        activeDraggable={this.activeDraggable}
                                                    />
                                                )
                                            ) : null}
                                        </div>

                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                        {provided.placeholder}

                                        {placeholderProps.id === 'unsorted' && (
                                            <div
                                                className={'placeholder-props'}
                                                style={{
                                                    position: 'absolute',
                                                    top: placeholderProps.clientY,
                                                    left: placeholderProps.clientX,
                                                    height: placeholderProps.clientHeight,
                                                    width: placeholderProps.clientWidth,
                                                }}
                                            />
                                        )}

                                        {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                        {addInput ?
                                            <div style={{ position: 'relative' }}>
                                                <ListItem
                                                    className={'list-draggable__item-include unsorted'}
                                                    style={{ backgroundColor: '#fff' }}
                                                >
                                                    <ProductView />
                                                    <Input
                                                        autoFocus={addInput}
                                                        disableUnderline
                                                        className={'add-item ' + (option.errorStatus ? 'error-field' : '')}
                                                        inputProps={{
                                                            'maxLength': 30,
                                                        }}
                                                        onChange={this.addItemOnChange}
                                                        onKeyPress={this.addItemOnKeyPress}
                                                    />
                                                </ListItem>
                                            </div>
                                            : null
                                        }

                                    </div>
                                </React.Fragment>

                            )}
                        </Droppable>
                    </React.Fragment>
                </DragDropContext>
                {this.state.confirm ?
                    <ConfirmDialog
                        heading={confirmHeading}
                        onAccept={this.onConfirmDialog}
                        onClose={this.handleCancel}
                        open={this.state.confirm}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

export default withTranslation()(DragDropTreeHr);