import { fabric } from 'fabric';
import { ICanvasObjectSchema } from '../interfaces';
import { actionHandler, anchorWrapper, polygonPositionHandler } from './fabricCustomControlsHelper';

/**
 * Objects which can be created on canvas
 *
 * @type {ICanvasObjectSchema}
 */
export const CanvasObject: ICanvasObjectSchema = {

    polygon: {

        create: ({ points, ...options }) => {

            const lastControl = points.length - 1;

            return new fabric.Polygon(points, {
                ...options,
                perPixelTargetFind: true,
                cornerStyle: 'circle',
                hasBorders: false,
                cornerSize: 14,
                cornerColor: options.stroke,
                controls: points.reduce((acc: any, point: Array<number>, index: number) => {
                    // @todo: Typescript cannot recognize Control class, should be added custom typings
                    // @ts-ignore
                    acc['p' + index] = new fabric.Control({
                        positionHandler: polygonPositionHandler,
                        actionHandler: anchorWrapper(index > 0 ? index - 1 : lastControl, actionHandler),
                        actionName: 'modifyPolygon',
                        pointIndex: index,
                    });
                    return acc;
                }, {}),
            });
        },
    },

    areaPolygon: {

        create: (points: Array<{ x: number, y: number }>): fabric.Polygon => {

            return new fabric.Polygon(points, {
                    stroke: '#333333',
                    strokeWidth: 1,
                    fill: '#cccccc',
                    opacity: 0.1,
                    selectable: false,
                    hasBorders: false,
                    hasControls: false,
                    evented: false,
                }
            );
        },
    },
};
