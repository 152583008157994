import { v4 } from 'uuid';
import { Canvas } from '../canvas/Canvas';
import { IHmiObject } from '../../../../../core/interfaces';
import { editorConstants } from '../constants';

/**
 * Draw object on canvas
 *
 * @param {Canvas} canvas
 * @param {string} type
 * @param {IHmiObject} object
 * @param {label} label
 */
export function drawObject(canvas: Canvas, type: string, object: IHmiObject, label: string): void {

    const itemPoints = object.points ?
        object.points.map((point: number[]) => {

            const [x = 0, y = 0] = point;

            return { x: x, y: y };
        }) : [];
    const optionsMapping = {
        [editorConstants.interaction.modeRectangle]: {
            strokeWidth: 1,
            stroke: '#74797d',
            fill: '#cdd0d4',
            opacity: 1,
            width: 18,
            height: 18,
        },
        [editorConstants.interaction.modeOval]: {
            strokeWidth: 1,
            stroke: '#74797d',
            fill: '#cdd0d4',
            opacity: 1,
            radius: 9,
        },
        [editorConstants.interaction.modeTriangle]: {
            strokeWidth: 1,
            stroke: '#74797d',
            fill: '#cdd0d4',
            opacity: 1,
            width: 18,
            height: 18,
        },
        [editorConstants.interaction.modePolygon]: {
            stroke: '#999999',
            fill: '#cccccc',
            strokeUniform: true,
            strokeWidth: 3,
            strokeWidthInitial: 3,
            opacity: 1,
            objectCaching: false,
            superType: 'drawing',
        },
    };
    const drawScale = object.scale ? {
            scaleX: object.scale.x,
            scaleY: object.scale.y,
        } : {},
        baseOption = {
            id: v4(),
            type: type,
            left: object.position?.x | 0,
            top: object.position?.y | 0,
            points: itemPoints,
            objectId: object.id,
            unitId: object.unit,
            factoryId: object.fabric,
            angle: object.angle,
            zIndex: 20,
            suppressCallback: true,
            pidCode: object.pidCode,
            pidCodePosition: object.pidCodePosition,
        },
        labelItemOption = {
            id: v4(),
            type: 'pitCode',
            label: label,
            left: object.position.x | 0,
            top: object.position.y | 0,
            objectId: object.id,
            unitId: object.unit,
            factoryId: object.fabric,
            zIndex: 21,
            suppressCallback: true,
            itemId: baseOption.id,
            pidCodePosition: object.pidCodePosition,
        };

    canvas.handler.add({ ...baseOption, ...drawScale, ...optionsMapping[type] });
    canvas.handler.add(labelItemOption);
}
