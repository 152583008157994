import React, { useEffect, useState } from 'react';
import { Button, TextInput } from '../../../../../../../core/ui/components';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IGpsPin } from '../../../../../interfaces';
import clsx from 'clsx';

import './AddGpsPinForm.scss';

const MIN_LATITUDE_VALUE = -90;
const MAX_LATITUDE_VALUE = +90;
const MIN_LONGITUDE_VALUE = -180;
const MAX_LONGITUDE_VALUE = +180;

export const AddGpsPinForm: React.FC<IProps> = (props: IProps) => {
    const {
        onCloseAddGps,
        onSaveGpsPin,
        onDeleteGpsPin,
        editingPin,
    } = props;

    const { t } = useTranslation();

    const [initialValues, setInitialValues] = useState<IFormValues>({ lat: 0, lng: 0 });

    const [pin, setPin] = useState<IGpsPin | null>();

    useEffect(() => {
        if (!editingPin) {
            return;
        }

        setPin(editingPin);
        setInitialValues({
            lat: editingPin.lat,
            lng: editingPin.lng,
        });
    }, [editingPin]);

    const formik = useFormik<IFormValues>({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: yup.object().shape({
            lat: yup.number()
                .typeError(t('VALUE_MUST_BE_VALID_COORDINATE'))
                .label(t('LATITUDE'))
                .required(t('LATITUDE_IS_REQUIRED'))
                .min(MIN_LATITUDE_VALUE, t('VALUE_MUST_BE_VALID_COORDINATE'))
                .max(MAX_LATITUDE_VALUE, t('VALUE_MUST_BE_VALID_COORDINATE')),
            lng: yup.number()
                .typeError(t('VALUE_MUST_BE_VALID_COORDINATE'))
                .label(t('LONGITUDE'))
                .required(t('LONGITUDE_IS_REQUIRED'))
                .min(MIN_LONGITUDE_VALUE, t('VALUE_MUST_BE_VALID_COORDINATE'))
                .max(MAX_LONGITUDE_VALUE, t('VALUE_MUST_BE_VALID_COORDINATE')),
        }),
        onSubmit: ({ lat, lng }) => {
            onSaveGpsPin({
                lat: Number(lat),
                lng: Number(lng),
            });
        },
    });

    return (
        <>
            <form className="gps-pin-form form" onSubmit={formik.handleSubmit} noValidate>
                <TextInput
                    label={t('LATITUDE')}
                    className={clsx(
                        'form-field',
                        (formik.touched.lat ? formik.errors.lat ? 'error-field' : 'success-field' : '')
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lat}
                    name="lat"
                    type="text"
                    placeholder={t('LATITUDE')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {((formik.touched.lat && formik.errors.lat)) &&
                <div
                    className="validation-massage"
                >
                    {formik.errors.lat}
                </div>
                }
                <TextInput
                    label={t('LONGITUDE')}
                    className={clsx(
                        'form-field',
                        (formik.touched.lng ? formik.errors.lng ? 'error-field' : 'success-field' : ''),
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lng}
                    name="lng"
                    type="text"
                    placeholder={t('LONGITUDE')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {((formik.touched.lng && formik.errors.lng)) &&
                <div
                    className="validation-massage"
                >{formik.errors.lng}
                </div>
                }
            </form>
            <div className="gps-pin-form button-group">
                <Button color="primary" onClick={onCloseAddGps}>{t('CANCEL')}</Button>
                {
                    pin && !pin.isDraft &&
                    <Button color="primary" className="btn-delete" onClick={onDeleteGpsPin}>{t('DELETE')}</Button>
                }
                <Button color="primary"
                        onClick={formik.submitForm}
                >
                    {!pin || pin.isDraft ? t('ADD') : t('EDIT')}
                </Button>
            </div>
        </>
    );
};

interface IProps {
    editingPin: IGpsPin | null;
    onCloseAddGps: () => void;
    onDeleteGpsPin: () => void;
    onSaveGpsPin: (coordinates: IFormValues) => void;
}

interface IFormValues {
    lat: number;
    lng: number;
}
