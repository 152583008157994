import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { hmiPlayerConstants } from '../constants';
import { IHmiSchema } from '../interfaces';

/**
 * HMI player actions
 *
 * @type {Object}
 */
export const HmiPlayerActions = {

    /**
     * Start HMI player
     *
     * @return {Promise<Object>}
     */
    play: () => {

        const action = () => {

            return {
                type: hmiPlayerConstants.PLAY,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Pause HMI player
     *
     * @return {Promise<Object>}
     */
    pause: () => {

        const action = () => {

            return {
                type: hmiPlayerConstants.PAUSE,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Stop HMI player
     *
     * @return {Promise<Object>}
     */
    stop: () => {

        const action = () => {

            return {
                type: hmiPlayerConstants.STOP,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Set new value to timeline
     *
     * @param {number} value
     *
     * @return {Promise<Object>}
     */
    setValue: (value: number) => {

        const action = (value: number) => {

            return {
                type: hmiPlayerConstants.SET_VALUE,
                value,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(value));
        };
    },

    /**
     * Set new value to play speed
     *
     * @param {number} value
     *
     * @return {Promise<Object>}
     */
    setSpeed: (value: number) => {

        const action = (value: number) => {

            return {
                type: hmiPlayerConstants.SET_SPEED,
                speed: value,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(value));
        };
    },

    /**
     * Set HMI schema to player
     *
     * @param {IHmiSchema} schema
     *
     * @return {Promise<Object>}
     */
    setSchema: (schema: IHmiSchema | null) => {

        const action = (schema: IHmiSchema | null) => {

            return {
                type: hmiPlayerConstants.SET_SCHEMA,
                schema,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(schema));
        };
    },

    /**
     * Show HMI schema on player
     *
     *
     * @return {Promise<Object>}
     */
    showObjectOnScheme: () => {

        const action = () => {

            return {
                type: hmiPlayerConstants.SHOW_OBJECT,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Show HMI schema on player
     *
     *
     * @return {Promise<Object>}
     */
    showHmiScheme: () => {

        const action = () => {

            return {
                type: hmiPlayerConstants.SHOW_HMI_SCHEMA,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Show HMI schema on player
     *
     *
     * @return {Promise<Object>}
     */
    setOpacity: (opacity: number | null) => {

        const action = (data: number | null) => {

            return {
                type: hmiPlayerConstants.SET_OPACITY,
                opacityHmiMap: data,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(opacity));
        };
    },

    /**
     * Set new value to play speed
     *
     * @param { boolean } realTime
     *
     * @return {Promise<Object>}
     */
    setRealTime: (realTime: boolean) => {

        const action = (data: boolean) => {

            return {
                type: hmiPlayerConstants.REALTIME,
                realTime: data,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action(realTime));
        };
    },
};
