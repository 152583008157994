import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Button, DeleteDialog, Select } from '../../../../../core/ui/components';

import '../styles/ObjectForm.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectAllFactoryItemInTree,
    selectAllProcessItemInTree,
    selectAllSensorItemInConfigurationTree,
    selectAllUnitItemInTree,
} from '../../../../../core/selectors/configurationTree/configurationTreeSelector';
import {
    IFlatTreeFactory, IFlatTreeProcess,
    IFlatTreeUnit, IHmiObject,
    ISensor,
} from '../../../../../core/interfaces';
import { FabricObject, ITargetObject } from '../../../../../base/components/Editor/interfaces';
import { HmiObjectAction } from '../../../../../core/actions/hmiObjectAction';
import { editorConstants } from '../constants';
import { selectHmiObjectErrors } from '../../../../../core/selectors/hmiSchemas/hmiOpenSchemeSelector';
import { InputLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface IProps {
    title: string | null;
    targetObject: ITargetObject | FabricObject | null;
    onSave?: (model: any) => void;
    onChange?: (model: any) => void;
    onCancel?: () => void;
}

interface IFormValues {
    id?: number;
    name: string;
    factory: any;
    unit: any;
    angle: number;
    sensor_id: IHMIOptions | null;
    position: string;
}

interface ICurrentSensor extends ISensor{
    factoryId: number | null;
    unitId: number | null;
}

interface IHMIOptions {
    label: string;
    value: string | number;
    type: string;
}

const defaultInitialValues = {
    name: '',
    factory: '',
    unit: '',
    angle: 0,
    sensor_id: null,
    position: 'top',
};

const ObjectForm: React.FC<IProps> = (
    {
        title,
        onSave,
        onCancel,
        onChange,
        targetObject,
    }: IProps,
) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const allFactory = useSelector(selectAllFactoryItemInTree),
        allProcess = useSelector(selectAllProcessItemInTree),
        allUnit = useSelector(selectAllUnitItemInTree),
        allSensorInTree = useSelector(selectAllSensorItemInConfigurationTree),
        hmiObjectErrors = useSelector(selectHmiObjectErrors);


    const [initialValues, setInitialValues] = useState<IFormValues>(defaultInitialValues);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

    const [unitForOption, setUnitForOption] = useState<IFlatTreeUnit[]>([]);
    const [sensorForOption, setSensorForOption] = useState<ISensor[]>([]);

    const [validationSchema] = useState(yup.object().shape({
        name: yup.string()
            .trim(),
        picture: yup.string()
            .trim(),
        factory: yup.number()
            .required(t('FIELD_IS_REQUIRED')),
        unit: yup.number()
            .required(t('FIELD_IS_REQUIRED')),
        sensor_id: yup.object()
            .nullable(true)
            .required(t('FIELD_IS_REQUIRED')),
    }));

    /**
     * Action on submit form.
     *
     * @type {(values: IFormValues) => void}
     */
    const handleSubmit = useCallback((values: IFormValues) => {

        setInitialValues(values);
        
        if (onSave && !('messages' in hmiObjectErrors)) {

            onSave({ values: values, targetObject: targetObject });
        }

    }, [setInitialValues, targetObject, hmiObjectErrors]);


    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    });

    useEffect(() => {

        const currentSensor: ICurrentSensor | undefined = sensorForOption.find(value => value.id === (targetObject as FabricObject)?.sensorId) as ICurrentSensor;

        dispatch(HmiObjectAction.clearErrorsInForm());
        
        if (targetObject?.objectId) {

            setInitialValues({
                ...initialValues,
                id: targetObject.objectId,
                unit: targetObject.unitId,
                factory: targetObject.factoryId,
                sensor_id: { value: currentSensor.id, label: currentSensor.pIdCode || currentSensor.name, type: currentSensor.sensorType },
                position: (targetObject as FabricObject).pidCodePosition,
                angle: (targetObject as FabricObject).angle || initialValues.angle || 0,
            });
        } else {

            formik.resetForm();

            setInitialValues({
                ...initialValues,
                id: undefined,
            });

            const clearAngle = setTimeout(()=> {
                setInitialValues({
                    ...initialValues,
                    angle: (targetObject as FabricObject)?.angle || 0,
                    sensor_id: (targetObject as FabricObject)?.sensorId ? {
                        value: currentSensor.id,
                        label: currentSensor.name,
                        type: currentSensor.sensorType,
                    } : null,
                });

                clearTimeout(clearAngle);

            }, 150);
        }

    }, [targetObject]);

    /**
     * Convert item to select options
     *
     * @param item
     * @return {IOptions}
     */
    const itemToOption = (item: any, options?: any): IHMIOptions => {

        const itemType = item.sensorType === 'graph' ? 'Sensor' : 'State';

        return { value: item.id, label: item.pIdCode || item.name, type: itemType };
    };

    useEffect(() => {

        const sensorForOptionData = allSensorInTree.map(value => {

            const currentUnit = allUnit.find(value1 => (value1.data as ISensor[]).find(value2 => value2.id === value.id));

            if (currentUnit) {
                const currentProcess = allProcess.find(value1 => (value1.data as number[]).find(value2 => value2 === currentUnit.id));
                const currentFactory = allFactory.find(value1 => (value1.data as number[]).find(value2 => currentProcess && value2 === currentProcess.id));

                const test = { unitId: currentUnit.id, factoryId: currentFactory?.id || null };

                return { ...value, ...test };
            }

            return value;
        });

        setSensorForOption(sensorForOptionData);

        const unitForOptionData = (allUnit as IFlatTreeUnit[]).map(unit => {

            const currentProcess = (allProcess as IFlatTreeProcess[]).find(process => (process.data as number[]).find(value => value === unit.id)),
                currentFactory = (allFactory as IFlatTreeFactory[]).find(factory => (factory.data as number[]).find(value => currentProcess && currentProcess.id === value));

            return { ...unit, factoryId: currentFactory?.id || null };
        });

        setUnitForOption(unitForOptionData);

    }, [allFactory, allSensorInTree, allUnit, allProcess]);

    /**
     * Action on form change
     *
     * @type {(event: React.ChangeEvent<{name?: string | undefined, value: unknown}>) => void}
     */
    const onChangeSelect = useCallback((event: React.ChangeEvent<{ name?: string | undefined, value: unknown }>) => {

        formik.handleChange(event);

        if (event.target.name === 'factory' && event.target.value) {

            setInitialValues({ ...initialValues, factory: parseInt(event.target.value as string), unit: '', sensor_id: null });
        }

        if (event.target.name === 'unit' && event.target.value) {

            const currentUnit = unitForOption.find(unit=> unit.id === parseInt(event.target.value as string)) as any;

            setInitialValues({ ...initialValues, unit: parseInt(event.target.value as string), sensor_id: null, factory: currentUnit.factoryId || null });

        }

        if (event.target.name === 'angle') {

            setInitialValues({ ...initialValues, angle: event.target.value as number });

            onChange && onChange({
                values: { ...initialValues, angle: event.target.value },
                targetObject: targetObject,
            });
        }

        if (event.target.name === 'position') {

            setInitialValues({ ...initialValues, position: event.target.value as string });

            onChange && onChange({
                values: { ...initialValues, position: event.target.value },
                targetObject: targetObject,
            });
        }

    }, [formik, setUnitForOption, setSensorForOption, sensorForOption]);

    /**
     * Action on open confirm delete modal
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onDeleteObject = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        setOpenDeleteModal(true);

    }, [setOpenDeleteModal]);

    /**
     * Action on cancel delete.
     *
     * @type {() => void}
     */
    const onDeleteCancel = useCallback(()=>{

        setOpenDeleteModal(false);

    }, [setOpenDeleteModal]);

    /**
     * Action on delete object on scheme.
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onDeleteSuccess = useCallback(() => {


        if (initialValues.id) {
            const sendData: IHmiObject = {
                id: initialValues.id,
                pidCode: initialValues.name,
                fabric: initialValues.factory,
                unit: initialValues.unit,
                angle: initialValues.angle,
                points: (targetObject as ITargetObject)?.points?.map(value => ({ x: value[0], y: value[1] }))[0] as any || null,
                position: {
                    x: 0,
                    y: 0,
                },
                type: Object(editorConstants.typeToId)[`${targetObject?.type}`],
                pidCodePosition: initialValues.position,
                hmiSchema: (targetObject as ITargetObject)?.schemaId || 0,
                scale: null,
                sensorId: null,
            };

            dispatch(HmiObjectAction.delete(sendData));

            if (onCancel) {

                onCancel();
            }
        }
    }, [dispatch, initialValues, targetObject, onCancel]);

    /**
     * Filter function for sensor
     * @type {(sensor: any) => (boolean | any)}
     */
    const filterFunctionForSensor = useCallback((sensor: any) =>{

            if (!parseInt(formik.values.unit) && typeof formik.values.factory === 'number') {

                return formik.values.factory === sensor.factoryId;
            }

            if (typeof formik.values.unit === 'number') {

                return formik.values.unit === sensor.unitId;
            }

            return sensor;

    }, [formik]);

    /**
     * Filter function for unit.
     *
     * @type {(unit) => (boolean | any)}
     */
    const filterFunctionForUnit = useCallback((unit) => {

        if (typeof initialValues.factory === 'number') {

            return unit.factoryId === formik.values.factory;

        } else {

            return unit;
        }

    }, [formik]);

    /**
     * On Cancel changes in form
     *
     * @type {() => void}
     */
    const onCancelAction = useCallback(() => {

        onChange && onChange({
            values: {
                ...initialValues,
                position: (targetObject as FabricObject).pidCodePosition,
                angle: (targetObject as FabricObject).angle,
                factory: (targetObject as FabricObject).factoryId,
                unit: (targetObject as FabricObject).unitId,
                sensor_id: (targetObject as FabricObject).sensorId || (targetObject as FabricObject).pidCode || null,
            },
            targetObject: targetObject,
        });

        if (onCancel) {

            dispatch(HmiObjectAction.clearErrorsInForm());

            onCancel();
        }

        setInitialValues({ ...initialValues, sensor_id: null });

    }, [onCancel, targetObject, onChange, initialValues, dispatch]);

    /**
     * Filtering and sorting sensor data for selection option.
     *
     * @type {ISensor[]}
     */
    const sensorDataForSelect = sensorForOption
        .filter(filterFunctionForSensor)
        .sort((a, b) => {

            if (a.sensorType < b.sensorType) {

                return -1;
            }

            if (a.sensorType > b.sensorType) {

                return 1;
            }

            return 0;
        });

    /**
     * Render input for autocomplete field
     *
     * @type {(params: Object) => React.ReactNode}
     */
    const renderInputAutocomplete = useCallback((params: Object): React.ReactNode => {

        return (
            <>
                <InputLabel>{t('SENSOR_HMI')}</InputLabel>
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder={t('SELECT')}
                />
                {formik.touched.sensor_id && formik.errors.sensor_id &&
                <div
                    className="validation-massage"
                >
                    {formik.errors.sensor_id}
                </div>
                }
            </>
        );
    }, [formik, t]);

    /**
     * On change autocomplete field.
     *
     * @type {(event: any, value: any, reason: any, detai: any) => void}
     */
    const onChangeAutocomplete = useCallback((event: any, value: any, reason: any, detai: any)=> {

        setInitialValues({ ...initialValues, sensor_id: value });

        const currentSensor: ICurrentSensor | undefined = sensorForOption.find(sensor => sensor.id === value?.value) as ICurrentSensor;

        if (currentSensor) {

            setInitialValues({
                ...initialValues,
                name: currentSensor.pIdCode || currentSensor.name,
                sensor_id: { value: currentSensor.id, label: currentSensor.pIdCode || currentSensor.name, type: currentSensor.sensorType },
                factory: currentSensor.factoryId,
                unit: currentSensor.unitId,
            });

            onChange && onChange({
                values: {
                    ...initialValues,
                    name: currentSensor.pIdCode || currentSensor.name,
                    sensor_id: { value: currentSensor.id, label: currentSensor.pIdCode || currentSensor.name, type: currentSensor.sensorType },
                    factory: currentSensor.factoryId,
                    unit: currentSensor.unitId,
                },
                targetObject: targetObject,
            });
        }

        dispatch(HmiObjectAction.clearErrorsInForm());

    }, [dispatch, setInitialValues, initialValues, sensorForOption]);

    /**
     * Group by type
     *
     * @type {(option: IHMIOptions) => string}
     */
    const groupBy = useCallback((option: IHMIOptions) => {

        return option.type;
    }, []);

    /**
     * Get option label
     *
     * @type {(option: IHMIOptions) => string}
     */
    const getOptionLabel = useCallback((option: IHMIOptions) => {

        return option.label;
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="section edit-object-form wrap-form-node">
                    <div className="table-header">
                        <div className="title">
                            <h3>{title}</h3>
                        </div>
                    </div>
                    {'messages' in hmiObjectErrors && hmiObjectErrors.messages ?
                        <div className="common-error">
                            {t(hmiObjectErrors.messages[0])}
                        </div>
                        : null
                    }
                    <div className="table-body">
                        <div className="form-group">
                            <Select
                                name="factory"
                                className={`form-field ${formik.touched.factory ? formik.errors.factory ? 'error-field' : 'success-field' : ''}`}
                                onChange={onChangeSelect}
                                label={t('FACTORY')}
                                placeholder={t('SELECT')}
                                value={formik.values.factory}
                                options={allFactory.map(itemToOption) || []}
                            >
                                {formik.touched.factory && formik.errors.factory &&
                                <div
                                    className="validation-massage"
                                >
                                    {formik.errors.factory}
                                </div>
                                }
                            </Select>
                        </div>
                        <div className="form-group">
                            <Select
                                name="unit"
                                className={`form-field ${formik.touched.unit ? formik.errors.unit ? 'error-field' : 'success-field' : ''}`}
                                onChange={onChangeSelect}
                                label={t('UNIT')}
                                value={formik.values.unit}
                                placeholder={t('SELECT')}
                                options={unitForOption
                                    .filter(filterFunctionForUnit)
                                    .map(itemToOption) || []}
                            >
                                {formik.touched.unit && formik.errors.unit &&
                                <div
                                    className="validation-massage"
                                >
                                    {formik.errors.unit}
                                </div>
                                }
                            </Select>
                        </div>
                        <div className="form-group">
                            <Autocomplete
                                renderInput={renderInputAutocomplete}
                                onChange={onChangeAutocomplete}
                                className={`autocomplete-field form-field ${formik.touched.sensor_id ? 
                                    (formik.errors.sensor_id || 
                                        ('messages' in hmiObjectErrors && hmiObjectErrors.messages)) ? 'error-field' : 'success-field' : ''}`}
                                placeholder={t('SELECT')}
                                options={sensorDataForSelect
                                    .filter((value: any) => value.unitId && value.factoryId)
                                    .map(itemToOption) || []
                                }
                                value={formik.values.sensor_id}
                                groupBy={groupBy}
                                getOptionLabel={getOptionLabel}
                            />
                        </div>
                        <div className="form-group">
                            <Select
                                name="angle"
                                className="form-field"
                                onChange={onChangeSelect}
                                placeholder={t('SELECT')}
                                label={t('OBJECT_ICON_POSITION')}
                                value={formik.values.angle}
                                options={[
                                    { value: 0, label: t('0°') },
                                    { value: 90, label: t('90°') },
                                    { value: 180, label: t('180°') },
                                    { value: 270, label: t('270°') },
                                ]}
                            />
                        </div>
                        <div className="form-group">
                            <Select
                                name="position"
                                className="form-field"
                                onChange={onChangeSelect}
                                label={t('PID_CODE_POSITION')}
                                value={formik.values.position}
                                placeholder={t('SELECT')}
                                options={[
                                    { value: 'top', label: t('TOP') },
                                    { value: 'topRight', label: t('TOP_RIGHT') },
                                    { value: 'right', label: t('RIGHT') },
                                    { value: 'rightBottom', label: t('RIGHT_BOTTOM') },
                                    { value: 'bottom', label: t('BOTTOM') },
                                    { value: 'bottomLeft', label: t('BOTTOM_LEFT') },
                                    { value: 'left', label: t('LEFT') },
                                    { value: 'topLeft', label: t('TOP_LEFT') },
                                ]}
                            />
                        </div>
                        <hr />
                        {initialValues.id ?
                            <div
                                className="form-btn-delete"
                                onClick={onDeleteObject}
                            >
                                {t('DELETE_OBJECT')}
                            </div>
                            :
                            null}
                        <div className="button-row">
                            <Button
                                type="button"
                                color="primary"
                                onClick={onCancelAction}
                            >
                                {t('CANCEL')}
                            </Button>
                            <Button type="submit" color="secondary">
                                {t('SAVE')}
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
            <DeleteDialog
                open={openDeleteModal}
                removeId={initialValues.id || null}
                heading={t('REMOVE_THE_OBJECT')}
                body={t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_OBJECT')}
                onAccept={onDeleteSuccess}
                onClose={onDeleteCancel}
            />
        </>
    );
};

export default React.memo(ObjectForm);
