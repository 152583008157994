import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as KeyIcon } from '../../../../core/ui/assets/images/icons/key.svg';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as SensorIcon } from '../../../../core/ui/assets/images/icons/parameter-v-2.svg';
import { ReactComponent as ParameterSelectedIcon } from '../../../../core/ui/assets/images/icons/parameter-selected.svg';

import { IMonitoringTreeSensorItemProps } from '../../../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { FormActions, MonitoringActions, statesActions } from '../../../../core/actions';
import { GraphActions } from '../../../../base/store/actions';
import { HistogramChart } from '../../../../base/components';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { graphConstants } from '../../../../core/constants';
import { selectForm } from '../../../../core/selectors/form/formSelector';
import { selectHmiPlayerMode } from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline } from '../../../../core/selectors/dashboard/dashboardSelector';


/**
 * SensorItemDragged component
 *
 * @param {number} histogramHeight
 * @param {ISensor} sensor
 * @param {Object} factory
 * @constructor
 *
 * @return JSX.Element
 */
const SensorItem: React.FC<IMonitoringTreeSensorItemProps> = (
    {
        histogramHeight,
        sensor,
        sensorTargetValue,
    }: IMonitoringTreeSensorItemProps,
) => {

    const dispatch = useDispatch();

    const selectedDashboard = useSelector(selectSelectedDashboard);

    const visibleSideBar = useSelector(selectVisibleSideBar);
    const dashboardRealTime = useSelector(selectDashboardOnline);

    const maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const [editPreferences, setEditPreferences] = useState(false);

    const selectFormData = useSelector(selectForm);

    const [isVisible, setIsVisible] = useState(sensor.isVisible);


    const { body: { offsetWidth } } = document;

    useEffect(() => {

        setIsVisible(sensor.isVisible);

    }, [sensor]);


    useEffect(() => {

        if (selectFormData?.formName === 'histogramForm' && selectFormData?.formModel) {

            if (selectFormData.formModel.sensor === sensor.id) {

                setEditPreferences(true);

            }
        }
        return ()=> setEditPreferences(false);

    }, [selectFormData]);


    /**
     * Deselect all states and alerts
     *
     * @type {() => void}
     */
    const deselectStatesAndAlertsCallback = useCallback(() => {

        dispatch(GraphActions.barToggleTableView(false, 0));

        dispatch(statesActions.deselectAllStates());

        dispatch(GraphActions.deselectAlertGraph());

    }, [dispatch]);

    /**
     * Update monitoring tree callback
     *
     * @type {() => void}
     */
    const updateMonitoringTreeCallback = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        event.preventDefault();

        event.stopPropagation();

        if (selectedDashboard) {

            dispatch(MonitoringActions.update(
                selectedDashboard.id,
                'sensor',
                sensor.id,
                {
                    sensorChange: {
                        ...sensor,
                        isVisible: !isVisible,
                        position: sensor.position,
                    },
                },
            ));

            dispatch(FormActions.toggle(false));

            setIsVisible(!isVisible);
        }

    }, [selectedDashboard, sensor, dispatch, setIsVisible, isVisible]);

    /**
     * On mouse move callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

                }
            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * On touch start callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchStartCallback = useCallback((event: React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }

        dispatch(GraphActions.barToggleTableView(false, 0));

        dispatch(statesActions.deselectAllStates());

        dispatch(GraphActions.deselectAlertGraph());

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * On touch move callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchMoveCallback = useCallback((event: React.TouchEvent<HTMLDivElement>) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {


                if (dashboardRealTime && offsetWidth - 36 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, offsetWidth]);

    /**
     * On mouse leave callback
     *
     * @type {() => void}
     */
    const onMouseLeaveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());
        }

    }, [dispatch, HMIPlayerStatus]);

    const rightSectionSensorNameStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
    };

    return (
        <div
            className={`item-title no-pointer ${editPreferences ? 'editPreferences' : ''} ${histogramHeight === graphConstants.histogramSmallHeight ? '': isVisible ? 'extended' : ''}`}
        >
            <div
                className="wrap-section"
            >
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-4 limit-padding hidden' : 'right-section sensor-name left-padding-4 '}
                    style={rightSectionSensorNameStyle}
                    onClick={deselectStatesAndAlertsCallback}
                    onTouchStart={deselectStatesAndAlertsCallback}
                >
                    {sensor.isKeyParameter ?
                        <KeyIcon
                            className="key-param tree-icon-item"
                        />
                        : null
                    }
                    <Checkbox
                        icon={
                            <SensorIcon
                                className="sensor tree-icon-item"
                            />
                        }
                        onClick={updateMonitoringTreeCallback}
                        checkedIcon={
                            <ParameterSelectedIcon
                                className="tree-icon-item"
                            />
                        }
                        value="factory"
                        checked={isVisible}
                    />
                    <div
                        className={`right-section-text ${isVisible ? Boolean(histogramHeight) : true}`}
                    >
                        {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                    </div>
                </div>
                <div
                    className={isVisible ? 'left-section with-chart' : 'left-section'}
                    onMouseMove={onMouseMoveCallback}
                    onTouchStart={onTouchStartCallback}
                    onTouchMove={onTouchMoveCallback}
                    onMouseLeave={onMouseLeaveCallback}
                >
                    <span hidden={!isVisible}>
                        <HistogramChart
                            sensor={sensor}
                            sensorTargetValue={sensorTargetValue}
                            hrMode={false}
                        />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(SensorItem);