import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IFilter, IJoin, IOrder, IHmiSchema } from '../interfaces';
import { hmiSchemasService } from '../services';
import { hmiSchemaConstants } from '../constants';
import { AxiosResponse } from 'axios';

/**
 * Unit related actions
 *
 * @type {Object}
 */
export const HmiSchemaAction = {

    /**
     *  Get list States
     *
     * @param { String } search Search string
     * @param { Object } order Sort settings
     * @param { IJoin } join
     * @param { IFilter } filter
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'desc' }, join?: IJoin, filter?: IFilter) => {

        //Action creators
        const success = (hmiSchemas: IHmiSchema[]) => {

            return {
                type: hmiSchemaConstants.LIST_HMI_SCHEMA_SUCCESS,
                schemas: hmiSchemas,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: hmiSchemaConstants.LIST_HMI_SCHEMA_FAILURE,
                errors,
            };
        }, service = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join, filter)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     *  Edit schema
     *
     *
     * @return {Promise<Object>}
     * @param { IHmiSchema } hmiSchemas
     * @param { boolean } clear
     * @param { Blob } picture
     */
    editSchema: (hmiSchemas: IHmiSchema | null, clear = false, picture?: Blob) => {

        //Action creators
        const success = (hmiSchemas: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.EDIT_HMI_SCHEMA_SUCCESS,
                schema: hmiSchemas,
                picture: hmiSchemas.picture,
            };

        }, clearState = () => {

            return {
                type: hmiSchemaConstants.CLEAR_HMI_SCHEMA_STATE,
            };

        }, updateStore = (hmiSchemas: IHmiSchema, picture?: Blob) => {

            return {
                type: hmiSchemaConstants.EDIT_HMI_LOAD_PICTURE_SCHEMA_SUCCESS,
                schema: hmiSchemas,
                picture: picture,
            };

        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            if (picture) {
                hmiSchemas && dispatch(updateStore(hmiSchemas, picture));
            } else {
                if (clear) {
                    dispatch(clearState());
                } else {
                    hmiSchemas && dispatch(success(hmiSchemas));
                }
            }
        };
    },

    /**
     *  Get picture schema
     *
     *
     * @return {Promise<Object>}
     * @param hmiSchemas
     */
    getPicture: (hmiSchemas: IHmiSchema) => {

        //Action creators
        const success = (picture: string) => {

            return {
                type: hmiSchemaConstants.GET_PICTURE_HMI_SCHEMA_SUCCESS,
                schema: hmiSchemas,
                picture: picture,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: hmiSchemaConstants.GET_PICTURE_HMI_SCHEMA_FAILURE,
                errors,
            };
        }, service = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.getPicture(hmiSchemas)
                .then(({ data, headers }: AxiosResponse) => {

                    const contentType = headers['content-type'];

                    const newFile = new File([data], hmiSchemas.picture as string, {
                        type: contentType,
                    });

                    dispatch(success(newFile as unknown as string));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * store cause
     *
     *
     *  @return {Promise<Object>}
     * @param hmiSchema
     */
    store: (hmiSchema: IHmiSchema) => {

        //Action creators
        const success = (hmiSchemaResponse: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.STORE_HMI_SCHEMA_SUCCESS,
                hmiSchema: hmiSchemaResponse,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: hmiSchemaConstants.STORE_HMI_SCHEMA_FAILURE,
                errors,
            };

        }, service = new hmiSchemasService();

        const successHmiForm = (hmiSchemaResponse: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.UPDATE_HMI_SCHEMA_FROM_SUCCESS,
                schema: hmiSchemaResponse,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.store(hmiSchema)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                    dispatch(successHmiForm(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Updated cause
     *
     *
     *  @return {Promise<Object>}
     * @param hmiSchema
     */
    update: (hmiSchema: IHmiSchema) => {

        //Action creators
        const success = (hmiSchemaResponse: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.UPDATE_HMI_SCHEMA_SUCCESS,
                hmiSchema: hmiSchemaResponse,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: hmiSchemaConstants.UPDATE_HMI_SCHEMA_FAILURE,
                errors,
            };

        },successList = (hmiSchemas: IHmiSchema[]) => {

            return {
                type: hmiSchemaConstants.LIST_HMI_SCHEMA_SUCCESS,
                schemas: hmiSchemas,
            };

        }, service = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(hmiSchema)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));

                }).finally(() => {

                    service.list('', { column: 'id', dir: 'desc' })
                        .then(({ data }: AxiosResponse) => {

                            dispatch(successList(data));
                        });
            });
        };
    },

    /**
     * Delete hmiSchema by ID
     *
     *  @return {Promise<Object>}
     * @param hmiSchema
     */
    delete: (hmiSchema: IHmiSchema) => {

        const deleteReason = (hmiSchemaResponse: IHmiSchema) => {

            return {
                type: hmiSchemaConstants.DELETE_HMI_SCHEMA_SUCCESS,
                hmiSchema: hmiSchemaResponse,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: hmiSchemaConstants.DELETE_HMI_SCHEMA_FAILURE,
                errors,
            };

        }, service = new hmiSchemasService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.remove(hmiSchema)
                .then(({ data }: AxiosResponse) => {

                    dispatch(deleteReason(hmiSchema));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

};
