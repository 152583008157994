import { dashboardConstants, formConstants, productConstants } from '../../constants';
import {
    IActiveProductAction,
    IUnitActiveProductsState,
} from '../../interfaces';


/**
 * Unit reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function unitActiveProducts(state: IUnitActiveProductsState = {
    activeProductData: [],
    activeProductDataRange: [],
    loaded: false,
    errors: {},
    lastUpdateProduct: Date.now(),
    activeProductInCurrentTime: [],
}, action: IActiveProductAction): IUnitActiveProductsState {

    switch (action.type) {

        case productConstants.LIST_ACTIVE_PRODUCT_SUCCESS:

            return {
                ...state,
                lastUpdateProduct: Date.now(),
                errors: {},
            };

        case productConstants.LIST_ACTIVE_PRODUCT_FAILURE:

            return {
                ...state,
                errors: action.errors,
            };

        case dashboardConstants.RECEIVE_SENSORS_DATA_LOADED:

            return {
                ...state,
                activeProductDataRange: [],
            };

        case productConstants.RECEIVE_ACTIVE_PRODUCT_DATA:

            return {
                ...state,
                activeProductData: action.range ? action.activeProductData : state.activeProductData,
                activeProductDataRange: !action.range ? action.activeProductData : [],
                loaded: false,
            };

        case productConstants.UPDATE_SENSORS_DATA:

            return {
                ...state,
                activeProductData: action.activeProductData,
                lastUpdateProduct: Date.now(),
            };

        case productConstants.ACTIVE_PRODUCT_IN_CURRENT_TIME_SUCCESS:

            return {
                ...state,
                activeProductInCurrentTime: action.dataCurrentTimeProduct,
            };

        case formConstants.OPEN:
        case formConstants.CLOSE:

            return {
                ...state,
                activeProductInCurrentTime: [],
            };

        case productConstants.ACTIVE_PRODUCT_IN_CURRENT_TIME_FAILURE:

            return {
                ...state,
                activeProductInCurrentTime: [],
            };

        default:

            return state;
    }

}
