import { formConstants, processConstants } from '../../constants';
import { IProcessChangeAction, IProcessChangeState } from '../../interfaces';

/**
 * Process add/update/delete reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function processChange(state: IProcessChangeState = {
    refresh: Date.now(),
    errors: {},
}, action: IProcessChangeAction): IProcessChangeState {

    switch (action.type) {

        case processConstants.BULK_UPDATE_SUCCESS:

            return {
                refresh: Date.now(),
                errors: {},
            };

        case processConstants.BULK_UPDATE_FAILURE:

            return {
                refresh: Date.now(),
                errors: action.errors,
            };

        case formConstants.CLOSE:
            return {
                ...state,
                errors: {},
            };

        default:

            return state;
    }
}
