import React, { FC, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IHmiObject, IHmiSchema } from '../../../core/interfaces';
import { Canvas } from '../../ConfigurationTree/HMI/Editor/canvas/Canvas';
import CanvasObject from '../../ConfigurationTree/HMI/Editor/canvas/CanvasObject';
import { addObjectByType } from '../../ConfigurationTree/HMI/Editor/functions/addObjectByType';
import { appConfig } from '../../../config/appConfig';
import { selectAllSensorInTree } from '../../../core/selectors/monitoringTree/minimapGetActiveSensorInTreeSelector';
import {
    selectIsFullScreenDrawer,
} from '../../../core/selectors/layout/responsiveDrawerSelector';
import {
    selectOpacityScheme,
    selectVisibilityObjectOnScheme,
    selectVisibilitySchemeOnScheme,
} from '../../../core/selectors/hmi/playerSelector';
import { FabricObject } from '../../../base/components/Editor/interfaces';
import { HmiPlayerActions } from '../../../core/actions';
import { selectDashboardOnline } from '../../../core/selectors/dashboard/dashboardSelector';

interface IProps {
    schema: IHmiSchema;
    workareaWidth: number;
    workareaHeight: number;
}

/**
 * HMI Player Schema component
 *
 * @param {IHmiSchema} schema
 * @param {number} workareaWidth
 * @param {number} workareaHeight
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Schema: FC<IProps> = ({ schema, workareaWidth, workareaHeight }: IProps) => {

    const dispatch = useDispatch();

    const canvasRef = useRef<Canvas | null>(null);
    const allSensorInTree = useSelector(selectAllSensorInTree);
    const showObjects = useSelector(selectVisibilityObjectOnScheme);
    const showHmiMap = useSelector(selectVisibilitySchemeOnScheme);
    const isFullScreen = useSelector(selectIsFullScreenDrawer);
    const dashboardRealTime = useSelector(selectDashboardOnline);
    const schemeOpacity = useSelector(selectOpacityScheme);

    const [src, setSrc] = useState<string | null>(null);
    const [width, setWidth] = useState<number>(970);
    const [height, setHeight] = useState<number>(774);

    useEffect(() => {

        if (schema) {

            const schemaImage = new Image();

            schemaImage.onload = () => {
                setWidth(schemaImage.width);
                setHeight(schemaImage.height);
                setSrc(appConfig.hmiApiEndpoint + schema.picture);

            };

            schemaImage.src = appConfig.hmiApiEndpoint + schema.picture;

            dispatch(HmiPlayerActions.setOpacity(schema.opacity));
            dispatch(HmiPlayerActions.setRealTime(dashboardRealTime));
            dispatch(HmiPlayerActions.setValue(new Date().getTime()));
            dispatch(HmiPlayerActions.play());
        }

    }, [schema, dispatch]);


    useEffect(() => {

        const { current } = canvasRef;

        if (current && schema) {

            const { hmiObjects = [] } = schema;

            hmiObjects.forEach((object: IHmiObject) => {

                addObjectByType(current, object);

            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canvasRef, schema, src]);


    useEffect(() => {

        if (canvasRef.current?.canvas && src) {

            canvasRef.current.canvas.setHeight(workareaHeight);
            canvasRef.current.canvas.setWidth(workareaWidth);

            canvasRef.current.handler.zoomHandler.zoomToFit();

        }

    }, [workareaWidth, workareaHeight, canvasRef, src, isFullScreen]);


    useEffect(() => {

        if (canvasRef.current?.canvas && src) {

            canvasRef.current.canvas.getObjects().forEach((value: FabricObject) => {

                if (value.id === 'workarea') {

                    value.visible = showHmiMap;
                    value.opacity = schemeOpacity !== null ? schemeOpacity : schema.opacity;

                } else {

                    value.visible = showObjects;

                }
            });

            canvasRef.current.canvas.requestRenderAll();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showObjects, showHmiMap, schemeOpacity]);


    if (!src) {

        return null;
    }

    return (
        <Canvas
            ref={canvasRef}
            minZoom={30}
            maxZoom={300}
            workareaOptions={{
                src: src,
                width: width,
                height: height,
            }}
            width={workareaWidth}
            height={workareaHeight}
            fabricObjects={CanvasObject}
        />
    );
};

export default Schema;
