import React, { useCallback } from 'react';
import { Input, ListItem } from '@material-ui/core';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { ReactComponent as ProductView } from '../../../assets/images/icons/product-view.svg';
import EditMenu from '../../EditMenu/EditMenu';

interface IDroppableItemBodyProps {
    draggableValue: any;
    droppableItem: Record<string, number | string>;
    editD: any[];
    editItem: any;
    getItemStyle: (a: any, b: any) => any;
    option: any;
    draggableName: string[];
    phone: boolean;
    beacon: boolean;
    activeDraggable: (id: number, name: string) => void;
    index: number;
}

const DroppableItemBody: React.FC<IDroppableItemBodyProps> = ({
                                                                  draggableValue,
                                                                  droppableItem,
                                                                  editD,
                                                                  editItem,
                                                                  getItemStyle,
                                                                  option,
                                                                  draggableName,
                                                                  phone,
                                                                  beacon,
                                                                  activeDraggable,
                                                                  index,
                                                              }: IDroppableItemBodyProps) => {

    /**
     * edit action callback
     *
     * @type {(event) => void}
     */
    const editActionCallback = useCallback((event) => {

        if (editItem && (editItem.product || editItem.unsorted || editItem.category)) {

            if (editItem.editAction && editItem.category) {

                if (editItem.category.id !== droppableItem.id) {

                    editItem.editAction(event, false, 'clean');
                }

            } else {

                editItem.editAction(event, false, 'clean');
            }
        }
    }, [editItem, droppableItem]);

    /**
     * On change callback
     *
     * @type {(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void}
     */
    const onChangeCallback = useCallback((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (editItem.editAction) {

            editItem.editAction(event, false, 'product');
        }

    }, [editItem]);

    /**
     * On key press callback
     *
     * @type {(event: React.KeyboardEvent<HTMLDivElement>) => void}
     */
    const onKeyPressCallback = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {

        if (event.key === 'Enter' && editItem.editAction) {

            editItem.editAction(event, true, 'product');

        }

    }, [editItem]);

    /**
     * Activate Droppable callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const activeDroppableCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        event.stopPropagation();

        activeDraggable(draggableValue.id, 'draggable');

    }, [activeDraggable, draggableValue]);

    return (
        <Draggable
            index={index}
            key={draggableValue.id}
            draggableId={`${draggableValue.id}`}
        >
            {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                <div
                    className={editD.indexOf(draggableValue.id) < 0 ? 'list-draggable__item-wrap' : 'list-draggable__item-wrap active'}
                    style={{
                        position: 'relative',
                    }}
                    onClick={editActionCallback}
                >
                    <ListItem
                        className={'list-draggable__item-include'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                        )}
                    >
                        {!option.colorField ?
                            <ProductView />
                            :
                            <div className="empty-space" />
                        }
                        <span
                            className="item-description-wrap"
                        >
                            {editItem && editItem.product && editItem.product.id === draggableValue.id ?
                                <Input
                                    autoFocus={Boolean(editItem.product.id)}
                                    disableUnderline
                                    value={editItem.product.name}
                                    className={'edit-product ' + (option.errorStatus ? 'error-field' : '')}
                                    inputProps={{
                                        'maxLength': 30,
                                        'minLength': 1,
                                    }}
                                    onChange={onChangeCallback}
                                    onKeyPress={onKeyPressCallback}
                                />
                                :
                                <React.Fragment>
                                    <span>{draggableName.map(value => `${draggableValue[value]} `).join(' ')}</span>
                                    {phone && draggableValue.phone ?
                                        <span>{draggableValue.phone}</span>
                                        : null
                                    }
                                    {beacon && draggableValue.beacon ?
                                        <span>
                                            <p>
                                                <ProductView />
                                                {draggableValue.beacon.name}
                                            </p>
                                        </span>
                                        : null
                                    }
                                </React.Fragment>

                            }
                        </span>
                    </ListItem>
                    <div className="wrap-menu-edit"
                         onClick={activeDroppableCallback}
                    >
                        {option.menu ?
                            <EditMenu
                                menuList={option.menu}
                                dataItem={draggableValue}
                                nodeName={'list'}
                            />
                            : null}
                    </div>
                </div>
            )}
        </Draggable>

    );
};

export default DroppableItemBody;
