import { monitoringTreeConstants, sensorConstants } from '../../constants';
import { IMonitoringTree, IMonitoringTreeAction, IMonitoringTreeState, ISensor } from '../../interfaces';


/**
 * Bar chart description visibility reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function monitoringTree(state: IMonitoringTreeState = {
    arrOfUnit: [],
    errors: {},
    monitoringTree: [],
    sensorsInMonitoringTree: [],
}, action: IMonitoringTreeAction): IMonitoringTreeState {

    switch (action.type) {

        case monitoringTreeConstants.GET_MONITORING_TREE_SUCCESS:

            return {
                ...state,
                monitoringTree: [...action.data],
                errors: {},
            };

            case monitoringTreeConstants.UPDATE_LOCAL_MONITORING_TREE_SUCCESS:

                return {
                ...state,
                monitoringTree: [...action.data],
                errors: {},
            };

        case monitoringTreeConstants.GET_MONITORING_TREE_FAILURE:

            return {
                monitoringTree: [],
                sensorsInMonitoringTree: [],
                arrOfUnit: [],
                errors: action.errors,
            };

        //Remove after checking exposure to other components
        // case sensorConstants.UPDATE_SENSOR_PREFERENCE_SUCCESS:
        // case monitoringTreeConstants.UPDATE_MONITORING_TREE_SUCCESS:
        //
        //     return {
        //         ...state,
        //         refresh: Date.now(),
        //     };

        default:

            return state;
    }
}
