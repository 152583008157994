import React from 'react';
import { IAlertSidebarProps, IAlertSidebarState, INotification } from '../../core/interfaces';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RootState } from '../../core/store';
import { NotificationAction, statesActions } from '../../core/actions';
import {
    AutoSizer,
} from 'react-virtualized';
import { GraphActions } from '../../base/store/actions';

import './AlertSidebar.scss';
import * as d3 from 'd3';
import { selectBrushSelection } from '../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectScreenWidth } from '../../core/selectors/dashboard/dashboardSelector';
import { selectNotificationCount } from '../../core/selectors/notification/notificationSelector';
import moment from 'moment';
import DynamicList, { createCache } from 'react-window-dynamic-list';
import { selectRBAC } from '../../core/selectors/auth/authSelector';
import AlertItemForm from './AlertItemForm';

class AlertSidebar extends React.PureComponent<IAlertSidebarProps & WithTranslation, IAlertSidebarState> {

    constructor(props: IAlertSidebarProps & WithTranslation) {

        super(props);

        const { graphSelection } = this.props;

        this.state = {
            list: [],
            calcLogic: false,
            showCommentInput: undefined,
        };

        this.selectedAlert = graphSelection && graphSelection.alert ? graphSelection.alert.id : undefined;
        this.scrollToIndex = undefined;
        this.notificationHover = undefined;
        this.notificationSelect = undefined;

        this.scaleTime = d3.scaleTime();
        this.cacheDC = createCache();
        this.dynamicListRef = React.createRef();

        this.handleListItemClick = this.handleListItemClick.bind(this);
        this.updateSelectionNotification = this.updateSelectionNotification.bind(this);
        this.renderRowClickHandler = this.renderRowClickHandler.bind(this);
        this.onMouseEnterRow = this.onMouseEnterRow.bind(this);
        this.onMouseLeaveBody = this.onMouseLeaveBody.bind(this);
        this.markAllAsRead = this.markAllAsRead.bind(this);

    }

    componentDidMount() {

        this.updateSelectionNotification();

        // If we are not on the monitoring pages and the selected range is not known to us
        if (!this.props.selection && this.state.list.length === 0) {

            this.props.getNotification(moment().subtract({ d: 1 }).toDate(), new Date());
        }
    }

    componentDidUpdate(prevProps: Readonly<IAlertSidebarProps & WithTranslation>) {

        if (prevProps.graphSelection?.alert !== this.props.graphSelection?.alert) {

            if (this.props.graphSelection?.alert) {

                this.updateSelectionNotification();
            }
        }

        const { selection, getNotification, notifications } = this.props;

        if ((notifications && notifications !== prevProps.notifications) || (this.state.list.length === 0 && notifications)) {
            this.setState({ list: notifications });

        }

        if (selection && prevProps.selection !== selection) {

            getNotification(selection.startDate, selection.endDate);
        }

        /**
         *  Scroll to desired position after selection on bar graph
         */
        if (this.scrollToIndex && this.dynamicListRef) {

            if (this.dynamicListRef.current && !this.state.showCommentInput) {

                setTimeout(() => {
                    this.dynamicListRef.current.scrollToItem(this.scrollToIndex, 'start');
                }, 0);

            }
        }
    }

    componentWillUnmount() {
        this.props.unHoverAlert();
    }


    /**
     * Scale time
     *
     * @type {d3.ScaleTime<number, number>}
     * @private
     */
    private readonly scaleTime: d3.ScaleTime<number, number>;

    /**
     *
     * @type {any}
     * @private
     */
    private readonly cacheDC: any;

    /**
     *
     * @type {React.RefObject<any>}
     * @private
     */
    private readonly dynamicListRef: React.RefObject<any | undefined>;

    private selectedAlert: number | undefined;
    private scrollToIndex: undefined | number;
    private notificationHover: undefined | INotification;
    private notificationSelect: undefined | INotification;

    /**
     * Update Selection
     */
    updateSelectionNotification() {

        if (this.props.graphSelection?.alert) {

            const scrollIndex = this.props.notifications.findIndex(value => this.props.graphSelection?.alert?.id &&
                value.id === this.props.graphSelection?.alert.id);

            if (this.state.list[scrollIndex]?.id && this.state.list[scrollIndex]?.isNew) {
                this.props.markAsReadNotification({ ...this.state.list[scrollIndex], isNew: false });
            }
            const newList = this.state.list;

            if (scrollIndex !== -1) {

                newList[scrollIndex].isNew = false;

            }

            if (!this.notificationSelect) {

                this.selectedAlert = this.props.graphSelection?.alert.id;
                this.scrollToIndex = scrollIndex != -1 ? scrollIndex : undefined;
                this.setState({
                    list: newList,
                });
            }
        }
    }

    /**
     * Click handler
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     * @param {number} index
     */
    handleListItemClick(
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) {
        this.selectedAlert = index;

    }

    /**
     * Row render click handler
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    renderRowClickHandler(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        const { list } = this.state,
            index = event.currentTarget.tabIndex,
            {
                markAsReadNotification,
                selectAlert,
                screenWidth,
                selectionRange,
                unHoverAlert,
                deselectStates,
            } = this.props;

        this.handleListItemClick(event, list[index]?.id || 0);

        if (this.state.showCommentInput && this.state.showCommentInput !== list[index].id) {

            this.setState({ showCommentInput: undefined });
        }

        if (list[index].isNew && list[index].id) {

            markAsReadNotification({ ...list[index], isNew: false });
        }

        if (selectionRange) {
            this.scaleTime.range([0, screenWidth])
                .domain(selectionRange);

            const x1 = this.scaleTime(new Date(list[index].startTime)),
                x2 = this.scaleTime(new Date(list[index].endTime || selectionRange[1]));
            const width = (x2) - (x1);

            if (this.selectedAlert && this.selectedAlert !== list[index].id) {

                selectAlert({ ...list[index], isNew: false }, {
                    left: x1,
                    width: width,
                });
            }
        }

        this.scrollToIndex = undefined;
        this.notificationSelect = list[index];

        if (!this.state.showCommentInput || this.state.showCommentInput !== this.notificationSelect.id) {
            this.forceUpdate();
            unHoverAlert();
            deselectStates();
        }

    }

    /**
     * On Mouse Enter Row
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    onMouseEnterRow(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.preventDefault();

        const { list } = this.state,
            index = event.currentTarget.tabIndex,
            { screenWidth, selectionRange, hoverAlert } = this.props;

        const notification: INotification = list[index];

        if (this.selectedAlert !== notification?.id) {

            if (selectionRange) {
                this.scaleTime.range([0, screenWidth])
                    .domain(selectionRange);
                const [from, to] = selectionRange,
                    x1CorrectTime = new Date(notification.startTime).getTime() < new Date(from).getTime() ?
                        new Date(from) : new Date(notification.startTime);

                const x1 = this.scaleTime(x1CorrectTime),
                    x2 = this.scaleTime(new Date(notification.endTime || to));
                const width = (x2) - (x1);

                hoverAlert(notification, {
                    left: x1,
                    width: width,
                });
            }
            this.scrollToIndex = undefined;
            this.notificationHover = notification;
        }
    }

    /**
     * On Mouse Leave from body
     *
     * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} event
     */
    onMouseLeaveBody(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.preventDefault();

        this.notificationHover = undefined;
        this.notificationSelect = undefined;
    }

    /**
     *  Mark all notifications as read
     */
    markAllAsRead() {

        this.props.markAllAsReadNotification();
    }

    /**
     * Set Show Component Input
     *
     * @param {number | undefined} itemId
     */
    setShowCommentInput(itemId: number | undefined) {

        this.setState({ showCommentInput: itemId });
    }

    /**
     * On mouse leave side bar.
     */
    onMouseLeaveSideBar() {

        this.props.unHoverAlert();

        this.props.deselectStates();
    }

    /**
     * Added reset for selected edit item.
     *
     * @param props
     */
    onScrollResetFunction(props: any) {

        const { showCommentInput } = this.state;

        if (showCommentInput) {
            this.setState({ showCommentInput: undefined });
        }
    }

    render() {

        const { list, showCommentInput } = this.state,
            { unRidNotification, t, rbac, updateComment } = this.props;

        return (
            <React.Fragment>
                <div
                    className="wrap-alert-sidebar"
                    onMouseLeave={this.onMouseLeaveSideBar.bind(this)}
                >
                    <div className="header">{this.props.t('ALERTS')}</div>
                    {unRidNotification > 0 &&
                    <div className="read-all">
                        <div
                            className="read-all-btn"
                            onClick={this.markAllAsRead}
                        >
                            {t('READ_ALL')}
                        </div>
                    </div>
                    }
                    <div
                        className="body"
                        onMouseLeave={this.onMouseLeaveBody}
                    >
                        <AutoSizer>
                            {({ height, width }) => (
                                <DynamicList
                                    ref={this.dynamicListRef}
                                    data={list}
                                    cache={this.cacheDC}
                                    height={height}
                                    width={width}
                                    recalculateItemsOnResize={{ width: false, height: false }}
                                    lazyMeasurement={false}
                                    onScroll={this.onScrollResetFunction.bind(this)}
                                >
                                    {({ index, style }) => (
                                        <>
                                            <div
                                                style={style}
                                                className={`alert-item ${this.selectedAlert === list[index].id ? 'selected' : ''} ${showCommentInput === list[index].id ? 'edit' : ''}`}
                                                onClick={this.renderRowClickHandler}
                                                onMouseEnter={this.onMouseEnterRow}
                                                tabIndex={index}
                                            >
                                                <div className={'alert-item--wrap'}>
                                                    <div className="alert-item--body">
                                                        <div className="alert-item--path">
                                                            <div className="text">
                                                                ...{`/${list[index].unit || ''}/${list[index].sensor || ''}/`}
                                                                <span className={'param'}>
                                                                    {`${list[index].um || ''} ${list[index].operator || ''}`}<span
                                                                    style={{ fontStyle: 'italic' }}
                                                                >
                                                                    {` ${list[index].value}`}
                                                                    </span>
                                                                    <span className={'for'}>
                                                                        {' ' + t('FOR') + ' '}
                                                                    </span>
                                                                    <span style={{ fontStyle: 'italic' }}>
                                                                        {Math.round(list[index].threshold / 60).toFixed(1)}
                                                                        {' ' + t('MINUTES')}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            {list[index]?.isNew ?
                                                                <span className={'read-icon'} /> : null}
                                                        </div>
                                                        <AlertItemForm
                                                            dataAlert={list[index]}
                                                            rbac={rbac}
                                                            showCommentInput={showCommentInput === list[index].id}
                                                            setShowCommentInput={this.setShowCommentInput.bind(this)}
                                                            updateComment={data => updateComment(data)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </DynamicList>
                            )}
                        </AutoSizer>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const {
        graphSelection,
        notification,
    } = state;

    const { notifications } = notification;

    const selectionRange = selectBrushSelection(state),
        screenWidth = selectScreenWidth(state);

    const unRidNotification = selectNotificationCount(state),
        rbac = selectRBAC(state);

    return {
        graphSelection,
        notifications: notifications,
        selection: state.graphPeriod.range,
        selectionRange,
        screenWidth,
        unRidNotification,
        rbac,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    getNotification: NotificationAction.list,
    selectAlert: GraphActions.selectAlertGraph,
    hoverAlert: GraphActions.hoveredAlertGraph,
    unHoverAlert: GraphActions.unHoveredAlertGraph,
    deselectStates: statesActions.deselectAllStates,
    markAsReadNotification: NotificationAction.markAsReadAction,
    markAllAsReadNotification: NotificationAction.markAllAsReadAction,
    updateComment: NotificationAction.updateCommentAction,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlertSidebar));
