import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ConfigurationFlatTreeService, ProcessService } from '../services';
import { configurationConstants, processConstants } from '../constants';
import { IProcess, IErrors, IOrder, IData } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Process related actions
 *
 * @type {Object}
 */
export const ProcessActions = {

    /**
     * Get list of processes
     *
     * @params {String} search Search string
     * @param search
     * @param {Object} order Sort settings
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'asc' }) => {

        //Action creators
        const success = (processes: IProcess[]) => {

            return {
                type: processConstants.LIST_SUCCESS,
                processes,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: processConstants.LIST_FAILURE,
                errors,
            };
        }, service = new ProcessService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Processes bulk update
     *
     * @param {IData[]} processes
     * @param updateConfig
     * @param refreshTree
     *
     * @return {Promise<Object>}
     */
    bulkUpdate: (processes: IData[], updateConfig = false, refreshTree = false) => {

        //Action creators
        const success = () => {

            return {
                type: processConstants.BULK_UPDATE_SUCCESS,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: processConstants.BULK_UPDATE_FAILURE,
                errors,
            };

        }, refreshTreeAction = () => {

            return {
                type: processConstants.REFRESH_TREE,
            };

        }, updateConfigurationTree = (data: any[]) => {
            return {
                type: configurationConstants.UPDATE_CONFIGURATION_PRODUCT_SUCCESS,
                payload: {
                    items: data,
                },

            };
        }, service = new ProcessService(), configurationService = new ConfigurationFlatTreeService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.bulkUpdate(processes)
                .then(() => {

                    dispatch(success());

                    if (refreshTree) {

                        dispatch(refreshTreeAction());
                    }

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                })
                .finally(() => {

                    if (updateConfig && !refreshTree) {

                        /**
                         * After the update is completed, one element of the tree is updated
                         */
                        configurationService.getPartOfList('process', processes[0].id).then(({ data }: AxiosResponse) => {

                            const updatedConfigurationTree = configurationService.updateLocalTree({ type: 'update_part_tree' }, data, 'process');

                            dispatch(updateConfigurationTree(updatedConfigurationTree));
                        });
                    }
                });
        };
    },
};
