import { createSelector } from 'reselect';
import { RootState } from '../../store';

const getAlert = (state: RootState, alertId: number) => {
    return state.notification.notifications.find(value => value?.id === alertId);
};

export const selectHoverAlerts = createSelector(
    [getAlert],
    (notification) => notification,
);
