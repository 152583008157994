import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { GraphActions } from '../../../../../base/store/actions';
import { IDepartmentItemProps } from '../../../../interfaces';

const DepartmentItem: React.FC<IDepartmentItemProps> = (
    {
        department,
        editable,
        peakEnterEmpty,
        maxWidthSideBar,
        peakLeave,
        children,
    }: IDepartmentItemProps
) => {

    /**
     * Callback on peakLeave
     *
     * @type {() => void}
     */
    const peakLeaveCallback = useCallback(() => {

        peakLeave();

    }, [peakLeave]);

    /**
     * Callback on Mouse Move
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        const index = (event.pageX - maxWidthSideBar);
        peakEnterEmpty(index);

    }, [peakEnterEmpty, maxWidthSideBar]);


    return (
        <div
            className={'process-item first-item-on-tree'}
        >
            <React.Fragment>
                <div className="item-title"
                     style={{ display: 'flex' }}
                >
                    <div
                        className={`right-section breadcrumb small-height ${editable ? 'padding-1' : ''}`}
                        style={{
                            transition: 'max-width 0.2s linear',
                            maxWidth: maxWidthSideBar,
                            minWidth: maxWidthSideBar,
                        }}
                        onMouseEnter={peakLeaveCallback}
                    >
                        {/*<Tooltip title={`${factory.name}/${process.name}/${unit.name}`}>*/}
                        <div className="breadcrumb-text without-dot" style={{ zIndex: 2 }}>
                            <div hidden={false}>
                                {/*{!visibleSideBar?'HR Graph:':null} */}
                                {department.id ? department.name || 'name not exist' : 'Not assigned to department'}
                            </div>
                        </div>
                        {/*</Tooltip>*/}
                        {/*{!visibleSideBar ? (*/}
                        {/*    <Can action="product:change">*/}
                        {/*        <ChangeProduct unitId={unit.id} />*/}
                        {/*    </Can>*/}
                        {/*) : null}*/}
                    </div>
                    <div
                        className="left-section product-line"
                        onMouseMove={onMouseMoveCallback}
                    />
                </div>
            </React.Fragment>
            {children}
        </div>
    );
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    peakEnterEmpty: GraphActions.peakEnterEmptyLine,
    peakLeave: GraphActions.peakLeave,
});

export default connect(null, mapDispatchToProps)(DepartmentItem);
