import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';

/**
 * Service to work with auth API resources
 *
 * @class AuthService
 */
export class AuthService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/auth/:action';
    }

    /**
     * Login request
     *
     * @param {string} email User email
     * @param {string} password User password
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    login(email: string, password: string): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url({ 'action': 'login' }), {
                    email: email,
                    password: password,
                }, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Current user profile request
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    profile() {

        return trackPromise(
            this.http
                .get(this.url({ 'action': 'profile' }), {
                    headers: this.headers,
                })
        );
    }

    /**
     * Current user change password request
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    changePassword(oldPassword: string, newPassword: string) {

        return trackPromise(
            this.http
                .post(this.url({ 'action': 'change-password' }), {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                }, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Request link to reset user password by given email
     *
     * @param {string} email User email
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    requestPassword(email: string) {

        return trackPromise(
            this.http
                .post(this.url({ 'action': 'forgot' }), {
                    email: email,
                }, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Set new user password
     *
     * @param {string} password New password
     * @param {string} confirmation Password confirmation
     * @param {string} token Access token
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    setPassword(password: string, confirmation: string, token: string) {

        return trackPromise(
            this.http
                .post(this.url({ 'action': 'set-password' }), {
                    password: password,
                    password2: confirmation,
                    token: token,
                }, {
                    headers: this.headers,
                })
        );
    }
}
