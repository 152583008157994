import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import {
    AlertAction,
    ConfigurationActions,
    EditAlertAction,
    FormActions,
    ProductActions,
    statesActions,
} from '../../../core/actions';
import {
    IAlertRule, IAlertRuleApi,
    IData, IError, IFilter, IFlatTreeUnit, IJoin,
    IMonitoringTreeUnit,
    IOptions, IOrder, IProduct,
    ISensor,
    IUnit,
} from '../../../core/interfaces';
import { Button, ConfirmDialog, InfoModal, Select } from '../../../core/ui/components';
import AlertFormRule from './AlertFormRule';

import './AlertForm.scss';
import { RootState } from '../../../core/store';
import { selectProductByUnitId } from '../../../core/selectors/product/productsSelector';
import { selectAllUnitItemInTree } from '../../../core/selectors/configurationTree/configurationTreeSelector';

interface IFormValues {
    productName: string;
    sensors: ISensor[];
    sensorOrigin: ISensor[];
    rules: IAlertRule[];
}

interface IProps {
    errors: IError;
    formOpened: boolean;
    toggleForm: (opened: boolean, name: string) => void;
    model: IData | null;
    storeAlert: (data: null) => void;
    currentUnit?: IUnit | null;
    alert?: IProduct | null;
    unitInTree?: IMonitoringTreeUnit[];
    updateConfigurationTree: (type: string, item: number, data: { isMinimized: boolean, alertsEnabled: boolean }) => void;
    loadStates: (search?: string, order?: IOrder, join?: IJoin) => void;
    bulkStoreAlertRules: (alertsRules: IAlertRuleApi[]) => void;
    updateAlertRules: (alertsRules: IAlertRuleApi[]) => void;
    deleteAlertRules: (alertsRules: IAlertRuleApi[]) => void;
    loadTree: (trackerLogic?: boolean) => void;
    loadProduct: (search?: string, order?: IOrder, join?: IJoin, filter?: IFilter) => void;
    alertRules: IAlertRuleApi[] | undefined
}

interface IState {
    initialValues: IFormValues;
    selectedUnit: string | number | IOptions;
    selectedProduct: string | number | IOptions;
    unitLabel: string | null;
    productLabel: string | null;
    confirm: boolean;
    confirmInfo: boolean;
    submittedValue: IFormValues | null;
    selectedUnitRules: boolean;
}

/**
 * Alert edit form
 *
 * @class AlertForm
 */
class AlertForm extends React.PureComponent<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, currentUnit, alert, alertRules } = this.props;

        this.state = {
            initialValues: {
                rules: alertRules ? this.alertRulesApiToFormData(alertRules) : [],
                productName: currentUnit?.name || alert?.name ? `${currentUnit?.name}${alert?.name ? `: ${alert?.name}` : ''}` : '',
                sensors: currentUnit && currentUnit.data ? this.sensorToOptions(currentUnit.data) : [],
                sensorOrigin: currentUnit && currentUnit.data ? currentUnit.data : [],
            },
            selectedUnit: currentUnit ? currentUnit.id : '',
            selectedProduct: alert && alert.id ? alert.id : '',
            unitLabel: currentUnit ? currentUnit.name : null,
            productLabel: alert && alert.name ? alert.name : null,
            confirm: false,
            confirmInfo: false,
            submittedValue: null,
            selectedUnitRules: Boolean(currentUnit && currentUnit.id),
        };

        yup.addMethod(yup.string, 'multipleEmails', function(this: yup.StringSchema, msg: string) {

            return this.test({
                name: 'multipleEmails',
                message: msg,
                test: (value) => {

                    if (value) {

                        const emails = value.replace(/\s/g, '').split(',');

                        const emailSchema = yup.string().email();

                        for (const email of emails) {

                            if (!emailSchema.isValidSync(email)) {

                                return false;
                            }
                        }
                    }

                    return true;
                },
            });
        });

        this.validationSchema = yup.object().shape({
            rules: yup
                .array()
                .of(yup.object().shape({
                    sensor: yup
                        .string()
                        .required(t('REQUIRED')),
                    operand: yup
                        .string()
                        .required(t('REQUIRED')),
                    value: yup
                        .string()
                        .trim()
                        .required(t('REQUIRED')),
                    time: yup
                        .number()
                        .typeError(t('VALUE_MUST_BE_AN_INTEGER_NUMBER')),
                    ruleCauseContexts: yup.array(),
                    comment: yup.string()
                        .trim(),
                    send_notifications: yup.boolean(),
                    address: yup
                        .string()
                        .trim()
                        .when('send_notifications', {
                            is: true,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            //@ts-ignore
                            then: yup.string().required(t('ADDRESS_IS_REQUIRED')).multipleEmails(t('ONE_OR_MORE_EMAILS_ARE_NOT_VALID')),
                        }),
                    subject: yup
                        .string()
                        .trim()
                        .when('send_notifications', {
                            is: true,
                            then: yup.string().required(t('SUBJECT_IS_REQUIRED')),
                        }),
                    message: yup
                        .string()
                        .trim()
                        .when('send_notifications', {
                            is: true,
                            then: yup.string().required(t('MESSAGE_IS_REQUIRED')),
                        }),
                })),
            productName: yup.string(),
            sensors: yup.array(),
            sensorOrigin: yup.array(),
        });

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.handleCancelConfirmDialog = this.handleCancelConfirmDialog.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);

        this.selectChangeFunction = this.selectChangeFunction.bind(this);

        this.handleCloseInfoModal = this.handleCloseInfoModal.bind(this);
    }

    componentDidMount() {
        this.props.loadStates('', { column: 'id', dir: 'asc' }, { table: ['causes'] });
    }

    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>, prevState: Readonly<IState>) {

        if (prevState.selectedUnit !== this.state.selectedUnit) {

            this.updateSensorArray();
        }

        if (prevState.unitLabel !== this.state.unitLabel || prevState.productLabel !== this.state.productLabel) {

            this.updateProductName();

        }
    }

    componentWillUnmount() {

        this.props.storeAlert(null);

    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;

    sensorToOptions(data: any[]) {
        const sensors: any[] = [];

        if (data) {

            data.forEach((value: any) => {
                sensors.push({
                    value: value.id,
                    label: value.name,
                });
            });
        }
        return sensors;
    }

    /**
     * Alert form submit handler
     *
     * @param {IFormValues} values
     */
     handleSubmit(values: IFormValues) {

        const { unitInTree, currentUnit, alert } = this.props,
            { selectedUnit } = this.state;

        const unitIndex = unitInTree?.findIndex(unit => unit.id === (currentUnit?.id || selectedUnit));

        const unitForUpdate = unitInTree && unitIndex !== undefined ? unitInTree[unitIndex] : null;

        if (unitForUpdate) {

            if (!alert) {
                this.setState({
                    confirm: !unitForUpdate.alertsEnabled,
                    submittedValue: values,
                    confirmInfo: unitForUpdate.alertsEnabled,
                });
            } else {
                this.setState({
                    confirm: !!alert,
                    submittedValue: values,
                });
            }
        }
    }

    /**
     * User form cancel handler
     */
    handleCancel() {

        this.props.toggleForm(this.props.formOpened, '');

    }

    /**
     * User form cancel handler
     */
    handleCloseInfoModal() {

        this.setState({ confirmInfo: false });

    }

    /**
     * User form cancel handler
     */
    handleCancelConfirmDialog() {

        this.setState({ confirm: false });

    }

    selectedDataUnit(items: IMonitoringTreeUnit[]): IOptions[] {
        const data: IOptions[] = [];
        items.forEach(value => {
            data.push({
                label: value.name,
                value: value.id,
            });
        });

        return data;
    }

    updateSensorArray() {

        const { selectedUnit, initialValues } = this.state;
        const { unitInTree } = this.props;

        if (unitInTree) {
            unitInTree.forEach(value => {
                if (value.id === selectedUnit) {

                    initialValues.sensors = value.data;
                }
            });
        }
    }

    alertRulesToSendData(alertRules: IAlertRule[]): IAlertRuleApi[] {
        
        return alertRules.map(value => {
            return {
                id: value.id || 0,
                sensor: Number(value.sensor),
                operator: value.operand,
                value: Number(value.value.replace(/[^\d.-]/g, '')),
                threshold: Number(value.time),
                unit: this.state.selectedUnit,
                product: this.props.alert!.id,
                sendMail: value.send_notifications,
                mailBody: value.message,
                subject: value.subject,
                emails: value.address,
                comment: value.comment,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                causes: value.ruleCauseContexts? value.ruleCauseContexts.map(status => status?.cause?.id || status?.id || status) : [],
            };
        }) as IAlertRuleApi[];
    }

    alertRulesApiToFormData(alertRules: IAlertRuleApi[]): IAlertRule[] {
        return alertRules.map(value => {
            return {
                id: value.id,
                sensor: value.sensor && 'id' in value.sensor ? value.sensor.id as number : '',
                operand: value.operator,
                value: String(value.value).replace(/[^\d.-]/g, '') || '',
                time: value.threshold,
                ruleCauseContexts: value.ruleCauseContexts,
                comment: value.comment || '',
                send_notifications: value.sendMail,
                address: value.emails || '',
                subject: value.subject || '',
                message: value.mailBody || '',
            };
        }) as IAlertRule[];
    }


    updateProductName() {

        const { initialValues, unitLabel, productLabel } = this.state;

        initialValues.productName = unitLabel ? `${unitLabel}${productLabel ? `: ${productLabel}` : ''}` : '';

        this.setState({ initialValues: initialValues });
    }

    /**
     * Handler create KPI
     */
    onConfirmDialog() {
        const {
                updateConfigurationTree,
                bulkStoreAlertRules,
                unitInTree,
                deleteAlertRules,
                updateAlertRules,
                alert,
                currentUnit,
                alertRules = [],
            } = this.props,
            { submittedValue, selectedUnit } = this.state;


        if (submittedValue && submittedValue.rules && submittedValue.rules.length === 0) {

            if (unitInTree) {

                const unitIndex = unitInTree?.findIndex(unit => unit.id === (currentUnit?.id || selectedUnit));
                const unitForUpdate = unitInTree && unitIndex !== undefined ? unitInTree[unitIndex] : null;

                if (unitForUpdate) {

                    updateConfigurationTree('unit', unitForUpdate.id, {
                        isMinimized: unitForUpdate.isMinimized,
                        alertsEnabled: true,
                    });
                }
            }

        } else if (submittedValue && alert) {

            const { rules = [] } = submittedValue;

            if (alertRules && alertRules.length !== 0 && (rules?.length > alertRules.length || rules?.length < alertRules.length || rules?.length === alertRules.length)) {
                //update delete create

                const differenceData = this.difference(this.alertRulesToSendData(this.alertRulesApiToFormData(alertRules)), this.alertRulesToSendData(rules));

                if (differenceData.modifiedArr.length > 0) {

                    updateAlertRules(differenceData.modifiedArr);
                }

                if (differenceData.deleteArr.length > 0) {

                    deleteAlertRules(differenceData.deleteArr);
                }

                if (differenceData.create.length > 0) {

                    bulkStoreAlertRules(differenceData.create);
                }

                const loadProductTimeout = setTimeout(()=>{

                    this.props.loadProduct('', { column: 'id', dir: 'asc' }, { table: ['alertRules', 'alertRules.unit', 'alertRules.sensor', 'alertRules.ruleCauseContexts', 'alertRules.ruleCauseContexts.cause', 'category'] }, undefined);

                    clearTimeout(loadProductTimeout);

                }, 200);
            }

            if (alertRules && rules?.length > 0 && alertRules.length === 0) {
                //create

                bulkStoreAlertRules(this.alertRulesToSendData(rules));

                const loadProductTimeout = setTimeout(()=>{

                    this.props.loadProduct('', { column: 'id', dir: 'asc' },
                        { table: ['alertRules', 'alertRules.unit', 'alertRules.sensor', 'alertRules.ruleCauseContexts', 'alertRules.ruleCauseContexts.cause', 'category'] },
                        undefined);

                    clearTimeout(loadProductTimeout);

                }, 200);
            }

        }

        const loadTreeTimeout = setTimeout(() => {

            this.props.loadTree(true);

            this.props.toggleForm(this.props.formOpened, '');

            clearTimeout(loadTreeTimeout);

        }, 200);

    }

    difference(oldArr: IAlertRuleApi[], newArr: IAlertRuleApi[]) {

        const modifiedArr = Array.from(newArr).filter((newAlertApi) => oldArr.some((oldAlertApi) => newAlertApi.id === oldAlertApi.id &&
            newAlertApi !== oldAlertApi)),
            deleteArr = Array.from(oldArr).filter((newAlertApi) => !newArr.some((oldAlertApi) => newAlertApi.id === oldAlertApi.id)),
            crateArr = Array.from(newArr).filter(value => !value.id || value.id === 0);

        return {
            modifiedArr: modifiedArr,
            deleteArr: deleteArr,
            create: crateArr,
        };
    }

    selectChangeFunction(event: React.ChangeEvent<Record<string, unknown>>) {

        this.setState({
            selectedUnit: event.target.value as string,
            selectedProduct: '',
            unitLabel: event.currentTarget.innerText as string,
            productLabel: null,
        });
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, unitInTree, alert } = this.props,
            { initialValues, selectedUnit, selectedProduct, productLabel, unitLabel, selectedUnitRules } = this.state;

        return (
            <div className={'alert-form'}>
                <h2 className="form-header-heavy">{t('CONFIGURE_ALERT_RULES')}
                    <span>{unitLabel ? ` (${unitLabel}${productLabel?`: ${productLabel}` : ''})` : ''}</span>
                </h2>
                <div className="tip">{t(productLabel && unitLabel ?
                    'TO_SHOW_THIS_ALERTS_YOU_MUST_TURN_ON_THE_GRAPHS_FOR_THE_CORRESPONDING_PARAMS'
                    :
                    'YOU_WILL_CONFIGURE_RULES_AT_THE_PRODUCT_SECTION'
                )}
                </div>
                {!(alert) ?
                    <div className="select-wrap form-group select">
                        <Select
                            className={'form-field '
                            +
                            (selectedUnit ? 'success-field' : '')}
                            options={unitInTree ? this.selectedDataUnit(unitInTree) : []}
                            placeholder={t('SELECT_UNIT')}
                            label={t('SELECT_UNIT')}
                            value={selectedUnit}
                            displayEmpty
                            disabled={selectedUnitRules}
                            onChange={this.selectChangeFunction}
                            inputProps={{ 'aria-label': 'Without label' }}
                        />
                    </div>
                    :
                    null
                }
                {selectedProduct && selectedUnit ?
                    <React.Fragment>
                        <div className="row-subheader">{t('TRIGGER_THE_ALERT_IF')}</div>
                        <hr />
                    </React.Fragment>
                    :
                    null
                }
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate style={{ paddingBottom: 80 }}>
                            {/*{props.setFieldValue('sensors', 12)}*/}
                            {selectedProduct && selectedUnit &&
                            <FieldArray name="rules" component={AlertFormRule} />
                            }
                            <div className="button-row flex-end space-2x">
                                <Button type="button" color="primary" onClick={this.handleCancel}>
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="secondary" disabled={!unitLabel}>
                                    {t('SAVE')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
                <ConfirmDialog
                    heading={this.props.t('DO_YOU_ACKNOWLEDGE_ALERT_CHANGES?')}
                    onAccept={this.onConfirmDialog}
                    onClose={this.handleCancelConfirmDialog}
                    open={this.state.confirm}
                />
                <InfoModal
                    onClose={this.handleCloseInfoModal}
                    open={Boolean(this.state.confirmInfo)}
                >
                    {this.props.t('YOU_ALREADY_HAVE_AN_ALERT_FOR_THIS_UNIT')}
                </InfoModal>
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { form, editAlert } = state;

    const { formOpened } = form,
        { unit, alert } = editAlert;
    const errors = {};

    const currentProductAlert = alert && selectProductByUnitId(state, alert.id!);
    let alertRules: IAlertRuleApi[] | undefined = [];

    if (currentProductAlert) {

        alertRules =  currentProductAlert.alertRules
            ?.filter(alertRule => (alertRule.unit as IUnit).id === unit?.id)
            ?.sort((a, b) => {
                return new Date(a.createdAt!).getTime() < new Date(b.createdAt!).getTime() ? -1 : 1;
            });

    }

    const unitInTree = selectAllUnitItemInTree(state) as IFlatTreeUnit[];

    return {
        formOpened,
        errors,
        currentUnit: unit,
        alert: currentProductAlert,
        unitInTree,
        alertRules,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
    storeAlert: EditAlertAction.store,
    updateConfigurationTree: ConfigurationActions.update,
    loadStates: statesActions.list,
    bulkStoreAlertRules: AlertAction.bulkStore,
    updateAlertRules: AlertAction.update,
    deleteAlertRules: AlertAction.delete,
    loadTree: ConfigurationActions.list,
    loadProduct: ProductActions.list,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AlertForm));