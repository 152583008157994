import ApiProvider from '../../../core/providers/apiProvider';
import { appConfig } from '../../../config/appConfig';
import { AxiosResponse } from 'axios';
import UrlPattern from 'url-pattern';

export class HrApiProvider extends ApiProvider {
    protected apiEndpoint = appConfig.hrApiEndpoint;
    protected baseApiEndpoint = appConfig.apiEndpoint;

    /**
     * Get API resource URL from given params and pattern
     *
     * @param {object} params URL params (optional)
     * @param {string} pattern URL pattern (optional)
     *
     * @return {string}
     */
    baseUrl(params = {}, pattern = null): string {

        return this.baseApiEndpoint + (
            new UrlPattern(pattern || this.urlPattern)
        ).stringify(params);
    }

    get formDataHeaders() {
        return {
            'Content-Type': 'multipart/form-data',
        };
    }

    protected getDataExtractor = <T>() => ({ data }: AxiosResponse<T>): T => {
        return data;
    }
}
