import React, { useCallback, useEffect } from 'react';
import { makeStyles, Popover, Radio, RadioGroup, withStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { ReactComponent as VerticalHmiIcon } from '../../../core/ui/assets/images/icons/verticalHmi.svg';
import { ReactComponent as HorizontalHmiIcon } from '../../../core/ui/assets/images/icons/horizontalHmi.svg';
import Slider from '@material-ui/core/Slider';
import Checkbox from '@material-ui/core/Checkbox';
import clsx from 'clsx';

import './styles/HmiParameters.scss';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutActions } from '../../../core/actions/layout';
import {
    selectOpacityScheme,
    selectVisibilityObjectOnScheme,
    selectVisibilitySchemeOnScheme,
} from '../../../core/selectors/hmi/playerSelector';
import { HmiPlayerActions } from '../../../core/actions';
import { isMobileOnly } from 'react-device-detect';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const OpacitySlider = withStyles({
    root: {
        height: 2,
        color: '#3880ff',
        padding: '15px 0',
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        boxShadow: iOSBoxShadow,
        marginTop: -10,
        marginLeft: -10,
        '&:focus, &:hover, &$active': {
            boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                boxShadow: iOSBoxShadow,
            },
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 12px)',
        top: -22,
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        height: 2,
    },
    rail: {
        height: 2,
        opacity: 0.5,
        backgroundColor: '#bfbfbf',
    },
    mark: {
        backgroundColor: '#bfbfbf',
        height: 8,
        width: 1,
        marginTop: -3,
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
    markLabel: {
        marginLeft: '50px',
        '&:nth-last-child(2)': {
            marginLeft: '-50px',
        },
    },
})(Slider);


const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#106ba3',
        },
    },
});

/**
 * Format slider value
 *
 * @param {number} value
 *
 * @return {string}
 */
function valueLabelFormat(value: number): string {

    return `${Math.ceil(value * 100)} %`;
}


const HmiParameter: React.FC<any> = ({ ...props }) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const schemeOpacity = useSelector(selectOpacityScheme);

    const [showHmi, setShowHmi] = React.useState('right');

    const showObjects = useSelector(selectVisibilityObjectOnScheme);
    const showHmiMap = useSelector(selectVisibilitySchemeOnScheme);


    /**
     * Handle Minimap visibility switch
     */
    const showHmiSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>, value: string) => {

        setShowHmi(event.target.value);

        dispatch(LayoutActions.drawerPosition(event.target.value));

    }, [setShowHmi, dispatch]);

    /**
     * On zoom method slider
     *
     * @param {any} e
     * @param {any} val
     * @returns {void}
     */
    const onOpacityChange = useCallback((e: any, val: any): void => {

        dispatch(HmiPlayerActions.setOpacity(val));

    }, [dispatch]);

    /**
     * Stop Propagation handler
     */
    const stopPropagationOnClick = useCallback((event) => {

        event.stopPropagation();

    }, []);

    /**
     * Checkbox change action handler for show object.
     *
     * @type {(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void}
     */
    const onChangeShowObject = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

        dispatch(HmiPlayerActions.showObjectOnScheme());

    }, [dispatch]);

    /**
     * Checkbox change action handler for show HMI map
     *
     * @type {(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void}
     */
    const onChangeShowHmiMap = useCallback((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {

        dispatch(HmiPlayerActions.showHmiScheme());

    }, [dispatch]);

    const stopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.stopPropagation();
    }, []);

    return (
        <>
            <Popover
                {...props}
                keepMounted
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        marginTop: 15,
                        border: '1px solid #d3d4d5',
                        padding: '17px 0 17px 0',
                        overflow: 'hidden',
                    },
                }}
            >
                <div
                    className="parameter-body"
                    onClick={stopPropagation}
                >
                    {!isMobileOnly &&
                        <div className="parameter-section show-hmi">
                            <div className="parameter-section-title">{t('Show HMI')}</div>
                            <div className="parameter-section-body">
                                <RadioGroup
                                    value={showHmi}
                                    onChange={showHmiSwitch}
                                >
                                    <FormControlLabel
                                        className={`field-checkbox ${showHmi === 'vertical' ? 'checked' : ''}`}
                                        control={
                                            <Radio
                                                value="right"
                                                name="right"
                                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                icon={<span className={classes.icon} />}
                                            />
                                        }
                                        label={
                                            <span className="wrapper-icon">
                                                  <span className="label-title">{t('Right')}</span>
                                                  <span className="label-title-icon"><VerticalHmiIcon /> </span>
                                            </span>
                                        }
                                    />
                                    <FormControlLabel
                                        className={`field-checkbox ${showHmi === 'horizontal' ? 'checked' : ''}`}
                                        control={
                                            <Radio
                                                value="bottom"
                                                name="bottom"
                                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                icon={<span className={classes.icon} />}
                                            />
                                        }
                                        label={
                                            <span className="wrapper-icon">
                                              <span className="label-title">{t('Down')}</span>
                                              <span className="label-title-icon"><HorizontalHmiIcon /></span>
                                            </span>
                                        }
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    }
                    <div className="parameter-section show-object">
                        <div className="parameter-section-title">{t('Show Objects')}</div>
                        <div className="parameter-section-body">
                            <FormControlLabel
                                className="field-checkbox"
                                onClick={stopPropagationOnClick}
                                control={
                                    <Checkbox
                                        value="showObjects"
                                        name="showObjects"
                                        checked={showObjects}
                                        onChange={onChangeShowObject}
                                        color="primary"
                                        icon={<span className="checkbox-icon" />}
                                    />
                                }
                                label={t('Show Objects')}
                            />
                            <FormControlLabel
                                className="field-checkbox"
                                onClick={stopPropagationOnClick}
                                control={
                                    <Checkbox
                                        value="showHmiMap"
                                        name="showHmiMap"
                                        checked={showHmiMap}
                                        onChange={onChangeShowHmiMap}
                                        color="primary"
                                        icon={<span className="checkbox-icon" />}
                                    />
                                }
                                label={t('Show HMI Map')}
                            />
                        </div>
                    </div>
                    <div className="parameter-section hmi-opacity">
                        <div className="parameter-section-title">{t('HMI map opacity')}</div>
                        <div className="parameter-section-body">
                            <OpacitySlider
                                valueLabelDisplay="on"
                                valueLabelFormat={valueLabelFormat}
                                value={schemeOpacity !== null ? schemeOpacity : 1}
                                min={0}
                                max={1}
                                onChange={onOpacityChange}
                                step={0.01}
                            />
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default HmiParameter;
