import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as KeyIcon } from '../../../../core/ui/assets/images/icons/key.svg';
import { ReactComponent as SensorIcon } from '../../../../core/ui/assets/images/icons/parameter-v-2.svg';
import { ReactComponent as StateIcon } from '../../../../core/ui/assets/images/icons/state_icon.svg';

import {
    IMonitoringTreeSensorProps,
    ISensorTargetValueState,
} from '../../../../core/interfaces';
import { statesActions } from '../../../../core/actions';
import { HistogramChart, StateChart } from '../../../../base/components';
import { isMobile } from 'react-device-detect';
import { selectHistogramHeight } from '../../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../../../../core/selectors/graphBarHeight/graphBarHeightSelector';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import { selectForm } from '../../../../core/selectors/form/formSelector';


/**
 * Monitoring tree sensor functional component
 *
 * @param {any} sensor
 * @param {any} unit
 * @param {any} process
 * @param {any} factory
 * @param {any} keySensor
 * @param {number} histogramHeight
 * @param {boolean} visibleSideBar
 * @param {number} maxWidthSideBar
 *
 * @param activeProductData
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Sensor: React.FC<IMonitoringTreeSensorProps> = (
    {
        sensor,
        unit,
        process,
        factory,
        keySensor,
        visibleSideBar,
        maxWidthSideBar,
        activeProductData,
    }: IMonitoringTreeSensorProps,
) => {
    const [delay, setDelay] = useState(true);

    const [sensorTargetValue, setSensorTargetValue] = useState<ISensorTargetValueState | undefined>(undefined);
    const [editPreferences, setEditPreferences] = useState(false);

    const histogramHeight = useSelector(selectHistogramHeight);

    const graphHeight = useSelector(selectGraphBarHeight);
    const selectFormData = useSelector(selectForm);

    const dispatch = useDispatch();

    const { targetValues } = sensor;

    /**
     * Stop click even propagation
     *
     * @param {React.MouseEvent} event
     */
    const stopClickPropagation = useCallback((event: React.MouseEvent) => {

        event.stopPropagation();
    }, []);

    useEffect(() => {

        if (selectFormData?.formName === 'histogramForm' && selectFormData?.formModel) {

            if (selectFormData.formModel.sensor === sensor.id) {

                setEditPreferences(true);

            }
        }
        return ()=> setEditPreferences(false);

    }, [selectFormData]);


    /**
     * Rendering delay
     */
    useEffect(() => {

        const delayTimeout = setTimeout(() => setDelay(false), 100);

        return ()=>clearTimeout(delayTimeout);

    }, [sensor]);

    useEffect(() => {

        if (activeProductData && targetValues) {

            const activeProducts = activeProductData.activeProducts;

            const sensorTargetValueData = {
                activeProducts: activeProducts,
                targetValues: targetValues,
            };
            setSensorTargetValue(sensorTargetValueData);
        }

    }, [activeProductData, targetValues]);

    /**
     * deselect states
     * @type {() => void}
     */
    const deselectStates = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        event.preventDefault();

        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);


    useEffect(() => {

        setDelay(true);

    }, [sensor]);

    const skeletonStyle = {
        lineHeight: 'inherit',
        borderBottom: '1px solid #e9ecef',
    };

    if (sensor && sensor.isVisible) {

        /**
         * Rendering delay
         */
        if (delay) {

            return (
                <div key={'sensor-key-' + sensor.id}>
                    <div className={`item-title no-pointer ${editPreferences ? 'editPreferences' : ''}`}>
                        <div
                            className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                        >
                            {sensor.sensorType === 'state' ?
                                (
                                    <React.Fragment>
                                        <StateIcon className="default-icon-size m8" />
                                        <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{sensor.name}</span>
                                    </React.Fragment>
                                )
                                :
                                (
                                    <React.Fragment>
                                        {sensor.isKeyParameter ?
                                            <KeyIcon className="key-param default-icon-size" />
                                            : null
                                        }
                                        <SensorIcon className="sensor default-icon-size m8" />
                                        <span className={`sensor-name ${sensor.name.length > 35 ? 'rtl' : ''} ${isMobile ? 'is-mobile' : ''}`}>
                                            {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                                        </span>
                                    </React.Fragment>
                                )
                            }
                        </div>
                        <div
                            className="left-section with-chart"
                        >
                            <SkeletonTheme color={'#ffffff'} >
                                <Skeleton height={sensor.sensorType === 'state' ? Number(graphHeight) : histogramHeight} style={skeletonStyle} />
                            </SkeletonTheme>
                        </div>
                    </div>
                </div>
            );
        }

        const rightSectionSensorNameStyle: CSSProperties = {
            maxWidth: maxWidthSideBar,
            minWidth: maxWidthSideBar,
            transition: 'max-width 0.2s linear',
            height: sensor.sensorType === 'state' ? Number(graphHeight) : histogramHeight,
        };

        return (
            <div key={'sensor-key-' + sensor.id} onClick={deselectStates}>
                <div className={`item-title no-pointer ${editPreferences ? 'editPreferences' : ''}`}>
                    <div
                        className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                        style={rightSectionSensorNameStyle}
                        onClick={deselectStates}
                    >
                        {sensor.sensorType === 'state' ?
                            (
                                <React.Fragment>
                                    <StateIcon className="default-icon-size m8" />
                                    <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{sensor.name}</span>
                                </React.Fragment>
                            )
                            :
                            (
                                <React.Fragment>
                                    {sensor.isKeyParameter ?
                                        <KeyIcon className="key-param default-icon-size" />
                                        : null
                                    }
                                    <SensorIcon className="sensor default-icon-size m8" />
                                    <span className={`sensor-name ${sensor.name.length > 35 ? 'rtl' : ''} ${isMobile ? 'is-mobile' : ''}`}>
                                        {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                                    </span>
                                </React.Fragment>
                            )
                        }
                    </div>
                    <div
                        className={sensor.isVisible ? 'left-section with-chart' : 'left-section'}
                        style={{ height: sensor.sensorType === 'state' ? graphHeight : histogramHeight }}
                        onClick={stopClickPropagation}
                    >
                        {sensor.sensorType === 'graph' ?
                            <HistogramChart
                                sensor={sensor}
                                sensorTargetValue={sensorTargetValue}
                                hrMode={false}
                            />
                            :
                            null
                        }

                        {sensor.sensorType === 'state' ?
                            <StateChart
                                sensor={sensor}
                                currentUnit={unit}
                                tableTitle={`${factory.name}/${process.name}/${unit.name}`}
                                keySensor={keySensor}
                                hrMode={false}
                            />
                            : null
                        }
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

export default React.memo(Sensor);
