import { editorConstants as baseEditorConstants } from '../../../../../base/components/Editor/constants';

export const editorConstants = {
    interaction: { ...baseEditorConstants.interaction, ...{
            modeEngine: 'engine',
            modeSensor: 'sensor',
            modeValve: 'valve',
            modeIndicator: 'indicator',
            modeRectangle: 'rectangle',
            modeOval: 'oval',
            modeTriangle: 'triangle',
            modeStar: 'star',
            modePitCode: 'pitCode',
        } },
    objects: { ...baseEditorConstants.objects, ...{
            engine: 'engine',
            sensor: 'sensor',
            valve: 'valve',
            indicator: 'indicator',
            rectangle: 'rectangle',
            oval: 'oval',
            triangle: 'triangle',
            star: 'star',
            draw: 'draw',
            pitCode: 'pitCode',
        } },
    colors: { ...baseEditorConstants.colors },
    typeToId: {
        engine: 1,
        sensor: 4,
        valve: 3,
        indicator: 2,
        rectangle: 5,
        oval: 6,
        triangle: 7,
        star: 8,
        draw: 9,
    },
};