import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertAction, FormActions, NotificationAction, statesActions } from '../../../core/actions';
import { graphConstants } from '../../../core/constants';
import {
    IActiveProducts,
    IChartAlert,
    INotification,
    ISensor,
    IStateData,
    IStateItem,
} from '../../../core/interfaces';
import RuleText from '../../../core/ui/components/Board/RuleText';

import settingsIcon from '../../../core/ui/assets/images/settings.png';

import '../../../core/ui/components/Board/styles/Chart.scss';
import { GraphActions } from '../../store/actions';
import { commentCanvasPath, unreadCanvasPath } from '../../helpers/histogramIconDraw';
import { useUpdateScale } from '../../../hooks/histogramChart/useUpdateScale';
import { selectHistogramHeight } from '../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import { selectGraphBarHeight } from '../../../core/selectors/graphBarHeight/graphBarHeightSelector';
import { selectScreenWidth } from '../../../core/selectors/dashboard/dashboardSelector';
import { selectBrushSelection } from '../../../core/selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { selectHistogramMode } from '../../../core/selectors/graphHistogramMode/graphHistogramModeSelector';
import { useGenerateStateIntervals } from '../../../hooks/histogramChart/useGenerateStateIntervals';
import { selectRBAC } from '../../../core/selectors/auth/authSelector';
import { selectMaxWidthSideBar } from '../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import {
    selectGraphSelectionAlert,
    selectGraphSelectionAlertHover,
} from '../../../core/selectors/graphSelection/graphSelectionSelector';
import * as d3 from 'd3';
import { IChartDataWithColor } from '../../../hooks/histogramChart/useDataHistogram';
import moment from 'moment';
import { selectAllNotification } from '../../../core/selectors/notification/notificationSelector';
import { selectHmiPlayerMode } from '../../../core/selectors/hmi/playerSelector';


interface IProps {
    forceMix?: boolean;
    dataState?: IStateData;
    sensor: ISensor;
    sensorTargetValue?: {
        activeProducts: IActiveProducts[],
        targetValues: {
            maxTargetValue: number | null,
            minTargetValue: number | null,
            productId: number;
            sensorId: number;
        }[]
    };
    hrMode?: boolean;
}

/**
 * A D3 Bar chart component
 *
 * @class HistogramChart
 */
const HistogramChart: React.FC<IProps> = (
    {
        sensor,
        sensorTargetValue,
        hrMode = false,
        forceMix = false,
        dataState,
    }: IProps,
) => {
    const dispatch = useDispatch();
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const alertRef = useRef<HTMLCanvasElement | null>(null);
    const chartWrapperRef = useRef<HTMLDivElement | null>(null);
    const [chartContext, setChartContext] = useState<CanvasRenderingContext2D | null>(null);
    const [alertContext, setAlertContext] = useState<CanvasRenderingContext2D | null>(null);
    const [mixMode, setMixMode] = useState<boolean>(forceMix);
    const [barWidth, setBarWidth] = useState<number>(1);
    const [supportsTouch, setSupportsTouch] = useState<boolean>(forceMix);
    const [selectedAlert, setSelectedAlert] = useState<any | null>(null);
    const [
        scaleLogPositive,
        scaleLogNegative,
        scaleLinear,
        positiveValuesHeight,
        negativeValuesHeight,
        minY,
        maxY,
        scale,
        bottomY,
        topY,
        alertPointArr,
        dataWithAlertColor,
        alertData,
    ] = useUpdateScale(sensor, forceMix, hrMode, mixMode);

    const [statesIntervalTotal, statesPerInterval] = useGenerateStateIntervals(dataState?.states || []);

    const histogramHeight = useSelector(selectHistogramHeight),
        stateHeight = useSelector(selectGraphBarHeight),
        screenWidth = useSelector(selectScreenWidth),
        selection: Date[] | undefined = useSelector(selectBrushSelection),
        brushSelection = useSelector(selectBrushSelection),
        mode = useSelector(selectHistogramMode),
        negativeValuesColor = '#bdd7e7',
        rbac = useSelector(selectRBAC),
        maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        graphSelectionAlert = useSelector(selectGraphSelectionAlert),
        graphSelectionAlertHover = useSelector(selectGraphSelectionAlertHover),
        notifications = useSelector(selectAllNotification),
        sideBarLogic = JSON.parse(localStorage.getItem('sidebar') as string),
        scaleTime: d3.ScaleTime<number, number> = d3.scaleTime(),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    useEffect(() => {

        checkTouchSupport();

        if (chartRef.current) {

            chartRef.current.width = screenWidth - (selection && new Date(selection[1]) > (new Date()) ? 36 : 0);
            chartRef.current.height = height;

            setChartContext(chartRef.current.getContext('2d'));
        }

        if (alertRef.current) {

            const height = forceMix ? stateHeight : histogramHeight;

            alertRef.current.width = screenWidth - (selection && new Date(selection[1]) > (new Date()) ? 36 : 0);
            alertRef.current.height = height;

            setAlertContext(alertRef.current.getContext('2d'));
        }

    }, []);

    const drawAlertIcon = useCallback((value: IChartAlert, endPoint: number, histogramHeight: number) => {

        if (value.comment && alertContext) {

            commentCanvasPath(alertContext, (endPoint + 4) * barWidth, histogramHeight - 16);
        }

    }, [alertContext, barWidth]);

    const unReading = useCallback((value: IChartAlert, endPoint: number, histogramHeight: number, graphSelection?: any) => {

        const currentNotification = notifications.find(value1 => value1.id === value.notificationId);

        if (currentNotification && currentNotification.isNew && graphSelection?.id !== value.notificationId && alertContext) {

            unreadCanvasPath(alertContext, (endPoint * barWidth) + (value.comment && value.comment.length > 0 ? 14 : 4), histogramHeight - 16);

        }

    }, [alertContext, barWidth, notifications]);

    /**
     * Draw dashed line
     * @type {(from: number, to: number) => void}
     */
    const drawDashedLine = useCallback((from: number, to: number) => {

        if (alertContext) {

            alertContext.beginPath();

            alertContext.setLineDash([2, 5]);

            alertContext.moveTo(from, histogramHeight - 20);

            alertContext.lineWidth = 1;

            alertContext.lineTo(to, histogramHeight - 20);

            alertContext.strokeStyle = '#ff0000';

            alertContext.stroke();

            alertContext.closePath();
        }
    }, [alertContext, histogramHeight]);

    /**
     * Creating an alert highlight area
     */
    const createNotificationTitle = useCallback(() => {

        if (alertContext && selection) {

            alertContext.clearRect(0, 0, screenWidth, histogramHeight);

            scaleTime.range([0, screenWidth])
                .domain(selection);

            const [from, to] = selection;

            const letteringLogic = moment(to).diff(from, 'd') < 7;

            let prevX1: number | undefined = undefined,
                prevX2: number | undefined = undefined;

            alertData.forEach((value) => {

                const { startTime, endTime } = value,
                    newDate = new Date();

                const selectionEndTime = new Date(to).getTime() > newDate.getTime() ? newDate : to;

                const x1 = scaleTime(new Date(startTime)),
                    x2 = scaleTime(new Date(endTime ? endTime : selectionEndTime));

                const currentX1: number = (x1 - 50) * barWidth,
                    currentX2: number = (x2 + 50) * barWidth;

                drawAlertIcon(value, x2, histogramHeight);

                unReading(value, x2, histogramHeight, graphSelectionAlert);

                // alert rectangle
                alertContext.fillStyle = 'rgba(235, 68, 75, 0.2)';

                alertContext.fillRect(x1, 0, x2 - x1 >= 1 ? x2 - x1 : 1, histogramHeight);

                // start/end line for Alert
                alertContext.beginPath();
                alertContext.setLineDash([]);
                alertContext.strokeStyle = 'rgba(235, 68, 75, 0.2)';
                alertContext.lineWidth = 1;
                alertContext.moveTo(x1, 0);
                alertContext.lineTo(x1, histogramHeight);
                alertContext.moveTo(x2, 0);
                alertContext.lineTo(x2, histogramHeight);
                alertContext.stroke();
                alertContext.closePath();

                if (letteringLogic) {

                    if ((!prevX1 && !prevX2) || (prevX1 && prevX1 > currentX2)) {

                        drawDashedLine(currentX1, currentX2);

                        prevX1 = currentX1;
                        prevX2 = currentX2;

                    } else if (prevX1 && prevX2 && prevX2 > currentX2 && prevX1 < currentX2) {

                        drawDashedLine(currentX1, prevX1);

                        prevX1 = currentX1;
                        prevX2 = currentX2;
                    }

                    // Adding a label over alert
                    alertContext.fillStyle = 'rgba(235, 68, 75, 1)';

                    alertContext.font = 'italic normal 12px IBM Plex Sans sans-serif';
                    alertContext.lineWidth = 2;
                    alertContext.fillText(`${value.value} ${value.um}`, x2 + 10, histogramHeight - 22);
                }

            });

        }


    }, [
        alertContext,
        barWidth,
        drawAlertIcon,
        graphSelectionAlert,
        histogramHeight,
        screenWidth,
        unReading,
        alertData,
        scaleTime,
        selection,
        drawDashedLine,
    ]);

    /**
     * Creating an alert highlight area
     */
    const createWrapRectangle = useCallback((notification: any) => {

        if (notification && selection) {

            scaleTime.range([0, screenWidth])
                .domain(selection);

            const { startTime, endTime } = notification as unknown as INotification;

            const x1 = scaleTime(new Date(startTime)), x2 = scaleTime(new Date(endTime || selection[1]));
            const width = (x2) - (x1);
            const wrapRectangleLogic = alertData.find(value => notification &&
                (value.notificationId === notification?.id || value.notificationId === notification?.notificationId));

            if (wrapRectangleLogic && alertContext) {

                // alertContext.restore();
                alertContext.setLineDash([1, 0]);

                alertContext.lineWidth = 2;

                alertContext.strokeStyle = '#ae0094';

                alertContext.strokeRect((x1) + 1, 1, width - 2, histogramHeight - 2);

            }

        }

    }, [
        alertContext,
        histogramHeight,
        screenWidth,
        scaleTime,
        selection,
        alertData,
    ]);


    useEffect(() => {

        createNotificationTitle();

        graphSelectionAlert && createWrapRectangle(graphSelectionAlert);
        graphSelectionAlertHover && createWrapRectangle(graphSelectionAlertHover);

        // Temporarily disabled because it blocked deselection.

        // selectedAlert && createWrapRectangle(selectedAlert);

    }, [selectedAlert, createNotificationTitle, createWrapRectangle, alertData, dataWithAlertColor, graphSelectionAlert, graphSelectionAlertHover]);


    /**
     * Check supports touch
     */
    const checkTouchSupport = () => {

        if ('ontouchstart' in window) {

            //iOS & android
            setSupportsTouch(true);

            // this.props.peakEnterTouch({ touches: [{ clientX: 440 }] }, this.supportsTouch, this.props.data);
        }
    };

    /**
     *
     * @TODO: temp method to find a state by peak index. Review once backend will be ready
     */
    const getStateByPeak = useCallback((index: number) => {

        const peakInterval = index * (statesIntervalTotal / (screenWidth));

        const result = statesPerInterval.find(s => s.start !== null && s.start <= peakInterval && s.end !== null && s.end > peakInterval);

        return result ? result.state : null;
    }, [screenWidth, statesIntervalTotal, statesPerInterval]);

    /**
     * Get Y-coordinate from a dataset value
     *
     * @param {number} value
     *
     * @return {number}
     */
    const getYFromValue = useCallback((value: number) => {

        if ((!mixMode && mode === graphConstants.histogramModeLogarithmic) || ((!mode || mode === 'default') && scale === graphConstants.histogramModeLogarithmic)) {

            if (value > 0) {

                return positiveValuesHeight - scaleLogPositive(value);
            }

            return positiveValuesHeight;
        }

        const domain = scaleLinear.domain();

        return domain[1] > 0 ? positiveValuesHeight - Math.max(0, scaleLinear(value)) : 0;
    }, [scale, scaleLinear, mixMode, mode, positiveValuesHeight, scaleLogPositive]);

    /**
     * Render chart peak
     *
     * @param {number} index
     * @param {number} y
     * @param {number} height
     * @param {string} color
     * @param {string} key
     *
     * @return {JSX.Element}
     */
    const renderPeak = useCallback((index: number, y: number, height: number, color: string) => {

        if (chartContext) {

            chartContext.fillStyle = color;

            chartContext.fillRect(barWidth * index, y, barWidth, height);

        }

    }, [chartContext, barWidth]);


    /**
     * Generate chart peaks
     *
     * @param {IChartData} data
     * @param {number} index
     *
     * @return {JSX.Element}
     */
    const generatePeaks = useCallback((data: IChartDataWithColor, index: number) => {

        // Checking the display of peaks in the wrong perspective
        if (new Date(data.timestamp) > new Date()) {
            return null;
        }

        let defaultMode = '';

        if (sensor.graphPreferences && sensor.graphPreferences[0]) {

            defaultMode = sensor.graphPreferences[0].scale;

        }

        let y = getYFromValue(data.value),
            currentPeaksColor = data.color;

        const height = forceMix ? stateHeight : histogramHeight,
            modeOverlappingLogic = ((mode === 'default') && defaultMode === graphConstants.histogramModeOverlapping) ||
                (!mixMode && mode === graphConstants.histogramModeOverlapping);

        if ((data.value >= 0 && minY && minY > 0 && data.value < minY) ||
            (data.value <= 0 && minY && maxY && minY < 0 && maxY < 0 && data.value > maxY) ||
            (data.value > 0 && minY && maxY && minY < 0 && maxY < 0 && !modeOverlappingLogic)
        ) {

            return null;
        }

        if (mixMode) {

            const state = getStateByPeak(index);

            currentPeaksColor = state && (state.color || state.zoneColor || '#e0e0e0') ?
                (state.color || state.zoneColor || '#e0e0e0') : '#e0e0e0';

        }

        let peakHeight = 0;

        if ((!mixMode && mode === graphConstants.histogramModeLogarithmic) || ((mode === 'default') && defaultMode === graphConstants.histogramModeLogarithmic)) {

            if (data.value !== 0) {

                peakHeight = Math.abs(data.value > 0 ? scaleLogPositive(data.value) : scaleLogNegative(data.value));

            }

            // For negative range and mode histogramModeLogarithmic
            if (minY !== null && maxY !== null && minY < 0 && maxY < 0) {

                y = 0;

            }

        } else if (data.value) {

            peakHeight = Math.abs(scaleLinear(data.value));

        }
        if ((!mixMode && mode !== graphConstants.histogramModeOverlapping) ||
            ((!mode || mode === 'default') && defaultMode !== graphConstants.histogramModeOverlapping)) {
            if (data.value < 0 && minY === null && maxY !== null && peakHeight > negativeValuesHeight) {

                peakHeight -= positiveValuesHeight;
            }
        }

        if ((!mixMode && mode === graphConstants.histogramModeOverlapping) || modeOverlappingLogic) {

            // Checking for data display restrictions at a set maximum and minimum range
            if ((data.value > 0 && maxY !== null && maxY < 0 && data.value < Math.abs(maxY)) ||
                (data.value < 0 && minY !== null && minY > 0 && Math.abs(data.value) < minY)) {

                return;
            }

            const negativeDataPositiveMeaning = data.value > 0 && minY && maxY && minY <= 0 && maxY <= 0,
                positiveDataPositiveMeaning = data.value < 0 && minY && ((maxY && minY >= 0 && maxY > 0) ||
                    (minY >= 0));

            const currentValue = negativeDataPositiveMeaning ?
                data.value - (Math.abs(maxY!) * 2) :
                positiveDataPositiveMeaning ? data.value + (Math.abs(minY!) * 2) :
                    data.value;

            peakHeight = Math.abs(scaleLinear(currentValue));

            const negativeHeight = negativeValuesHeight !== 0 ?
                negativeValuesHeight :
                positiveValuesHeight,
                positiveHeight = positiveValuesHeight !== 0 ?
                    positiveValuesHeight :
                    negativeValuesHeight;

            const overlappingHeight = (data.value < 0 ? negativeHeight : positiveHeight);

            if ((positiveValuesHeight === height || negativeValuesHeight === height) && data.value < 0) {

                y = 0;

                if (minY !== null) {

                    currentPeaksColor = negativeValuesColor;
                }

            }

            if (positiveValuesHeight === height && data.value >= 0) {

                y = positiveValuesHeight - peakHeight;
            }

            if (peakHeight > positiveValuesHeight || peakHeight > negativeValuesHeight) {

                const overlapCount = Math.floor((peakHeight - overlappingHeight) / overlappingHeight) + 1,
                    peaks = [];

                let newHeight = overlappingHeight;

                const unsignedData = Math.abs(data.value);

                const overlapDrawLogic = ((minY !== null || maxY !== null) &&
                    (unsignedData > (maxY! <= 0 ? Math.abs(maxY!) : Math.abs(minY!)))) ||
                    (minY! < 0 && maxY! > 0);

                // if maxY = 0 and data negative
                if (maxY !== null && maxY === 0 && data.value > 0) {

                    y = (data.value < 0 ? negativeHeight : positiveHeight) - peakHeight;
                }

                peaks.push(renderPeak(index, y, peakHeight, currentPeaksColor));

                if (overlapDrawLogic) {

                    if (data.value <= 0) {

                        currentPeaksColor = negativeValuesColor;
                    }

                    for (let i = 0; i < overlapCount; i++) {

                        newHeight = peakHeight - overlappingHeight;

                        if (newHeight >= overlappingHeight) {

                            newHeight = overlappingHeight;

                        } else if (newHeight < 0) {

                            newHeight = peakHeight - overlappingHeight;
                        }

                        if (peakHeight !== newHeight && newHeight !== 0 && peakHeight > newHeight) {
                            peakHeight -= newHeight;
                        }

                        currentPeaksColor = colorTransform(currentPeaksColor, -10);

                        y = data.value < 0 ?
                            positiveValuesHeight >= 0 ? positiveValuesHeight : 0
                            :
                            negativeValuesHeight >= 0 ?
                                height - newHeight - negativeValuesHeight
                                :
                                height - newHeight;

                        if (data.value > 0 && (maxY || maxY === 0) && maxY <= 0) {

                            y = height - newHeight;
                        }

                        if ((maxY || maxY === 0) && (minY || minY === 0) && data.value < 0 && minY < maxY) {

                            if ((minY >= 0 && maxY > 0) || (minY <= 0 && maxY < 0)) {

                                y = 0;

                            }
                        }

                        peaks.push(renderPeak(index, y, newHeight, currentPeaksColor), false);
                    }

                }

                return peaks;
            }

        }


        renderPeak(index, y, peakHeight, currentPeaksColor);


    }, [
        forceMix,
        getStateByPeak,
        getYFromValue,
        histogramHeight,
        maxY,
        minY,
        mixMode,
        mode,
        negativeValuesHeight,
        positiveValuesHeight,
        renderPeak,
        scaleLinear,
        scaleLogNegative,
        scaleLogPositive,
        sensor,
        stateHeight,
    ]);

    /**
     * Draw a horizontal line for the target value
     *
     * @param {number} x1
     * @param {number} x2
     * @param {number} y
     * @param {string} color
     */
    const drawLine = useCallback((x1: number, x2: number, y: number, color: string) => {

        if (chartContext) {
            chartContext.beginPath();
            chartContext.moveTo(x1, y);
            chartContext.strokeStyle = color;
            chartContext.lineWidth = 0.5;
            chartContext.lineTo(x2, y);
            chartContext.stroke();
        }
    }, [chartContext]);


    /**
     * Rendering target value in overlap mode
     *
     * @param {number} x1
     * @param {number} x2
     * @param {number} YLine
     * @param {number} histogramHeight
     * @param {number} targetValue
     * @param {boolean} minMaxLogic
     */
    const newYLine = useCallback((
        x1: number,
        x2: number,
        YLine: number,
        histogramHeight: number,
        targetValue: number,
        minMaxLogic: boolean,
    ) => {
        const negativeHeight = negativeValuesHeight !== 0 ?
            negativeValuesHeight :
            positiveValuesHeight,
            positiveHeight = positiveValuesHeight !== 0 ?
                positiveValuesHeight :
                negativeValuesHeight;

        let maxYLine = Math.abs(scaleLinear(targetValue)),
            y: number;

        const overlapCount = Math.floor((maxYLine - (targetValue < 0 ?
            negativeHeight : positiveHeight)) / (targetValue < 0 ?
            negativeHeight : positiveHeight)) + 1;

        if (positiveValuesHeight > 0 && negativeValuesHeight > 0) {

            y = targetValue > 0 ? positiveHeight - YLine : positiveHeight + YLine;

        } else {

            y = targetValue < 0 ? YLine : positiveHeight - YLine;
        }


        let newYLine = (targetValue <= 0 ? negativeHeight : positiveHeight);

        for (let i = 0; i < overlapCount; i++) {

            newYLine = maxYLine - (i > 0 ? histogramHeight : (targetValue < 0 ? negativeHeight : positiveHeight));

            if (newYLine > histogramHeight) {

                newYLine = histogramHeight;

            } else if (newYLine < 0) {

                newYLine = maxYLine - (targetValue <= 0 ? negativeHeight : positiveHeight);
            }

            maxYLine -= newYLine;

            if (positiveValuesHeight > 0 && negativeValuesHeight > 0) {

                y = targetValue > 0 ? newYLine ? positiveHeight - newYLine : 0 :
                    newYLine ? positiveHeight + newYLine : positiveHeight;

            } else {

                y = targetValue < 0 ? newYLine : positiveHeight - newYLine;
            }

            if (newYLine < (negativeHeight || positiveHeight)) {

                return drawLine(x1, x2, y, minMaxLogic ? 'red' : 'yellow');

            }
        }


        if (overlapCount === 0) {

            if (targetValue !== 0) {

                if (topY <= 0 && bottomY < 0 && targetValue >= 0) {

                    y = YLine;
                }

                return drawLine(x1, x2, y, minMaxLogic ? 'red' : 'yellow');

            } else if (targetValue === 0) {

                return drawLine(x1, x2, histogramHeight - negativeValuesHeight, minMaxLogic ? 'red' : 'yellow');
            }
        }
    }, [
        bottomY,
        drawLine,
        negativeValuesHeight,
        positiveValuesHeight,
        scaleLinear,
        topY,
    ]);

    /**
     * Rendering target values for active products
     */
    const drawTargetValue = useCallback(() => {

        const defaultMode = mode === 'default' ? sensor.graphPreferences && sensor.graphPreferences[0].scale : mode;

        if (selection) {
            scaleTime.range([0, screenWidth])
                .domain(selection);

            if (sensorTargetValue) {

                sensorTargetValue.activeProducts.forEach(activeProduct => {

                    const currentTarget = sensorTargetValue.targetValues && sensorTargetValue.targetValues.find(targetValue =>
                        targetValue.productId && activeProduct.product && targetValue.productId === activeProduct.product.id,
                    );

                    if (currentTarget) {

                        const { minTargetValue, maxTargetValue } = currentTarget,
                            startTime = new Date(activeProduct.startTime) > new Date(selection[0]) ? new Date(activeProduct.startTime) : new Date(selection[0]),
                            x1 = scaleTime(startTime),
                            x2 = scaleTime(new Date(activeProduct.endTime || selection[1]));

                        if (defaultMode === graphConstants.histogramModeOverlapping || mode === graphConstants.histogramModeOverlapping) {

                            if (defaultMode === graphConstants.histogramModeOverlapping) {
                                if ((maxTargetValue || maxTargetValue === 0)) {

                                    const checkingTheDisplayOfMaxTargetValue = (topY > 0 && bottomY >= 0 && Math.abs(maxTargetValue) >= bottomY) ||
                                        (topY <= 0 && bottomY < 0 && Math.abs(maxTargetValue) >= Math.abs(topY)) ||
                                        (topY > 0 && bottomY < 0);

                                    if (checkingTheDisplayOfMaxTargetValue) {

                                        const positiveDataNegativeMeaning = maxTargetValue > 0 && minY && maxY && minY <= 0 && maxY < 0,
                                            negativeDataPositiveMeaning = maxTargetValue < 0 && minY && maxY && minY > 0 && maxY >= 0;

                                        const maxYLine = Math.abs(scaleLinear(
                                            negativeDataPositiveMeaning ? maxTargetValue + (Math.abs(minY!) * 2) :
                                                positiveDataNegativeMeaning ? maxTargetValue - Math.abs(maxY!) : maxTargetValue,
                                        ));

                                        newYLine(
                                            x1,
                                            x2,
                                            maxYLine,
                                            histogramHeight,
                                            maxTargetValue,
                                            true,
                                        );
                                    }
                                }

                                if (minTargetValue || minTargetValue === 0) {

                                    const checkingTheDisplayOfMinTargetValue = (topY > 0 && bottomY >= 0 && Math.abs(minTargetValue) > bottomY) ||
                                        (topY < 0 && bottomY <= 0 && Math.abs(minTargetValue) > Math.abs(topY)) ||
                                        (topY > 0 && bottomY <= 0);

                                    if (checkingTheDisplayOfMinTargetValue) {

                                        const positiveDataNegativeMeaning = minTargetValue > 0 && minY && maxY && minY <= 0 && maxY < 0,
                                            negativeDataPositiveMeaning = minTargetValue < 0 && minY && maxY && minY > 0 && maxY >= 0;

                                        const minYLine = Math.abs(scaleLinear(
                                            negativeDataPositiveMeaning ? minTargetValue + (Math.abs(minY!) * 2) :
                                                positiveDataNegativeMeaning ? minTargetValue - Math.abs(maxY!) : minTargetValue,
                                        ));

                                        newYLine(
                                            x1,
                                            x2,
                                            minYLine,
                                            histogramHeight,
                                            minTargetValue,
                                            false,
                                        );
                                    }
                                }
                            }

                        } else {

                            if ((maxTargetValue || maxTargetValue === 0) && (!maxY || (maxY && maxY >= maxTargetValue))) {
                                if ((maxY && maxY >= maxTargetValue) || maxTargetValue) {

                                    let maxYLine = Math.abs(scaleLinear(maxTargetValue));

                                    if (maxTargetValue < 0 && maxY && minY && maxY > 0 && minY < 0) {

                                        maxYLine = getYFromValue(maxYLine);

                                    } else {

                                        maxYLine = positiveValuesHeight - maxYLine;

                                    }

                                    if (minY && minY > maxTargetValue) {

                                        return null;
                                    }

                                    drawLine(x1, x2, maxYLine, 'red');
                                }
                            }

                            if ((minTargetValue || minTargetValue === 0) && (!minY || (minY && minY <= minTargetValue))) {

                                if ((minY && minY <= minTargetValue) || minTargetValue) {

                                    let minYLine = Math.abs(scaleLinear(minTargetValue));

                                    if (positiveValuesHeight >= 0 && minTargetValue && minTargetValue > 0) {

                                        minYLine = positiveValuesHeight - Math.abs(minYLine);

                                    }

                                    if ((!maxY || (maxY && maxY > 0)) && positiveValuesHeight >= 0 && minTargetValue && minTargetValue < 0) {

                                        minYLine = positiveValuesHeight + Math.abs(minYLine);

                                    }

                                    drawLine(x1, x2, minYLine, 'yellow');
                                }
                            }
                        }
                    }
                });

            }
        }
    }, [
        bottomY,
        drawLine,
        getYFromValue,
        histogramHeight,
        maxY,
        minY,
        mode,
        newYLine,
        positiveValuesHeight,
        scaleLinear,
        scaleTime,
        screenWidth,
        selection,
        sensor,
        sensorTargetValue,
        topY,
    ]);


    /**
     * Update chart with new data
     */
    const updateChart = useCallback(() => {

        if (selection && brushSelection && chartContext) {

            if (dataWithAlertColor) {

                const [from, to] = selection;

                const renderedData = dataWithAlertColor
                    .filter(value =>
                        new Date(value.timestamp).getTime() > new Date(from).getTime() &&
                        new Date(value.timestamp).getTime() < new Date(to).getTime());
                
                setBarWidth(!isFinite(Math.round((screenWidth - (selection && new Date(selection[1]) > (new Date()) ? 36 : 0)) / renderedData.length)) ?
                    barWidth : Math.round((screenWidth - (selection && new Date(selection[1]) > (new Date()) ? 36 : 0)) / renderedData.length));

                const height = forceMix ? stateHeight : histogramHeight;

                chartContext.clearRect(0, 0, screenWidth, height);

                renderedData.forEach((d: IChartDataWithColor, index: number) => {

                    generatePeaks(d, index);
                });


                drawTargetValue();
            }
        }
    }, [
        dataWithAlertColor,
        selection,
        chartContext,
        brushSelection,
        screenWidth,
        stateHeight,
        histogramHeight,
        barWidth,
        setBarWidth,
        generatePeaks,
        forceMix,
        drawTargetValue,
    ]);

    useEffect(() => {

        if (dataWithAlertColor) {

            updateChart();
        }

    }, [dataWithAlertColor, updateChart]);

    /**
     * Make a given color lighten/darken
     *
     * @param {string} color
     * @param {number} percent
     *
     * @return {string}
     */
    const colorTransform = (color: string, percent: number) => {

        const original = parseInt(color.substring(1), 16),
            amt = Math.round(2.55 * percent),
            R = (original >> 16) + amt,
            G = ((original >> 8) & 0x00FF) + amt,
            B = (original & 0x0000FF) + amt;

        return '#' + (0x1000000 +
            ((R < 255 ? R < 1 ? 0 : R : 255) * 0x10000) +
            ((G < 255 ? G < 1 ? 0 : G : 255) * 0x100) +
            (B < 255 ? B < 1 ? 0 : B : 255)
        ).toString(16).slice(1);
    };

    /**
     * Show state details, when it have a comment
     *
     * @param state
     */
    const showStateDetails = useCallback((state: IStateItem | null) => {

        if (state) {

            dispatch(statesActions.toggleStateDetails(true, state));

        } else {

            dispatch(statesActions.toggleStateDetails(false));
        }
    }, [dispatch]);

    /**
     * Hide graph settings icon
     */
    const hideStateDetails = useCallback((event) => {

        event.preventDefault();

        if (mixMode) {

            showStateDetails(null);

        }

        if (!supportsTouch) {

            if (HMIPlayerStatus === 'stop') {

                dispatch(GraphActions.peakLeave());
            }
        }

        dispatch(AlertAction.toggleStateDetails(false));

    }, [dispatch, mixMode, showStateDetails, supportsTouch, HMIPlayerStatus]);

    /**
     * Show graph settings edit form
     */
    const editSettings = useCallback((event) => {

        event.preventDefault();
        event.stopPropagation();

        if (sensor.graphPreferences && sensor.graphPreferences[0]) {

            const preference = sensor.graphPreferences[0];

            preference['sensor'] = sensor.id;

            dispatch(FormActions.toggle(false, 'histogramForm', { ...preference, sensorName: sensor.name }));
        }
    }, [dispatch, sensor]);


    /**
     * Toggle histogram mix mode with state chart
     */
    const toggleMixMode = useCallback((event) => {

        event.preventDefault();

        if (!forceMix && sensor.isKeyParameter && dataState) {

            if (mixMode) {

                showStateDetails(null);
            }

            setMixMode(!mixMode);
        }
    }, [mixMode, setMixMode, sensor, forceMix, dataState, showStateDetails]);

    /**
     * Show state details, when it have a comment
     *
     * @param index
     */
    const showAlertDetails = useCallback((index: number) => {

        if (alertPointArr.length > 0) {

            if (selection) {

                let selectedItem: null | number = null;

                const currentTime = scaleTime.range([0, screenWidth])
                    .domain(selection).invert(index);

                alertData.forEach((value) => {
                    const showLogic = new Date(value.startTime).getTime() <= new Date(currentTime).getTime() &&
                        new Date(value.endTime || new Date()).getTime() >= new Date(currentTime).getTime();

                    if (showLogic) {

                        selectedItem = index;

                        dispatch(AlertAction.toggleStateDetails(true, value));
                    }

                    if (!showLogic && selectedItem === null) {

                        dispatch(AlertAction.toggleStateDetails(false));
                    }
                });
            }
        }

    }, [dispatch, alertPointArr, selection, scaleTime, screenWidth, alertData, barWidth]);

    /**
     * Handling Alert Selection in state
     *
     * @param { index } index
     */
    const selectedAlertCallback = useCallback((index: number) => {


        let selectedA: any | null = null;

        if (selection){
            scaleTime.range([0, screenWidth])
                .domain(selection);

            const pointDate = scaleTime.invert(index);

            alertPointArr.forEach((value, indexAlertPointArr) => {

                const startTime = new Date(value.startTime).getTime(),
                    endTime = new Date(value.endTime).getTime(),
                    pointDateTimestamp = pointDate.getTime();

                if ((startTime <= pointDateTimestamp && endTime >= pointDateTimestamp) && !mixMode) {

                    selectedA = value;

                    dispatch(FormActions.toggle(false, 'alert-sidebar'));

                    if (value.isNew) {

                        alertPointArr[indexAlertPointArr] = { ...value, isNew: false };

                        dispatch(NotificationAction.markAsReadAction({
                            ...value as unknown as INotification,
                            isNew: false,
                        }));

                    }
                }
            });

            if (!selectedA) {

                dispatch(GraphActions.deselectAlertGraph());
            }

            if (selectedA) {

                const x1 = scaleTime(new Date(selectedA.startTime)),
                    x2 = scaleTime(new Date(selectedA.endTime || selection[1]));
                const width = (x2) - (x1);

                dispatch(GraphActions.selectAlertGraph(selectedA, {
                    left: x1,
                    width: width,
                }));
            }
        }

        setSelectedAlert(selectedA);

    }, [dispatch, setSelectedAlert, alertPointArr, mixMode, scaleTime, screenWidth, selection]);

    /**
     * On click handler
     *
     * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
     */
    const onClickCanvasHandler = useCallback((event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {

        event.preventDefault();

        const index = (event.pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar));

        selectedAlertCallback(index);

    }, [maxWidthSideBar, sideBarLogic, selectedAlertCallback]);

    /**
     * Оn mouse move handler
     *
     * @param {React.MouseEvent<HTMLCanvasElement, MouseEvent>} event
     */
    const onMouseMoveCanvasHandler = useCallback((event: React.MouseEvent<HTMLCanvasElement | HTMLDivElement, MouseEvent>) => {

        event.preventDefault();

        const index = (event.pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar));

        //this one is too heavy. Try to do anything around, when backend will be ready
        if (mixMode) {

            const state = getStateByPeak(index);

            if (state) {
                showStateDetails(state);
            }
        }

        if (alertData.length) {

            showAlertDetails(index);
        }

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakEnter(index, supportsTouch));
        }

    }, [
        dispatch,
        supportsTouch,
        alertData,
        showStateDetails,
        getStateByPeak,
        maxWidthSideBar,
        sideBarLogic,
        showAlertDetails,
        mixMode,
        HMIPlayerStatus,
    ]);


    /**
     * On touch start handler
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    const onTouchStartCanvasHandler = useCallback((event: React.TouchEvent<HTMLCanvasElement | HTMLDivElement>) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakEnterTouch((event.touches[0].pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar)), supportsTouch));
        }

    }, [dispatch, maxWidthSideBar, supportsTouch, sideBarLogic, HMIPlayerStatus]);

    /**
     * On touch move handler
     *
     * @param {React.TouchEvent<HTMLCanvasElement>} event
     */
    const onTouchMoveCanvasHandler = useCallback((event: React.TouchEvent<HTMLCanvasElement | HTMLDivElement>) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakEnterTouch((event.touches[0].pageX - (sideBarLogic ? 76 : 320 || maxWidthSideBar)), supportsTouch));
        }

    }, [dispatch, supportsTouch, maxWidthSideBar, sideBarLogic, HMIPlayerStatus]);

    /**
     * deselect states
     * @type {() => void}
     */
    const deselectStates = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        event.preventDefault();

        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);

    const height = forceMix ? stateHeight : histogramHeight;
    
    return (
        <div
            className="chart-wrapper"
            ref={chartWrapperRef}
            onClick={deselectStates}
            onDoubleClick={toggleMixMode}
            onMouseLeave={hideStateDetails}
        >
            <RuleText hrMode={hrMode} data={dataWithAlertColor || []}  sensor={sensor} />
            {rbac.can('histogram-setting:update') && !hrMode && !forceMix ? (
                <div
                    className="graph-settings"
                    onClick={editSettings}
                    onMouseMove={onMouseMoveCanvasHandler}
                    onTouchStart={onTouchStartCanvasHandler}
                    onTouchMove={onTouchMoveCanvasHandler}
                >
                    <img src={settingsIcon} alt="icon" />
                </div>
            ) : null}
            <canvas
                ref={chartRef}
                className="chart"
                width={screenWidth - (selection && new Date(selection[1]) > (new Date()) ? 36 : 0)}
                height={height}
            />
            <canvas
                ref={alertRef}
                className="chart-alert"
                width={screenWidth - (selection && new Date(selection[1]) > (new Date()) ? 36 : 0)}
                height={height}
                onClick={onClickCanvasHandler}
                onMouseMove={onMouseMoveCanvasHandler}
                onTouchStart={onTouchStartCanvasHandler}
                onTouchMove={onTouchMoveCanvasHandler}
            />
        </div>
    );
};

export default React.memo(HistogramChart);