import React, { FC, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CropFreeIcon from '@material-ui/icons/CropFree';
import TuneIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import { GraphActions } from '../../../base/store/actions';
import { HmiPlayerActions, HmiSchemaAction } from '../../../core/actions';
import { selectHmiPlayerVisibility } from '../../../core/selectors/hmi/visibilitySelector';
import { selectHmiPlayerSchema } from '../../../core/selectors/hmi/playerSelector';
import { RootState } from '../../../core/store';
import { IHmiSchema } from '../../../core/interfaces';
import { HmiPlayerWatcher } from '../../../core/watchers/hmiPlayer';
import ResponsiveDrawer from '../../../core/ui/components/Layout/ResponsiveDrawer';
import Schema from './Schema';
import ControlPanel from './ControlPanel';

import './styles/Player.scss';
import HmiParameters from './HmiParameters';
import {
    selectDrawerHeight, selectIsFullScreenDrawer,
    selectPositionDrawer,
} from '../../../core/selectors/layout/responsiveDrawerSelector';
import { LayoutActions } from '../../../core/actions/layout';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { selectMaxWidthSideBar } from '../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { usePrevious } from '../../../hooks/usePrevious';

interface IProps {
    isVisible: boolean;
    schema: IHmiSchema | null,
    toggleHMI: (isVisible: boolean) => void;
    setSchema: (schema: IHmiSchema | null) => void;
    stopPlayer: () => void;
}

const DEFAULT_WIDTH = window.innerWidth;

/**
 * HMI Player component
 *
 * @param {boolean} isVisible
 * @param {IHmiSchema} schema
 * @param {function} toggleHMI
 * @param {function} setSchema
 * @param {function} stopPlayer
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Player: FC<IProps> = ({ isVisible, schema, toggleHMI, setSchema, stopPlayer }: IProps) => {

    const dispatch = useDispatch();

    const hmiParametersBtnRef = useRef<null | HTMLButtonElement>(null);
    const [hmiWrapperRef, setHmiWrapperRef] = useState<null | HTMLElement>(null);
    const [hmiParametersMenuAnchor, setParametersHmiMenuAnchor] = useState<null | HTMLButtonElement>(null);


    const anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom';
    const drawerParam = useSelector(selectDrawerHeight);
    const isFullScreen = useSelector(selectIsFullScreenDrawer) || false;
    const barWidth = useSelector(selectMaxWidthSideBar);

    const preAnchor = usePrevious(anchor);
    const preIsFullScreen = usePrevious(isFullScreen);
    const preBarWidth = usePrevious(barWidth);

    useEffect(() => {

        const watcher = new HmiPlayerWatcher();

        return () => {

            watcher.unsubscribe();
        };

    }, []);

    //TODO: Implemement resize for workarea width and height
    const schemaContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {

        setParametersHmiMenuAnchor(null);

    }, [anchor]);

    /**
     * Handle drawer close event
     */
    function onClose() {

        toggleHMI(isVisible);
        setSchema(null);
        dispatch(LayoutActions.drawerToggleFullScreen(false));
        dispatch(HmiPlayerActions.setRealTime(false));
        dispatch(HmiSchemaAction.editSchema(null,  true));

        stopPlayer();
    }
    /**
     * Close HMI menu handler
     */
    const closeHmiMenu = useCallback(() => {

        setParametersHmiMenuAnchor(null);

    }, [setParametersHmiMenuAnchor]);


    /**
     * Update
     */
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const runForceUpdate = () => {
        const forceUpdateTimeout = setTimeout(() => {
            forceUpdate();
            clearTimeout(forceUpdateTimeout);
        }, 150);
    };

    useEffect(() => {

        if (!isVisible) {

            window.addEventListener('resize', runForceUpdate);
            window.addEventListener('orientationchange', runForceUpdate);
        }

        return () => {
            if (!isVisible) {
                window.removeEventListener('resize', runForceUpdate);
                window.addEventListener('orientationchange', runForceUpdate);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        if ((preAnchor !== anchor) || (preIsFullScreen !== isFullScreen)|| (preBarWidth !== barWidth)) {

            forceUpdate();

            runForceUpdate();
        }

    }, [runForceUpdate, forceUpdate, isFullScreen, preAnchor, preIsFullScreen,  anchor, preBarWidth, barWidth]);


    /**
     *
     * @type {() => void}
     */
    const onPressFullScreenBtn = useCallback(() => {

        dispatch(LayoutActions.drawerToggleFullScreen((!isFullScreen)));

    }, [isFullScreen, dispatch]);

    const anchorRule = anchor === 'right' ? 0 : 80;

    const schemaContainerStyle: React.CSSProperties = {
        height: hmiWrapperRef && hmiWrapperRef?.children[0] && schemaContainerRef.current ?
            isFullScreen ? hmiWrapperRef?.children[0].clientHeight - (isMobile ? 0 : 250) :
                (hmiWrapperRef?.children[0].clientHeight - 350 + anchorRule + (isMobile ? 140 : 0)) : 0,
    };

    const preventClickAction = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        event.preventDefault();
        event.stopPropagation();

    }, []);

    return (
        <>
            <ResponsiveDrawer
                defaultWidth={(DEFAULT_WIDTH - barWidth)/2}
                open={isVisible}
                onClose={onClose}
                anchor={'right'}
               currentRef={setHmiWrapperRef}
            >
                <h3 className="player-title" >
                    {schema? schema.name : ''}
                </h3>
                <div
                    className="button-panel"
                    onClick={preventClickAction}
                >
                    {!isMobileOnly &&
                        <Button
                            className={`parameter-btn ${isFullScreen ? 'active' : ''}`}
                            variant="outlined"
                            onClick={onPressFullScreenBtn}
                        >
                            <CropFreeIcon />
                        </Button>
                    }
                    <Button
                        variant="outlined"
                        ref={hmiParametersBtnRef}
                        className={`parameter-btn ${hmiParametersMenuAnchor ? 'active' : ''}`}
                        onClick={(event)=>{
                            if (!hmiParametersMenuAnchor) {
                                setParametersHmiMenuAnchor(event.currentTarget);
                            }
                        }}
                    >
                        <TuneIcon />
                    </Button>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div
                    className="schema-container"
                    ref={schemaContainerRef}
                    style={schemaContainerStyle}
                    onClick={preventClickAction}
                >
                    {schema && (
                        <Schema
                            schema={schema}
                            workareaWidth={schemaContainerRef?.current?.clientWidth || 0}
                            workareaHeight={schemaContainerRef?.current?.clientHeight || 0}
                        />
                    )}
                </div>
                <ControlPanel />
            </ResponsiveDrawer>
            <HmiParameters
                anchorEl={hmiParametersMenuAnchor}
                open={Boolean(hmiParametersMenuAnchor)}
                onClose={closeHmiMenu}
            />
        </>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const schema = selectHmiPlayerSchema(state);

    return {
        isVisible: selectHmiPlayerVisibility(state) && schema !== null,
        schema,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleHMI: GraphActions.toggleHMI,
    setSchema: HmiPlayerActions.setSchema,
    stopPlayer: HmiPlayerActions.stop,
});

export default connect(mapStateToProps, mapDispatchToProps)(Player);
