import { IHmiSchemaEditAction, IHmiSchemaEditState, IHMIStateAction } from '../../interfaces';
import { hmiObjectConstants, hmiSchemaConstants } from '../../constants';


/**
 * Process reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function hmiSchemaFormState(state: IHmiSchemaEditState = {
    errors: {},
    errorsObject: {},
    schema: null,
    picture: null,
}, action: IHmiSchemaEditAction): IHmiSchemaEditState {

    switch (action.type) {

        case hmiSchemaConstants.EDIT_HMI_SCHEMA_SUCCESS:
        case hmiSchemaConstants.UPDATE_LOCAL_OPEN_HMI_SCHEMA_SUCCESS:

            return {
                ...state,
                schema: action.schema,
                errors: {},
            };

        case hmiObjectConstants.STORE_HMI_OBJECT_SUCCESS:
        case hmiObjectConstants.CLEAR_HMI_OBJECT_ERRORS:

            return {
                ...state,
                errorsObject: {},
            };
        case hmiSchemaConstants.UPDATE_HMI_SCHEMA_FROM_SUCCESS:

            return {
                ...state,
                schema: action.schema,
            };

        case hmiObjectConstants.STORE_HMI_OBJECT_FAILURE:
        case hmiObjectConstants.UPDATE_HMI_OBJECT_FAILURE:

            return {
                ...state,
                errorsObject: action.errors,
            };

        case hmiSchemaConstants.EDIT_HMI_LOAD_PICTURE_SCHEMA_SUCCESS:
        case hmiSchemaConstants.GET_PICTURE_HMI_SCHEMA_SUCCESS:

            return {
                ...state,
                schema: action.schema,
                picture: action.picture,
                errors: {},
            };

        case hmiSchemaConstants.CLEAR_HMI_SCHEMA_STATE:

            return {
                errors: {},
                errorsObject: {},
                schema: null,
                picture: null,
            };

        default:

            return state;
    }

}