import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, Theme } from '@material-ui/core/styles';
import styled from 'reshadow';

import TextInput from '../Input/TextInput';
import ActionMenu from '../ActionMenu/ActionMenu';
import  Button from '../Button/Button';
import  ConditionalWrapper from '../Layout/ConditionalWrapper';
import { TableContainer } from '@material-ui/core';
import { IColumn, IData } from '../../../../interfaces';
import { IOrder } from '../../../interfaces';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const customStyles = require('./List.scss');

const ErrorTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: '#ff3b30',
        border: '1px solid #dadde9',
    },
}))(Tooltip);

/**
 * Data list component
 *
 * @class List
 */
const ListStickyHeader: React.FC<IProps> = (
    {
        columns,
        data,
        searchable,
        refresh,
        selected,
        onSaveChanges,
        searchableField,
        searchableField2,
        selectedParam,
        maxHeightSlider,
        minHeightSlider,
        searchPlaceholder,
        maxWidth,
    }
) => {

    const [search, setSearch] = useState<string>(''),
        [order, setOrder] = useState<any>({
            column: 'id',
            dir: 'desc',
        }),
        [mouseEnterRows, setMouseEnterRows] = useState<any>({}),
        [cellsInEdit, setCellsInEdit] = useState<any>({}),
        [cellsValidation, setCellsValidation] = useState<any>({}),
        [unsavedChanges, setUnsavedChanges] = useState<boolean>(false),
        { t } = useTranslation();

    /**
     * Handle input to search field
     *
     * @param {Object} event
     */
    const searchFieldChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {

        setSearch(event.target.value);
    };

    /**
     * Handle search
     */
    const searchHandler = () => {

        refresh(search, order);
    };

    /**
     * Handle sort by column
     *
     * @param {string} column The column to sort by
     */
    const sortHandler = (column: string) => {

        const newOrder = {
            column: column,
            dir: order.column === column && order.dir === 'desc' ? 'asc' : 'desc',
        };

        setOrder(newOrder);

        refresh(search, newOrder);
    };

    /**
     * Row mouse enter handler
     *
     * @param {IData} row
     */
    const rowEnterHandler = (row: IData) => {

        const rows: any = {};

        rows[row.id] = true;

        setMouseEnterRows(rows);
    };

    /**
     * Row mouse leave handler
     *
     * @param {IData} row
     */
    const rowLeaveHandler = (row: IData) => {

        const rows: any = { ...mouseEnterRows };

        rows[row.id] = false;

        setMouseEnterRows(rows);
    };

    /**
     * Switch an editable cell to edit mode
     *
     * @param {IData} row
     * @param {IColumn} col
     */
    const editCell = (row: IData, col: IColumn) => {

        const cells = Object.assign({}, cellsInEdit),
            validation = Object.assign({}, cellsValidation);

        // eslint-disable-next-line no-prototype-builtins
        if (!cells.hasOwnProperty(row.id)) {

            cells[row.id] = {};
            validation[row.id] = {};
        }

        cells[row.id][col.name] = row[col.name];
        validation[row.id][col.name] = {
            valid: true,
            error: '',
        };

        setCellsInEdit(cells);
        setCellsValidation(validation);
        setUnsavedChanges(true);
    };

    /**
     * Cell change event handler
     *
     * @param {object} event
     * @param {IData} row
     * @param {IColumn} col
     */
    const cellChangeHandler = (event: any, row: IData, col: IColumn) => {

        const cells = Object.assign({}, cellsInEdit);

        cells[row.id][col.name] = event.target.value;

        setCellsInEdit(cells);
    };

    /**
     * Validate models that have been changed
     *
     * @return {boolean}
     */
    const validateChanges = () => {

        let valid = true;
        const validationRules: any = {};

        const validation = Object.assign({}, cellsValidation);

        for (const column of columns) {

            // eslint-disable-next-line no-prototype-builtins
            if (column.editable && column.hasOwnProperty('validation')) {

                validationRules[column.name] = column.validation;
            }
        }

        for (const id in cellsInEdit) {

            for (const column in cellsInEdit[id]) {

                // eslint-disable-next-line no-prototype-builtins
                if (validationRules.hasOwnProperty(column)) {

                    //TODO: add more validation check here if needed
                    if (validationRules[column].required && !cellsInEdit[id][column].trim()) {

                        valid = false;
                        validation[id][column].valid = false;
                        validation[id][column].error = t('FIELD_IS_REQUIRED');
                    }
                }
            }
        }

        setCellsValidation(validation);

        return valid;
    };

    /**
     * Save models that have been changed
     */
    const saveChanges = () => {

        if (validateChanges()) {

            const changes = [],
                cells = Object.assign({}, cellsInEdit);

            for (const id in cells) {

                const row = data.find(d => parseInt(d.id) === parseInt(id));

                if (row) {

                    changes.push(Object.assign(row, cells[id]));
                }
            }

            if (onSaveChanges !== undefined) {

                onSaveChanges(changes);
            }

            cancelEdit();
        }
    };

    /**
     * Cancel cells editing
     */
    const cancelEdit = () => {

        setCellsInEdit({});
        setCellsValidation({});
        setUnsavedChanges(false);
    };

    /**
     * Searchable filter
     *
     * @param {IData[]} dataItem
     *
     * @return IData[]
     */
    const searchableFilter=(dataItem: IData[])=>{

        const filterableWord = search.trim().toLowerCase();

        const test: string[] = ['name', 'id'];

        if (filterableWord && filterableWord.length > 0 && searchableField) {

            return dataItem.filter(value => (value[searchableField] && value[searchableField].toLowerCase().includes(filterableWord)) ||
                (searchableField2 && value[searchableField2] && value[searchableField2].toLowerCase().includes(filterableWord)));

        } else {

            return dataItem as IData[];

        }
    };

    const defaultColWidth = (100 / columns.length).toString() + '%';

    return styled(customStyles)(
        <div className="list">
            {searchable &&
                <TextInput
                    className={'form-field search-field'}
                    value={search}
                    name="search"
                    type="text"
                    placeholder={t(searchPlaceholder?searchPlaceholder:'SEARCH')}
                    icon={'search'}
                    onChange={searchFieldChangeHandler}
                    onClick={(event: any) => {

                        if (event.target.tagName.toLowerCase() === 'img') {

                            searchHandler();
                        }
                    }}
                />
            }
                    <TableContainer
                        className={'table-container'}
                        style={{
                            maxHeight: maxHeightSlider,
                            minHeight: minHeightSlider,
                            maxWidth: maxWidth,
                        }}
                    >
                    <Table stickyHeader aria-label="sticky table">
                        <colgroup>
                            {columns.map((column, index) => {

                                const thisWidth = parseInt(String(columns[index + 1 === columns.length ? index - 1 : index + 1]));

                                columns[index + 1 === columns.length ? index - 1 : index + 1].width = `${thisWidth + parseInt(column?.width || defaultColWidth)}%`;

                                return (
                                    <col
                                        key={'col-' + column.name}
                                        width={column.width ? column.width : defaultColWidth}
                                    />
                                );
                            })}
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                {columns.map(column => {

                                    if (column.disabled && column.disabled(searchableFilter(data))) {
                                        return null;
                                    }

                                   return (
                                        <TableCell
                                            style={column.headerStyle || {}}
                                            key={column.name}
                                            component="th"
                                            sortDirection={order.column === column.name ? order.dir : false}
                                        >
                                            {column.sortable ?
                                                (
                                                    <TableSortLabel
                                                        active={order.column === column.name}
                                                        direction={order.dir}
                                                        IconComponent={ArrowDropDownIcon}
                                                        onClick={() => sortHandler(column.name)}
                                                    >
                                                        {column.label}
                                                    </TableSortLabel>
                                                ) : <span>{column.label}</span>
                                            }

                                            {column.action ?
                                                (
                                                    <div className="column-action" onClick={column.action.handler}>
                                                        <img src={column.action.icon} alt="icon" />
                                                    </div>
                                                ) : null
                                            }
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        {data && data.length ? (
                            <TableBody>
                                {searchableFilter(data).map((row, index, array) => (
                                    <TableRow
                                        key={index}
                                        selected={selected && selected[selectedParam] === row[selectedParam]}
                                    >
                                        {columns.map(column => {

                                            if (column.disabled && column.disabled(array)) {

                                                return null;
                                            }

                                            return (
                                                // eslint-disable-next-line no-prototype-builtins
                                                cellsInEdit.hasOwnProperty(row.id) && cellsInEdit[row.id].hasOwnProperty(column.name) ? (
                                                    <TableCell key={column.name}>
                                                        <ConditionalWrapper
                                                            condition={
                                                                // eslint-disable-next-line no-prototype-builtins
                                                                cellsValidation.hasOwnProperty(row.id) &&
                                                                // eslint-disable-next-line no-prototype-builtins
                                                                cellsValidation[row.id].hasOwnProperty(column.name) &&
                                                                !cellsValidation[row.id][column.name].valid
                                                            }
                                                            wrapper={
                                                                (children: any) => (
                                                                    <ErrorTooltip
                                                                        placement="bottom-start"
                                                                        title={cellsValidation[row.id][column.name].error}
                                                                    >
                                                                        {children}
                                                                    </ErrorTooltip>
                                                                )
                                                            }
                                                        >
                                                            <TextInput
                                                                type="text"
                                                                value={cellsInEdit[row.id][column.name]}
                                                                // autoFocus
                                                                onChange={(e) => {

                                                                    cellChangeHandler(e, row, column);
                                                                }}
                                                                inputProps={column.validation && column.validation.maxLength ? { maxLength: column.validation.maxLength } : {}}
                                                                className={
                                                                    // eslint-disable-next-line no-prototype-builtins
                                                                    cellsValidation.hasOwnProperty(row.id) &&
                                                                    // eslint-disable-next-line no-prototype-builtins
                                                                    cellsValidation[row.id].hasOwnProperty(column.name) &&
                                                                    !cellsValidation[row.id][column.name].valid ? 'error-field' : undefined
                                                                }
                                                            />
                                                        </ConditionalWrapper>
                                                    </TableCell>
                                                ) : (
                                                    <TableCell key={column.name}
                                                               style={column.cellStyle || {}}
                                                               onDoubleClick={() => {

                                                                   if (column.editable) editCell(row, column);
                                                               }}
                                                               onTouchStart={() => {

                                                                   if (column.editable) editCell(row, column);
                                                               }}
                                                               onMouseEnter={() => {

                                                                   if (column.menu) rowEnterHandler(row);
                                                               }}
                                                               onFocus={() => {

                                                                   if (column.menu) rowEnterHandler(row);
                                                               }}
                                                               onMouseLeave={() => {

                                                                   if (column.menu) rowLeaveHandler(row);
                                                               }}
                                                    >
                                                        {column.mutate ? column.mutate(row[column.name], row) : row[column.name]}
                                                        {column.menu ?
                                                            <ActionMenu
                                                                items={column.menu}
                                                                visible={mouseEnterRows[row.id]}
                                                                data={row}
                                                            /> : null
                                                        }
                                                    </TableCell>
                                                )
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        ) : (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            {t('THERE_ARE_NO_RELATED_ITEMS_TO_SHOW')}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                    </Table>
                    </TableContainer>
            {unsavedChanges &&
                <Box pt={4} width={400}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Button
                                type="button"
                                color={'primary'}
                                onClick={cancelEdit}
                            >{t('CANCEL')}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                type="button"
                                color={'secondary'}
                                onClick={saveChanges}
                            >{t('SAVE_CHANGES')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            }
        </div>
    );
};

export default ListStickyHeader;

interface IProps {
    columns: IColumn[];
    data: IData[];
    refresh: (search: string, order: IOrder) => void;
    searchable?: boolean;
    selectedParam: string;
    selected?: IData;
    maxHeightSlider?: string;
    minHeightSlider?: string;
    maxWidth?: string;
    onSaveChanges?: (changes: IData[]) => void;
    searchableField?: string;
    searchableField2?: string;
    searchPlaceholder? : string;
}
