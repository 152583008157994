import { Action } from 'redux';
import { IDepartment, IEmployee } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';

export enum DepartmentActionTypes {
    SAVE_DEPARTMENT_SUCCESS = '[Department] Save Department Success',
    SAVE_DEPARTMENT_FAILURE = '[Department] Save Department Failure',
    FETCH_DEPARTMENTS_REQUEST = '[Department] Fetch Department Request',
    FETCH_DEPARTMENTS_SUCCESS = '[Department] Fetch Department Success',
    FETCH_DEPARTMENTS_FAILURE = '[Department] Fetch Department Failure',
    UPDATE_DEPARTMENT_SUCCESS = '[Department] Update Department Success',
    UPDATE_DEPARTMENT_FAILURE = '[Department] Update Department Failure',
    DELETE_DEPARTMENT_FAILURE = '[Department] Delete Department Failure',
    DELETE_DEPARTMENT_ACTION = '[Department] Delete Department Action',
    CLEAR_DEPARTMENT_ERROR = '[Department] Clear department error',
    DELETE_EMPLOYEE_IN_DEPARTMENT_ACTION = '[Employee] Delete Employee in Department Action',
}

export class FetchDepartmentsRequest implements Action {

    readonly type = DepartmentActionTypes.FETCH_DEPARTMENTS_REQUEST;
}

export class FetchDepartmentsSuccess implements Action {

    readonly type = DepartmentActionTypes.FETCH_DEPARTMENTS_SUCCESS;

    constructor(
        public payload: {
            departments: IDepartment[]
        }
    ) {}
}

export class FetchDepartmentsFailure implements Action {

    readonly type = DepartmentActionTypes.FETCH_DEPARTMENTS_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        }
    ) {}
}

export class SaveDepartmentAction implements Action {

    readonly type = DepartmentActionTypes.SAVE_DEPARTMENT_SUCCESS;

    constructor(
        public payload: {
            department: IDepartment,
        },
    ) {}
}

export class SaveDepartmentFailure implements Action {

    readonly type = DepartmentActionTypes.SAVE_DEPARTMENT_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        }
    ) {}
}

export class UpdateDepartmentSuccess implements Action {

    readonly type = DepartmentActionTypes.UPDATE_DEPARTMENT_SUCCESS;

    constructor(
        public payload: {
            department: IDepartment,
        },
    ) {}
}

export class UpdateDepartmentFailure implements Action {

    readonly type = DepartmentActionTypes.UPDATE_DEPARTMENT_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        }
    ) {}
}


export class DeleteDepartmentAction implements Action {

    readonly type = DepartmentActionTypes.DELETE_DEPARTMENT_ACTION;

    constructor(
        public payload: {
            department: IDepartment
        }
    ) {
    }
}

export class DeleteEmployeeInDepartmentAction implements Action {

    readonly type = DepartmentActionTypes.DELETE_EMPLOYEE_IN_DEPARTMENT_ACTION;

    constructor(
        public payload: {
            employee: IEmployee
        }
    ) {
    }
}

export class DeleteDepartmentFailure implements Action {

    readonly type = DepartmentActionTypes.DELETE_DEPARTMENT_FAILURE;

    constructor(
        public payload: {
            error: IErrors
        }
    ) {}
}

export class ClearDepartmentError implements Action {

    readonly type = DepartmentActionTypes.CLEAR_DEPARTMENT_ERROR;
}

export type DepartmentActions =
    FetchDepartmentsRequest |
    FetchDepartmentsSuccess |
    FetchDepartmentsFailure |
    SaveDepartmentAction |
    UpdateDepartmentSuccess |
    UpdateDepartmentFailure |
    SaveDepartmentFailure |
    DeleteEmployeeInDepartmentAction |
    DeleteDepartmentFailure |
    ClearDepartmentError |
    DeleteDepartmentAction;
