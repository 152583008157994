import React, { Component } from 'react';
import CursorPopover from '../../../../core/ui/components/Layout/CursorPopover';
import DurationIcon, { ReactComponent as Durations } from '../../../../core/ui/assets/images/icons/duration.svg';
import moment from 'moment';
import StartIcon from '../../../../core/ui/assets/images/icons/start.svg';
import StopIcon from '../../../../core/ui/assets/images/icons/stop.svg';
import UserIcon from '../../../../core/ui/assets/images/icons/user.svg';
import { connect } from 'react-redux';
import { RootState } from '../../../../core/store';
import { IAlertDetailsState, IChartAlert, INotification, IStateDetailsState } from '../../../../core/interfaces';
import { WithTranslation, withTranslation } from 'react-i18next';
import { selectHoverAlerts } from '../../../../core/selectors/notification/monitoringTreeAlertsSelector';


interface IPopUpDetailsProps {
    stateDetails?: IStateDetailsState;
    alertDetails?: IAlertDetailsState;
}

class PopUpDetails extends Component<IPopUpDetailsProps & WithTranslation> {

    constructor(props: IPopUpDetailsProps & WithTranslation) {

        super(props);

        this.locale = 'en';
    }


    componentDidMount() {

        this.getLocale();
    }

    /**
     *  Locale
     *
     * @type {string}
     * @private
     */
    private locale: string;

    getLocale() {

        const language = localStorage.getItem('language');

        if (language) {

            this.locale = language;
        }
    }

    /**
     * Checking if the current time is exceeded
     *
     * @param {Date} date
     *
     * @return {Date}
     */
    getCorrectEndTime(date: Date): Date {

        if (new Date().getTime() < new Date(date).getTime()) {

            return new Date();
        }

        return date;
    }

    render() {

        const { stateDetails, alertDetails, t } = this.props,
            { locale } = this;

        return (
            <React.Fragment>

                {stateDetails && stateDetails.visible && (
                    <CursorPopover open>
                        <div className="state-details">
                            <div className="fill" style={{ backgroundColor: stateDetails.state.color }} />
                            <div className="text">
                                <div className="title">
                                    {((!stateDetails.state.stateCategoryName || stateDetails.state.stateCategoryName) &&
                                        stateDetails.state.causeName && stateDetails.state.causeName.length > 0) ?
                                        <React.Fragment>
                                            <span
                                                className="category"
                                            >
                                                {stateDetails.state.stateCategoryName ?
                                                    stateDetails.state.stateCategoryName :
                                                    this.props.t('NO_CATEGORY')}:&nbsp;
                                            </span>
                                            <span className="reason">{stateDetails.state.causeName}</span>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {stateDetails.state.zoneName ?
                                                <React.Fragment>
                                                    <span
                                                        className="category"
                                                    >
                                                        {stateDetails.state.zoneName ?
                                                            stateDetails.state.zoneName :
                                                            this.props.t('NO_CATEGORY')}
                                                    </span>
                                                    <span className="reason">{stateDetails.state.causeName}</span>
                                                </React.Fragment>
                                                :
                                                this.props.t('NO_DATA')
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="comment">
                                    {stateDetails.state.comment}
                                </div>
                                {stateDetails.state.comment && stateDetails.state?.user  &&
                                    <div className="comment-author">
                                        <img src={UserIcon} alt="icon" />&nbsp; {stateDetails.state.user.username}
                                    </div>
                                }
                                <div className="duration">
                                    <img src={DurationIcon} alt="icon" />&nbsp;
                                    {moment.duration(moment(this.getCorrectEndTime(stateDetails.state.endTimeOriginal || stateDetails.state.endTime))
                                        .diff(new Date(stateDetails.state.startTimeOriginal || stateDetails.state.startTime)))
                                        .locale(locale)
                                        .format('d[d] hh[h] mm[m]')
                                    }
                                </div>
                                <div className="times">
                                    <span className="start">
                                        <img src={StartIcon}
                                             alt="icon"
                                        />&nbsp;
                                        {moment(stateDetails.state.startTimeOriginal || stateDetails.state.startTime)
                                            .locale(locale)
                                            .format('DD MMMM HH:mm ')
                                        }
                                    </span>
                                    <span className="end">
                                        <img src={StopIcon}
                                             alt="icon"
                                        />&nbsp;
                                        {moment(this.getCorrectEndTime(stateDetails.state.endTimeOriginal || stateDetails.state.endTime))
                                            .locale(locale)
                                            .format('DD MMMM HH:mm ')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </CursorPopover>
                )}
                {alertDetails && alertDetails.state ? (
                    <CursorPopover open>
                        <div className="alert-details">
                            <div className="alert-details-wrap">
                                <div className="alert-item--path">
                                    <div className="text">
                                        ...{`/${alertDetails.state?.unit}/${alertDetails.state?.sensor}/`}
                                        <span className={'param'}>
                            {`${'um'} ${alertDetails.state?.operator} ${alertDetails.state?.peakValue}`}
                                            <span className={'for'}>{` ${t('FOR')} `}</span>
                                            {moment(alertDetails.state?.endTime || new Date()).diff(alertDetails.state?.startTime, 'minutes')} {t('MINUTES')}
                                        </span>
                                    </div>
                                    {alertDetails.state?.isNew && <span className={'read-icon'} />}
                                </div>
                                <div className="comment">{alertDetails.state?.comment}</div>
                                <div className="alert-item--duration">
                                    <div className="text">
                                        <Durations />
                                        {moment(alertDetails.state?.startTime)
                                            .locale(locale).format('D MMM')},&nbsp;
                                        {moment(alertDetails.state?.startTime)
                                            .locale(locale).format('HH:mm')}
                                        –
                                        {moment(alertDetails.state?.endTime || new Date())
                                            .isBefore(moment(alertDetails.state?.startTime)
                                                .add({ 'd': 1 })) ? '' : `${moment(alertDetails.state?.endTime || new Date())
                                            .locale(locale).format('D MMM')}, `}
                                        {moment(alertDetails.state?.endTime || new Date()).locale(locale).format('HH:mm')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CursorPopover>
                ): null}
            </React.Fragment>
        );
    }
}


//

const mapStateToProps = (state: RootState) => {

    const { stateDetails, alertDetails } = state;

    const alertDetailsCurrent: { visible: boolean, state?: INotification } = { visible: false, state: undefined };

    if (alertDetails.state && (alertDetails.state.id || (alertDetails.state as unknown as  IChartAlert).notificationId)) {

        const alertDetailsItem = selectHoverAlerts(state, (alertDetails.state.id || (alertDetails.state as unknown as  IChartAlert).notificationId));

        if (alertDetailsItem) {

            alertDetailsCurrent.state = { ...alertDetailsItem };
            alertDetailsCurrent.visible = true;

        }
    }

    return {
        stateDetails,
        alertDetails: alertDetailsCurrent,
    };
};
export default connect(mapStateToProps, null)(withTranslation()(PopUpDetails));