import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { fabric } from 'fabric';
import { Point } from 'fabric/fabric-impl';

import { MainMenu } from '../../../../base/components/Editor/panels/MainMenu';
import { LeftPanel } from '../../../../base/components/Editor/panels/LeftPanel';
import {
    IEditorTool,
    InteractionMode,
    IObject,
    ITargetObject,
    FabricObject,
} from '../../../../base/components/Editor/interfaces';

import RightSidebar from '../../../Sidebar/RightSidebar';
import ObjectForm from './components/ObjectForm';
import { FooterPanel } from './panels/FooterPanel';

import { editorConstants } from './constants';
import { Canvas } from './canvas/Canvas';
import CanvasObject from './canvas/CanvasObject';
import { ReactComponent as EngineIcon } from './img/engine-icon.svg';
import { ReactComponent as IndicatorIcon } from './img/indicator-icon.svg';
import { ReactComponent as SensorIcon } from './img/sensor-icon.svg';
import { ReactComponent as ValveIcon } from './img/valve-icon.svg';
import { ReactComponent as PlusIcon } from './img/plus-icon.svg';
import { ReactComponent as RectangleIcon } from './img/rectangle-icon.svg';
import { ReactComponent as TriangleIcon } from './img/triangle-icon.svg';
import { ReactComponent as OvalIcon } from './img/oval-icon.svg';
import { ReactComponent as StarIcon } from './img/star-icon.svg';
import { ReactComponent as PencilIcon } from './img/pencil-icon.svg';

import '../../../../base/components/Editor/styles/Editor.scss';
import { RootState } from '../../../../core/store';
import { IHmiObject, IHmiSchema, ITypeObject } from '../../../../core/interfaces';
import { HmiObjectAction } from '../../../../core/actions/hmiObjectAction';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction, bindActionCreators } from 'redux';
import {
    selectHmiObjectErrors,
    selectHmiOpenScheme,
} from '../../../../core/selectors/hmiSchemas/hmiOpenSchemeSelector';
import { FormActions, HmiSchemaAction } from '../../../../core/actions';
import { Button, DeleteDialog } from '../../../../core/ui/components';
import { selectAllSensorItemInConfigurationTree } from '../../../../core/selectors/configurationTree/configurationTreeSelector';
import { maxString } from '../../../../core/helpers/fittingStringHelper';



interface OwnProps {
    toggleForm: (opened: boolean, name?: string) => void;
    schema: IHmiSchema | null;
    picture: File;
}

type IProps = OwnProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & WithTranslation;

interface IState {
    selectedItem: FabricObject | null;
    selectedTool: string | null;
    zoomRatio: number;
    scale: number;
    editing: boolean;
    grab: boolean;
    dialogOpened: boolean;
    deleteDialogOpened: boolean;
    deleteItem: FabricObject | null;
    formOpened: boolean;
    formTitle: string | null;
    pitCodeLabel: string | undefined;
    targetObject: ITargetObject | null;
    src: string;
    width: number;
    height: number;
    meterWidth: number;
    meterHeight: number;
    canvasReady: boolean;
    workareaWidth: number;
    workareaHeight: number;
    schemaOpacity: number;
    schemaMapShow: boolean;
    schemaObjectShow: boolean;
}

/**
 * Map editor
 *
 * @class Editor
 */
class Editor extends React.PureComponent<IProps, IState> {

    /**
     * Constructor
     *
     * @param props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        this.state = {
            selectedItem: null,
            selectedTool: null,
            zoomRatio: 1,
            scale: 10 / 800,
            editing: false,
            grab: true,
            dialogOpened: false,
            deleteDialogOpened: false,
            deleteItem: null,
            formOpened: false,
            formTitle: null,
            targetObject: null,
            width: 1279,
            height: 800,
            meterWidth: 10,
            meterHeight: 10,
            src: '',
            canvasReady: false,
            workareaWidth: 0,
            workareaHeight: 0,
            pitCodeLabel: undefined,
            schemaMapShow: true,
            schemaObjectShow: true,
            schemaOpacity: 1,
        };

        this.canvasRef = null;

        this.firstZoomToFit = false;

        this.transformedPoints = null;

        const { t } = this.props;

        this.menuTools = [
            {
                id: v4(),
                title: t('ADD_A_STANDARD_OBJECT'),
                superType: 'label',
                type: 'label',
                style: {
                    marginLeft: '20px',
                },
            },
            {
                id: editorConstants.objects.engine,
                title: t('DRAW_ENGINE'),
                superType: 'drawing',
                type: editorConstants.interaction.modeEngine,
                icon: (<EngineIcon title={t('DRAW_ENGINE')} />),
            },
            {
                id: editorConstants.objects.sensor,
                title: t('DRAW_SENSOR'),
                superType: 'drawing',
                type: editorConstants.interaction.modeSensor,
                icon: (<SensorIcon title={t('DRAW_SENSOR')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#33bbff',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.objects.valve,
                title: t('DRAW_VALVE'),
                superType: 'drawing',
                type: editorConstants.interaction.modeValve,
                icon: (<ValveIcon title={t('DRAW_VALVE')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#33bbff',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: editorConstants.objects.indicator,
                title: t('DRAW_INDICATOR'),
                superType: 'drawing',
                type: editorConstants.interaction.modeIndicator,
                icon: (<IndicatorIcon title={t('DRAW_INDICATOR')} />),
                options: {
                    strokeWidth: 5,
                    stroke: '#33bbff',
                    fill: 'rgba(0, 0, 0, 0)',
                    opacity: 1,
                },
            },
            {
                id: v4(),
                title: '',
                superType: 'separator',
                type: 'separator',
            },
            {
                id: v4(),
                title: t('ADD_A_CUSTOM_OBJECT'),
                superType: 'label',
                type: 'label',
            },
            {
                id: v4(),
                title: '',
                superType: 'dropdown',
                type: 'dropdown',
                icon: (<PlusIcon title={t('ADD_A_CUSTOM_OBJECT')} />),
                items: [
                    {
                        id: editorConstants.objects.rectangle,
                        title: t('RECTANGLE'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeRectangle,
                        icon: (<RectangleIcon title={t('DRAW_A_RECTANGLE')} />),
                    },
                    {
                        id: editorConstants.objects.oval,
                        title: t('OVAL'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeOval,
                        icon: (<OvalIcon title={t('DRAW_AN_OVAL')} />),
                    },
                    {
                        id: editorConstants.objects.triangle,
                        title: t('TRIANGLE'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeTriangle,
                        icon: (<TriangleIcon title={t('DRAW_A_TRIANGLE')} />),
                    },
                    {
                        id: editorConstants.objects.star,
                        title: t('STAR'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modeStar,
                        icon: (<StarIcon title={t('DRAW_A_STAR')} />),
                    },
                    {
                        id: editorConstants.objects.draw,
                        title: t('DRAW'),
                        superType: 'drawing',
                        type: editorConstants.interaction.modePolygon,
                        icon: (<PencilIcon title={t('DRAW_A_FREE_FIGURE')} />),
                        options: {
                            strokeWidth: 5,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                        },
                    },
                ],
            },
        ];

        this.sliderZoomChange = this.sliderZoomChange.bind(this);
        this.switchGrab = this.switchGrab.bind(this);
        this.selectTool = this.selectTool.bind(this);
        this.setCanvasRef = this.setCanvasRef.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onZoom = this.onZoom.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onFormSave = this.onFormSave.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onToggleObject = this.onToggleObject.bind(this);
        this.lockEditObj = this.lockEditObj.bind(this);
        this.mapEditorClose = this.mapEditorClose.bind(this);
        this.onSaveObjectForm = this.onSaveObjectForm.bind(this);
        this.objectTypeCheck = this.objectTypeCheck.bind(this);
        this.drawSchemaObject = this.drawSchemaObject.bind(this);
        this.removeObjectItemAfterUpdate = this.removeObjectItemAfterUpdate.bind(this);
        this.mapHmiObjects = this.mapHmiObjects.bind(this);
        this.onCloseObjectForm = this.onCloseObjectForm.bind(this);
        this.onChangeObjectForm = this.onChangeObjectForm.bind(this);
        this.onEditScheme = this.onEditScheme.bind(this);
        this.onOpenObjectForm = this.onOpenObjectForm.bind(this);
        this.onChangePositionActiveObject = this.onChangePositionActiveObject.bind(this);
        this.deleteHmiObject = this.deleteHmiObject.bind(this);
        this.onDeleteCancel = this.onDeleteCancel.bind(this);
        this.mapOpacityChange = this.mapOpacityChange.bind(this);
        this.changeVisibleObject = this.changeVisibleObject.bind(this);
        this.onShowMap = this.onShowMap.bind(this);
        this.onShowObjects = this.onShowObjects.bind(this);
        this.capitalize = this.capitalize.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount(): void {

        window.addEventListener('resize', this.updateCanvasSize.bind(this));
        window.addEventListener('orientationchange', this.updateCanvasSize.bind(this));

        this.updateCanvasSize();

        this.canvasRef && this.canvasRef.handler.interactionHandler.grab();
    }

    /**
     * Component props update handler
     *
     * @param {IProps & WithTranslation} prevProps
     */
    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>): void {

        const { schema, picture } = this.props;

        if (this.state.canvasReady) {

            if (this.state.selectedTool !== editorConstants.interaction.modeEdit) {

                this.lockEditObj(true);
            }

        }

        if (schema && schema?.hmiObjects && this.canvasRef && !this.hmiObjectsLoaded) {

            this.mapHmiObjects(schema.hmiObjects);

            this.hmiObjectsLoaded = true;

            this.canvasRef.handler.interactionHandler.grab(true);
        }

        if (schema && schema?.hmiObjects && this.canvasRef && this.hmiObjectsLoaded && schema !== prevProps.schema) {

            this.removeObjectItemAfterUpdate();

            this.setState({ targetObject: null });

            this.mapHmiObjects(schema.hmiObjects);
        }


        if (schema && schema.picture !== prevProps.schema?.picture) {

            const currentSchemeImg = new Image();

            currentSchemeImg.onload = () => {

                this.setState({
                    src: picture,
                    width: currentSchemeImg.width,
                    height: currentSchemeImg.height,
                    schemaOpacity: schema.opacity ? schema.opacity : 1,
                    schemaObjectShow: schema?.showObjects ? Boolean(schema.showObjects) : true,
                    schemaMapShow: schema.showMinimap ? Boolean(schema.showMinimap) : true,
                });
            };

            currentSchemeImg.src = picture;

        }

        if (!this.firstZoomToFit && this.canvasRef) {

            this.canvasRef.handler.zoomHandler.zoomToFit();
            this.firstZoomToFit = true;
        }
    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount(): void {

        const { src } = this.state;

        if (src) {

            URL.revokeObjectURL(src);

            this.setState({ src: '' });
        }

        window.removeEventListener('resize', this.updateCanvasSize.bind(this));
        window.removeEventListener('orientationchange', this.updateCanvasSize.bind(this));
    }

    /**
     * Canvas component reference
     *
     * @type {Canvas}
     */
    private canvasRef: Canvas | null;

    /**
     * Array of drawn objects
     *
     * @type {Record<string, unknown>[]}
     */
    private objects: IObject[] = [];

    /**
     * Application header height in pixels
     *
     * @type {number}
     */
    headerHeight = 40;

    /**
     * Left sidebar width in pixels
     *
     * @type {number}
     */
    leftSidebarWidth = 320;

    /**
     *
     * @type {any}
     */
    savedObject: FabricObject | null = null;

    /**
     *
     *
     * @type {Point[] | null}
     */
    transformedPoints: null | Point[];

    /**
     * Main menu tools
     *
     * @type {IEditorTool[]}
     */
    menuTools: IEditorTool[];

    /**
     * First loaded object for scheme HMI
     *
     * @type {Boolean}
     * @private
     */
    private hmiObjectsLoaded = false;

    /**
     * First zoom to fit
     *
     * @type {boolean}
     * @private
     */
    private firstZoomToFit: boolean;

    /**
     *
     * @param {IHmiObject[]} hmiObjects
     */
    mapHmiObjects(hmiObjects: IHmiObject[]) {

        hmiObjects.map(value => {

            this.objectTypeCheck(value);

        });

        //After Update, overwrites the entity for the open form
        if (this.state.targetObject && this.state.formOpened && this.canvasRef) {

            const afterChange = this.canvasRef.canvas.getObjects().find((value: FabricObject) =>
                value.objectId === (this.state.targetObject as unknown as FabricObject).objectId &&
                value.id !== (this.state.targetObject as unknown as FabricObject).id);

            if (afterChange) {

                this.setState({ targetObject: afterChange as unknown as ITargetObject });
            }
        }
    }

    /**
     *
     */
    removeObjectItemAfterUpdate() {

        if (this.canvasRef) {

            const allEngine = this.canvasRef.handler.canvas.getObjects().filter(item => item.type !== 'image');

            allEngine.map(item => {
                this.canvasRef && this.canvasRef.handler.canvas.remove(item);
            });

        }
    }


    /**
     * Adding schematic objects to the canvas
     *
     * @param {string} type
     * @param {IHmiObject} item
     */
    drawSchemaObject(type: string, item: IHmiObject) {

        const optionsMapping = {
            [editorConstants.interaction.modeRectangle]: {
                strokeWidth: 1,
                stroke: '#74797d',
                fill: '#cdd0d4',
                opacity: 1,
                width: 18,
                height: 18,
            },
            [editorConstants.interaction.modeOval]: {
                strokeWidth: 1,
                stroke: '#74797d',
                fill: '#cdd0d4',
                opacity: 1,
                radius: 9,
            },
            [editorConstants.interaction.modeTriangle]: {
                strokeWidth: 1,
                stroke: '#74797d',
                fill: '#cdd0d4',
                opacity: 1,
                width: 18,
                height: 18,
            },
            [editorConstants.interaction.modePolygon]: {
                stroke: '#999999',
                fill: '#cccccc',
                strokeUniform: true,
                strokeWidth: 3 / this.state.zoomRatio,
                strokeWidthInitial: 3,
                opacity: 1,
                objectCaching: false,
                superType: 'drawing',
            },
        };

        if (this.canvasRef) {
            const currentPitCodeLabel = this.props.allSensorInTree.find(sensor => sensor.id === parseInt(item.pidCode) || sensor.id === item.sensorId),
                { schema } = this.props;

            if (currentPitCodeLabel) {
                const itemPoints = item.points ?
                    item.points.map((point: number[]) => {

                        const [x = 0, y = 0] = point;

                        return { x: x, y: y };
                    }) : [];

                const itemOption = {
                    id: v4(),
                    type: type,
                    left: item.position.x | 0,
                    top: item.position.y | 0,
                    objectId: item.id,
                    unitId: item.unit,
                    factoryId: item.fabric,
                    schemaId: schema?.id || 0,
                    zIndex: 20,
                    suppressCallback: true,
                    pidCode: item.pidCode,
                    angle: item.angle,
                    pidCodePosition: item.pidCodePosition,
                    points: itemPoints,
                    visible: Boolean(schema!.showObjects),
                    sensorId: currentPitCodeLabel?.id || null,
                };

                const drawScale = item.scale ? {
                        scaleX: item.scale.x,
                        scaleY: item.scale.y,
                    } : {},
                    labelItemOption = {
                        id: v4(),
                        type: 'pitCode',
                        label: currentPitCodeLabel ? maxString(currentPitCodeLabel.pIdCode || currentPitCodeLabel.name, 15) : '',
                        left: item.position.x | 0,
                        top: item.position.y | 0,
                        objectId: item.id,
                        unitId: item.unit,
                        factoryId: item.fabric,
                        zIndex: 21,
                        suppressCallback: true,
                        itemId: itemOption.id,
                        pidCodePosition: item.pidCodePosition,
                        schemaId: schema?.id || 0,
                        visible: Boolean(schema!.showObjects),
                    };

                this.canvasRef.handler.add({ ...itemOption, ...drawScale, ...optionsMapping[type] });
                this.canvasRef.handler.add(labelItemOption);
            }
        }
    }

    /**
     * Object type check
     *
     * @param {IHmiObject} item
     */
    objectTypeCheck(item: IHmiObject) {

        const {
            modePolygon,
            modeEngine,
            modeSensor,
            modeValve,
            modeIndicator,
            modeRectangle,
            modeOval,
            modeTriangle,
            modeStar,
        } = editorConstants.interaction;

        const {
            engine,
            sensor,
            valve,
            indicator,
            rectangle,
            oval,
            triangle,
            star,
            draw,
        } = editorConstants.typeToId;

        if (this.canvasRef) {

            switch ((item.type as ITypeObject).id) {

                case engine:

                    this.drawSchemaObject(modeEngine, item);

                    break;

                case indicator:

                    this.drawSchemaObject(modeIndicator, item);

                    break;

                case valve:

                    this.drawSchemaObject(modeValve, item);

                    break;

                case sensor:

                    this.drawSchemaObject(modeSensor, item);

                    break;

                case rectangle:

                    this.drawSchemaObject(modeRectangle, item);

                    break;

                case oval:

                    this.drawSchemaObject(modeOval, item);

                    break;

                case triangle:

                    this.drawSchemaObject(modeTriangle, item);

                    break;

                case star:

                    this.drawSchemaObject(modeStar, item);

                    break;

                case draw:

                    this.drawSchemaObject(modePolygon, item);

                    break;
            }
        }
    }

    /**
     * Update canvas size on resize window
     */
    updateCanvasSize() {
        const configureDraggable = document.getElementById('hmi-editor-container');

        if (configureDraggable) {
            const heightOffsetValue = 215,
                widthOffsetValue = 120;


            const editorWidth = configureDraggable.clientWidth - widthOffsetValue,
                editorHeight = configureDraggable.clientHeight - heightOffsetValue;

            this.setState({ workareaWidth: editorWidth, workareaHeight: editorHeight });

            if (this.canvasRef) {

                this.canvasRef.canvas.setHeight(editorHeight);

                this.canvasRef.canvas.setWidth(editorWidth);

                this.canvasRef.canvas.requestRenderAll();

                this.forceUpdate();
            }
        }
    }

    /**
     * Set canvas reference
     *
     * @param {Canvas} ref
     */
    setCanvasRef(ref: Canvas | null) {

        this.canvasRef = ref;
    }

    /**
     * Select a tool
     *
     * @param {IEditorTool} tool
     */
    selectTool(tool: IEditorTool) {

        const { selectedTool, formOpened, targetObject } = this.state;

        const {
            modeGrab,
            modePolygon,
            modeEngine,
            modeSensor,
            modeValve,
            modeIndicator,
            modeRectangle,
            modeOval,
            modeTriangle,
            modeStar,
            modeDelete,
            modeEdit,
        } = editorConstants.interaction;

        if (this.canvasRef) {

            if (formOpened && targetObject) {

                const removeItem = this.canvasRef.handler.canvas.getObjects().find((item: FabricObject) => item.id === targetObject.id && !item.objectId);

                this.setState({targetObject: null});

                removeItem && this.canvasRef.canvas.remove(removeItem);                
            }


            //TODO: add check for an active shape
            if (this.canvasRef.handler.interactionHandler.isDrawingMode()) {

                if (this.canvasRef.handler.activeLine) {

                    this.canvasRef.handler.remove(this.canvasRef.handler.activeLine);
                }

                this.canvasRef.handler.drawingHandler.polygon.cancel();

                if (this.canvasRef.handler.onCancel) {

                    this.canvasRef.handler.onCancel();
                }
            }

            if (this.canvasRef.handler.interactionMode === modeGrab) {
                this.switchGrab();
            }

            if (tool.superType === 'drawing') {

                switch (tool.type) {

                    case modePolygon:

                        this.canvasRef.handler.drawingHandler.polygon.activate({ ...tool.options, strokeWidth: 3 });

                        break;

                    case modeEngine:

                        this.canvasRef.handler.interactionHandler.drawing(modeEngine as InteractionMode);

                        break;

                    case modeSensor:

                        this.canvasRef.handler.interactionHandler.drawing(modeSensor as InteractionMode);

                        break;

                    case modeValve:

                        this.canvasRef.handler.interactionHandler.drawing(modeValve as InteractionMode);

                        break;

                    case modeIndicator:

                        this.canvasRef.handler.interactionHandler.drawing(modeIndicator as InteractionMode);

                        break;

                    case modeRectangle:

                        this.canvasRef.handler.interactionHandler.drawing(modeRectangle as InteractionMode);

                        break;

                    case modeOval:

                        this.canvasRef.handler.interactionHandler.drawing(modeOval as InteractionMode);

                        break;

                    case modeTriangle:

                        this.canvasRef.handler.interactionHandler.drawing(modeTriangle as InteractionMode);

                        break;

                    case modeStar:

                        this.canvasRef.handler.interactionHandler.drawing(modeStar as InteractionMode);

                        break;

                }
            }

            if (tool.id === modeDelete && this.canvasRef.canvas.getActiveObjects().length) {

                this.setState({ dialogOpened: true, formTitle: null });
            }

            if (tool.id === selectedTool) {

                this.canvasRef.handler.interactionHandler.selection();
            }

            if (tool.id === modeEdit && this.canvasRef.canvas.getActiveObject()?.type === 'activeSelection') {
                this.canvasRef.canvas.discardActiveObject();
            }

            this.canvasRef.handler.menuActionMode = selectedTool !== tool.id ? tool.id : '';

            const lock = !(tool.id === modeEdit && selectedTool !== tool.id);

            this.lockEditObj(lock);
        }

        const deleteTool = tool.id === modeDelete && this.canvasRef?.canvas.getActiveObject();

        this.setState({
            selectedTool: (tool.id !== selectedTool && tool.id !== modeDelete) || deleteTool ? tool.id : null,
        });
    }

    /**
     * On toggle object switch object active mode
     *
     * @param object
     */
    onToggleObject(object: IObject) {

        const { canvasRef } = this;

        this.objects.forEach((o, i) => o.id === object.id ? this.objects[i] = { ...o, active: !o.active } : null);

        if (object?.id && canvasRef) {

            const obj = canvasRef.handler.findById(object.id);

            if (!obj) {

                const layer = canvasRef.canvas.getObjects()
                    .filter((object: FabricObject) => object.id?.search(/^editor-layer-/) !== -1)
                    .find((item: any) => item.id === object.id);

                if (layer) layer.visible = !object.active;
            }

            if (obj?.opacity) obj.visible = !object.active;

            canvasRef.canvas.discardActiveObject();

            canvasRef.canvas.renderAll();

            this.forceUpdate();
        }
    }

    /**
     * Handler for slider zoom change event
     *
     * @param {React.ChangeEvent} event
     * @param {number | number[]} value
     */
    sliderZoomChange(event: React.ChangeEvent<Record<string, unknown>> | null, value: number | number[]) {

        if (this.canvasRef && !Array.isArray(value)) {

            this.canvasRef.handler.zoomHandler.zoomToValue(value / 100);
        }
    }

    /**
     * Switch between selection and grab modes
     *
     * @returns {boolean}
     */
    switchGrab() {

        if (this.canvasRef && !this.canvasRef.handler.interactionHandler.isDrawingMode()) {

            if (this.canvasRef.handler.interactionMode === editorConstants.interaction.modeSelection) {

                this.canvasRef.handler.interactionHandler.grab();

                this.setState({
                    grab: true,
                });

            } else {

                this.canvasRef.handler.interactionHandler.selection();

                this.setState({
                    grab: false,
                });
            }
        }
    }

    /**
     * Create new object callback
     *
     * @param target
     */
    onAdd(target: FabricObject) {

        const { selectedTool } = this.state;
        const { t } = this.props;

        this.setState({ selectedItem: target });

        if (!this.state.editing) {

            this.setState({
                editing: true,
            });
        }

        if (target.type === 'activeSelection') {

            this.onSelect(null);
        }

        if (this.canvasRef) {

            this.canvasRef.handler.select(target);
        }

        let formTitle = null;

        if (selectedTool) {

            if ([editorConstants.objects.engine,
                editorConstants.objects.sensor,
                editorConstants.objects.valve,
                editorConstants.objects.indicator,
            ].includes(selectedTool)) {

                formTitle = t('STANDARD_OBJECT');

            } else {

                formTitle = t('CUSTOM_OBJECT');
            }
        }

        this.setState({
            selectedTool: null,
            formOpened: true,
            formTitle: formTitle,
            targetObject: {
                id: target.id || '',
                type: selectedTool,
                active: true,
                points: target.points?.map((point: { x: number, y: number }) => {

                    return [point.x, point.y];
                }),
                center: target.getCenterPoint(),
            },
        });
    }

    /**
     * Remove object callback
     */
    onRemove(el: FabricObject) {

        if (!this.state.editing) {

            this.setState({
                editing: true,
            });
        }

        if (this.canvasRef) {

            this.canvasRef.canvas.add(el);
        }

        this.setState({
            deleteItem: el,
            deleteDialogOpened: true,
        });

        this.onSelect(null);
    }

    /**
     * On delete success.
     */
    deleteHmiObject() {

        const { deleteHmiObject, schema } = this.props,
            { deleteItem } = this.state;

        if (deleteItem) {

            const sendData: IHmiObject = {
                id: deleteItem.objectId,
                pidCode: deleteItem.pidCode,
                fabric: deleteItem.factoryId,
                unit: deleteItem.unitId,
                angle: deleteItem.angle || 0,
                points: (deleteItem as unknown as ITargetObject)?.points?.map(value => ({
                    x: value[0],
                    y: value[1],
                }))[0] as any || null,
                position: {
                    x: 0,
                    y: 0,
                },
                type: Object(editorConstants.typeToId)[`${deleteItem?.type}`],
                pidCodePosition: deleteItem.position,
                hmiSchema: schema?.id || 0,
                scale: null,
                sensorId: null,
            };

            deleteHmiObject(sendData);
        }
    }

    /**
     * On delete dialog close
     */
    onDeleteCancel() {

        if (this.canvasRef) {

            this.canvasRef.canvas.add(this.state.deleteItem as FabricObject);

            this.setState({
                deleteDialogOpened: false,
                deleteItem: null,
                formOpened: false,
            });
        }
    }

    /**
     * Select object callback
     *
     * @param {Object} target
     */
    onSelect(target: FabricObject | null) {

        const { selectedItem } = this.state;

        if (target && target.id && target.id !== 'workarea' && target.id.search(/^editor-layer-/) === -1 && target.type !== 'activeSelection') {

            if (selectedItem && target.id === selectedItem.id) {

                return;
            }

            this.setState({
                selectedItem: target,
            });

            return;
        }

        this.setState({
            selectedItem: null,
        });
    }

    /**
     * Drawing cancel callback
     */
    onCancel() {

        this.setState({
            selectedTool: null,
        });
    }

    /**
     * Zoom callback
     *
     * @param {number} zoom
     */
    onZoom(zoom: number) {

        const { height, width, meterHeight, meterWidth } = this.state;

        // calc scale on editord depends from coordinates and zoom value
        const scale = (this.state.meterWidth / this.state.width) * zoom;

        this.setState({
            zoomRatio: zoom,
            scale: scale,
        });
    }

    /**
     * Form submit handler
     *
     * @param {} model
     */
    onFormSave(model: any) {

        return 1;
    }

    /**
     * Cancel form handler
     */
    onFormCancel(): void {

        const { selectedItem, selectedTool } = this.state;

        if (this.canvasRef && selectedTool !== editorConstants.interaction.modeEdit) {

            this.canvasRef.handler.remove(selectedItem || undefined);
        }

        this.setState({ dialogOpened: false, targetObject: null });

        this.transformedPoints = null;
    }

    /**
     * Enable editable and selectable properties to objects
     *
     * @param mode
     */
    lockEditObj(mode: boolean) {

        if (this.canvasRef) {

            this.canvasRef.handler.getObjects().forEach((object: FabricObject) => {

                if (object.type !== 'pitCode') {
                    object.lockMovementX = mode;
                    object.lockMovementY = mode;
                    object.lockScalingX = mode;
                    object.lockScalingY = mode;
                    object.lockRotation = mode;
                }
            });

            this.canvasRef.canvas.renderAll();
        }
    }

    /**
     * On edit object attach callback to scale, move, rotate and click actions
     *
     * @param targetEl
     */
    onEdit(targetEl: FabricObject) {

        const { interaction, objects } = editorConstants,
            { allSensorInTree } = this.props,
            { formOpened } = this.state;

        const targetObject = this.objects.find(obj => obj.id === targetEl?.id),
        targetLiablePitCode = targetEl && allSensorInTree.find(sensor=> sensor.id === targetEl.sensorId || sensor.id === parseInt(targetEl.pidCode));

        if (this.state.selectedTool === interaction.modeEdit && targetObject) {

            let transformedPoints = null;

            if (targetEl.type === 'polygon') {

                const matrix = targetEl.calcTransformMatrix();

                transformedPoints = targetEl.get('points')
                    .map((p: { x: number, y: number }) => {

                        return fabric.util.transformPoint(
                            new fabric.Point(
                                p.x - targetEl.pathOffset.x,
                                p.y - targetEl.pathOffset.y),
                            matrix,
                        );
                    });
            }

            this.setState({
                pitCodeLabel: targetEl ? targetLiablePitCode?.pIdCode || targetLiablePitCode?.name : undefined,
                formTitle: targetObject.type ? this.capitalize(targetObject.type) : null,
                targetObject: { ...targetObject, center: targetEl.getCenterPoint() },
            });

            this.transformedPoints = transformedPoints;
        }

        if (targetEl && targetEl.objectId) {


            this.setState({
                formTitle: targetEl?.type ? this.props.t(targetEl.type) : '',
                targetObject: { ...targetEl as unknown as IObject },
                pitCodeLabel: targetLiablePitCode?.pIdCode || targetLiablePitCode?.name,
            });
        }

        if (!targetEl && !formOpened) {

            this.setState({ targetObject: null });
        }
    }

    /**
     * Capitalize text string
     *
     * @param {string} word
     * @return {string}
     */
    capitalize(word: string): string {
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    /**
     * Get rotate point accord to the passed angle value
     *
     * @param {fabric.Point} center
     * @param {number} angle
     *
     * @return fabric.Point
     */
    getRotatedPoint(center: fabric.Point, angle: number): fabric.Point {

        if (this.canvasRef?.handler && this.canvasRef.handler.workarea) {

            const { workarea } = this.canvasRef.handler;

            if (workarea.width && workarea.height && center) {

                const rads = fabric.util.degreesToRadians(360 - angle);

                const objOrigin = new fabric.Point(center.x, center.y);

                const canvasCenter = workarea.getCenterPoint();

                return fabric.util.rotatePoint(objOrigin, canvasCenter, rads);
            }
        }

        return center;
    }

    /**
     * Get target object data model
     */
    getTargetObjectModel() {

        const { targetObject } = this.state;

        if (targetObject) {

            const object: any = this.objects.find(obj => obj.id === targetObject.id) || {};

            return object;
        }

        return null;
    }

    getObjById = (id: string): IObject | null => {

        const result = this.objects.find(obj => obj.id === id);

        return result ? result : null;
    };

    /**
     * Get object by type method
     * @param type
     */
    getObjectsByType = (type: string) => this.objects.filter(object => object.type === type);

    /**
     * On delete method responsible to delete object from array of objects and from canvas object
     *
     * @param single
     * @param multiple
     */
    onDelete(single?: boolean, multiple?: boolean) {

        this.setState({ dialogOpened: false, selectedTool: null });
    }

    /**
     *
     *
     * @param {number} value
     */
    mapOpacityChange(value: number) {

        const i = value;

        if (this.canvasRef && this.canvasRef?.canvas) {

            const activeObjectsFabric: FabricObject[] | undefined = this.canvasRef.canvas.getObjects();

            const workareaObject = activeObjectsFabric ? activeObjectsFabric.find(obj => obj.id === 'workarea') : null;

            if (workareaObject) {

                (workareaObject as unknown as FabricObject).opacity = i;

                this.canvasRef.canvas.requestRenderAll();

            }
            this.setState({ schemaOpacity: i });

        }
    }

    /**
     *
     *
     * @param {boolean} schemaMain
     * @param {boolean} visible
     */
    changeVisibleObject(schemaMain: boolean,  visible: boolean) {

        if (this.canvasRef && this.canvasRef?.canvas) {

            const activeObjectsFabric: FabricObject[] | undefined = this.canvasRef.canvas.getObjects();

            const workareaObject = activeObjectsFabric ? activeObjectsFabric.find(obj => obj.id === 'workarea') : null;

            const activeObject = activeObjectsFabric ? activeObjectsFabric.filter(obj => obj.id !== 'workarea') : null;

            if (activeObject && !schemaMain) {

                (activeObject as unknown as FabricObject[]).map(obj => {

                    obj.visible = visible;

                });

                this.canvasRef.canvas.requestRenderAll();

                this.setState({ schemaObjectShow: visible });

            }

            if (schemaMain && workareaObject) {

                workareaObject.visible = visible;

                this.canvasRef.canvas.requestRenderAll();

                this.setState({ schemaMapShow: visible });
            }

        }
    }

    /**
     *
     *
     * @param {boolean} value
     */
    onShowMap(value: boolean) {

        this.changeVisibleObject(true, value);
    }

    /**
     *
     *
     * @param {boolean} value
     */
    onShowObjects(value: boolean) {

        this.changeVisibleObject(false, value);
    }

    /**
     * close editor
     */
    mapEditorClose() {

        const { src } = this.state;

        if (src) {

            URL.revokeObjectURL(src);

            this.setState({ src: '' });
        }

        this.props.toggleForm(true);

        if (this.props.schema) {

            this.props.editSchema(this.props.schema, true);

            this.props.updateSchema({
                ...this.props.schema,
                opacity: this.state.schemaOpacity,
                showObjects: this.state.schemaObjectShow ? '1' : '0',
                showMinimap: this.state.schemaMapShow ? '1' : '0',
            });
        }

    }

    /**
     * Action on form close
     */
    onCloseObjectForm() {


        const { targetObject } = this.state;

        if (this.canvasRef && targetObject) {

            const removeItem = this.canvasRef.handler.canvas.getObjects().find((item: FabricObject) => item.id === targetObject.id && !item.objectId);

            this.setState({ formOpened: false, targetObject: null });

            removeItem && this.canvasRef && this.canvasRef.canvas.remove(removeItem);

            this.lockEditObj(false);
        }

    }

    /**
     * Action on save form.
     *
     * @param {{values: {id?: number, name: string, factory: number, unit: number, angle: number, sensor_id: number, position: string}, targetObject: ITargetObject}} arg
     */
    onSaveObjectForm(arg: {
        values: {
            id?: number,
            name: string,
            factory: number,
            unit: number,
            angle: number,
            sensor_id: {
                label: string;
                value: string | number;
                type: string;
            },
            position: string,
        }, targetObject: ITargetObject
    }) {
        const { storeObjet, schema, updateObjet} = this.props;
        const { values, targetObject } = arg;


        const objectWithTheSameSensor = this.canvasRef && this.canvasRef.handler.canvas.getObjects().find((value: FabricObject) =>
            (value.sensorId === values.sensor_id.value || value.pitCode === values.sensor_id.value.toString()) &&
        value.id !== targetObject.id);

        if (targetObject) {
            const objectForSendData = this.canvasRef && this.canvasRef.handler.canvas.getObjects().find((value: FabricObject) => value.id === targetObject.id);

            const currentScale = objectForSendData?.scaleX && objectForSendData?.scaleY ? {
                x: objectForSendData.scaleX,
                y: objectForSendData.scaleY,
            } : null;

            const currentSensorForPidCode = this.props.allSensorInTree.find(sensor => sensor.id === values.sensor_id.value);

            if (!values.id && objectForSendData) {

                const sendDataPoints = targetObject.type === 'draw' ?
                    (objectForSendData as unknown as FabricObject).points.map((value: { x: number, y: number }) => ([value.x, value.y])) || null :
                    (objectForSendData as unknown as FabricObject).points?.map((value: number[]) => ({ x: value[0], y: value[1] }))[0] as any || null;

                const sendData: IHmiObject = {
                    pidCode: currentSensorForPidCode?.pIdCode || currentSensorForPidCode?.name || values.sensor_id.toString(),
                    fabric: values.factory,
                    unit: values.unit,
                    angle: Math.ceil((objectForSendData as unknown as FabricObject)?.angle || values.angle),
                    points: sendDataPoints,
                    position: {
                        x: objectForSendData.left || targetObject.center!.x,
                        y: objectForSendData.top || targetObject.center!.y,
                    },
                    type: Object(editorConstants.typeToId)[`${targetObject.type}`],
                    pidCodePosition: values.position,
                    hmiSchema: schema?.id || 0,
                    scale: currentScale,
                    sensorId: currentSensorForPidCode?.id || (targetObject as unknown as FabricObject).sensorId,
                };

                storeObjet(sendData);
            }

            if (values.id) {

                const sendDataPoints = (targetObject as unknown as FabricObject).type === 'polygon' ?
                    (targetObject as unknown as FabricObject).points
                        ?.map((value: { x: number, y: number }) => ([value.x, value.y])) as any || null
                    : null;

                const sendData: IHmiObject = {
                    id: values.id,
                    pidCode: currentSensorForPidCode?.pIdCode || currentSensorForPidCode?.name || values.sensor_id.value.toString(),
                    fabric: values.factory,
                    unit: values.unit,
                    angle: values.angle,
                    points: sendDataPoints,
                    position: {
                        x: (targetObject as unknown as FabricObject).left || 0,
                        y: (targetObject as unknown as FabricObject).top || 0,
                    },
                    type: Object(editorConstants.typeToId)[`${targetObject.type}`],
                    pidCodePosition: values.position,
                    hmiSchema: schema?.id || 0,
                    scale: currentScale,
                    sensorId: currentSensorForPidCode?.id || null,
                };

                updateObjet(sendData);
            }
        }

        if (!objectWithTheSameSensor) {

            this.onCloseObjectForm();
        }
    }

    /**
     *
     *
     * @param {{values: {id?: number, name: string, factory: number, unit: number, angle: number, sensor_id: {label: string, value: string | number, type: string}, position: string}, targetObject: ITargetObject}} arg
     */
    onChangeObjectForm(arg: {
        values: {
            id?: number,
            name: string,
            factory: number,
            unit: number,
            angle: number,
            sensor_id: {
                label: string;
                value: string | number;
                type: string;
            },
            position: string,
        }, targetObject: ITargetObject
    }) {
        if (this.canvasRef) {

            let currentTargetObject: FabricObject | undefined = undefined;
            let currentTargetLabelObject: FabricObject | undefined = undefined;

            this.canvasRef.handler.getObjects().forEach((obj: FabricObject) => {

                if (obj.id === arg.targetObject.id) {
                    currentTargetObject = obj;
                    obj.set({ angle: arg.values.angle, pidCodePosition: arg.values.position, sensorId: arg.values.sensor_id?.value || arg.targetObject.sensorId });
                }
                if (obj.itemId === arg.targetObject.id && currentTargetObject) {

                    currentTargetLabelObject = obj;
                }
            });

            if (currentTargetObject && currentTargetLabelObject) {

                const position = this.canvasRef.handler.getPositionRelativeToObject(currentTargetObject, currentTargetLabelObject);

                if (position) {

                    (currentTargetLabelObject as FabricObject).set(position);
                    (currentTargetLabelObject as FabricObject).setCoords();
                }
            }

            this.canvasRef && this.canvasRef.canvas.renderAll();
        }
    }

    /**
     * Action to open edit scheme form.
     */
    onEditScheme() {

        const { schema, toggleForm } = this.props;

        toggleForm(false, 'hmi', schema);

    }

    /**
     * Action to open object form
     */
    onOpenObjectForm() {

        this.setState({ formOpened: true });

    }

    /**
     * Action on change position object on scheme
     *
     * @param {FabricObject} targetEl
     */
    onChangePositionActiveObject(targetEl: FabricObject) {

        const { updateObjet, schema } = this.props;

        if (targetEl && 'objectId' in targetEl && schema) {

            const { hmiObjects } = schema;

            const originObject = hmiObjects?.find(obj=>obj.id === parseInt(targetEl.objectId));

            const currentScale = targetEl.scaleX && targetEl.scaleY ? {
                x: targetEl.scaleX,
                y: targetEl.scaleY,
            } : null;


            const sendDataPoints = targetEl.type === 'polygon' ?
                targetEl.points
                    ?.map((value: { x: number, y: number }) => ([value.x, value.y])) as any || null
                : null;

            const sendData: IHmiObject = {
                id: targetEl.objectId,
                pidCode: targetEl.pidCode,
                fabric: targetEl.factoryId,
                unit: targetEl.unitId,
                angle: targetEl.angle!,
                points: sendDataPoints,
                position: {
                    x: targetEl.left || 0,
                    y: targetEl.top || 0,
                },
                type: Object(editorConstants.typeToId)[`${targetEl.type}`],
                pidCodePosition: targetEl.pidCodePosition,
                hmiSchema: this.props.schema?.id || 0,
                scale: currentScale,
                sensorId: targetEl.sensorId,
            };

            if (originObject &&
                (Math.floor(originObject.position.y) !== Math.floor(sendData.position.y)
                    || Math.floor(originObject.position.x) !== Math.floor(sendData.position.x)
                    || Math.floor(originObject.angle) !== Math.floor(sendData.angle))) {

                updateObjet(sendData);
            }
        }

        if (targetEl && !('objectId' in targetEl) && schema) {
            const { targetObject } = this.state;

            this.setState({ targetObject: { ...targetObject as  ITargetObject, angle: targetEl.angle || 0, sensorId: targetEl?.sensorId || null }, });
        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const {  schema, hmiObjectErrors, t } = this.props;
        const {
            selectedTool,
            grab,
            zoomRatio,
            formOpened,
            formTitle,
            width,
            height,
            src,
            targetObject,
            workareaWidth,
            workareaHeight,
            pitCodeLabel,
            deleteDialogOpened,
            deleteItem,
            schemaOpacity,
            schemaMapShow,
            schemaObjectShow,
        } = this.state;

        const sliderOptions = {
            value: zoomRatio * 100,
            min: 30,
            max: 300,
        };

        const { modeDelete } = editorConstants.interaction;

        const activeObjectFabric: FabricObject | undefined = this.canvasRef?.canvas.getActiveObject();

        const activeObject = activeObjectFabric?.id ? this.getObjById(activeObjectFabric.id) : null;

        return src.length > 0 ? (
            <React.Fragment>
                <div id="map-editor-hmi" className="map-editor hmi">
                    <div className="font-preloader">.</div>
                    <LeftPanel
                        slider={sliderOptions}
                        grabActive={grab}
                        onZoomChange={this.sliderZoomChange}
                        onGrabSwitch={this.switchGrab}
                    />
                    <div className="map-editor-canvas-container">
                        <div style={{ display: 'flex' }}>
                            <MainMenu tools={this.menuTools} selectedTool={selectedTool} onSelect={this.selectTool} />
                            <div className="edit-scheme" onClick={this.onEditScheme}>{this.props.t('EDIT_SCHEME_HMI')}</div>
                        </div>
                        <div className="map-editor-canvas hmi">
                            <div className="map-editor-title">{schema?.name || ''}</div>
                            {targetObject && targetObject.id === activeObjectFabric?.id && pitCodeLabel?
                                <div className="map-editor-object-edit-group">
                                    <div className="objet-title">{this.props.t('SELECTED')} <span>{pitCodeLabel}</span></div>
                                    <Button
                                        color="primary"
                                        size="small"
                                        onClick={this.onOpenObjectForm}
                                    >
                                        {this.props.t('EDIT_OBJECT')}
                                    </Button>
                                </div>
                                : null
                            }
                            <Canvas ref={this.setCanvasRef}
                                    minZoom={30}
                                    maxZoom={300}
                                    workareaOptions={{
                                        src: src,
                                        width: width,
                                        height: height,
                                        opacity: schemaOpacity,
                                        visible: schemaMapShow,
                                    }}
                                    width={workareaWidth}
                                    height={workareaHeight}
                                    fabricObjects={CanvasObject}
                                    onAdd={this.onAdd}
                                    onRemove={this.onRemove}
                                    onSelect={this.onSelect}
                                    onZoom={this.onZoom}
                                    onEdit={this.onEdit}
                                    onMouseUp={this.onChangePositionActiveObject}
                                    onCancel={this.onCancel}
                            />
                        </div>
                    </div>
                    <FooterPanel
                        panelSetting={{
                            showMap: schemaMapShow,
                            showObjects: schemaObjectShow,
                            opacity: schemaOpacity,
                        }}
                        onSlide={this.mapOpacityChange}
                        onShowMap={this.onShowMap}
                        onShowObjects={this.onShowObjects}
                        onClose={this.mapEditorClose}
                    />
                    <DeleteDialog
                        open={deleteDialogOpened}
                        removeId={deleteItem?.objectId || null}
                        heading={t('REMOVE_THE_OBJECT')}
                        body={t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THE_OBJECT')}
                        onAccept={this.deleteHmiObject}
                        onClose={this.onDeleteCancel}
                    />
                </div>
                <RightSidebar openSidebar={formOpened}>
                    <ObjectForm
                        title={formTitle}
                        targetObject={activeObject || targetObject}
                        onSave={this.onSaveObjectForm}
                        onChange={this.onChangeObjectForm}
                        onCancel={this.onCloseObjectForm}
                    />
                </RightSidebar>
            </React.Fragment>
        ) : null;
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @returns {Object}
 */
const mapStateToProps = (state: RootState) => {
    const { hmiSchemaFormState } = state;
    const { picture } = hmiSchemaFormState;
    const schema = selectHmiOpenScheme(state),
        hmiObjectErrors = selectHmiObjectErrors(state),
        allSensorInTree = selectAllSensorItemInConfigurationTree(state);

    return {
        schema,
        picture,
        hmiObjectErrors,
        allSensorInTree,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = (dispatch: Dispatch<RootState, void, AnyAction>) => ({
    dispatch,
    ...bindActionCreators({
        storeObjet: HmiObjectAction.store,
        updateObjet: HmiObjectAction.update,
        updateSchema: HmiSchemaAction.update,
        toggleForm: FormActions.toggle,
        editSchema: HmiSchemaAction.editSchema,
        loadSchemas: HmiSchemaAction.list,
        deleteHmiObject: HmiObjectAction.delete,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Editor));
