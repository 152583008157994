import { fabric } from 'fabric';

/**
 * Marker object definition
 *
 * @type {any}
 */
const Marker = fabric.util.createClass(fabric.IText, {
    type: 'marker',
    superType: 'drawing',
    initialize(text: string, options: any) {

        options.label = text;
        options.fontFamily = 'Font Awesome 5 Free';
        options.fontWeight = 900;
        options.fontSize = 60;
        options.editable = false;
        options.evented = true;
        options.selectable = true;
        options.hasControls = false;
        options.hasBorders = false;
        options.hasRotatingPoint = false;
        options.fill = '#4092f5';
        options.originX = 'center';
        options.originY = 'bottom';
        options.objectCaching = false;
        options.ignoreZoom = true;
        options._fontSizeFraction = 0.12;
        options._fontSizeMult = 1;

        this.callSuper('initialize', '\uf041', options);

        this.font && this.font.load().then(() => {

            if (!this.loaded) {

                this.canvas.requestRenderAll();

                this.loaded = true;

            }
        });
    },
    transform: function (ctx: CanvasRenderingContext2D) {

        this.callSuper('transform', ctx);

        if (this.ignoreZoom && !this.group && this.canvas) {

            const zoom = 1 / this.canvas.getZoom();

            this.scale(zoom);

            if (this.prevZoom && this.prevZoom !== zoom) {

                this.canvas.requestRenderAll();
            }

            this.prevZoom = zoom;
        }
    },
    _render(ctx: CanvasRenderingContext2D) {

        this.callSuper('_render', ctx);

        ctx.save();

        ctx.fillStyle = '#ffffff';
        ctx.font = 'bold 16px IBM Plex Sans';

        const metrics = ctx.measureText(this.label);

        ctx.fillText(this.label, -((metrics.width + 1) / 2), 0);

        ctx.restore();
    },
});

Marker.fromObject = (options: any, callback: Function) => {

    const { text = '' } = options;

    return callback(new Marker(text, options));
};

//@ts-ignore
window.fabric.Marker = Marker;

export default Marker;
