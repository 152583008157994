import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { Button, DeleteDialog, EditMenu } from '../../../../core/ui/components';
import { ReactComponent as DropdownArrowWhiteIcon } from '../../../../core/ui/assets/images/icons/dropdown-arrow-white.svg';
import { ReactComponent as AlertPanelIcon } from '../../../../core/ui/assets/images/icons/alert-panel.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormActions,
    EditKpiAction,
    EditAlertAction,
    UnitActions,
    ConfigurationActions,
} from '../../../../core/actions';
import {
    IAlertRule,
    IKpi,
    IProcessItemDragDropTreeProps,
    IProcessItemDragDropTreeState,
    IProduct,
    IUnit,
} from '../../../../core/interfaces';
import { isIE } from 'react-device-detect';
import { selectAllProducts } from '../../../../core/selectors/product/productsSelector';
import { appConfig } from '../../../../config/appConfig';


const ProcessItem: React.FC<IProcessItemDragDropTreeProps> = (
    {
        btnTitle,
        action,
        name,
        svgIcon,
        processRule,
        productScheme,
        productSchemeTitle,
        descriptionTargetValue,
        productTitle,
        productInUnit,
        itemName,
        currentUnit,
        currentKPI,
        refWrap,
        scrollLeft,
        searchFilter,
    }: IProcessItemDragDropTreeProps,
) => {

    const dispatch = useDispatch(),
        { t } = useTranslation(),
        products = useSelector(selectAllProducts);

    /**
     * Default state
     */
    const [state, setState] = useState<IProcessItemDragDropTreeState>({
        removeId: null,
        activate: true,
        activateID: [],
        productTitle: [],
    });

    const [removeAlert, setRemoveAlert] = useState<boolean>(false);

    const [elementContainer, setElementContainer] = useState<Element | null>(null);

    const [kpiMenuActive, setKpiMenuActive] = useState(false);

    /**
     *  Product Deactivation Handler
     *
     * @param {Object} props
     */
    // const deactivate = (props: any) => {
    //
    //     const {activateID} = state;
    //
    //     if (activateID.indexOf(parseInt(props.id)) < 0) {
    //
    //         const newItem = activateID;
    //
    //         newItem.push(parseInt(props.id))
    //
    //         setState({...state, activateID: newItem});
    //
    //     } else {
    //
    //         setState({...state, activateID: activateID.filter(value => value !== parseInt(props.id))});
    //     }
    //
    // }

    /**
     * Product Delete Handler
     *
     * @param item
     */
    const deleteItem = (item: { current: IProduct }) => {

        setState({ ...state, removeId: item.current });

    };

    /**
     * KPI Delete Handler
     *
     * @param data
     */
    const deleteKpi = (data: IKpi) => {

        setState({ ...state, removeId: data });

    };

    /**
     * KPI Delete Handler
     *
     */
    const deleteAlert = () => {

        setRemoveAlert(true);
    };

    /**
     * KPI Delete Handler
     *
     */
    const editKpi = () => {

        if (currentUnit && currentKPI) {

            const kpiData: { unit: IUnit, kpi: IKpi } = {
                unit: currentUnit,
                kpi: currentKPI,
            };

            dispatch(EditKpiAction.store(kpiData));
        }

        setState({ ...state, removeId: null });

        dispatch(FormActions.toggle(false, 'kpi'));

    };

    useEffect(() => {

        setElementContainer(document.getElementsByClassName('configure-draggable')[0]);

    }, [setElementContainer]);

    /**
     * Product Confirm Delete Handler
     *
     */
    const confirmDeleteItem = useCallback(() => {

        if (currentUnit && state.removeId) {

            const updateData = { ...currentUnit };

            updateData.products = [state.removeId.id];

            dispatch(UnitActions.update(updateData, 'remove', state.removeId));
        }

        setState({ ...state, removeId: null });

    }, [setState, dispatch, state, currentUnit]);

    /**
     * Handler when closing a popup
     */
    const onCloseDialog = useCallback(() => {

        setState({ ...state, removeId: null });

    }, [setState, state]);

    /**
     * Product Confirm Delete Handler
     *
     */
    const confirmDeleteAlert = useCallback(() => {

        if (currentUnit) {

            dispatch(ConfigurationActions.update('unit', currentUnit.id, {
                isMinimized: Boolean(currentUnit.isMinimized),
                alertsEnabled: false,
            }));

            setRemoveAlert(false);

            const reloadTreeTimeout = setTimeout(() => {

                dispatch(ConfigurationActions.list(true));

                clearTimeout(reloadTreeTimeout);

            }, 300);
        }


    }, [setRemoveAlert, dispatch, currentUnit]);

    /**
     * Handler when closing a popup
     */
    const onCloseDialogAlert = useCallback(() => {

        setRemoveAlert(false);

    }, [setRemoveAlert]);

    /**
     * callback  closing edit menu
     *
     * @type {(event) => void}
     */
    const editMenuCloseCallback = useCallback((event) => {

        setKpiMenuActive(Boolean(event));

    }, [setKpiMenuActive]);

    /**
     * callback  opening edit menu
     *
     * @type {(event) => void}
     */
    const editMenuOpenCallback = useCallback((event) => {

        setKpiMenuActive(Boolean(event));

        dispatch(FormActions.toggle(true, ''));

    }, [setKpiMenuActive, dispatch]);

    /**
     * menu KPI state
     */
    const [menuKPI] = useState<Record<string, unknown>[]>([
        {
            title: t('EDIT_FORMULA'),
            action: editKpi,
            color: '',
        },
        {
            title: t('DELETE'),
            action: deleteKpi,
            icon: '',
            color: 'red',
        },
    ]);

    /**
     * menu Alert
     */
    const [menuAlert] = useState<Record<string, unknown>[]>([
        {
            title: t('DELETE'),
            action: deleteAlert,
            icon: '',
            color: 'red',
        },
    ]);


    if (!processRule) {

        return null;
    }

    /**
     * return alert icon
     *
     * @param {number} i
     * @return {JSX.Element}
     */
    const alertIcon = (i: number) => {

        const currentAlertRules = products.find(value => value.id === i);

        const AlertRulesByUnit = currentAlertRules?.alertRules?.filter(value => (value.unit as IUnit).id === currentUnit?.id);

        if (itemName === 'ALERT' && AlertRulesByUnit && AlertRulesByUnit.length !== 0) {

            return <AlertPanelIcon style={{ width: 24, height: 24, marginLeft: 4 }}/>;
        }
    };

    /**
     * Return current alert
     *
     * @param {{alert: IAlertRule[]}[]} product
     * @param {number} index
     * @return {{alert: IAlertRule[]}}
     */
    const currentAlert = (product: { alert: IAlertRule[] }[], index: number) => {

        return product[index];

    };

    /**
     * Action button click action.
     *
     * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
     */
    const actionButtonClickAction = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        event.preventDefault();

        const index = event.currentTarget.tabIndex;

        if (action) {
            action();
        }

        if (itemName === 'ALERT' && currentUnit) {

            const currentAlertByUnit = currentAlert(currentUnit.products, index);

            dispatch(EditAlertAction.store({
                unit: currentUnit,
                alert: currentAlertByUnit,
            }));
        }
    };

    /**
     *  Action button
     *
     * @return React.ReactNode
     */
    const actionButton = () => {

        return (
            <React.Fragment>
                {currentUnit && currentUnit.products.length > 0 && currentUnit.products.map((product, index, products) => (
                    <div
                        key={index}
                        className={`description__targetvalue ${descriptionTargetValue} ${products.length === (index + 1) ? 'last-child' : ''}`}
                    >
                        <Button
                            color="primary"
                            size="small"
                            tabIndex={index}
                            onClick={actionButtonClickAction}
                        >
                            {btnTitle}
                        </Button>
                        {alertIcon(product.id)}
                    </div>
                ))}
            </React.Fragment>
        );

    };

    /**
     * Return empty block
     *
     * @param {number} value
     *
     * @return {JSX}
     */
    const emptyProductSpace = (value: number) => {

        const content = [];

        for (let i = 0; i < value; i++) {

            content.push(
                <div key={i + 1} className={`empty-product-space ${value === (i + 1) ? 'last-child' : ''}`}
                     style={{
                         width: value === (i + 1) ? 144 : 152,
                         background: '#f5f6f7',
                         borderBottom: '1px solid #e9ecef',
                     }}
                />);
        }

        return content;
    };

    const numberOfInitialLines = 2;

    const productSelectWrapStyle: CSSProperties = {
        position: 'absolute',
        height: refWrap && refWrap.status && currentUnit && currentUnit.data ?
            (currentUnit.data.filter(value => value.name.toLowerCase().match(searchFilter)).length + numberOfInitialLines)
            * appConfig.defaultHeightConfigTreeItem
            : appConfig.defaultHeightConfigTreeItem * numberOfInitialLines,
        transition: 'height ease-out 0s',
        top: 0,
        left: 0,
        zIndex: 4,
        overflow: 'visible',
    };

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    return (
        <React.Fragment>
            <div className={`item-title no-pointer ${productSchemeTitle ? 'product-scheme' : ''}`}>
                <div
                    className={`right-section ${productScheme} ${kpiMenuActive ? 'active' : ''}`}
                    style={{
                        zIndex: 23,
                        left: isIE ? scrollLeft : 0,
                        position: isIE ? 'relative' : 'sticky',
                    }}
                >
                    {productSchemeTitle ?
                        <div className="product-select-wrap"
                             style={productSelectWrapStyle}
                        />
                        :
                        null
                    }
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {svgIcon ? svgIcon : null}
                        {name}
                        {productSchemeTitle ?
                            <div className="product-scheme-title">
                                {productSchemeTitle}
                            </div>
                            :
                            null
                        }
                        {itemName === 'ALERT' ?
                            <span className={'edit-kpi-menu--wrap'}>
                                <EditMenu
                                    dataItem={{}}
                                    menuList={menuAlert}
                                    container={elementContainer}
                                    classNameMenu={'edit-kpi-menu'}
                                    onOpen={editMenuOpenCallback}
                                    onClose={editMenuCloseCallback}
                                />
                            </span>
                            : null}
                        {itemName === 'KPI' && currentKPI ?
                            <span className={'edit-kpi-menu--wrap'}>
                                <EditMenu
                                    dataItem={{ current: currentKPI }}
                                    menuList={menuKPI}
                                    container={elementContainer}
                                    classNameMenu={'edit-kpi-menu'}
                                    onOpen={editMenuOpenCallback}
                                    onClose={editMenuCloseCallback}
                                />
                            </span>
                            : null
                        }
                    </div>
                </div>
                <div className="left-section setting-box ">
                    <div className={'setting-box--empty-space lightgrey ' + itemName.toLowerCase()} />
                    {productTitle && productTitle.map((value, index) => (
                        <div
                            key={value.id}
                            className={`description__targetvalue ${index !== 0 ? 'second-child' : ''} ${descriptionTargetValue} ${!value.active ? '' : ' deactivate'}`}
                        >
                                <span className="product-title">
                                    <p>{value.name}</p>
                                    <div style={{ cursor: 'pointer', zIndex: 999, position: 'relative' }}>
                                            <DropdownArrowWhiteIcon
                                                style={{
                                                    marginTop: -8,
                                                    marginBottom: -8,
                                                }}
                                            />
                                            <EditMenu
                                                dataItem={{ current: value }}
                                                menuList={[
                                                    //TODO after API add activation
                                                    // {
                                                    //     title: props.t(value.active ?'DEACTIVATE':'ACTIVATE'),
                                                    //     action: deactivate,
                                                    //     color: ''
                                                    // },
                                                    {
                                                        title: t('DELETE'),
                                                        action: deleteItem,
                                                        icon: '',
                                                        color: 'red',
                                                    },
                                                ]}
                                                container={elementContainer}
                                                classNameMenu={'edit-product-menu'}
                                            />
                                    </div>
                                </span>
                            <ul className={'target-value-range'}>
                                <li>{t('MIN')}</li>
                                <li>{t('MAX')}</li>
                            </ul>
                        </div>
                    ))}
                    {btnTitle ? actionButton() : emptyProductSpace(productInUnit ? productInUnit : 0)}
                </div>
            </div>
            {state.removeId ?
                <DeleteDialog
                    open={Boolean(state.removeId)}
                    removeId={state.removeId.id as number}
                    heading={t('DELETE_PRODUCT_Q', { name: state.removeId?.name as string })}
                    body={t('REMOVE_PRODUCT_FROM_UNIT_IN_THE_SETTING')}
                    onAccept={confirmDeleteItem}
                    onClose={onCloseDialog}
                    className={'configuration-popover'}
                /> : null
            }
            {removeAlert ?
                <DeleteDialog
                    open={removeAlert}
                    removeId={currentUnit?.id as number}
                    heading={t('DELETE_ALERT_Q', { name: currentUnit?.name as string })}
                    body={t('REMOVE_ALERT_FROM_UNIT')}
                    onAccept={confirmDeleteAlert}
                    onClose={onCloseDialogAlert}
                    className={'configuration-popover'}
                /> : null
            }
        </React.Fragment>
    );
};

export default ProcessItem;
