import { IStateItem } from '../../core/interfaces';


/**
 * Generate state intervals to find a color from state
 *
 * @param {any[]} states
 */
export const useGenerateStateIntervals = (states: IStateItem[]):[
    number,
    {start: number, state: IStateItem, end: number}[],
] => {
    let statesIntervalTotal = 0;

    const statesPerInterval = [];

    for (const state of states) {

        const interval = {
            start: statesIntervalTotal,
            state: state,
            end: 0,
        };

        statesIntervalTotal += Math.abs((new Date(state.startTime).getTime() - new Date(state.endTime).getTime()) / 1000);

        interval.end = statesIntervalTotal;

        statesPerInterval.push(interval);
    }
    return [statesIntervalTotal, statesPerInterval];
};