import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { IDepartment, IEmployee } from '../interfaces';


export interface IDepartmentApiModel {
    id: number;
    name: string;
    description: string;
    employees?: IEmployee[];
}


export function mapToDepartmentModel(rawDepartment: IDepartmentApiModel): IDepartment {

    return {
        id: rawDepartment.id,
        description: rawDepartment.description,
        name: rawDepartment.name,
        employees: rawDepartment.employees ? rawDepartment.employees : [],
    };
}

/**
 * Service to work with Employee API resources
 *
 * @class EmployeeService
 */
export class DepartmentService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/departments(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     return await    * Get list of Department
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<IDepartment[]> {

        return await this.http
            .get<IDepartmentApiModel[]>(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            })
            .then(this.getDataExtractor())
            .then(list => list.map(mapToDepartmentModel));
    }

    /**
     * Create Department
     *
     * @param { IDepartment } department
     */
    store(department: IDepartment): Promise<IDepartment> {


        return trackPromise(
            this.http
                .post<IDepartmentApiModel>(this.url(), department, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToDepartmentModel)
        );
    }

    /**
     * Update Department
     *
     * @param { IDepartment } department
     */
    update(department: IDepartment): Promise<IDepartment> {

        const formData = mapToDepartmentApiModel(department);

        return trackPromise(
            this.http
                .patch<IDepartmentApiModel>(this.url({ id: department.id }), formData, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToDepartmentModel)
        );
    }

    /**
     * Delete Department
     * @param { IDepartment } department
     */
    delete(department: IDepartment): Promise<boolean> {

        return trackPromise(
            this.http
                .delete<IDepartmentApiModel>(this.url({ id: department.id }), {
                    headers: this.headers,
                })
                .then(Boolean)
        );
    }

}

export function mapToDepartmentApiModel(department: IDepartment): IDepartmentApiModel {

    return {
        id: department.id,
        description: department.description,
        name: department.name,
    };
}
