import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { appConfig } from '../../../config/appConfig';

export const selectGraphStructuralTreeVisibility = createSelector(
    (state: RootState) => state.graphStructuralTreeVisibility,
    (graphStructuralTreeVisibility) => graphStructuralTreeVisibility,
);

export const selectVisibleSideBar = createSelector(
    [selectGraphStructuralTreeVisibility],
    (graphStructuralTreeVisibility) => graphStructuralTreeVisibility.visible,
);

export const selectMaxWidthSideBar = createSelector(
    [selectGraphStructuralTreeVisibility],
    (graphStructuralTreeVisibility) => graphStructuralTreeVisibility.maxWidth || appConfig.dashboardLeftOffsetLarge,
);
