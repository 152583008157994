import { fabric } from 'fabric';
import { editorConstants } from '../../constants';

const Indicator = fabric.util.createClass(fabric.Object, {

    type: editorConstants.objects.indicator,

    superType: 'drawing',

    initialize(options: any) {

        options.originX = 'center';
        options.originY = 'center';
        options.width = 24;
        options.height = 24;

        this.callSuper('initialize', options || {});
    },

    _render(ctx: CanvasRenderingContext2D) {

        const { strokeColor = '#74797d', fillColor = '#ffffff' } = this;

        ctx.fillStyle = strokeColor;

        ctx.beginPath();
        ctx.moveTo(-12, -7);
        ctx.bezierCurveTo(-12, -7.5, -11.5, -8, -11, -8);
        ctx.lineTo(11, -8);
        ctx.bezierCurveTo(11.5, -8, 12, -7.5, 12, -7);
        ctx.lineTo(12, 6.8);
        ctx.bezierCurveTo(12, 7.5, 11.5, 8, 11, 8);
        ctx.lineTo(-11, 8);
        ctx.bezierCurveTo(-11.5, 8, -12, 7.5, -12, 6.8);
        ctx.lineTo(-12, -7);
        ctx.closePath();

        ctx.fill();

        ctx.fillStyle = fillColor;

        ctx.beginPath();
        ctx.moveTo(-10, -3.75);
        ctx.bezierCurveTo(-10, -4.5, -9.5, -5, -9, -5);
        ctx.lineTo(9, -5);
        ctx.bezierCurveTo(9.5, -5, 10, -4.5, 10, -3.75);
        ctx.lineTo(10, 3.75);
        ctx.bezierCurveTo(10, 4.5, 9.5, 5, 9, 5);
        ctx.lineTo(-9, 5);
        ctx.bezierCurveTo(-9.5, 5, -10, 4.5, -10, 3.75);
        ctx.lineTo(-10, -3.75);
        ctx.closePath();

        ctx.fill();

        ctx.fillStyle = '#333333';

        ctx.beginPath();
        ctx.moveTo(-7, -2);
        ctx.lineTo(-5.5, -2);
        ctx.bezierCurveTo(-5.2, -2, -5, -2, -4.7, -1.9);
        ctx.bezierCurveTo(-4.5, -1.8, -4.3, -1.7, -4.2, -1.5);
        ctx.bezierCurveTo(-4, -1.3, -3.9, -1.1, -3.8, -0.9);
        ctx.bezierCurveTo(-3.7, -0.6, -3.7, -0.4, -3.7, 0);
        ctx.bezierCurveTo(-3.7, 0.3, -3.7, 0.6, -3.8, 0.9);
        ctx.bezierCurveTo(-3.9, 1.1, -4, 1.3, -4.2, 1.5);
        ctx.bezierCurveTo(-4.3, 1.7, -4.5, 1.8, -4.7, 1.9);
        ctx.bezierCurveTo(-5, 1.9, -5.2, 2, -5.5, 2);
        ctx.lineTo(-7, 2);
        ctx.lineTo(-7, -2);

        ctx.closePath();

        ctx.moveTo(-5.5, 1.2);
        ctx.bezierCurveTo(-5.2, 1.2, -5, 1.1, -4.8, 1);
        ctx.bezierCurveTo(-4.7, 0.9, -4.6, 0.6, -4.6, 0.3);
        ctx.lineTo(-4.6, -0.3);
        ctx.bezierCurveTo(-4.6, -0.6, -4.7, -0.9, -4.8, -1);
        ctx.bezierCurveTo(-5, -1.2, -5.2, -1.2, -5.5, -1.2);
        ctx.lineTo(-6.1, -1.2);
        ctx.lineTo(-6.1, 1.2);
        ctx.lineTo(-5.5, 1.2);

        ctx.closePath();

        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(-0.6, 2);
        ctx.lineTo(-0.9, 1);
        ctx.lineTo(-2.3, 1);
        ctx.lineTo(-2.6, 2);
        ctx.lineTo(-3.5, 2);
        ctx.lineTo(-2.1, -2);
        ctx.lineTo(-1, -2);
        ctx.lineTo(0.3, 2);
        ctx.lineTo(-0.6, 2);

        ctx.closePath();

        ctx.moveTo(-1.6, -1.2);
        ctx.lineTo(-1.6, -1.2);
        ctx.lineTo(-2, 0.3);
        ctx.lineTo(-1.1, 0.3);
        ctx.lineTo(-1.6, -1.2);

        ctx.closePath();

        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(2.2, -1.2);
        ctx.lineTo(2.2, 2);
        ctx.lineTo(1.3, 2);
        ctx.lineTo(1.3, -1.2);
        ctx.lineTo(0.2, -1.2);
        ctx.lineTo(0.2, -2);
        ctx.lineTo(3.3, -2);
        ctx.lineTo(3.3, -1.2);
        ctx.lineTo(2.2, -1.2);

        ctx.closePath();

        ctx.fill();

        ctx.beginPath();
        ctx.moveTo(6, 2);
        ctx.lineTo(5.8, 1);
        ctx.lineTo(4.4, 1);
        ctx.lineTo(4.1, 2);
        ctx.lineTo(3.2, 2);
        ctx.lineTo(4.5, -2);
        ctx.lineTo(5.6, -2);
        ctx.lineTo(7, 2);
        ctx.lineTo(6, 2);

        ctx.closePath();

        ctx.moveTo(5.1, -1.2);
        ctx.lineTo(5, -1.2);
        ctx.lineTo(4.6, 0.3);
        ctx.lineTo(5.5, 0.3);
        ctx.lineTo(5.1, -1.2);

        ctx.closePath();

        ctx.fill();

        ctx.restore();
    },
});

Indicator.fromObject = (options: any, callback: Function) => {

    return callback(new Indicator(options));
};

// @ts-ignore
window.fabric.Indicator = Indicator;

export default Indicator;
