import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IUpdateOverrideOptions } from './../../core/interfaces/props';
import { AxiosResponse } from 'axios';

export class GraphDataService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {
        return '/graph-data/sensor';
    }

    /**
     * 
     * @param sensorId 
     * @param range 
     * @returns 
     */
    async item(sensorId: number, range: IUpdateOverrideOptions): Promise<AxiosResponse> {

        const { startDate: from, endDate: to, screenWidth: numberOfDataPoints } = range;

        const accessToken = localStorage.getItem('auth_token');

        return trackPromise(this.http
            .post(this.url(), {
                sensorId,
                from,
                to,
                access_token: accessToken,
                numberOfDataPoints,
            }, {
                headers: this.headers,
            }));
    }
}