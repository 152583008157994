import store from '../store';
import { hmiPlayerConstants } from '../constants';
import { selectHmiPlayerMode, selectHmiPlayerValue, selectHmiPlayerSpeed } from '../selectors/hmi/playerSelector';
import { selectBrushSelection } from '../selectors/graphMinimapBrush/graphMinimapBrushSelector';
import { PlayerMode } from '../interfaces';

/**
 * Watcher update interval in milliseconds
 *
 * @type {number}
 */
const INTERVAL = 500;

/**
 * HMI PLayer watcher
 *
 * @class HmiPlayerWatcher
 */
export class HmiPlayerWatcher {

    /**
     * @type {function}
     */
    subscription: () => void;

    /**
     * @type {NodeJS.Timeout}
     */
    interval: NodeJS.Timeout | null;

    /**
     * HmiPlayerWatcher constructor
     */
    constructor() {

        this.subscription = store.subscribe(() => {

            this.listener();
        });

        this.interval = null;
    }

    /**
     * Unsubscribe from store
     */
    unsubscribe(): void {

        this.subscription();
    }

    /**
     * Store listener
     */
    listener(): void {

        const state = store.getState();

        const playerMode = selectHmiPlayerMode(state);

        if (playerMode === PlayerMode.MODE_PLAY && !this.interval) {

            const setValueAction = (value: number) => {

                return {
                    type: hmiPlayerConstants.SET_VALUE,
                    value,
                };
            };

            const stopPlayAction = () => {

                return {
                    type: hmiPlayerConstants.STOP,
                };
            };

            this.interval = setInterval(() => {

                const state = store.getState();

                const speed = selectHmiPlayerSpeed(state);
                let value = selectHmiPlayerValue(state);

                value += (INTERVAL * speed);

                const [, endDate] = selectBrushSelection(state);

                if (value >= endDate.getTime()) {

                    store.dispatch(stopPlayAction());

                } else {

                    store.dispatch(setValueAction(value));
                }

            }, INTERVAL);

        } else if (playerMode !== PlayerMode.MODE_PLAY && this.interval) {

            clearInterval(this.interval);

            this.interval = null;
        }
    }
}
