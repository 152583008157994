import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'element-closest-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import store from './core/store';
import './core/i18n';
import 'normalize.css';
import './core/ui/assets/css/styles.scss';

import App from './App';
import { LoadingSpinner } from './core/ui/components';

const theme = createTheme({
    palette: {
        // @ts-ignore
        default: {
            light: '#f44336',
            main: '#f44336',
            dark: '#f44336',
            contrastText: '#f44336',
        },
        primary: {
            light: '#0f448f',
            main: '#333333',
            dark: '#0f448f',
            contrastText: '#fff',
        },
        secondary: {
            light: '#bdab81',
            main: '#ac955f',
            dark: '#bdab81',
            contrastText: '#fff',
        },
    },
});

if (process.env.REACT_APP_SENRTY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENRTY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

// ========================================

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
            <LoadingSpinner />
            <App />
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);
