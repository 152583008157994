import ApiProvider from '../providers/apiProvider';
import {
    IActivateProduct,
    IFlatTreeFactory,
    IFlatTreeProcess,
    IFlatTreeUnit,
    IProduct,
    ISensor,
    IUnit,
} from '../interfaces';
import { trackPromise } from 'react-promise-tracker';
import { AxiosResponse } from 'axios';
import { selectConfigurationTree } from '../selectors/configurationTree/configurationTreeCollectionSelector';
import store from '../store';

/**
 * Service to work with user API resources
 *
 * @class ProductService
 */
export class ConfigurationFlatTreeService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/configuration-tree/list';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [''];
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    async list(trackerLogic?: boolean): Promise<AxiosResponse> {

        if (!trackerLogic) {
            return trackPromise(
                this.http
                    .get(this.url(), {
                        headers: { ...this.headers, 'Pragma': 'no-cache' },
                    }));
        } else {
            return this.http
                .get(this.url(), {
                    headers: { ...this.headers, 'Pragma': 'no-cache' },
                });

        }
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    async getPartOfList(entityType: string, entityId: number): Promise<AxiosResponse> {

        const newUrl = `${this.url()}?entityType=${entityType}&entityId=${entityId}`;

        return this.http
                .get(newUrl, {
                    headers: { ...this.headers, 'Pragma': 'no-cache' },
                });
    }

    /**
     * Update local tree data
     *
     * @param {{product: IActivateProduct, unit: IUnit, type: string}} mutateData
     * @param updateData
     * @param updateType
     */
    updateLocalTree(
        mutateData: {
            product?: IActivateProduct | IProduct | null,
            type: string,
            unit?: IUnit | undefined,
            sensor?: ISensor | undefined
            unitId?: number | null;
        },
        updateData?: any[],
        updateType?: string,
    ): (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[] {

        const configurationTree = selectConfigurationTree(store.getState());
        const { product, sensor, unitId } = mutateData;

        if (product || sensor || updateType || updateData) {
            return configurationTree.map(treeItem => {

                if (treeItem.type === 'unit') {
                    if (treeItem.id === mutateData?.unit?.id) {

                        if (mutateData.type === 'add') {

                            (treeItem as IFlatTreeUnit).products.push(product as IActivateProduct);

                            (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data.map(value => {
                                if (value.targetValues) {
                                    value.targetValues.push({
                                        maxTargetValue: null,
                                        minTargetValue: null,
                                        productId: product?.id || 0,
                                        sensorId: value.id,
                                    });

                                    return { ...value };
                                }
                                return { ...value };
                            });

                            return { ...treeItem };
                        }

                        if (mutateData.type === 'remove' && product) {

                            const removeIndex = (treeItem as IFlatTreeUnit).products.findIndex(value => value.id === product.id);

                            (treeItem as IFlatTreeUnit).products.splice(removeIndex, 1);

                            (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data.map(value => {

                                if (value.targetValues) {

                                    value.targetValues = value.targetValues.filter(tv => tv.productId !== product.id);


                                }

                                return { ...value };
                            });

                            return { ...treeItem };
                        }

                    }

                    if (mutateData.type === 'sensor') {

                        if (unitId && treeItem.id === unitId && sensor) {

                            (treeItem as IFlatTreeUnit).data = [...(treeItem as IFlatTreeUnit).data, sensor];
                        }

                        if (unitId && (treeItem as IFlatTreeUnit).id !== unitId) {

                            (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data.filter(value => value.id !== sensor?.id);
                        }

                        (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data.map(value => {

                            if (value.id === sensor?.id) {

                                return { ...value, ...sensor };

                            }

                            if (value.id !== sensor?.id && sensor?.isKeyParameter && (treeItem as IFlatTreeUnit).id === sensor?.unit) {

                                value.isKeyParameter = false;

                                return { ...value };

                            }

                            return { ...value };
                        });
                    }

                }

                if (updateType && updateData && updateType === treeItem.type) {

                    const [updateDataItem] = updateData;

                    if (updateDataItem.id == treeItem.id) {

                        return { ...treeItem, ...updateDataItem };
                    }
                }

                return treeItem;
            });
        }

        return configurationTree;
    }

}