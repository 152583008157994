import { createSelector } from 'reselect';
import { selectMousePosition } from '../mouse/mouseSelector';
import { RootState } from '../../store';
import { getWidthFromMousePosition } from '../../functions/getWidthFromMousePosition';
import { getHeightFromMousePosition } from '../../functions/getHeightFromMousePosition';

const stateSelector = (state: RootState) => state.responsiveDrawer;

export const selectDrawerIsResize = createSelector(
    stateSelector,
    drawer => drawer.isResize,
);

export const selectDrawerLastMousePosition = createSelector(
    stateSelector,
    drawer => drawer.lastMousePosition,
);

export const selectPositionDrawer = createSelector(
    stateSelector,
    drawer => drawer.positionDrawer,
);

export const selectIsFullScreenDrawer = createSelector(
    stateSelector,
    drawer => drawer.isFullScreen,
);

//TODO: Do a similar calculation for height, when player position is `top` or `bottom`
//TODO: Use left sidebar width to limit player resize
export const selectDrawerWidth = createSelector(
    selectDrawerIsResize,
    selectMousePosition,
    selectDrawerLastMousePosition,
    (isResize, position, lastMousePosition) => isResize ? getWidthFromMousePosition(position) : getWidthFromMousePosition(lastMousePosition),
);

export const selectDrawerHeight = createSelector(
    selectDrawerIsResize,
    selectMousePosition,
    selectDrawerLastMousePosition,
    (isResize, position, lastMousePosition) => isResize ? getHeightFromMousePosition(position) : getHeightFromMousePosition(lastMousePosition),
);
