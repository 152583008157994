export const stateConstants = {
    LIST_SUCCESS: 'STATE_LIST_SUCCESS',
    LIST_FAILURE: 'STATE_LIST_FAILURE',

    STORE_CATEGORY_SUCCESS: 'STATE_CATEGORY_STORE_SUCCESS',
    STORE_CATEGORY_FAILURE: 'STATE_CATEGORY_STORE_FAILURE',

    STORE_REASON_SUCCESS: 'STATE_REASON_STORE_SUCCESS',
    STORE_REASON_FAILURE: 'STATE_REASON_STORE_FAILURE',

    STORE_STATES_ITEM_SUCCESS: 'STATE_STATES_ITEM_SUCCESS',
    STORE_STATES_ITEM_FAILURE: 'STATE_STATES_ITEM_FAILURE',

    STORE_ON_DRAG_END_SUCCESS: 'STATE_ON_DRAG_END_STORE_SUCCESS',
    STORE_ON_DRAG_END_FAILURE: 'STATE_ON_DRAG_END_STORE_FAILURE',

    UPDATE_SUCCESS: 'STATE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'STATE_UPDATE_FAILURE',

    UPDATE_STATES_SUCCESS: 'STATES_UPDATE_SUCCESS',
    UPDATE_STATES_FAILURE: 'STATES_UPDATE_FAILURE',

    UPDATE_REASON_SUCCESS: 'REASON_UPDATE_SUCCESS',
    UPDATE_REASON_FAILURE: 'REASON_UPDATE_FAILURE',

    DELETE_SUCCESS: 'STATE_DELETE_SUCCESS',
    DELETE_FAILURE: 'STATE_DELETE_FAILURE',

    DELETE_STATES_SUCCESS: 'STATES_DELETE_SUCCESS',
    DELETE_STATES_FAILURE: 'STATES_DELETE_FAILURE',

    DELETE_REASON_SUCCESS: 'REASON_DELETE_SUCCESS',
    DELETE_REASON_FAILURE: 'REASON_DELETE_FAILURE',

    SHOW_DETAILS: 'STATE_SHOW_DETAILS',
    HIDE_DETAILS: 'STATE_HIDE_DETAILS',

    SELECT: 'STATE_SELECT',
    DESELECT_ALL: 'STATE_DESELECT_ALL',

    HOVERED: 'STATE_HOVERED',
    UNHOVERED: 'STATE_UNHOVERED',

    STORE_CURRENT_LIST_STATE_SUCCESS: 'STORE_CURRENT_LIST_STATE_SUCCESS',
    STORE_CURRENT_LIST_STATE_FAILURE: 'STORE_CURRENT_LIST_STATE_FAILURE',
};
