import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IFilter, IJoin, IOrder, ICause } from '../interfaces';
import { causesConstants } from '../constants';
import { CausesService } from '../services';

/**
 * Unit related actions
 *
 * @type {Object}
 */
export const causesActions = {

    /**
     *  Get list States
     *
     * @param { String } search Search string
     * @param { Object } order Sort settings
     * @param { IJoin } join
     * @param { IFilter } filter
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'desc' }, join?: IJoin, filter?: IFilter) => {

        //Action creators
        const success = (causes: ICause[]) => {

            return {
                type: causesConstants.LIST_SUCCESS,
                causes,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: causesConstants.LIST_FAILURE,
                errors,
            };
        }, service = new CausesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join, filter)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * store cause
     *
     * @param { ICause } causes
     *
     *  @return {Promise<Object>}
     */
    store: (causes: ICause) => {

        //Action creators
        const success = (causes: ICause) => {

            return {
                type: causesConstants.STORE_CAUSES_SUCCESS,
                causes,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: causesConstants.STORE_CAUSES_FAILURE,
                errors,
            };

        }, service = new CausesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.store(causes)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Updated cause
     *
     * @param { ICause } causes
     *
     *  @return {Promise<Object>}
     */
    update: (causes: ICause) => {

        //Action creators
        const success = (causes: ICause) => {

            return {
                type: causesConstants.UPDATE_SUCCESS,
                causes,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: causesConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new CausesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(causes)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Delete cause by ID
     *
     * @param { ICause } causes
     *
     *  @return {Promise<Object>}
     */
    delete: (causes: ICause) => {

        const deleteReason = (causes: ICause) => {

            return {
                type: causesConstants.DELETE_SUCCESS,
                causes,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: causesConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new CausesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.remove(causes)
                .then(({ data }: any) => {

                    dispatch(deleteReason(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

};
