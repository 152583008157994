import React from 'react';
import { InputLabel, Select as MuiSelect, MenuItem } from '@material-ui/core';
import { SelectProps as MuiSelectProps } from '@material-ui/core/Select';
import styled from 'reshadow';

const styles = require('./Select.scss');

/**
 * An Input component for a select box, using an array of objects for the options
 *
 * @param {React.ReactNode} children
 * @param {object} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Select: React.FC<SelectProps> = ({
    children,
    ...props
}) => {

	const { className, options, placeholder, size, displayEmpty } = props,
        classList = 'select-box' + (size === 'small' ? ' select-small' : ''),
        placeholderValue = placeholder ?
		(
			<MenuItem value="" disabled={!displayEmpty}>
				<span className="placeholder">{placeholder}</span>
			</MenuItem>
		) : null;

	return styled(styles)(
		<div className={className}>
			<InputLabel>{props.label}</InputLabel>
			<MuiSelect
				fullWidth
				{...props}
				variant="outlined"
				className={classList}
                displayEmpty={displayEmpty}
			>
				{placeholderValue}
				{options.map(option => (
					<MenuItem key={option.value} value={option.value} >
						{option.label}
					</MenuItem>
				))}
			</MuiSelect>
			{children}
		</div>
	);
};

interface IOptions {
    label: string;
    value: string | number;
}

interface IProps {
    options: IOptions[];
    label?: string;
    size?: 'small' | 'medium' | 'large';
	placeholderDisabled?: boolean;
}

export type SelectProps = IProps & MuiSelectProps;

export default Select;
