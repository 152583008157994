import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { IGpsLocation, ILocation, IPointPosition } from '../interfaces';
import { serialize as objectToFormData } from 'object-to-formdata';
import { IZoneApiModel } from './zoneService';


export interface IGatewayApiModel {
    id?: string;
    name: string;
    batteryLevel?: number;
    ledState?: number;
    uuidFilterState?: number;
    uuidFilter?: string;
    distance?: number;
    interval?: number;
    lastSeen?: string | null;
    comment?: string | null;
    location: IGpsLocation | null;
    position: IPointPosition | null;
    plan: ILocation | null;
    zone?: number | null | IZoneApiModel;
    pictures?: File[];
    type?: string | null;
    version?: string | null;
}

export type IGatewayIndentOnly = Pick<IGatewayApiModel, 'id'>;

/**
 * Service to work with gateway API resources
 *
 * @class GatewayService
 */
export class GatewayService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/gateways(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [];
    }

    /**
     * Get list of gateways
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<IGatewayApiModel[]> {

        return await this.http
            .get<IGatewayApiModel[]>(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            })
            .then(this.getDataExtractor());
    }

    /**
     * Create gateway
     * @param model
     */
    store(model: IGatewayApiModel): Promise<IGatewayApiModel> {
        const url = this.url();

        let dataToSend: any = model;

        if (model.pictures && model.pictures.length > 0) {
            dataToSend = objectToFormData(model);
        }

        return trackPromise(
            this.http
                .post<IGatewayApiModel>(url, dataToSend, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
        );
    }

    /**
     * Update gateway
     * @param model
     */
    update(model: IGatewayApiModel): Promise<IGatewayApiModel> {
        const url = this.url({ id: model.id });

        let dataToSend: any = model;

        if (model.pictures && model.pictures.length > 0) {
            dataToSend = objectToFormData(model);
        }

        return trackPromise(
            this.http
                .patch<IGatewayApiModel>(url, dataToSend, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
        );
    }

    /**
     * Save gateway
     *
     * @param {IBuildingApiModel} gateway
     *
     * @return {Promise<IBuildingApiModel>}
     */
    save(gateway: IGatewayApiModel): Promise<IGatewayApiModel> {

        if (gateway.id) {

            return this.update(gateway);
        }

        return this.store(gateway);
    }

    /**
     * Delete gateway form editor map
     *
     * @param model
     */
    deleteFromEditor(model: IGatewayIndentOnly): Promise<boolean> {
        const url = this.url({ id: model.id });

        // call patch request because we unassign plan in gateway and must not delete from db
        return trackPromise(
            this.http
                .patch<IGatewayApiModel>(url, { plan: null }, {
                    headers: this.headers,
                })
                .then(Boolean)
        );
    }

    /**
     * Delete gateway form editor map
     * @param model
     */
    delete(model: IGatewayIndentOnly): Promise<boolean> {

        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .delete<IGatewayApiModel>(url, {
                    headers: this.headers,
                })
                .then(Boolean)
        );
    }
}
