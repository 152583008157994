import { fabric } from 'fabric';
import { Handler } from './Handler';
import { FabricObject, IDrawingOptions, InteractionMode } from '../../interfaces';
import { editorConstants } from '../../constants';

/**
 * Interaction handler
 *
 * @class InteractionHandler
 */
export class InteractionHandler {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    protected handler: Handler;

    /**
     * Previous MovingEvent
     */
    private previousMovingEvent: MouseEvent | null = null;

    private previousTouch: any;

    /**
     * Constructor
     *
     * @param {Handler} handler
     */
    constructor(handler: Handler) {

        this.handler = handler;
    }

    /**
     * Switch handler to selection mode
     */
    selection(): void {

        if (this.handler.interactionMode === editorConstants.interaction.modeSelection) {

            return;
        }

        this.handler.interactionMode = editorConstants.interaction.modeSelection as InteractionMode;

        this.handler.canvas.selection = true;

        this.handler.canvas.defaultCursor = 'default';
        this.handler.workarea.hoverCursor = 'default';

        this.handler.getObjects().forEach((object: FabricObject) => {

            if (!object.zIndex) {

                object.zIndex = 0;

            }
            if (object.type !== 'pitCode') {
                object.hoverCursor = 'move';
                object.moveCursor = 'grabbing';

                object.selectable = object.editable;
                object.evented = true;
            }
            this.handler.canvas.moveTo(object, object.zIndex);
        });

        this.handler.canvas.renderAll();
    }

    /**
     * Switch handler to grab mode
     */
    grab(refresh = false): void {

        if (this.handler.interactionMode === editorConstants.interaction.modeGrab && !refresh) {

            return;
        }

        this.handler.interactionMode = editorConstants.interaction.modeGrab as InteractionMode;
        this.handler.canvas.selection = false;
        this.handler.canvas.defaultCursor = 'grab';
        this.handler.workarea.hoverCursor = 'grab';

        this.handler.getObjects().forEach((object: FabricObject) => {

            if (!object.zIndex) {

                object.zIndex = 0;

            }
if (object.type !== 'pitCode') {
    object.selectable = false;
    object.evented = this.handler.editable ? false : true;
}
            this.handler.canvas.moveTo(object, object.zIndex);
        });
    }

    /**
     * Switch handler to drawing mode
     *
     * @param {InteractionMode} type
     * @param options
     */
    drawing(type: InteractionMode, options: IDrawingOptions = {}): void {

        if (this.isDrawingMode()) {

            return;
        }

        this.handler.interactionMode = type;
        this.handler.canvas.selection = false;
        this.handler.canvas.defaultCursor = options.cursor || 'crosshair';
        this.handler.workarea.hoverCursor = options.cursor || 'crosshair';

        this.handler.getObjects().forEach((object: FabricObject) => {

            if (!object.zIndex) {

                object.zIndex = 0;
            }

            object.selectable = false;
            object.evented = !this.handler.editable;

            this.handler.canvas.moveTo(object, object.zIndex);
        });

        this.handler.canvas.renderAll();
    }

    /**
     * Moving workarea in grab mode
     *
     * @param {MouseEvent | TouchEvent} evt
     */
    moving(evt: MouseEvent | TouchEvent): void {

        const event = evt as MouseEvent;

        if (this.isDrawingMode()) {

            this.previousMovingEvent = null;
            return;
        }

        this.handler.canvas.defaultCursor = 'grabbing';
        this.handler.workarea.hoverCursor = 'grabbing';

        let movementX = event.movementX;
        let movementY = event.movementY;

        if (!event.movementX) {

            const touch = evt as TouchEvent;

            if (this.previousTouch && touch.touches && touch.touches[0]) {

                const touchX = Math.abs(touch.touches[0].pageX - this.previousTouch.pageX),
                    touchY = Math.abs(touch.touches[0].pageY - this.previousTouch.pageY);

                if (this.previousTouch && (touchX > 50 || touchY > 50)) {

                    this.previousTouch = null;

                } else {

                    movementX = touch.touches[0].pageX - this.previousTouch.pageX;

                    movementY = touch.touches[0].pageY - this.previousTouch.pageY;

                }
            }

            if (touch.touches && touch.touches[0]) {

                this.previousTouch = touch.touches[0];

            }
        }


        // invalidate previous event if it fired more than delay time
        const eventDelay = 300;
        if (this.previousMovingEvent && (event.timeStamp - this.previousMovingEvent.timeStamp) > eventDelay) {
            this.previousMovingEvent = null;
        }

        if (movementX === undefined && this.previousMovingEvent) {
            movementX = event.pageX - (this.previousMovingEvent.pageX || 0);
            movementY = event.pageY - (this.previousMovingEvent.pageY || 0);
        }

        this.previousMovingEvent = event;

        const delta = new fabric.Point(movementX || 0, movementY || 0);

        this.handler.canvas.relativePan(delta);
        this.handler.canvas.requestRenderAll();
    }

    /**
     * Check if handler is switched to drawing mode
     *
     * @returns {boolean}
     */
    isDrawingMode(): boolean {

        return this.handler.interactionMode === editorConstants.interaction.modePolygon;
    }
}
