import React, { useCallback, useEffect, useState } from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../ui/components/Button/Button';
import { connect } from 'react-redux';
import { IHrState } from '../../../../../store/reducers';
import { selectSettingPageState } from '../../../../../store/selectors/settingPageSelector';
import { selectEmployee, selectEmployeeState } from '../../../../../store/selectors/employeeSelector';
import { selectBeaconState } from '../../../../../store/selectors/beaconSelector';
import { SettingPageThunks } from '../../../../../store/thunks/settingPageThunks';
import { IGatewayApiModel } from '../../../../../services/gatewayService';
import TextInput from '../../../../../ui/components/Input/TextInput';
import Select from '../../../../../ui/components/Select/Select';
import { IZoneApiModel } from '../../../../../services/zoneService';
import { Dialog, DialogActions, DialogContent, InputLabel } from '@material-ui/core';

import './GatewayForm.scss';
import { IOptions, IErrors } from '../../../../../../../core/interfaces';
import { ConfirmDialog } from '../../../../../../../core/ui/components';
import { gatewayThunks } from '../../../../../store/thunks/gatewayThunks';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PhotoIcon from '@material-ui/icons/Photo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { appConfig } from '../../../../../../../config/appConfig';

interface IGatewayFormProps {
    model?: IGatewayApiModel;
    toggleForm: (formOpening: boolean, formName?: string, model?: IGatewayApiModel) => void;
    saveGateway: (model: IGatewayApiModel) => void;
    clearError: () => void;
    gatewayError: IErrors | null;
}

interface IImageState {
    previewUrl: string | null;
    previewImg: File | null;
    previewLoaded: boolean;
}

const GatewayForm: React.FC<IGatewayFormProps> = (
    {
        model,
        toggleForm,
        saveGateway,
        gatewayError,
        clearError,
    }: IGatewayFormProps
) => {

    const { t } = useTranslation();

    const [initialValues, setInitialValues] = useState<IGatewayApiModel>({
        id: '',
        name: '',
        comment: '',
        zone: null,
        ledState: 0,
        pictures: [],
        plan: null,
        location: null,
        position: null,
    });

    const [formValue, setFormValue] = useState<IGatewayApiModel | null>(null);

    const [zoneOption, setZoneOption] = useState<IOptions[] | null>(null);

    const [uuidFilterLEDsState] = useState<IOptions[]>([
        {
            label: t('ON'),
            value: 1,
        },
        {
            label: t('OFF'),
            value: 0,
        },
    ]);

    const [imageState, setImageState] = useState<IImageState>({
        previewUrl: null,
        previewImg: null,
        previewLoaded: false,
    });

    const [dialog, setDialog] = useState<boolean>(false);

    /**
     * Checking if files are too big
     *
     * @param {[File]} files
     *
     * @return {boolean}
     */
    const checkIfFilesAreTooBig = (files?: [File]): boolean => {

        let valid = true;

        if (files) {

            let size = 0;

            files.map(file => {

                if (file instanceof File) {

                    size += file.size / 1024 / 1024;

                }
            });

            if (size > 15) {

                valid = false;
            }
        }
        return valid;
    };

    /**
     * Checking the correct file type
     *
     * @param {[File]} files
     *
     * @return {boolean}
     */
    const checkIfFilesAreCorrectType = (files?: [File]): boolean => {

        let valid = true;

        if (files) {

            files.map(file => {

                if (file instanceof File && !['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {

                    valid = false;

                }
            });
        }
        return valid;
    };

    const validationSchema = yup.object().shape({
        name: yup.string()
            .trim()
            .min(1, t('MUST_CONTAIN_AT_LEAST_1_CHARACTERS'))
            .max(63, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '63' }))
            .nullable(true),
        uuidFilter: yup.string()
            .trim()
            .test('len', t('FIELD_LENGTH_EQUAL', { name: t('UUID_FILTER'), length: 32 }), val => val?.length === 32)
            .nullable(true),
        distance: yup.number()
            .integer(t('VALUE_MUST_BE_AN_INTEGER_NUMBER'))
            .typeError(t('VALUE_MUST_BE_AN_NUMBER'))
            .min(0, t('THE_PARAMETER_VALUE_MUST_BE_BETWEEN_MIN_AND_MAX', { min: 0, max: 10000 }))
            .max(10000, t('THE_PARAMETER_VALUE_MUST_BE_BETWEEN_MIN_AND_MAX', { min: 0, max: 10000 })),
        ledState: yup.number(),
        zone: yup.object().nullable(true).notRequired(),
        uuidFilterState: yup.number().nullable(true).notRequired(),
        comment: yup.string()
            .trim()
            .max(1500, t('MAX_ITEM_LENGTH', { name: t('COMMENT'), length: 1500 }))
            .notRequired()
            .nullable(true),
        id: yup.string().notRequired(),
        interval: yup.number()
            .typeError(t('VALUE_MUST_BE_AN_NUMBER'))
            .min(60, t('THE_PARAMETER_VALUE_MUST_BE_BETWEEN_MIN_AND_MAX', { min: 60, max: 86400 }))
            .max(86400, t('THE_PARAMETER_VALUE_MUST_BE_BETWEEN_MIN_AND_MAX', { min: 300, max: 86400 }))
            .nullable(false),
        pictures: yup
            .array()
            .test(
                'is-correct-file',
                t('ERROR_FILES_MUST_NOT_EXCEED_SIZE_MB', { size: 15 }),
                checkIfFilesAreTooBig
            )
            .test(
                'is-big-file',
                t('ERROR_ACCEPTABLE_FORMATS_FOR_UPLOADING_FORMAT', { format: 'jpeg, jpg, png' }),
                checkIfFilesAreCorrectType
            )
            .max(10, t('PICTURES_RESTRICTION_MAX')),
    });

    /**
     * Handler cancel dialog form
     *
     * @type {() => void}
     */
    const handleCancel = useCallback(() => {

        setFormValue(null);

        setZoneOption(null);

        clearError();

    }, [setFormValue, setZoneOption]);

    /**
     * Handler the form submit
     *
     * @param value
     */
    const handleSubmit = useCallback((value: IGatewayApiModel) => {

        setFormValue(value);

    }, [setFormValue]);

    /**
     * Popup handler. Unset anchor element.
     */
    const handleClose = useCallback(() => {

        toggleForm(false, '');

        handleCancel();

    }, [toggleForm, handleCancel]);

    /**
     *
     * @param {IZoneApiModel} zones
     *
     * @return {IOptions}
     */
    const zonesToOptions = (zones: IZoneApiModel): IOptions => {

        return { label: zones.name, value: zones.id };
    };

    useEffect(() => {

        if (model) {

            setInitialValues(model);

            if (model.zone) {

                const selectedZone = model.zone as IZoneApiModel;

                setZoneOption([zonesToOptions(selectedZone)]);
            }

        }

    }, [model]);

    /**
     *
     * @param {IZoneApiModel | number} data
     *
     * @return {number}
     */
    const returnSelectedValue = (data: IZoneApiModel | number): number => {

        return typeof data === 'number' ? data : data.id;
    };

    /**
     *
     * @type {() => void}
     */
    const onConfirmDialogCustom = useCallback(() => {

        if (formValue) {

            const dataZone = formValue.zone as IZoneApiModel;

            const ledState = formValue.ledState && (formValue.ledState < 0 || formValue.ledState) > 1
                ? 1
                : formValue.ledState;

            const uuidFilterState = formValue.uuidFilterState && (formValue.uuidFilterState < 0 || formValue.uuidFilterState) > 1
                ? 1
                : formValue.uuidFilterState;

            const sendData: IGatewayApiModel = {
                id: formValue.id ? formValue.id : '',
                pictures: formValue.pictures || [],
                zone: dataZone && dataZone.id ? dataZone.id : null,
                comment: formValue.comment ? formValue.comment.trim() : null,
                interval: formValue.interval && formValue.interval >= 60 ? formValue.interval : 3600,
                distance: formValue.distance || 0,
                name: formValue.name && formValue.name.trim().length !== 0 ? formValue.name.trim() : initialValues.name,
                uuidFilter: formValue.uuidFilter ? formValue.uuidFilter.toString().trim() : '',
                uuidFilterState,
                location: formValue.location,
                position: formValue.position,
                plan: formValue.plan,
                ledState,
            };

            saveGateway(sendData);
        }

    }, [setZoneOption, initialValues, formValue, setFormValue, toggleForm, saveGateway]);

    /**
     *
     * @type {() => void}
     */
    const onCancelDialog = useCallback(() => {

        setFormValue(null);

        setZoneOption(null);

    }, [setFormValue, setZoneOption]);

    /**
     * On change file field
     *
     * @param e
     * @param props
     */
    const onChangeFile = (e: React.ChangeEvent<any>, props: FormikProps<IGatewayApiModel>) => {

        props.setFieldTouched('pictures');

        const uploadingFiles = Array.prototype.slice.call(e.currentTarget.files);

        const files = props.values.pictures?.length ? [...props.values.pictures, ...uploadingFiles] : uploadingFiles;

        if (files.length > 10) {

            props.setErrors({ pictures: t('PICTURES_RESTRICTION_MAX') });

        }

        props.setFieldValue('pictures', files);
    };

    /**
     * Open preview image
     *
     * @param image
     */
    const onPreviewImage = (image: File | { link: string }) => {

        if (model) {

            if (image instanceof File) {

                const url = URL.createObjectURL(image);


                setImageState({ ...imageState, previewUrl: url, previewImg: image });

            } else {

                setImageState({
                    ...imageState, previewUrl: appConfig.hrApiEndpoint +
                        `${image.link}`, previewImg: image as any
                });
            }
            setDialog(true);
        }
    };

    const handleCloseCallback = useCallback(() => {

        if (imageState.previewUrl) {

            URL.revokeObjectURL(imageState.previewUrl);

        }

        setImageState({ previewUrl: null, previewImg: null, previewLoaded: false });

        setDialog(false);

    }, [imageState, setImageState, setDialog]);

    const onDeleteImage = useCallback((props: FormikProps<IGatewayApiModel>) => {

        const { previewImg } = imageState;

        if (props.values?.pictures && previewImg) {

            const pictures = [...props.values.pictures];

            const index = pictures.findIndex((pic: File | { id: number }) => {

                if (pic instanceof File) {

                    return pic.name === previewImg.name
                        && pic.lastModified === previewImg.lastModified;

                } else {

                    const prevImg = previewImg as any;

                    return pic.id === prevImg.id;
                }
            });

            if (index !== -1) {

                pictures.splice(index, 1);

                props.setFieldValue('pictures', pictures);

                props.setErrors({});

                props.setTouched({});
            }

        }

        handleCloseCallback();

    }, [imageState, handleCloseCallback]);

    /**
     * OnLoadPreview method use to load image preview
     */
    const onLoadPreview = useCallback(() => {

        const { previewImg } = imageState;

        previewImg && previewImg.size > 1048576 ?
            setTimeout(() => setImageState({ ...imageState, previewLoaded: true }), 500)
            :
            setImageState({ ...imageState, previewLoaded: true });

    }, [imageState, setImageState]);

    const { previewLoaded, previewUrl } = imageState;

    /**
     * Component Did Unmount
     */
    useEffect(() => {

        return () => {

            toggleForm(false);

            setFormValue(null);

            setZoneOption(null);

        };
    }, [setFormValue, setZoneOption, toggleForm]);

    const onHandleChangeName = ((e: React.ChangeEvent<any>, props: FormikProps<IGatewayApiModel>) => {
        props.handleChange(e);

        if (gatewayError?.message) {
            clearError();
        }
    });

    const validatedClass =(
        name: 'name' | 'ledState' | 'uuidFilterState' | 'uuidFilter' | 'distance' | 'interval' | 'zone',
        props: FormikProps<IGatewayApiModel>
    ): string => {

        const validateClassString = props.touched[name] ? props.errors[name] || (name=== 'name' && gatewayError?.message === 'Name already taken') ? 'error-field' : 'success-field' : '';

        return `form-field ${validateClassString}`;
    };

    return (
        <React.Fragment>
            <div className="form-box form-box-space node-form gateway-edit-form">
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {(props: FormikProps<IGatewayApiModel>) => (
                        <form
                            noValidate
                            encType="multipart/form-data"
                            onSubmit={props.handleSubmit}
                            className="gateway-edit-modal"
                        >
                            <div className="table-header">
                                <div
                                    className={'title'}
                                >
                                    {t(!model ? 'ADD_GATEWAY' : 'EDIT_GATEWAY')}
                                </div>
                            </div>
                            {/*<div className="common-error">{t(error.message)}</div>*/}
                            <div className="table-body">
                                <div className="form-group">
                                    <TextInput
                                        className={validatedClass('name', props)}
                                        label={t('NAME')}
                                        onChange={(e) => onHandleChangeName(e, props)}
                                        onBlur={props.handleBlur}
                                        value={props.values.name ? props.values.name : ''}
                                        name="name"
                                        type="text"
                                        placeholder={t(initialValues.name ? initialValues.name : 'NAME')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        {((props.touched.name && props.errors.name) || !!gatewayError?.message) &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.name || t('GATEWAY_NAME_ALREADY_TAKEN')}
                                            </div>
                                        }
                                    </TextInput>
                                </div>
                                <div className="form-group select">
                                    <InputLabel>{t('LEDS_STATE')}</InputLabel>
                                    <Select
                                        className={validatedClass('ledState', props)}
                                        value={props.values.ledState && props.values.ledState > 0 ? 1 : 0}
                                        name="ledState"
                                        onChange={props.handleChange}
                                        displayEmpty={false}
                                        options={uuidFilterLEDsState}
                                        MenuProps={{
                                            disableScrollLock: true,
                                        }}
                                    />
                                </div>
                                <div className="form-group select">
                                    <InputLabel>{t('UUID_FILTER_STATE')}</InputLabel>
                                    <Select
                                        className={validatedClass('uuidFilterState', props)}
                                        value={props.values.uuidFilterState && props.values.uuidFilterState > 0 ? 1 : 0}
                                        name="uuidFilterState"
                                        onChange={props.handleChange}
                                        displayEmpty={false}
                                        options={uuidFilterLEDsState}
                                        MenuProps={{
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextInput
                                        className={validatedClass('uuidFilter', props)}
                                        label={t('UUID_FILTER')}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.uuidFilter ? props.values.uuidFilter : ''}
                                        name="uuidFilter"
                                        type="text"
                                        placeholder={t('ENTER_UUID_FILTER')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        {props.touched.uuidFilter && props.errors.uuidFilter &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.uuidFilter}
                                            </div>
                                        }
                                    </TextInput>
                                </div>
                                <div className="form-group">
                                    <TextInput
                                        className={validatedClass('distance', props)}
                                        label={t('FILTER_BY_DISTANCE')}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.distance !== null ? props.values.distance : ''}
                                        name="distance"
                                        type="number"
                                        placeholder={t('ENTER_FILTER_BY_DISTANCE')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        {props.touched.distance && props.errors.distance &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.distance}
                                            </div>
                                        }
                                    </TextInput>
                                </div>
                                <div className="form-group">
                                    <TextInput
                                        className={validatedClass('interval', props)}
                                        label={t('DATA_SEND_INTERVAL_SEC')}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.interval ? props.values.interval : ''}
                                        name="interval"
                                        type="number"
                                        placeholder={t('ENTER_DATA_SEND_INTERVAL_SEC')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    >
                                        {props.touched.interval && props.errors.interval &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.interval}
                                            </div>
                                        }
                                    </TextInput>
                                </div>
                                <div className="form-group select">
                                    <InputLabel>{t('LINKED_ZONE')}:</InputLabel>
                                    <Select
                                        className={validatedClass('zone', props)}
                                        value={props.values.zone ? returnSelectedValue(props.values.zone) : ''}
                                        name="zone"
                                        placeholder={t('NONE')}
                                        onChange={props.handleChange}
                                        displayEmpty
                                        options={zoneOption ? zoneOption : []}
                                        MenuProps={{
                                            disableScrollLock: true,
                                            style: {
                                                maxHeight: 300,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="form-group">
                                    <TextInput
                                        label={t('COMMENT')}
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        className={
                                            'form-field comment-input '
                                            +
                                            (props.touched.comment ? props.errors.comment ? 'error-field' : 'success-field' : '')
                                        }
                                        placeholder={`${t('ADD_COMMENT')}`}
                                        name="comment"
                                        rowsMax="4"
                                        multiline
                                        aria-label="comment"
                                        style={{ width: '100%' }}
                                        value={props.values.comment || ''}
                                    >
                                        {props.touched.comment && props.errors.comment &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.comment}
                                            </div>
                                        }
                                    </TextInput>
                                </div>
                                <div className="form-field gateway-file-field">
                                    <div className="gateway-file-wrapper">
                                        <div>
                                            <input
                                                accept="image/*"
                                                className="gateway-file-input"
                                                id="icon-button-file"
                                                type="file"
                                                onChange={(e) => onChangeFile(e, props)}
                                                onBlur={props.handleBlur}
                                                multiple
                                            />
                                            <label htmlFor="icon-button-file">
                                                <IconButton color="primary" aria-label="upload picture" component="span">
                                                    <AttachFileIcon />
                                                </IconButton>
                                            </label>
                                        </div>
                                        <div className="gateway-file-list">
                                            {props.values.pictures?.map((picture, index) => <PhotoIcon key={index} onClick={() => onPreviewImage(picture)} />)}
                                        </div>
                                    </div>
                                    {(props.touched.pictures && props.errors.pictures) &&
                                        <div className="validation-massage">{props.errors.pictures}</div>
                                    }
                                </div>
                                <div className="form-group version-info">
                                    <span><span className="version-info-color">{t('TYPE')}: </span>{model?.type ? model.type : t('NOT_SET')}</span>
                                    <span><span className="version-info-color">{t('FW_VERSION')}: </span>{model?.version ? model.version : t('NOT_SET')}</span>
                                </div>
                                <div className="form-group btn-group">
                                    <Button
                                        type="reset"
                                        color={'primary'}
                                        onClick={handleClose}
                                    >
                                        {t('CANCEL')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        color={'secondary'}
                                    >
                                        {t(!model ? 'CREATE' : 'SAVE_CHANGES')}
                                    </Button>
                                </div>
                            </div>
                            <Dialog
                                className="preview-gateway-dialog"
                                onClose={handleCloseCallback}
                                maxWidth="xl"
                                open={dialog}
                                scroll={'body'}
                                closeAfterTransition
                            >

                                <DialogContent>
                                    {previewUrl && !previewLoaded &&
                                        <div>
                                            <CircularProgress color="primary" />
                                        </div>
                                    }
                                    {previewUrl &&
                                        <>
                                            <img
                                                src={previewUrl}
                                                style={{
                                                    maxHeight: '82vh',
                                                    margin: '0 auto',
                                                    display: previewLoaded ? 'block' : 'none',
                                                }}
                                                onLoad={onLoadPreview}
                                            />
                                        </>
                                    }
                                </DialogContent>
                                <DialogActions style={{ padding: '8px 24px 20px' }}>
                                    <Button onClick={() => onDeleteImage(props)} style={{ color: 'red' }} color="primary" autoFocus>
                                        {t('DELETE')}
                                    </Button>
                                    <Button onClick={handleCloseCallback} color="primary" autoFocus>
                                        {t('CLOSE')}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </form>
                    )}
                </Formik>
            </div>
            {formValue ?
                <ConfirmDialog
                    heading={model ? t('EDIT_GATEWAY_Q') : t('ADD_GATEWAY_Q')}
                    onAccept={onConfirmDialogCustom}
                    onClose={onCancelDialog}
                    open={Boolean(formValue)}
                />
                :
                null
            }
        </React.Fragment>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { hr: IHrState }) => {

    const { formOpening, formName, model } = selectSettingPageState(state);

    const { error } = selectEmployeeState(state);

    const { beacons } = selectBeaconState(state);

    const employee = selectEmployee(state);

    const gatewayError = state.hr.editorGatewayState.error;

    return {
        formOpening,
        formName,
        model: model as IGatewayApiModel,
        error,
        beacons,
        employee,
        gatewayError
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: SettingPageThunks.toggleForm,
    saveGateway: gatewayThunks.saveGateway,
    clearError: gatewayThunks.clearError,
});


export default connect(mapStateToProps, mapDispatchToProps)(GatewayForm);
