import { Action } from 'redux';
import { IBeacon, IDepartment, IEmployee } from '../../interfaces';
import { IGatewayApiModel } from '../../services/gatewayService';

export enum SettingPageActionTypes {
    OPENING_FORM_ON_THE_PAGE_SETTING = '[ISettingPage] Opening form on the page Setting',
}

export class OpeningForm implements Action {
    readonly type = SettingPageActionTypes.OPENING_FORM_ON_THE_PAGE_SETTING;

    constructor(
        public payload: {
            formOpening: boolean,
            formName?: string,
            model?: IEmployee | IDepartment | IGatewayApiModel | IBeacon,
        }
    ) {
    }
}


export type SettingPageActions = OpeningForm;
