import React, { useCallback, useEffect, useState } from 'react';
import { CommonError, ConfirmDialog, FormDialog } from '../../../../../../core/ui/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IFormValues, LocationForm } from './LocationForm/LocationForm';
import { AddGpsPinForm } from './AddGpsPinForm/AddGpsPinForm';
import {
    selectLocationFormEditorErrors,
    selectLocationFormModel,
} from '../../../../store/selectors/locationFormSelector';
import { locationThunks } from '../../../../store/thunks/locationThunks';
import { SetEditorModeAction } from '../../../../store/actions/locationFormActions';
import { EditorMode, IGpsPin } from '../../../../interfaces';
import { useFormHandlers } from '../../../../utils/useFormHandlers';

interface IProps {
    errors?: any;
    openSidebar: boolean;
    toggleForm: (opened: boolean, name?: string) => void;
    reloadData: () => void;
    openEditor: () => void;
}

/**
 * Form HR Plan Component
 */
const FormLocationSidebar = (props: IProps) => {
    const {
        toggleForm,
        openSidebar,
        reloadData,
        openEditor,
    } = props;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [initialValues, setInitialValues] = useState<IFormValues>({
        name: '',
        picture: '',
        scale: 0,
        angle: 0,
        measure: {
            scaleX: 1,
            scaleY: 1,
        },
    });

    const model = useSelector(selectLocationFormModel);

    const errors = useSelector(selectLocationFormEditorErrors);

    useEffect(() => {
        return () => {
            dispatch(locationThunks.closeForm());
        };
    }, [dispatch]);

    useEffect(() => {
        setInitialValues({
            id: model?.id || 0,
            name: model?.name || '',
            picture: model?.picture || '',
            scale: model?.scale || 0,
            angle: model?.angle || 0,
            measure: model?.measure || {
                scaleX: 1,
                scaleY: 1,
            },
        });
    }, [model]);

    const [validationSchema] = useState(yup.object().shape({
        name: yup.string()
            .trim()
            .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
            .required(t('LOCATION_NAME_IS_REQUIRED')),
        picture: yup.string()
            .trim()
            .required(t('SVG_IMAGE_IS_REQUIRED')),
        angle: yup.number()
            .typeError(t('VALUE_MUST_BE_AN_NUMBER'))
            .max(360, t('PLAN_ANGLE_RESTRICTION_MAX', { value: 360 }))
            .min(0, t('PLAN_ANGLE_RESTRICTION_MIN', { value: 0 }))
            .label(t('PLAN_ANGLE'))
            .required(t('PLAN_ANGLE_IS_REQUIRED')),
    }));

    const formHandlers = useFormHandlers({
        setInitialValues,
        toggleForm,
        openSidebar,
        reloadData,
        openEditor,
    });

    const {
        confirm,
        handleSubmit,
        onCancel,
        onCancelDialog,
        onConfirmDialog,
    } = formHandlers;

    /**
     * Handler confirm dialog form
     */
    const onConfirmDialogCustom = useCallback(async () => {

        const modelToSave = {
            id: initialValues.id || 0,
            name: initialValues.name,
            picture: initialValues.picture,
            layers: [],
            pins: [],
            scale: initialValues.scale,
            angle: initialValues.angle,
            measure: initialValues.measure,
        };

        await dispatch(locationThunks.saveLocation(modelToSave));

        onConfirmDialog();
        dispatch(locationThunks.fetchLocations());
    }, [dispatch, initialValues, onConfirmDialog]);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema,
        onSubmit: handleSubmit,
    });

    const [editingPin, setEditingPin] = useState<IGpsPin | null>(null);

    /**
     * Add GPS Pin logic
     */
    const [addingGpsPin, setAddingGpsPin] = useState<boolean>(false);
    const onOpenAddGpsModal = useCallback((pin: IGpsPin) => {
        setEditingPin(pin);
        setAddingGpsPin(true);
        dispatch(new SetEditorModeAction({ mode: EditorMode.DEFAULT }));
    }, [dispatch]);

    const onCloseAddGpsModal = useCallback(() => {
        setAddingGpsPin(false);

        if (editingPin?.isDraft) {
            dispatch(locationThunks.deletePin(editingPin.localId));
        }
    }, [dispatch, editingPin]);

    const onSaveGpsPin = useCallback(({ lat, lng }) => {
        if (editingPin) {
            dispatch(locationThunks.savePin(editingPin.localId, { lat, lng, isDraft: false }));
        }
        setAddingGpsPin(false);
    }, [dispatch, editingPin]);

    const onDeleteGpsPinModal = useCallback(() => {
        if (editingPin) {
            dispatch(locationThunks.deletePin(editingPin.localId));
        }

        onCloseAddGpsModal();
    }, [dispatch, editingPin, onCloseAddGpsModal]);

    const onClickAddGpsButton = useCallback(() => {
        dispatch(new SetEditorModeAction({ mode: EditorMode.ADD }));
    }, [dispatch]);

    /**
     * Checking Errors
     * @type {() => ({common?: string | undefined} | undefined)}
     */
    const checkingErrors= useCallback(():{common?: string | undefined} | undefined => {

        const loadLocationImageError = model?.error?.errors?.common[0];

        if (errors) {
            // eslint-disable-next-line no-prototype-builtins
            if (errors.hasOwnProperty('message')) {

                if (errors.message === 'Internal server error' || errors.message[0] === 'Plan not found') {

                    return {
                        common: 'PLAN_NOT_FOUND_RELOAD_THE_PAGE',
                    };

                }

                return {
                    common: errors.message && typeof errors.message === 'string' ? errors.message : undefined,
                };

            } else {

                return errors;
            }
        }

        if (loadLocationImageError) {

            return {
                common: loadLocationImageError,
            };
        }

    }, [errors, model]);

    return (
        <>
            <div className="node-form">
                <CommonError errors={checkingErrors()} />
                <LocationForm
                    formikProps={formik}
                    locationData={model}
                    onCancel={onCancel}
                    onClickAddGpsButton={onClickAddGpsButton}
                    onOpenAddGpsModal={onOpenAddGpsModal}
                />
            </div>
            <ConfirmDialog
                heading={model ? t('EDIT_LOCATION_Q') : t('ADD_LOCATION_Q')}
                onAccept={onConfirmDialogCustom}
                onClose={onCancelDialog}
                open={confirm}
            />
            <FormDialog
                heading={!editingPin ? '' : editingPin.isDraft ? t('ADD_LOCATION_PIN_Q') : t('LOCATION_PIN')}
                open={addingGpsPin}
                hideBackdrop
                TransitionProps={{ exit: false }}
            >
                {
                    <AddGpsPinForm
                        editingPin={editingPin}
                        onCloseAddGps={onCloseAddGpsModal}
                        onDeleteGpsPin={onDeleteGpsPinModal}
                        onSaveGpsPin={onSaveGpsPin}
                    />
                }
            </FormDialog>
        </>
    );
};

export default FormLocationSidebar;
