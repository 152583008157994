import { DrawingHandler } from './DrawingHandler';
import { EventHandler } from './EventHandler';
import { InteractionHandler } from './InteractionHandler';
import { ShortcutHandler } from './ShortcutHandler';
import { ZoomHandler } from './ZoomHandler';
import { WorkareaHandler } from './WorkareaHandler';
import {
    FabricCanvas,
    FabricObject,
    InteractionMode,
    IHandlerOptions,
    FabricObjectOptions,
    ICanvasObjectSchema,
    WorkareaObject,
    IWorkareaOptions, FabricImage,
} from '../../interfaces';
import { editorConstants } from '../../constants';
import { fabric } from 'fabric';

/**
 * Main handler for editor events
 *
 * @class Handler
 */
export class Handler implements IHandlerOptions {

    /**
     * Canvas instance
     *
     * @type {FabricCanvas}
     */
    public canvas: FabricCanvas;

    /**
     * Workarea instance
     *
     * @type {WorkareaObject}
     */
    //@ts-ignore
    public workarea: WorkareaObject;

    /**
     * Editor layers
     *
     * @type {FabricImage[]}
     */
    public layers: FabricImage[] = [];

    /**
     * Canvas container
     *
     * @type {HTMLDivElement}
     */
    public container: HTMLDivElement;

    /**
     * Editable mode switch
     *
     * @type {boolean}
     */
    public editable: boolean;

    /**
     * Interaction mode switch
     *
     * @type {InteractionMode}
     */
    public interactionMode: InteractionMode = editorConstants.interaction.modeSelection as InteractionMode;

    /**
     * Min zoom level (in percent)
     *
     * @type {number}
     */
    public minZoom: number;

    /**
     * Max zoom level (in percent)
     *
     * @type {number}
     */
    public maxZoom: number;

    /**
     * Workarea options
     *
     * @type {IWorkareaOptions}
     */
    public workareaOptions?: IWorkareaOptions;

    /**
     * Objects which can be created on canvas
     *
     * @type {Object}
     */
    public fabricObjects: ICanvasObjectSchema;

    /**
     * Canvas width
     *
     * @type {number}
     */
    public width?: number;

    /**
     * Canvas height
     *
     * @type {number}
     */
    public height?: number;

    /**
     * Zoom callback
     *
     * @type {function}
     */
    public onZoom?: (zoomRatio: number) => void;

    /**
     * Add new object callback
     *
     * @type {function}
     */
    public onAdd?: (object: FabricObject) => void;

    /**
     * Remove object callback
     *
     * @type {function}
     */
    public onRemove?: (target: FabricObject) => void;

    /**
     * Edit object callback
     * 
     * @type {function}
     */
    public onEdit?: (target: FabricObject) => void;

    /**
     * Cancel object callback
     *
     * @type {function}
     */
    public onCancel?: () => void;

    /**
     * Get object callback on mouse up
     *
     * @type {function}
     */
    public onMouseUp?: (target: FabricObject) => void;

    /**
     * Load layers callback
     */
    public onLoadLayers?: (objects: FabricObject[]) => void;

    /**
     * Interaction handler instance
     *
     * @type {InteractionHandler}
     */
    public interactionHandler: InteractionHandler;

    /**
     * Drawing handler instance
     *
     * @type {DrawingHandler}
     */
    public drawingHandler: DrawingHandler;

    /**
     * Event handler instance
     *
     * @type {EventHandler}
     */
    public eventHandler: EventHandler;

    /**
     * Shortcut handler instance
     *
     * @type {ShortcutHandler}
     */
    public shortcutHandler: ShortcutHandler;

    /**
     * Zoom handler instance
     *
     * @type {ZoomHandler}
     */
    public zoomHandler: ZoomHandler;

    /**
     * Workarea handler instance
     *
     * @type {WorkareaHandler}
     */
    public workareaHandler: WorkareaHandler;

    /**
     * Drawn objects
     *
     * @type {FabricObject[]}
     */
    public objects: FabricObject[] = [];

    /**
     * Last drawn or selected line
     *
     * @type {FabricObject}
     */
    public activeLine: FabricObject | null = null;

    /**
     * Last drawn or selected shape
     *
     * @type {FabricObject}
     */
    public activeShape: FabricObject | null = null;

    /**
     * Current zoom level
     *
     * @type {number}
     */
    public zoom = 1;

    /**
     * Array of points
     *
     * @type {FabricObject[]}
     */
    public pointArray: FabricObject[] = [];

    /**
     * Array of lines
     *
     * @type {FabricObject[]}
     */
    public lineArray: FabricObject[] = [];

    /**
     * Selected action mode in the menu.
     *
     * @type {string}
     */
    public menuActionMode = '';

    /**
     * Constructor
     *
     * @param options
     */
    constructor(options: any) {

        this.canvas = options.canvas;
        this.container = options.container;
        this.editable = options.editable;
        this.interactionMode = options.interactionMode;
        this.minZoom = options.minZoom;
        this.maxZoom = options.maxZoom;
        this.workareaOptions = options.workareaOptions;
        this.width = options.width;
        this.height = options.height;
        this.menuActionMode = options.menuItem;

        this.onZoom = options.onZoom;
        this.onAdd = options.onAdd;
        this.onRemove = options.onRemove;
        this.onEdit = options.onEdit;
        this.onCancel = options.onCancel;
        this.onLoadLayers = options.onLoadLayers;
        this.onMouseUp = options.onMouseUp;

        this.fabricObjects = options.fabricObjects;

        this.zoomHandler = new ZoomHandler(this);
        this.workareaHandler = new WorkareaHandler(this);
        this.interactionHandler = new InteractionHandler(this);
        this.eventHandler = new EventHandler(this);
        this.drawingHandler = new DrawingHandler(this);
        this.shortcutHandler = new ShortcutHandler(this);
    }

    /**
     * Select given object
     *
     * @param {FabricObject} object
     */
    select(object: FabricObject): void {

        this.canvas.discardActiveObject();
        this.canvas.setActiveObject(object);
        this.canvas.requestRenderAll();
    }

    /**
     * Select an object by ID
     *
     * @param {string} id
     */
    selectById(id: string): void {

        const object = this.findById(id);

        if (object) {

            this.canvas.discardActiveObject();
            this.canvas.setActiveObject(object);
            this.canvas.requestRenderAll();
        }
    }

    /**
     * Drawing objects according to index
     */
    moveToIndex():void {

        this.canvas.getObjects().forEach((value: any) => {

            if (!value.zIndex) {

                value.zIndex = 0;

            }

            this.canvas.moveTo(value, value.zIndex);

        });
    }

    /**
     * Create and add new object to canvas
     *
     * @param {boolean} suppressCallback
     * @param {FabricObjectOptions} options
     *
     * @returns {FabricObject}
     */
    add({ suppressCallback = false, ...options }: FabricObjectOptions): FabricObject | undefined {

        if (options.type) {

            const { editable, onAdd } = this;

            if (options.type === 'image') {
                const image = new Image();
                image.src = options.src;
                image.onload = () => {

                    this.canvas.renderAll();
                };

                options.image = image;
            }

            const controlOptions: Record<string, unknown> = {
                hasControls: editable,
                hasBorders: editable,
                selectable: editable,
                lockMovementX: !editable,
                lockMovementY: !editable,
                hoverCursor: !editable ? 'pointer' : 'move',
            };

            const objectOptions = Object.assign(
                {},
                options,
                {
                    container: this.container.id,
                    editable,
                },
                controlOptions,
            );

            const newObject = this.fabricObjects[options.type].create(objectOptions);

            if (options.type === 'pitCode') {

                this.canvas.getObjects().forEach((obj: FabricObject) => {
                    if (obj.id === newObject.itemId) {
                        const position = this.getPositionRelativeToObject(obj, newObject);

                        newObject.set(position);
                        newObject.setCoords();

                    }
                });
            }

            this.canvas.add(newObject);

            this.canvas.moveTo(newObject, options.zIndex);

            this.objects = this.getObjects();

            if (onAdd && editable && !suppressCallback) {

                onAdd(newObject);

                this.canvas.moveTo(newObject, options.zIndex);
            }

            this.moveToIndex();

            this.canvas.renderAll();
            this.canvas.calcOffset();

            return newObject;
        }
    }

    /**
     * Remove object from canvas
     *
     * @param {FabricObject} target
     */
    remove(target?: FabricObject): void {

        const activeObject = target || (this.canvas.getActiveObject() as FabricObject);

        if (!activeObject || (activeObject.deletable !== undefined && !activeObject.deletable)) {

            return;
        }

        //check for multiple selection
        if (activeObject.type !== 'activeSelection') {

            this.canvas.discardActiveObject();

            this.canvas.remove(activeObject);

        } else {

            const { _objects: activeObjects } = activeObject;

            const undeletableExists = activeObjects.some((object: FabricObject) => object.deletable !== undefined && !object.deletable);

            if (undeletableExists) {

                return;
            }

            this.canvas.discardActiveObject();

            activeObjects.forEach((object: FabricObject) => {

                this.canvas.remove(object);
            });
        }

        this.objects = this.getObjects();

        const { onRemove } = this;

        if (onRemove) {

            // in a case to delete multiple objects
            const { _objects: activeObjects } = activeObject;

            activeObjects ? activeObjects.forEach((object: FabricObject) => onRemove(object)) : onRemove(activeObject);
        }
    }

    /**
     * Get canvas objects
     *
     * @returns {FabricObject[]}
     */
    getObjects(): FabricObject[] {

        return this.canvas.getObjects().filter((object: FabricObject) => {

            return object.id && object.id !== 'workarea' &&
                object.id !== 'grid' && object.id.search(/^editor-layer-/) === -1;

        }) as FabricObject[];
    }

    /**
     * Find an object by ID
     *
     * @param {string} id
     *
     * @returns {FabricObject}
     */
    findById(id: string): FabricObject | undefined {

        return this.objects.find((object: FabricObject) => {

            return object.id === id;
        });
    }

    getPositionRelativeToObject(obj: FabricObject, modifyObj: FabricObject): Record<string, unknown> {

        const coordinates: { x: number, y: number }[] = obj.getCoords(),
            height = obj.getScaledHeight(),
            width = obj.getScaledWidth(),
            modifyHeight = modifyObj.getScaledHeight(),
            modifyWidth = modifyObj.getScaledWidth(),
            distanceFromTheObject = 2,
            matrix = obj.calcTransformMatrix(),
            transforms = fabric.util.qrDecompose(matrix);

        const [tl, tr, br, bl] = coordinates;

        switch (obj.pidCodePosition) {

            case 'top':
                return {
                    left: transforms.translateX,
                    top: transforms.translateY - (height / 2) - (modifyHeight / 2) - distanceFromTheObject,
                };

            case 'topRight':
                return {
                    left: transforms.translateX + (width / 2) + modifyWidth + distanceFromTheObject,
                    top: transforms.translateY - (height / 2) - (modifyHeight / 2) - distanceFromTheObject,
                };

            case 'right':

                return {
                    left: transforms.translateX + (width / 2) + modifyWidth + distanceFromTheObject,
                    top: transforms.translateY,
                };

            case 'rightBottom':
                return {
                    left: transforms.translateX + (width / 2) + modifyWidth + distanceFromTheObject,
                    top: transforms.translateY + (height/2) + (modifyHeight / 2) + distanceFromTheObject,
                };

            case 'bottom':
                return {
                    left: transforms.translateX,
                    top: transforms.translateY + (height/2) + modifyHeight + distanceFromTheObject,
                };

            case 'bottomLeft':
                return {
                    left: transforms.translateX - (width / 2) - modifyWidth - distanceFromTheObject,
                    top: transforms.translateY + (height/2) + modifyHeight + distanceFromTheObject,
                };

            case 'left':
                return {
                    left: transforms.translateX - (width / 2) - modifyWidth - distanceFromTheObject,
                    top: transforms.translateY,
                };

            case 'topLeft':
                return {
                    left: transforms.translateX - (width / 2) - modifyWidth - distanceFromTheObject,
                    top: transforms.translateY - (height/2) - (modifyHeight / 2) - distanceFromTheObject,
                };
            default :
                return {
                    left: obj.left,
                    top: obj.top,
                };
        }

    }
}
