import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { GetMonitoringTreeService, UpdateMonitoringTreeService } from '../services';
import { monitoringTreeConstants } from '../constants';
import { IErrors, IFlatTreeFactory, IFlatTreeProcess, IFlatTreeUnit, IMonitoringTree } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Factory related actions
 *
 * @type {Object}
 */
export const MonitoringActions = {

    /**
     * Get list of factories
     *
     * @params { String } search Search string
     * @param { number } dashboardId
     *
     * @return {Promise<Object>}
     */
    list: (dashboardId: number) => {

        //Action creators
        const success = (data: IMonitoringTree) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREE_SUCCESS,
                data,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREE_FAILURE,
                errors,
            };

        }, service = new GetMonitoringTreeService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.get(dashboardId)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Get monitorins tree data for all given dashboards
     *
     * @param {number[]} ids
     * @param {number} selectedId
     *
     * @return {Promise<Object>}
     */
    listAll: (ids: number[], selectedId?: number) => {

        const success = (data: IMonitoringTree, dashboardId: number) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREES_PART_SUCCESS,
                data,
                dashboardId,
            };

        }, selectionLoaded = (data: IMonitoringTree) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREE_SUCCESS,
                data,
            };

        }, service = new GetMonitoringTreeService();

        return async (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            for await (const id of ids) {
                await service.get(id)
                    .then(({ data }: AxiosResponse) => {

                        dispatch(success(data, id));

                        if (selectedId && selectedId === id) {

                            dispatch(selectionLoaded(data));
                        }

                    })
                    .catch(() => {

                        //any idea what can be done here?
                    });
            }
        };
    },

    /**
     *
     * @param { number } dashboardId
     * @param { string } type
     * @param { number } item
     * @param {Object} data
     *
     *  @return {Promise<Object>}
     */
    update: (dashboardId: number, type: string, item: number, data: any, onlyLocal?: boolean) => {

        //Action creators
        const failure = ({ errors }: IErrors) => {

            return {
                type: monitoringTreeConstants.UPDATE_MONITORING_TREE_FAILURE,
                errors,
            };

        }, updateMonitoringTree = (data: (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[]) => {

            return {
                type: monitoringTreeConstants.UPDATE_LOCAL_MONITORING_TREE_SUCCESS,
                data,
            };

        }, updateMonitoringTreePart = (data: (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[], dashboardId: number) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREES_PART_SUCCESS,
                data,
                dashboardId,
            };

        }, service = new UpdateMonitoringTreeService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            const updatedTree = service.updateLocalTree(dashboardId, type, item, data);

            if (updatedTree) {
                dispatch(updateMonitoringTree(updatedTree));

                dispatch(updateMonitoringTreePart(updatedTree, dashboardId));
            }

            if (!onlyLocal) {

                const value = data?.sensorChange || data;

                service.update(dashboardId, type, item, value)
                    .catch((error) => {

                        dispatch(failure(service.errorHandler(error)));
                    });
            }
        };
    },
};