import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { IZoneApiModel } from './zoneService';

/**
 * Service to work with barrier API resources
 *
 * @class BarrierService
 */
export class BarrierService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/barriers(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [];
    }

    /**
     * Get list of barriers
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<IBarrierApiModel[]> {

        return trackPromise(
            this.http
                .get<IBarrierRawApiModel[]>(this.url(), {
                    params: this.prepareListParams(search, order, join, filter),
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(rawBarriers => rawBarriers.map(mapToIBarrierApiModel))
        );
    }

    /**
     * Create barrier
     * @param model
     */
    store(model: IBarrierApiModel): Promise<IBarrierApiModel> {
        const url = this.url();

        return trackPromise(
            this.http
                .post<IBarrierRawApiModel>(url, model, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToIBarrierApiModel)
        );
    }

    /**
     * Update barrier
     * @param model
     */
    update(model: IBarrierApiModel): Promise<IBarrierApiModel> {
        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .patch<IBarrierRawApiModel>(url, model, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToIBarrierApiModel)
        );
    }

    /**
     * Delete barrier
     * @param model
     */
    delete(model: IBarrierIndentOnly): Promise<boolean> {
        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .delete<IBarrierApiModel>(url, {
                    headers: this.headers,
                })
                .then(Boolean)
        );
    }

    /**
     * Save barrier
     *
     * @param {IBarrierApiModel} barrier
     *
     * @return {Promise<IBarrierApiModel>}
     */
    save(barrier: IBarrierApiModel): Promise<IBarrierApiModel> {

        if (barrier.id && barrier.id > 0) {

            return this.update(barrier);
        }

        return this.store(barrier);
    }
}

export function mapToIBarrierApiModel(rawBarrier: IBarrierRawApiModel): IBarrierApiModel {
    return {
        id: rawBarrier.id,
        name: rawBarrier.name,
        points: rawBarrier.points,
        zone: rawBarrier.zone ? rawBarrier.zone.id : null,
        plan: rawBarrier.plan,
    };
}

export interface IBarrierApiModel {
    id: number;
    name: string;
    points: Array<Array<number>>;
    zone: number | null;
    plan: number;
}

export interface IBarrierRawApiModel {
    id: number;
    name: string;
    points: Array<Array<number>>;
    zone: IZoneApiModel;
    plan: number;
}

export type IBarrierIndentOnly = Pick<IBarrierApiModel, 'id'>;
