// import {SensorService} from '../services';
import { sensorConstants } from '../constants';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { ISensor } from '../interfaces';


/**
 * Sensors related actions
 *
 * @type {Object}
 */
export const EditSensorActions = {


    /**
     * Get list of sensors
     *
     * @params {String} sensor Search string
     *
     * @return {Promise<Object>}
     */

    edit: (sensor: any, open: boolean) => {

        //Action creators
        function openSideBar(sensor: object, open: boolean) {
            return {
                type: sensorConstants.EDIT_OPEN_RIGHT_SIDEBAR,
                sensor: sensor,
                open: open,
            };
        }


        // const service = new SensorService();

        return async(dispatch: Dispatch<ISensor, {}, any>) => {

            dispatch(openSideBar(sensor, open));

        };
    },
};
