import React, { useCallback } from 'react';
import { Modal as MuiModal, ModalProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'reshadow';

import Button from '../Button/Button';

const styles = require('./InfoModal.scss');

/**
 * Information modal popup component
 *
 * @param {boolean} open Popup open/close switcher
 * @param {object} children Popup child elements
 * @param {object} props The rest props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const InfoModal: React.FC<ModalProps> = ({
    open,
    children,
    onClose,
    ...props
}) => {

    const { t } = useTranslation();

    const handleClose = useCallback(
        e => {
            e.stopPropagation();
            onClose && onClose({}, 'backdropClick');
        },
        [onClose]
    );

    return styled(styles)(
        <MuiModal
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={open}
            onClose={handleClose}
            {...props}
            disableEnforceFocus
        >
            <div className="info-modal">
                <h2 id="modal-title">{t('INFORMATION')}</h2>
                <p id="modal-description">
                    {children}
                </p>
                <div>
                    <Button
                        type="button"
                        color={'primary'}
                        onClick={handleClose}
                    >{t('CLOSE')}
                    </Button>
                </div>
            </div>
        </MuiModal>
    );
};

export default InfoModal;
