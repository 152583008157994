import { trackPromise } from 'react-promise-tracker';
import { AxiosResponse } from 'axios';
import HmiProvider from '../providers/hmiProvider';
import { IFilter, IHmiObject, IJoin, IOrder } from '../interfaces';

/**
 * Service to work with auth API resources
 *
 * @class AppSetting
 */
export class hmiObjectsService extends HmiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/hmi-objects(/:id)';
    }


    /**
     * Get list of hmi schema
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse> {

        return await this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }

    /**
     * Store hmi schema
     * @param {IHmiObject} hmiObject
     * @return {Promise<AxiosResponse>}
     */
    store(hmiObject: IHmiObject): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url(), hmiObject, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Update hmi schema
     *
     * @return {Promise<Object>}
     * @param hmiObject
     */
    update(hmiObject: IHmiObject): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: hmiObject.id }), hmiObject, {
                    headers: this.headers,
                }),
        );
    }

    /**
     * Remove hmi schema by ID
     *
     *
     * @return {Promise<Object>}
     * @param hmiObject
     */
    remove(hmiObject: IHmiObject): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ id: hmiObject.id }), {
                    headers: this.headers,
                }),
        );
    }
}
