import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';
import moment from 'moment';

/**
 * Service to work with auth API resources
 *
 * @class AppSetting
 */
export class DataExportService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/data-export(/:export)';
    }

    /**
     * Export state data
     *
     * @return {Promise<AxiosResponse>}
     */
    exportState(fromDate: string, toDate: string, controllerId: string, sensorId: string): Promise<AxiosResponse> {

        const tz = moment().format('Z');

        return trackPromise(
            this.http
                .post(this.url() + '/export-state', {
                    from: fromDate,
                    to: toDate,
                    controllerId: controllerId,
                    sensorId: sensorId,
                    // Wait for update backend
                    // tz: tz,
                }, {
                    headers: this.headers,
                    responseType: 'blob',
                })
        );
    }

    /**
     * Export all state data
     *
     * @return {Promise<AxiosResponse>}
     */
    exportAllState(fromDate: string, toDate: string, dashboardId: number): Promise<AxiosResponse> {

        const tz = moment().format('Z');

        return trackPromise(
            this.http
                .post(this.url() + '/export-sensors', {
                    from: fromDate,
                    to: toDate,
                    dashboardId,
                    // Wait for update backend
                    // tz: tz,
                }, {
                    headers: this.headers,
                    responseType: 'blob',
                })
        );
    }

}
