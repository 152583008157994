import React, { useCallback, useEffect, useState } from 'react';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import './DateInput.scss';

/**
 * add format duration function
 */
momentDurationFormatSetup.bind(moment());

interface IProps {
    time: Date;
    label?: string;
    newTime: (date: Date, startDate: string) => void;
    inputName?: string;
    datePosition: string;
    onError: (event: boolean) => void;
    disabled?: boolean;
}

/**
 * component DurationInput
 *
 */
const DateInput: React.FC<IProps> = ({
                                         time,
                                         label,
                                         newTime,
                                         datePosition,
                                         inputName,
                                         onError,
                                         disabled,
                                     }: IProps) => {

    const [timeState, setTimeState] = useState('0000');

    const [error, setError] = useState(false);


    useEffect(() => {

        setTimeState(moment(time).format('HH:mm'));

    }, [time]);

    /**
     * Change handler
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {

        const value = event.target.value;

        if (value.length === 5) {

            setTimeState(moment(time).format('HH:mm'));


            onError(false);

            setError(false);

            const newDate = moment(time).set({
                h: parseInt(value.split(':')[0]),
                m: parseInt(value.split(':')[1]),
            }).toDate();

            newTime(newDate, datePosition);

        } else {

            onError(true);

            setError(true);
        }

    }, [setTimeState, onError, setError, newTime, datePosition, time]);

    return (
        <React.Fragment>
            <div className={'form-field date ' + (inputName ? inputName : '')}>
                <label
                    className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated"
                >
                    {label}
                </label>
                <div
                    className={'MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth' + (error ? ' error' : '') + (disabled ? ' Mui-disabled' : '')}
                >
                    <div
                        className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense"
                    >
                        <Cleave
                            options={{
                                numericOnly: true,
                                time: true,
                                timePattern: ['h', 'm'],
                            }}
                            placeholder={'--:--'}
                            type="tel"
                            className={'MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense'}
                            value={timeState}
                            disabled={disabled ? disabled : false}
                            onChange={onChange}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default DateInput;
