import {
    alertConstants,
    configurationConstants,
    factoryConstants,
    processConstants,
    sensorConstants, unitConstants,
} from '../../constants';
import {
    IConfigurationTreeAction,
    IConfigurationTreeState,
    IMonitoringTree,
    IMonitoringTreeUnit,
} from '../../interfaces';


/**
 * Graph Minimap visibility reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function configurationTree(state: IConfigurationTreeState = {
    items: [],
    refresh: Date.now(),
    errors: {},
}, action: IConfigurationTreeAction): IConfigurationTreeState {

    const selectUnitFromTree = (items: IMonitoringTree[]): IMonitoringTreeUnit[] => {

        const units: IMonitoringTreeUnit[] = [];

        items.forEach(factory => {
            if (factory.type === 'factory') {
                factory.data.forEach(process => {
                    process.data.forEach(unit => {
                        units.push(unit);
                    });
                });
            }
        });

        return units;
    };
    switch (action.type) {

        case configurationConstants.LIST_CONFIGURATION_SUCCESS:

            return {
                ...state,
                items: [...action.item],
                // unitInTree: selectUnitFromTree(action.item),
                errors: {},
            };


        // case factoryConstants.BULK_UPDATE_SUCCESS:
        case processConstants.REFRESH_TREE:
        case configurationConstants.REMOVE_CONFIGURATION_SUCCESS:
        case sensorConstants.EDIT_SUCCESS:
        case unitConstants.UPDATE_SUCCESS:
        case alertConstants.BULK_STORE_ALERT_SUCCESS:

            return {
                ...state,
                refresh: Date.now(),
            };

        case configurationConstants.UPDATE_CONFIGURATION_PRODUCT_SUCCESS:

            return {
                ...state,
                items: action.payload.items,
            };

        case configurationConstants.LIST_CONFIGURATION_FAILURE:

            return {
                ...state,
                errors: action.errors,
                refresh: Date.now(),
                items: [],
                unitInTree: [],
            };

        default:

            return state;
    }
}