import { IHRDashboard, IHRDashboardUpdateModel } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';
import { MonitoringTreeActions, MonitoringTreeActionTypes } from '../actions/monitoringTreeActions';

export interface IMonitoringTreeState {
    isLoading: boolean;
    monitoringTree: IHRDashboard | null;
    hrData: any[];
    hrDataRange: any[];
    error: IErrors;
    range: boolean;
}

export const initialState: IMonitoringTreeState = {
    isLoading: false,
    monitoringTree: null,
    hrData: [],
    hrDataRange: [],
    error: { },
    range: false,
};

export function monitoringTreeReducer(state = initialState, action: MonitoringTreeActions): IMonitoringTreeState {

    switch (action.type) {

        case MonitoringTreeActionTypes.FETCH_MONITORING_TREE_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: {},
            };
        }

        case MonitoringTreeActionTypes.FETCH_MONITORING_TREE_SUCCESS: {

            const { monitoringTree } = action.payload;

            return {
                ...state,
                isLoading: false,
                monitoringTree: monitoringTree,
            };
        }

        case MonitoringTreeActionTypes.FETCH_MONITORING_TREE_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case MonitoringTreeActionTypes.UPDATE_MONITORING_TREE_SUCCESS: {

            const { monitoringTree } = action.payload;

            return {
                ...state,
                isLoading: false,
                monitoringTree: updateMonitoringTree(state.monitoringTree, monitoringTree),
            };
        }


        case MonitoringTreeActionTypes.UPDATE_MONITORING_TREE_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }


        case MonitoringTreeActionTypes.CLEAR_MONITORING_TREE_ERROR: {

            return {
                ...state,
                error: {},
            };
        }

        case MonitoringTreeActionTypes.PROCESS_HR_DATA: {

            const { dataHr, override } = action.payload;

            return {
                ...state,
                hrData: override ? [...[dataHr]] : appendHRData(state.hrData, [dataHr]),
            };
        }

        case MonitoringTreeActionTypes.PROCESS_HR_DATA_UPDATE: {

            const { dataHr } = action.payload;

            return {
                ...state,
                hrData: dataHr,
            };
        }

        case MonitoringTreeActionTypes.PROCESS_HR_DATA_RANGE: {

            const { dataHrRange, override } = action.payload;

            return {
                ...state,
                hrDataRange: override ? [...[dataHrRange]] : appendHRData(state.hrDataRange, [dataHrRange]),
            };
        }

        case MonitoringTreeActionTypes.PROCESS_HR_DATA_RANGE_UPDATE: {

            const { dataHrRange } = action.payload;

            return {
                ...state,
                hrDataRange: dataHrRange,
            };
        }

        default: {
            return state;
        }
    }
}

/**
 *
 * @param {IHRDashboard | null} prevState
 * @param {IHRDashboardUpdateModel} updateModel
 * @return {IHRDashboard | null}
 */
const updateMonitoringTree = (prevState: IHRDashboard | null, updateModel: IHRDashboardUpdateModel): IHRDashboard| null =>{

    if (prevState?.id === updateModel.id) {

        prevState.isMinimized = updateModel.isMinimized;

        if (updateModel.department) {

            const { department } = updateModel;
            const departmentIndex = prevState.departments.findIndex(value => value.id === department.id);

            if (departmentIndex >= 0) prevState.departments[departmentIndex].isMinimize = department.isMinimized;
        }

        if (updateModel.employee) {

            prevState.departments.map(department => {

               const employeeIndex: number = department.employees.findIndex(employee => employee.id === updateModel.employee?.id);

                if (employeeIndex >= 0 && updateModel.employee) {

                    department.employees[employeeIndex].position = updateModel.employee.position;
                    department.employees[employeeIndex].isVisible = updateModel.employee.isVisible;
                }

                return department;
            });
        }

    }

    return prevState;
};

const appendHRData = <T>(currentData: T[], newData: T[]) => {

    return [...currentData, ...newData];
};