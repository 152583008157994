import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
    ActivateProductService,
    ProductService,
    ProductSortedByCategoryService,
    ProductTargetValueService,
    SensorService
} from '../services';
import { productConstants } from '../constants';
import {
    IActivateProduct,
    IActivateProductInCurrentTime, ICurrentTimeProduct,
    IErrors,
    IFilter,
    IJoin,
    IOrder,
    IProduct, IProductCategory, ITargetValue,
} from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Process related actions
 *
 * @type {Object}
 */
export const ProductActions = {

    /**
     * Get list of processes
     *
     * @params {String} search Search string
     * @param search
     * @param {Object} order Sort settings
     * @param join
     * @param filter
     *
     * @return {Promise.<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'asc' }, join?: IJoin, filter?: IFilter) => {

        //Action creators
        const success = (products: IProduct[]) => {

            return {
                type: productConstants.LIST_SUCCESS,
                products,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.LIST_FAILURE,
                errors,
            };
        }, service = new ProductService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join, filter)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Create new user account
     *
     * @param {IProduct} product
     *
     * @return {Promise<Object>}
     */
    store: (product: IProduct) => {

        //Action creators
        const success = (product: IProduct) => {

            return {
                type: productConstants.STORE_SUCCESS,
                product,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.STORE_FAILURE,
                errors,
            };

        }, service = new ProductService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.store(product)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Update user account
     *
     * @param {IProduct} product
     *
     * @return {Promise<Object>}
     */
    update: (product: IProduct) => {

        //Action creators
        const success = (product: IProduct) => {

            return {
                type: productConstants.UPDATE_SUCCESS,
                product,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new ProductService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(product)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Remove product by ID
     *
     * @param {number} productId
     *
     * @return {Promise<Object>}
     */
    remove: (productId: number) => {

        //Action creators
        const success = () => {

            return {
                type: productConstants.DELETE_SUCCESS,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new ProductService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.remove(productId)
                .then(() => {

                    dispatch(success());

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     *
     * @param ProductInCurrentTime
     */
    getActivateProduct: (ProductInCurrentTime: IActivateProductInCurrentTime) => {

        //Action creators
        const success = (data: ICurrentTimeProduct[]) => {

            return {
                type: productConstants.ACTIVE_PRODUCT_IN_CURRENT_TIME_SUCCESS,
                dataCurrentTimeProduct: data,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.ACTIVE_PRODUCT_IN_CURRENT_TIME_FAILURE,
                errors,
            };

        }, service = new ActivateProductService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.getActiveProduct(ProductInCurrentTime)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));

                });

        };
    },

    activateProduct: (activateProduct: IActivateProduct) => {
        //Action creators
        const success = (data: any[]) => {

            return {
                type: productConstants.LIST_ACTIVE_PRODUCT_SUCCESS,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.LIST_ACTIVE_PRODUCT_FAILURE,
                errors,
            };
        }, service = new ActivateProductService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.activateProduct(activateProduct)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Receive data from products and put it into storage
     *
     * @param {array} data
     * @param {boolean} override
     * @param {boolean} range
     *
     * @return {Promise<Object>}
     */
    processProductData: (data: IActivateProduct[], override: boolean, range: boolean) => {

        const receiveData = (data: IActivateProduct[]) => {

            return {
                type: productConstants.RECEIVE_ACTIVE_PRODUCT_DATA,
                activeProductData: data,
                override: override,
                range: range,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(receiveData(data));
        };
    },

    /**
     * Receive only new data from sensors and update data in storage
     *
     * @param {array} data
     *
     * @return {Promise<Object>}
     */
    updateProductData: (data: IActivateProduct[]) => {

        const updateData = (data: IActivateProduct[]) => {

            return {
                type: productConstants.UPDATE_SENSORS_DATA,
                activeProductData: data,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(updateData(data));
        };
    },

    /**
     * Get Target Value for sensor
     *
     *
     * @return {Promise<Object>}
     * @param search
     * @param order
     * @param join
     */
    getAllTargetValue: (search = '', order: IOrder = {
        column: 'id',
        dir: 'asc',
    }, join: IJoin = { table: ['productTargetValues', 'productTargetValues.product'] }) => {

        const success = (data: ITargetValue[]) => {

            return {
                type: productConstants.GET_ALL_TARGET_VALUE_SUCCESS,
                allTargetValue: data,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.GET_ALL_TARGET_VALUE_FAILURE,
                errors,
            };
        }, service = new SensorService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order, join)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },
    /**
     * Get Target Value for sensor
     *
     * @param productId
     *  @param sensorId
     *
     * @return {Promise<Object>}
     */
    getTargetValue: (productId: number, sensorId: number) => {

        const success = (data: ITargetValue[]) => {

            return {
                type: productConstants.GET_TARGET_VALUE_SUCCESS,
                targetValue: data,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.GET_TARGET_VALUE_FAILURE,
                errors,
            };
        }, service = new ProductTargetValueService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.get(productId, sensorId)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },
    /**
     * Receive data from sensors and put it into storage
     *
     * @param productId
     * @param sensorId
     * @param {array} data
     *
     * @return {Promise<Object>}
     */
    updateTargetValue: (productId: number, sensorId: number, data: { minTargetValue: number | null, maxTargetValue: number | null }) => {

        const success = (data: { minTargetValue: number, maxTargetValue: number }[]) => {

            return {
                type: productConstants.UPDATE_TARGET_VALUE_SUCCESS,
                targetValue: data,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.UPDATE_TARGET_VALUE_FAILURE,
                errors,
            };
        }, service = new ProductTargetValueService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(productId, sensorId, data)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Get list of processes
     *
     * @params {String} search Search string
     * @param search
     * @param {Object} order Sort settings
     * @param join
     * @param filter
     *
     * @return {Promise.<Object>}
     */
    getAllProductSortedByCategory: () => {

        //Action creators
        const success = (products: IProductCategory[]) => {

            return {
                type: productConstants.GET_All_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS,
                products,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.GET_All_PRODUCTS_SORTED_BY_CATEGORY_FAILURE,
                errors,
            };
        }, service = new ProductSortedByCategoryService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.getAllProduct()
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Get list of processes
     *
     * @params {String} search Search string
     * @param search
     * @param {Object} order Sort settings
     * @param join
     * @param filter
     *
     * @return {Promise.<Object>}
     */
    getProductByUnitId: (unitId: number) => {

        //Action creators
        const success = (products: IProductCategory[]) => {

            return {
                type: productConstants.GET_PRODUCTS_SORTED_BY_CATEGORY_SUCCESS,
                products,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: productConstants.GET_PRODUCTS_SORTED_BY_CATEGORY_FAILURE,
                errors,
            };
        }, service = new ProductSortedByCategoryService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.getProductByUnitId(unitId)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

};
