import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';
import { selectMonitoringTreeCollection } from '../selectors/monitoringTree/monitoringTreeCollectionSelector';
import store from '../store';
import { ObjectHelper } from '../helpers/objectHelper';
import {
    IFlatTreeFactory,
    IFlatTreeProcess,
    IFlatTreeUnit,
    ISensor,
} from '../interfaces';

/**
 * Service to work with user API resources
 *
 * @class ProductService
 */
export class UpdateMonitoringTreeService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/configuration-tree/extra(/:dashboardId/:type/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [''];
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    update(dashboardId: number, type: string, item: number, data: any): Promise<AxiosResponse> {

        return this.http
            .patch(this.url({ dashboardId: dashboardId, type: type, id: item }), data, {
                headers: this.headers,
            });
    }

    /**
     * Update monitoring tree data in the local storage
     *
     * @param {number} dashboardId
     * @param {string} type
     * @param {number} item
     * @param {object} data
     */
    updateLocalTree(dashboardId: number, type: string, item: number, data: any): (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)[] | false {

        const id = dashboardId.toString();

        const monitoringTrees = selectMonitoringTreeCollection(store.getState());

        const monitoringTree = (monitoringTrees && Object.prototype.hasOwnProperty.call(monitoringTrees, id)) ?
            ObjectHelper.getKeyValue(monitoringTrees, id) : null;

        if (monitoringTree) {

            const updatedTree = monitoringTree.map((treeItem: (IFlatTreeFactory | IFlatTreeProcess | IFlatTreeUnit)) => {

                if (type === 'factory' && treeItem.type === 'factory' && item === treeItem.id) {

                    return { ...treeItem, ...data };
                }

                if (type === 'process' && treeItem.type === 'process' && item === treeItem.id) {

                    return { ...treeItem, ...data };
                }

                if (type === 'unit' && treeItem.type === 'unit' && item === treeItem.id) {

                    return { ...treeItem, ...data };
                }

                if (treeItem.type === 'unit') {

                    if ('moveSensor' in data) {

                        const removeSensorId = (treeItem as IFlatTreeUnit).data.findIndex(value => value.id === data.moveSensor?.sensor?.id);

                        if (data.moveSensor?.unitId === (treeItem as IFlatTreeUnit).id && removeSensorId === -1) {

                            (treeItem as IFlatTreeUnit).data.push(data.moveSensor.sensor as ISensor);

                        }
                    }

                    if (('moveSensor' || 'isKeyParameter' || 'graphPreferences' || 'sensorChange') in data || type === 'sensor') {

                        if (('isKeyParameter') in data) {
                            (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data
                                .map((sensor: ISensor) => {

                                    if ('isKeyParameter' in data) {

                                        sensor.isKeyParameter = false;
                                    }

                                    if (type === 'sensor' && item === sensor.id) {

                                        return { ...sensor, ...data };
                                    }

                                    return sensor;
                                });
                        }

                        if (('moveSensor') in data) {

                            (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data
                                .filter((sensor: ISensor) => 'moveSensor' in data &&
                                    data.moveSensor?.unitId !== (treeItem as IFlatTreeUnit).id &&
                                    data.moveSensor?.sensor?.id !== sensor.id);
                        }

                        if ('sensorChange' in data) {

                            (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data
                                .map((sensor: ISensor) => {

                                    if (sensor.id === data.sensorChange.id) {

                                        return { ...sensor, ...data.sensorChange };
                                    }

                                    return sensor;
                                });
                        }

                        if ('graphPreferences' in data) {

                            (treeItem as IFlatTreeUnit).data = (treeItem as IFlatTreeUnit).data
                                .map((sensor: ISensor) => {

                                    if (sensor.id === item) {

                                        return { ...sensor, ...data };
                                    }

                                    return sensor;
                                });
                        }

                        return { ...treeItem };
                    }

                    return treeItem;

                }

                return treeItem;
            });

            return updatedTree;
        }

        return false;
    }
}