import ApiProvider from '../providers/apiProvider';
import { IJoin, IOrder, IProductCategory } from '../interfaces';
import { trackPromise } from 'react-promise-tracker';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class ProductCategoriesService
 */
export class ProductCategoriesService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/categories(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of product categories
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join: IJoin): Promise<AxiosResponse> {

        return this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order, join),
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }

    /**
     * Create new user category
     *
     * @param {IProductCategory} category
     *
     * @return {Promise<Object>}
     */
    store(category: IProductCategory): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url(), category, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Update product category
     *
     * @param {IProductCategory} category
     *
     * @return {Promise<Object>}
     */
    update(category: IProductCategory): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: category.id }), category, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Remove product category by ID
     *
     * @param {number} categoryId
     *
     * @return {Promise<Object>}
     */
    remove(categoryId: number): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ id: categoryId }), {
                    headers: this.headers,
                })
        );
    }

}