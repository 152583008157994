import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape } from 'yup';

import { FormActions } from '../../../core/actions';
import { ICause, IError } from '../../../core/interfaces';
import { Button, TextInput, ConfirmDialog } from '../../../core/ui/components';
import './StatesForm.scss';
import { RootState } from '../../../core/store';


interface IFormValues {
    id?: number | null;
    name: string;
}

interface IProps {
    errors: IError;
    formOpened: boolean;
    toggleForm: (opened: boolean, name: string) => void;
    model: ICause | null;
    confirmAdd: (item: ICause, name: string) => void;
    confirmUpdate: (item: ICause, name: string) => void;
}

interface IState {
    initialValues: IFormValues;
    confirm: boolean;
}

/**
 * Reason add/edit form
 *
 * @class ReasonForm
 */
class ReasonForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, model } = this.props;

        this.state = {
            initialValues: {
                id: model && model.id ? parseInt(model.id.toString()) : null,
                name: model ? model.name.toString() : '',
            },
            confirm: false,
        };

        this.validationSchema = yup.object().shape({
            name: yup
                .string()
                .trim()
                .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
                .required(t('CAUSE_IS_REQUIRED')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleCloseSidebar = this.handleCloseSidebar.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);
    }

    /**
     * Component props update handler
     *
     * @param {IProps} nextProps Updated component properties
     */
    componentDidUpdate(nextProps: IProps) {

        const { model } = this.props;

        if (nextProps.model !== model && model && model.id) {

            this.setState({
                initialValues: {
                    id: parseInt(model.id.toString()),
                    name: model.name.toString() || '',
                },
            });
        }
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<Record<string, unknown>, IFormValues>>;


    /**
     * Reason form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        if (values.name) {

            this.setState({ confirm: true, initialValues: values });

        }
    }

    /**
     * Reason form cancel handler
     */
    handleCancel() {

        this.setState({ confirm: false });

    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {

        const { initialValues } = this.state;
        const { errors } = this.props;

        if (!initialValues.id) {

            this.props.confirmAdd({
                name: initialValues.name,
            }, 'reason');

        } else {

            this.props.confirmUpdate({
                id: initialValues.id,
                name: initialValues.name,
            }, 'reason');
        }

        if (!errors || (errors && !errors.field)) {


            this.handleCancel();
        }
    }

    /**
     *  Close sidebar handler
     */
    handleCloseSidebar() {

        this.props.toggleForm(this.props.formOpened, '');

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render(): JSX.Element {

        const { t, errors = {} } = this.props,
            { initialValues } = this.state;

        return (
            <div className={'form-add-edit-states'}>
                <h3 className={'title'}>{t(!initialValues.id ? 'ADD_CAUSE' : 'EDIT_CAUSE')}</h3>
                {errors && errors.field === 'name' &&
                <div className="common-error">
                    {t('THE_CAUSE_HAS_ALREADY_BEEN_TAKEN')}
                </div>}
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate style={{ paddingBottom: 80 }}>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.name ? (props.errors.name || errors.field === 'name') ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                label={this.props.t('NAME_REASON')}
                                value={props.values.name}
                                name="name"
                                type="text"
                                placeholder={this.props.t('NAME_REASON')}
                                inputProps={{ maxLength: 50 }}
                            >
                                {((props.touched.name && props.errors.name) || errors.field === 'name') &&
                                <div
                                    className="validation-massage"
                                >{props.errors.name}
                                </div>
                                }
                            </TextInput>
                            <div className="button-row">
                                <Button
                                    type="button"
                                    color="primary"
                                    onClick={this.handleCloseSidebar}
                                >
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="secondary">
                                    {t(initialValues.id ? 'SAVE_CHANGES' : 'SAVE')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
                {this.state.confirm?
                    <ConfirmDialog
                        heading={t(initialValues.id ? 'CHANGE_REASON_Q' : 'ADD_REASON_Q')}
                        onAccept={this.onConfirmDialog}
                        onClose={this.handleCancel}
                        open={this.state.confirm}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { errors } = state.statesList,
        { formOpened } = state.form;

    return {
        errors,
        formOpened,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReasonForm));