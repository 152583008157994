import { dashboardConstants } from '../../../../core/constants';
import { IDashboardAction, IDashboardState } from '../../../../core/interfaces';


/**
 * Dashboard reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function dashboard(state: IDashboardState = {
    dashboard: null,
    dashboards: [],
    errors: {},
    sensorsData: [],
    sensorsDataRange: [],
    online: true,
    refreshListDashboard: Date.now(),
    currentTime: true,
    lastUpdate: Date.now(),
    screenWidth: 0,
    loaded: false,
    range: false,
    overrideSensors: [],
    overrideSensorsRange: [],
}, action: IDashboardAction): IDashboardState {

    switch (action.type) {

        case dashboardConstants.STORE_SUCCESS:
        case dashboardConstants.DUPLICATE_SUCCESS:
        case dashboardConstants.UPDATE_SUCCESS:

            return {
                ...state,
                dashboard: action.dashboard,
                refreshListDashboard: Date.now(),
                errors: {},
            };

        case dashboardConstants.DELETE_SUCCESS:

            return {
                ...state,
                refreshListDashboard: Date.now(),
            };

        case dashboardConstants.STORE_FAILURE:
        case dashboardConstants.UPDATE_FAILURE:

            return {
                ...state,
                dashboard: null,
                refreshListDashboard: Date.now(),
                errors: action.errors,
            };

        case dashboardConstants.CLEAR_RANGE_DATA:
            return {
                ...state,
                sensorsDataRange: [],
            };

        case dashboardConstants.RECEIVE_SENSORS_DATA_LOADED:

            return {
                ...state,
                loaded: true,
            };

        case dashboardConstants.RECEIVE_SENSORS_DATA:

            return {
                ...state,
                sensorsData: action.sensorsData,
                loaded: false,
                overrideSensors: action.overrideSensors,
            };

        case dashboardConstants.RECEIVE_SENSORS_DATA_RANGE:

            return {
                ...state,
                sensorsDataRange: action.sensorsData,
                loaded: false,
                overrideSensorsRange: action.overrideSensorsRange,
            };

        case dashboardConstants.UPDATE_SENSORS_DATA:

            return {
                ...state,
                sensorsData: action.sensorsData,
                lastUpdate: Date.now(),
                overrideSensors: action.overrideSensors,
            };

        case dashboardConstants.UPDATE_SENSOR_DATA:

            return {
                ...state,
                // sensorsDataRange: action.sensorsData,
                sensorsData: action.sensorsData,
                lastUpdate: Date.now(),
            };

        case dashboardConstants.UPDATE_SENSORS_DATA_RANGE:

            return {
                ...state,
                sensorsDataRange: action.sensorsData,
                // sensorsData: [...state.sensorsData, ...action.sensorsData],
                lastUpdate: Date.now(),
                overrideSensorsRange: action.overrideSensorsRange,
            };

        case dashboardConstants.SET_ONLINE:

            return {
                ...state,
                online: true,
            };

        case dashboardConstants.SET_OFFLINE:

            return {
                ...state,
                online: false,
            };

        case dashboardConstants.UPDATE_SCREEN_WIDTH:

            return {
                ...state,
                screenWidth: action.width,
            };

        case dashboardConstants.LIST_SUCCESS:

            return {
                ...state,
                dashboards: action.dashboards,
            };

        case dashboardConstants.LIST_FAILURE:

            return {
                ...state,
                // dashboards: [],
            };

        case dashboardConstants.SET_ON_CURRENT_TIME:

            return {
                ...state,
                currentTime: true,
                online: true,
            };

        case dashboardConstants.SET_OFF_CURRENT_TIME:

            return {
                ...state,
                currentTime: false,
                online: false,
            };

        default:

            return state;
    }
}
