import React, { FC, useState } from 'react';
import Slider from './controls/Slider';
import Buttons from './controls/Buttons';
import { ReactComponent as HmiRectangleArrow } from '../../../core/ui/assets/images/icons/hmiRectangle.svg';
import './styles/ControlPanel.scss';
import { isAndroid, isIOS, isMobile, isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import {
    selectIsFullScreenDrawer,
    selectPositionDrawer
} from '../../../core/selectors/layout/responsiveDrawerSelector';
import { selectMaxWidthSideBar } from '../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';

/**
 * HMI Player control panel
 *
 * @constructor
 */
const ControlPanel: FC = () => {

    const [mobileControlPanelStatus, setMobileControlPanelStatus] = useState(false);
    const anchor: 'right' | 'bottom'  = useSelector(selectPositionDrawer) as 'right' | 'bottom';
    const barWidth = useSelector(selectMaxWidthSideBar);
    const isFullScreen = useSelector(selectIsFullScreenDrawer) || false;

    function changeControlPanelStatus(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.preventDefault();

        setMobileControlPanelStatus(!mobileControlPanelStatus);
    }

    const controlPanelClasses = `control-panel 
    ${isTablet ? 'tablet' : ''} 
    ${isMobile ? 'mobile' : ''} 
    ${isAndroid? 'android':''} 
    ${isIOS? 'ios':''} 
    ${mobileControlPanelStatus ? 'close' : ''} ${anchor}`;

    const controlPanelStyle = {
        width: anchor === 'bottom' ? `calc(100% - ${isFullScreen ? 0 : barWidth}px)` : '100%',
    };

    return (
        <div
            className={controlPanelClasses}
            style={controlPanelStyle}
        >
            {isMobile ?
                <div
                    className={`mobile-navigation ${mobileControlPanelStatus ? '' : 'close'}`}
                    onClick={changeControlPanelStatus}
                >
                    <HmiRectangleArrow />
                </div>
                : null
            }
            <Slider />
            <Buttons />
        </div>
    );
};

export default ControlPanel;
