import { createSelector } from 'reselect';
import { RootState } from '../../store';
import { selectDashboardStore } from '../dashboard/dashboardSelector';
import { selectGraphMinimapBrushRule } from '../graphMinimapBrush/graphMinimapBrushSelector';
import { IHistogramData } from '../../interfaces';

const getSensorData = createSelector(
    selectDashboardStore,
    dashboard => dashboard.sensorsData,
);

const getSensorDataRange = createSelector(
    selectDashboardStore,
    dashboard => dashboard.sensorsDataRange,
);

const getDataRangeRole = createSelector(
    selectDashboardStore,
    dashboard => dashboard.range,
);

const getSensorId = (state: RootState, sensorId: string) => sensorId;

export const selectSensorDataBySensorId = createSelector(
    getSensorData,
    getSensorDataRange,
    getDataRangeRole,
    selectGraphMinimapBrushRule,
    getSensorId,
    (sensorsData, sensorsDataRange, range, brushRule, sensorId) =>
        (!brushRule ? sensorsData : sensorsDataRange).find(sensorData => sensorData.sensorId === sensorId
            && sensorData.type === 'graph') as IHistogramData,
);
