import { graphConstants } from '../../../../core/constants';
import { IGraphPeriodSelectAction, IGraphPeriodSelectState } from '../../../../core/interfaces';
import moment from 'moment';


/**
 * Graph rule reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphPeriod(state: IGraphPeriodSelectState = {
    range: {
        startDate: moment().subtract({ d: 1 }).toDate(),
        endDate: moment().add({ m: 10 }).toDate(),
        key: '',
    },
}, action: IGraphPeriodSelectAction): IGraphPeriodSelectState {

    switch (action.type) {

        case graphConstants.minimapSelectPeriod:

            return {
                ...state,
                currentPeriod: action.currentPeriod,
                scaleDates: action.scaleDates,
                range: action.range,
                currentPeriodRefresh: new Date().getTime(),
            };
        case graphConstants.minimapRangeSelect:

            return {
                ...state,
                range: action.range,
                currentPeriod: !action.timerRule ? '' : state.currentPeriod,
                scaleDates: action.scaleDates,
            };

        case graphConstants.minimapSelectZoomIn:

            return {
                ...state,
                range: { ...action.range },
                currentPeriod: '',
                scaleDates: action.scaleDates,
                currentPeriodRefresh: new Date().getTime(),
            };

        case graphConstants.minimapSelectZoomOut:

            return {
                ...state,
                range: { ...action.range },
                currentPeriod: '',
                scaleDates: action.scaleDates,
                currentPeriodRefresh: new Date().getTime(),
            };

        case graphConstants.minimapReset:

            return {
                ...state,
                currentPeriod: '',
            };

        case graphConstants.minimapShifrRight:

            return {
                ...state,
                range: { ...action.range },
                scaleDates: state.scaleDates,
                currentPeriodRefresh: new Date().getTime(),
            };

        case graphConstants.minimapShiftLeft:

            return {
                ...state,
                range: { ...action.range },
                scaleDates: state.scaleDates,
                currentPeriodRefresh: new Date().getTime(),
            };


        default:

            return state;
    }
}
