import { dashboardConstants } from '../../../../core/constants';
import { IDashboardSelectAction, IDashboardSelectState } from '../../../../core/interfaces';


/**
 * Select dashboard reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function dashboardSelect(state: IDashboardSelectState = {}, action: IDashboardSelectAction): IDashboardSelectState {

    switch (action.type) {

        case dashboardConstants.SELECT:
        case dashboardConstants.STORE_SUCCESS:
        case dashboardConstants.DUPLICATE_SUCCESS:

            return {
                selectedDashboard: action.dashboard,
            };

        default:

            return state;
    }
}
