import { factoryConstants, formConstants } from '../../constants';
import { IFactoryChangeAction, IFactoryChangeState } from '../../interfaces';

/**
 * Factory add/update/delete reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function factoryChange(state: IFactoryChangeState = {
    refresh: Date.now(),
    errors: {},
}, action: IFactoryChangeAction): IFactoryChangeState {

    switch (action.type) {

        case factoryConstants.BULK_UPDATE_SUCCESS:

            return {
                refresh: Date.now(),
                errors: {},
            };

        case factoryConstants.BULK_UPDATE_FAILURE:

            return {
                refresh: Date.now(),
                errors: action.errors,
            };

        case formConstants.CLOSE:
            return {
                ...state,
                errors: {},
            };

        default:

            return state;
    }
}
