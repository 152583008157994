import { layoutConstants } from '../../constants';
import { ILayoutResponsiveDrawerAction, ILayoutResponsiveDrawerState } from '../../interfaces';

const initialState = {
    isResize: false,
    lastMousePosition: null,
    positionDrawer: 'right',
    isFullScreen: false,
};

/**
 * Responsive drawer reducer
 *
 * @param {ILayoutResponsiveDrawerState} state
 * @param {Object} action
 *
 * @return {ILayoutResponsiveDrawerState}
 */
export function responsiveDrawer(state: ILayoutResponsiveDrawerState = initialState, action: ILayoutResponsiveDrawerAction): ILayoutResponsiveDrawerState {

    switch (action.type) {

        case layoutConstants.DRAWER_RESIZE_START:

            return {
                ...state,
                isResize: true,
            };

        case layoutConstants.DRAWER_RESIZE_END:

            return {
                ...state,
                isResize: false,
                lastMousePosition: action.lastMousePosition || null,
            };

        case layoutConstants.DRAWER_POSITION:

            return {
                ...state,
                positionDrawer: action.positionDrawer,
            };

        case layoutConstants.DRAWER_TOGGLE_FULL_SCREEN:

            return {
                ...state,
                isResize: false,
                isFullScreen: action.toggleFullScreen,
            };

        default:

            return state;
    }
}
