import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { IBeacon, IDepartment, IEmployee, IFormEmployee } from '../../../../interfaces';
import * as yup from 'yup';
import TextInput from '../../../../ui/components/Input/TextInput';
import ConfirmDialog from '../../../../ui/components/Dialog/ConfirmDialog';
import Button from '../../../../ui/components/Button/Button';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IHrState } from '../../../../store/reducers';
import { selectSettingPageState } from '../../../../store/selectors/settingPageSelector';
import { SettingPageThunks } from '../../../../store/thunks/settingPageThunks';
import PhoneField from '../../../../ui/components/PhoneField/PhoneField';
import { EmployeeThunks } from '../../../../store/thunks/employeeThunks';
import { selectEmployee, selectEmployeeState } from '../../../../store/selectors/employeeSelector';
import { IErrors, IFilter, IJoin, IOrder } from '../../../../interfaces';
import { Select, MenuItem, InputLabel } from '@material-ui/core';
import { BeaconThunks } from '../../../../store/thunks/beaconThunks';
import { selectBeaconState } from '../../../../store/selectors/beaconSelector';
import { ReactComponent as Checked } from '../../../../ui/assets/images/icons/subcategory-checked.svg';


import './EmployeeForm.scss';
import { IGatewayApiModel } from '../../../../services/gatewayService';

interface IEmployeeFormProps extends WithTranslation {
    formOpening?: boolean;
    formName?: string;
    model?: IEmployee | IDepartment | IGatewayApiModel;
    toggleForm: (formOpening: boolean, formName?: string, model?: IEmployee | IDepartment) => void;
    beacons: IBeacon[];
    createEmployee: (model: IFormEmployee) => void;
    updateEmployee: (model: IFormEmployee) => void;
    error?: IErrors;
    loadBeacon: (search: string, order: IOrder, join?: IJoin, filter?: IFilter) => void;
    getEmployee: (data: IEmployee) => void;
    employee: IEmployee | null;
    clearEmployeeError: () => void;
}

const EmployeeForm: React.FC<IEmployeeFormProps> = (
    {
        t,
        model,
        toggleForm,
        createEmployee,
        updateEmployee,
        error,
        loadBeacon,
        clearEmployeeError,
        getEmployee,
        employee,
        beacons,
    }: IEmployeeFormProps
) => {

    const [initialValues, setInitialValues] = useState<IFormEmployee>({
        surname: '',
        additionalPhone: '',
        beacon: '',
        department: undefined,
        email: '',
        firstName: '',
        middleName: '',
        phone: '',
    });

    const [formValue, setFormValue] = useState<IFormEmployee | null>(null);

    const [beacon, setBeacon] = useState<IBeacon | null>(null);

    const validationSchema = yup.object().shape({
        surname: yup
            .string()
            .trim()
            .max(30, t('MAX_ITEM_LENGTH', { name: t('SURNAME'), length: '30' }))
            .required(t('SURNAME_IS_REQUIRED')),
        additionalPhone: yup.string(),
        email: yup.string()
            .trim()
            .email(t('EMAIL_MUST_BE_A_VALID_EMAIL')),
        firstName: yup.string()
            .trim()
            .max(30, t('MAX_ITEM_LENGTH', { name: t('FIRST_NAME'), length: '30' }))
            .required(t('FIRST_NAME_IS_REQUIRED')),
        middleName: yup.string()
            .min(1, t('MIN_ITEM_LENGTH', { name: t('MIDDLE_NAME'), length: '1' }))
            .max(30, t('MAX_ITEM_LENGTH', { name: t('MIDDLE_NAME'), length: '30' })),
        phone: yup.string()
            .required(t('PHONE_IS_REQUIRED')),
    });

    useEffect(() => {

        loadBeacon('', {
            column: 'id',
            dir: 'asc',
        }, { table: ['employee'] });

    }, [loadBeacon]);

    useEffect(() => {

        return () => {

            toggleForm(false);

            clearEmployeeError();
        };

    }, [toggleForm, clearEmployeeError]);

    /**
     *  Update initValue with model data
     */
    useEffect(() => {

        if (employee && model) {

            const currentModel = model as IEmployee;

            const initValue: IFormEmployee = {
                id: currentModel.id,
                surname: currentModel.surname,
                additionalPhone: currentModel.additionalPhone,
                beacon: employee.beacon ? employee.beacon.id : '',
                department: currentModel.department ? currentModel.department.id : undefined,
                email: currentModel.email ? currentModel.email : '',
                firstName: currentModel.firstName,
                middleName: currentModel.middleName,
                phone: currentModel.phone,
            };

            setInitialValues(initValue);
        }

    }, [employee, setInitialValues, model]);

    useEffect(() => {

        if (model) {

            const employee = model as IEmployee;

            getEmployee(employee);

        } else {
            setInitialValues({
                surname: '',
                additionalPhone: '',
                beacon: '',
                department: undefined,
                email: '',
                firstName: '',
                middleName: '',
                phone: '',
            });
        }

    }, [model, getEmployee]);

    /**
     * Handler confirm dialog form
     *
     * @type {() => void}
     */
    const onConfirmDialog = useCallback(() => {

        if (formValue) {

            if (formValue.id) {

                const { orderInDepartment } = model as IEmployee;

                formValue.email = formValue.email && formValue.email.length === 0 ? null : formValue.email;

                if (!formValue.orderInDepartment) {
                    formValue.orderInDepartment = orderInDepartment;
                }

                updateEmployee(formValue);

            } else {

                formValue.email = formValue.email && formValue.email.length === 0 ? null : formValue.email;

                createEmployee(formValue);

            }
        }

    }, [createEmployee, updateEmployee, formValue, model]);

    /**
     * Handler cancel dialog form
     *
     * @type {() => void}
     */
    const handleCancel = useCallback(() => {

        setFormValue(null);

    }, [setFormValue]);

    /**
     * Handler the form submit
     *
     * @param value
     */
    const handleSubmit = useCallback((value: IFormEmployee) => {

        const beacon = beacons.find(beacon => beacon.id === value.beacon);

        if (beacon) {

            setBeacon(beacon);

        }

        setFormValue(value);

    }, [setFormValue, beacons, setBeacon]);

    /**
     * Popup handler. Unset anchor element.
     */
    const handleClose = useCallback(() => {

        toggleForm(false);

        setFormValue(null);

        clearEmployeeError();

    }, [toggleForm, clearEmployeeError]);

    const sortBeacon = (beacons: IBeacon[]) => {

        const sort = (a: IBeacon, b: IBeacon) => {

            const aName = a.name.trim().toLowerCase(), bName = b.name.trim().toLowerCase();

            if (a.employee && b.employee) {

                const aEmployee = a.employee.surname.toLowerCase(), bEmployee = b.employee.surname.toLowerCase();

                return aName < bName && aEmployee < bEmployee ? -1 : 1;

            }
            if (!a.employee && !b.employee) {

                return aName < bName ? -1 : 1;

            }
            return 0;

        };

        const sortNumber = (a: IBeacon, b: IBeacon) => {

            const aName = a.name, bName = b.name;

            if (!a.employee && !b.employee) {

                return parseInt(aName) - parseInt(bName);

            }
            return 0;

        };

        const withEmployee = beacons.filter(value => value.employee),
            withOutEmployee = beacons.filter(value => !value.employee);

        return [...withOutEmployee.sort(sort).sort(sortNumber), ...withEmployee.sort(sort).sort(sortNumber)];
    };

    const ownerName = `${beacon?.employee?.surname} ${beacon?.employee?.firstName} ${beacon?.employee?.middleName}`;

    return (
        <div className="form-box form-box-space node-form">
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <form
                        noValidate
                        onSubmit={props.handleSubmit}
                    >
                        <div className="table-header">
                            <div
                                className={'title'}
                            >
                                {t(!model ? 'ADD_EMPLOYEE' : 'EDIT_EMPLOYEE')}
                            </div>
                        </div>
                        {error && error.message &&
                        <div className="common-error">{t(error.message)}</div>}
                        <div className="table-body">
                            <div className="form-group">
                                <TextInput
                                    className={
                                        'form-field '
                                        +
                                        (props.touched.surname ? props.errors.surname ? 'error-field' : 'success-field' : '')
                                    }
                                    label={`${t('SURNAME')}*`}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.surname}
                                    id="surname"
                                    name="surname"
                                    type="text"
                                    placeholder={t('SURNAME')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {props.touched.surname && props.errors.surname &&
                                    <div
                                        className="validation-massage"
                                    >
                                        {props.errors.surname}
                                    </div>
                                    }
                                </TextInput>
                            </div>
                            <div className="form-group">
                                <TextInput
                                    className={
                                        'form-field '
                                        +
                                        (props.touched.firstName ? props.errors.firstName ? 'error-field' : 'success-field' : '')
                                    }
                                    label={`${t('FIRST_NAME')}*`}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.firstName}
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    placeholder={t('FIRST_NAME')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {props.touched.firstName && props.errors.firstName &&
                                    <div
                                        className="validation-massage"
                                    >{props.errors.firstName}
                                    </div>
                                    }
                                </TextInput>
                            </div>
                            <div className="form-group">
                                <TextInput
                                    className={
                                        'form-field '
                                        +
                                        (props.touched.middleName ? props.errors.middleName ? 'error-field' : 'success-field' : '')
                                    }
                                    label={t('MIDDLE_NAME')}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.middleName}
                                    id="middleName"
                                    name="middleName"
                                    type="text"
                                    placeholder={t('MIDDLE_NAME')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {props.touched.middleName && props.errors.middleName &&
                                    <div
                                        className="validation-massage"
                                    >{props.errors.middleName}
                                    </div>
                                    }
                                </TextInput>
                            </div>
                            <div className="form-group">
                                <PhoneField
                                    inputClass="mobile-phone"
                                    formProps={props}
                                    label={`${t('MOBILE_PHONE')}*`}
                                    fieldName={'phone'}

                                />
                            </div>
                            <div className="form-group">
                                <PhoneField
                                    inputClass="additional-phone"
                                    formProps={props}
                                    label={t('ADDITIONAL_PHONE')}
                                    fieldName={'additionalPhone'}

                                />
                            </div>
                            <div className="form-group">
                                <TextInput
                                    className={
                                        'form-field '
                                        +
                                        (props.touched.email ? props.errors.email ? 'error-field' : 'success-field' : '')
                                    }
                                    label={t('EMAIL')}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.email}
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder={'example@example.com'}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {props.touched.email && props.errors.email &&
                                    <div
                                        className="validation-massage"
                                    >{props.errors.email}
                                    </div>
                                    }
                                </TextInput>
                            </div>
                            <div className="form-group select">

                                <InputLabel>{t('ASSIGNED_BEACON')}</InputLabel>
                                <Select
                                    displayEmpty
                                    className={
                                        'form-field beacon '
                                        +
                                        (props.touched.beacon ? props.errors.beacon ? 'error-field' : 'success-field' : '')
                                    }
                                    onChange={props.handleChange}
                                    placeholder={t('SELECT_BEACON')}
                                    value={props.values.beacon}
                                    name="beacon"
                                    fullWidth
                                    variant="outlined"
                                >
                                    <MenuItem value="">
                                        <span className="placeholder">{t('NONE_BEACON')}</span>
                                    </MenuItem>
                                    {sortBeacon(beacons).map(value =>
                                        (
                                            <MenuItem key={value.id} value={value.id} className="beacon-item">
                                                {(value.employee && props.values.id === value.employee.id && props.values.beacon === value.id) || props.values.beacon === value.id ?
                                                    <Checked className="checked-beacon" />
                                                    :
                                                    null
                                                }
                                                {value.employee && props.values.id !== value.employee.id && props.values.beacon !== value.id ?
                                                    <div className="selected-beacon" />
                                                    :
                                                    null
                                                }
                                                {value.name}
                                                {value.employee && props.values.id !== value.employee.id ?
                                                    <span className="employee">
                                                        {`${value.employee.surname} ${value.employee.firstName}`}
                                                    </span>
                                                    :
                                                    null
                                                }
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </div>
                            <div className="form-group btn-group">
                                <Button
                                    id="cancel"
                                    type="reset"
                                    color={'primary'}
                                    onClick={handleClose}
                                >
                                    {t('CANCEL')}
                                </Button>
                                <Button
                                    id="add"
                                    type="submit"
                                    color={'secondary'}
                                >
                                    {t(!model ? 'ADD' : 'SAVE_CHANGES')}
                                </Button>

                            </div>
                        </div>
                    </form>
                )}
            </Formik>
            {formValue ?
                <ConfirmDialog
                    heading={t(!model ? 'ADD_EMPLOYEE_Q' : 'CHANGE_EMPLOYEE_Q')}
                    onAccept={onConfirmDialog}
                    onClose={handleCancel}
                    body={beacon && beacon.employee && beacon.employee.id !== formValue?.id ? t('THIS_BEACON_HAS_ALREADY_BEEN_ASSIGNED',
                        { owner: ownerName.trim() }) : ''}
                    open={Boolean(formValue)}
                />
                :
                null
            }
        </div>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {

    const { formOpening, formName, model } = selectSettingPageState(state);

    const { error } = selectEmployeeState(state);

    const { beacons } = selectBeaconState(state);

    const employee = selectEmployee(state);

    return {
        formOpening,
        formName,
        model: model as IEmployee,
        error,
        beacons,
        employee,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: SettingPageThunks.toggleForm,
    createEmployee: EmployeeThunks.createEmployee,
    updateEmployee: EmployeeThunks.updateEmployee,
    clearEmployeeError: EmployeeThunks.clearEmployeeError,
    getEmployee: EmployeeThunks.getEmployee,
    loadBeacon: BeaconThunks.fetchBeacon,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmployeeForm));
