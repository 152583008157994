import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class ProductService
 */
export class GetMonitoringTreeService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/configuration-tree/list(/:dashboardId)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [''];
    }

    /**
     * Get list of products
     *
     * @params {number} dashboardId
     *
     * @return {Promise<Object>}
     */
    async get(dashboardId: number): Promise<AxiosResponse> {

        return this.http
            .get(this.url({ dashboardId: dashboardId }), {
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }
}