import { fabric } from 'fabric';
import { editorConstants } from '../../constants';

// TODO code clear
/**
 * Marker object definition
 *
 * @type {any}
 */
const PitCodeLabel = fabric.util.createClass(fabric.IText, {

    type: editorConstants.objects.pitCode,

    superType: 'drawing',
    initialize(options: any) {

        options.fontFamily = 'arial';
        options.fontWeight = 500;
        options.fontSize = 12;
        options.textBackgroundColor = '#000';
        options.fill = '#fff';
        options.originX = 'center';
        options.originY = 'center';
        options.objectCaching = false;
        options.ignoreZoom = false;
        options.hasControls = false;
        options.hasBorders = false;
        options.selectable = false;
        options.evented = false;
        options.lockMovementX = true;
        options.lockMovementY = true;
        options.hoverCursor = 'default';

        this.callSuper('initialize', options.label, options);

    },
    _render(ctx: CanvasRenderingContext2D) {

        ctx.save();

        ctx.beginPath();

        ctx.font = '500 12px arial';

        const metrics = ctx.measureText(this.label);

        const fontHeight = metrics.fontBoundingBoxAscent + metrics.fontBoundingBoxDescent;
        const fontWidth = (metrics.actualBoundingBoxLeft + metrics.actualBoundingBoxRight);

        this.measureWidth = fontWidth;
        this.measureHeight = fontHeight;

        ctx.fillStyle = '#000000';
        ctx.lineWidth = 3;

        ctx.fillRect((-(fontWidth) / 2) - 2, (-(fontHeight / 2)), (fontWidth) + 4, fontHeight);

        ctx.closePath();

        ctx.restore();

        this.callSuper('_render', ctx);
    },
});

PitCodeLabel.fromObject = (options: any, callback: Function) => {

    const { text = '' } = options;

    return callback(new PitCodeLabel(options));
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
window.fabric.PitCodeLable = PitCodeLabel;

export default PitCodeLabel;
