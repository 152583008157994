import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import { history } from '../../helpers';
import { AppAction, UserActions } from '../../core/actions';
import { Button, TextInput, CommonError, InfoModal } from '../../core/ui/components';
import { IAuthState, ILoginProps, ILoginState } from '../../core/interfaces';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import { ReactComponent as QiVisorLogoIcon } from '../../core/ui/assets/images/icons/logo_horisontal.svg';

interface IFormValues {
    email: string;
    password: string;
}

/**
 * Login page component
 *
 * @class Login
 */
class Login extends React.Component<ILoginProps & WithTranslation, ILoginState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: ILoginProps & WithTranslation) {

        super(props);

        this.state = {
            showAccountDeleted: !!(history.location.state && history.location.state.deleted),
        };

        const { t, user } = this.props;

        if (user && !this.state.showAccountDeleted) {

            history.push('/');
        }

        this.props.resetError();

        this.initialValues = {
            email: '',
            password: '',
        };

        this.validationSchema = yup.object().shape({
            email: yup
                .string()
                .email(t('LOGIN_MUST_BE_A_VALID_EMAIL'))
                .required(t('LOGIN_IS_REQUIRED')),
            password: yup
                .string()
                .required(t('PASSWORD_IS_REQUIRED')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleModalClose = this.handleModalClose.bind(this);
    }

    componentDidMount() {

        document.body.classList.add('login-page');

        this.props.loadAppSetting();

    }

    componentWillUnmount() {

        document.body.classList.remove('login-page');
    }

    /**
     * Form initial values
     *
     * @type {IFormValues}
     */
    private readonly initialValues: IFormValues;

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;

    /**
     * Login form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        this.props.loadAppSetting();

        this.props.login(values.email, values.password);
    }

    handleModalClose() {

        this.setState({
            showAccountDeleted: false,
        });
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, errors = {} } = this.props,
            { showAccountDeleted } = this.state;

        return (
            <section className="auth-section">
                <Helmet>
                    <meta name="viewport" content="width=device-width, user-scalable=none" />
                </Helmet>
                <LanguageSwitcher variant={'standard'} class={'login-page'} defaultLanguage={'en'} />
                <div className="container">
                    <QiVisorLogoIcon className="brand-logo" />
                    <div className="form-box">
                        <CommonError errors={errors} />
                        <Formik
                            initialValues={this.initialValues}
                            validationSchema={this.validationSchema}
                            onSubmit={this.handleSubmit}
                        >
                            {props => (
                                <form onSubmit={props.handleSubmit} noValidate>
                                    <TextInput
                                        className={
                                            'form-field ' +
                                            (props.touched.email ? (props.errors.email || errors.email) ? 'error-field' : 'success-field' : '')
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.email}
                                        name="email"
                                        id="email"
                                        type="email"
                                        placeholder={t('LOGIN')}
                                    >
                                        {((props.touched.email && props.errors.email) || errors.email) &&
                                            <div className="validation-massage">{props.errors.email || errors.email}</div>
                                        }
                                    </TextInput>
                                    <TextInput
                                        className={
                                            'form-field ' +
                                            (props.touched.password ? (props.errors.password || errors.password) ? 'error-field' : 'success-field' : '')
                                        }
                                        onChange={props.handleChange}
                                        onBlur={props.handleBlur}
                                        value={props.values.password}
                                        name="password"
                                        id="password"
                                        type="password"
                                        placeholder={t('PASSWORD')}
                                    >
                                        {((props.touched.password && props.errors.password) || errors.password) &&
                                            <div className="validation-massage">{props.errors.password || errors.password}</div>
                                        }
                                    </TextInput>
                                    <div className="button-row">
                                        <Link to="/forgot-password">{t('FORGOT_PASSWORD')}</Link>
                                        <Button
                                            id="loginButton"
                                            type="submit"
                                            color={'primary'}
                                        >
                                            {t('LOGIN')}
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div >
                <InfoModal open={showAccountDeleted} onClose={this.handleModalClose}>
                    {t('YOUR_ACCOUNT_HAS_BEEN_DELETED_PLEASE_CONTACT_ADMINISTRATOR')}
                </InfoModal>
            </section >
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: { auth: IAuthState }) => {

    const { user, errors } = state.auth;

    return {
        user,
        errors,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    login: UserActions.login,
    resetError: UserActions.logout,
    loadAppSetting: AppAction.getAppSetting,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Login));
