import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ConfigurationFlatTreeService, FactoryService } from '../services';
import { configurationConstants, factoryConstants } from '../constants';
import { IFactory, IErrors, IOrder, IData } from '../interfaces';
import { AxiosResponse } from 'axios';
import { ConfigurationActions } from './configurationTree';

/**
 * Factory related actions
 *
 * @type {Object}
 */
export const FactoryActions = {

    /**
     * Get list of factories
     *
     * @params {String} search Search string
     * @param search
     * @param {Object} order Sort settings
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'asc' }) => {

        //Action creators
        const success = (factories: IFactory[]) => {

            return {
                type: factoryConstants.LIST_SUCCESS,
                factories,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: factoryConstants.LIST_FAILURE,
                errors,
            };

        }, service = new FactoryService();


        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.list(search, order)
                .then(({ data }: AxiosResponse) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    /**
     * Factories bulk update
     *
     * @param {IData[]} factories
     * @param updateConfig
     * @param reloadTree
     *
     * @return {Promise<Object>}
     */
    bulkUpdate: (factories: IData[], updateConfig = false, reloadTree = false) => {

        //Action creators
        const success = () => {

            return {
                type: factoryConstants.BULK_UPDATE_SUCCESS,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: factoryConstants.BULK_UPDATE_FAILURE,
                errors,
            };

        }, updateConfigurationTree = (data: any[]) => {
            return {
                type: configurationConstants.UPDATE_CONFIGURATION_PRODUCT_SUCCESS,
                payload: {
                    items: data,
                },

            };
        },successListTree = (item: Record<string, unknown>[]) => {

            return {
                type: configurationConstants.LIST_CONFIGURATION_SUCCESS,
                item,
            };

        }, failureListTree = ({ errors }: IErrors) => {

            return {
                type: configurationConstants.LIST_CONFIGURATION_FAILURE,
                errors,
            };

        }, service = new FactoryService(), configurationService = new ConfigurationFlatTreeService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.bulkUpdate(factories)
                .then(() => {

                    dispatch(success());

                    if (reloadTree) {

                        configurationService.list(true)
                            .then(({ data }: AxiosResponse) => {

                                dispatch(successListTree(data));

                            })
                            .catch((error) => {

                                dispatch(failureListTree(service.errorHandler(error)));
                            });
                    }
                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                })
                .finally(() => {

                    if (updateConfig) {

                        /**
                         * After the update is completed, one element of the tree is updated
                         */
                        configurationService.getPartOfList('factory', factories[0].id).then(({ data }: AxiosResponse) => {

                            const updatedConfigurationTree = configurationService.updateLocalTree({ type: 'update_part_tree' }, data, 'factory');

                            dispatch(updateConfigurationTree(updatedConfigurationTree));
                        });
                    }
                });
        };
    },
};
