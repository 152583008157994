import { graphConstants } from '../../../../core/constants';
import { IGraphMinimapBrushAction, IGraphMinimapBrushState } from '../../../../core/interfaces';
import moment from 'moment';


/**
 * Graph Minimap brush reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function graphMinimapBrush(state: IGraphMinimapBrushState = {
    selection: [moment().subtract({ d: 1 }).toDate(), moment().add({ m: 10 }).toDate()],
    brushRule: false,
    timerRule: false,
}, action: IGraphMinimapBrushAction): IGraphMinimapBrushState {

    switch (action.type) {

        case graphConstants.minimapBrush:

            return {
                ...state,
                selection: action.selection,
                brushRule: action.brushRule,
            };

        case graphConstants.minimapBrushTimerOnOff:

            return {
                ...state,
                timerRule: action.timerRule,
            };

        default:

            return state;
    }
}
