import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { IGpsPin, ILocation, IMeasure } from '../interfaces';
import { serialize as objectToFormData } from 'object-to-formdata';
import { ILayerApiModel, mapToLayerModel } from './layerService';
import { v4 } from 'uuid';

/**
 * Service to work with location API resources
 *
 * @class LocationService
 */
export class LocationService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/plans(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return ['name'];
    }

    /**
     * Get list of locations
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<ILocation[]> {

        return  this.http
            .get<ILocationApiModel[]>(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: this.headers,
            })
            .then(this.getDataExtractor())
            .then(rawLocations => rawLocations.map(mapToLocationModel));
    }

    /**
     * Create location
     * @param location
     * @param pins
     */
    store(location: ILocation, pins: IGpsPin[]): Promise<ILocation> {

        const formData = objectToFormData({
            name: location.name,
            picture: location.picture,
            scale: location.scale,
            pins: pins.map(mapGpsPinModelToApiPayload),
            angle: location.angle,
            measure: location.measure,
        }, { indices: true });

        return trackPromise(
            this.http
                .post<ILocationApiModel>(this.url(), formData, {
                    headers: {
                        ...this.headers,
                        ...this.formDataHeaders,
                    },
                })
                .then(this.getDataExtractor())
                .then(mapToLocationModel)
        );
    }

    /**
     * Update location
     * @param location
     * @param pins
     */
    update(location: ILocation, pins: IGpsPin[]): Promise<ILocation> {
        const formData = objectToFormData({
            name: location.name,
            picture: location.picture,
            scale: location.scale,
            pins: pins.length > 0 ? pins.map(mapGpsPinModelToApiPayload) : null,
            angle: location.angle,
            measure: location.measure,
        }, { indices: true });

        return trackPromise(
            this.http
                .patch<ILocationApiModel>(this.url({ id: location.id }), formData, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
                .then(mapToLocationModel)
        );
    }

    /**
     * Delete location
     * @param location
     */
    delete(location: ILocation): Promise<boolean> {

        return trackPromise(
            this.http
                .delete<ILocationApiModel>(this.url({ id: location.id }), {
                    headers: this.headers,
                })
                .then(Boolean)
        );
    }

    /**
     * Save new or existing model
     * @param location
     * @param pins
     */
    saveLocation(location: ILocation, pins: IGpsPin[] = []): Promise<ILocation> {
        if (location.id >= 1) {
            return this.update(location, pins);
        }

        return this.store(location, pins);
    }

    /**
     * Fetch SVG picture data
     * @param location
     */
    getPicture(location: ILocation): Promise<string> {

        const url = this.url({ id: location.id }) + '/picture';

        return trackPromise(
            this.http
                .get<string>(url, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
        );
    }
}

export function mapToLocationModel(rawModel: ILocationApiModel): ILocation {
    return {
        id: rawModel.id,
        name: rawModel.name,
        picture: rawModel.picture as unknown as string,
        layers: rawModel.layers.map(mapToLayerModel),
        pins: (rawModel.pins || []).map(mapToGpsPinModel),
        scale: rawModel.scale,
        width: rawModel.width,
        height: rawModel.height,
        bounds: rawModel.bounds,
        angle: rawModel.angle,
        measure: rawModel.measure
    };
}

function mapToGpsPinModel(rawModel: IGpsPinApiModel): IGpsPin {
    return {
        id: rawModel.id,
        x: Number(rawModel.position.x) || 0,
        y: Number(rawModel.position.y) || 0,
        lat: Number(rawModel.location.lat) || 0,
        lng: Number(rawModel.location.long) || 0,
        localId: v4(),
        isDraft: false,
    };
}

function mapGpsPinModelToApiPayload(gpsPin: IGpsPin): IGpsPinApiModel {
    return {
        location: {
            lat: gpsPin.lat,
            long: gpsPin.lng,
        },
        position: {
            x: gpsPin.x,
            y: gpsPin.y,
        },
    };
}

export interface ILocationApiModel {
    id: number;
    name: string;
    picture: File;
    layers: ILayerApiModel[];
    pins: IGpsPinApiModel[] | null;
    scale: number | null;
    width: number | null;
    height: number | null;
    angle: number;
    measure: IMeasure;
    bounds?: {
        east: number;
        north: number;
        south: number;
        west: number;
    } | null;
}

interface IGpsPinApiModel {
    id?: number;
    location: {
        lat: number;
        long: number;
    },
    position: {
        y: number;
        x: number;
    }
}
