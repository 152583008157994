import { stateConstants } from '../../constants';
import { IStateSelectionAction, IStateSelectionState } from '../../interfaces';

// export interface

/**
 * State selection reducer. Controls form state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function stateSelection(state: IStateSelectionState = {}, action: IStateSelectionAction): IStateSelectionState {

    switch (action.type) {

        case stateConstants.SELECT:

            return {
                state: action.state,
                position: action.position,
                states: action.states,
                hrMode: action.hrMode,
            };

        case stateConstants.DESELECT_ALL:

            return {};

        default:

            return state;
    }
}
