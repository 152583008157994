import React, { createContext, FC, useEffect } from 'react';
import { Drawer, makeStyles } from '@material-ui/core';
import { connect, useDispatch, useSelector } from 'react-redux';

import './RightSidebar.scss';
import { RootState } from '../../core/store';
import { GraphActions } from '../../base/store/actions';
import { HmiPlayerActions } from '../../core/actions';
import { LayoutActions } from '../../core/actions/layout';
import { selectHmiPlayerSchema } from '../../core/selectors/hmi/playerSelector';



interface IRightSideBar {
    data?: Record<string, unknown> | Record<string, unknown>[];
    children?: React.ReactNode;
    openSidebar?: boolean;
    close?: (item: Record<string, unknown>, status: boolean) => void;
    name?: string;
    transitionDuration?: number | { appear?: number | undefined, enter?: number | undefined, exit?: number | undefined };
}

type RightSideBarTContext = { cancel?: (event: React.KeyboardEvent<HTMLElement> | React.MouseEvent<Element, MouseEvent>) => void };

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        minWidth: 400,
        paddingTop: 20,
        paddingLeft: 32,
        paddingRight: 32,
    },
    modal: {
        top: 40,
    },
});

/**
 * A right sidebar component
 *
 * @param  {object} children Component children
 * @param {boolean} openSidebar Flag to open / close sidebar
 * @param {function} close Close sidebar callback
 * @param {string} name
 * @param {number | { appear?: number | undefined, enter?: number | undefined, exit?: number | undefined }} transitionDuration
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const RightSidebar: FC<IRightSideBar> = (
    {
        children,
        openSidebar,
        close,
        name,
        transitionDuration = {
            enter: 200,
            exit: 80,
        },
    }: IRightSideBar,
) => {

    const classes = useStyles();

    const dispatch = useDispatch();
    const schema = useSelector(selectHmiPlayerSchema);

    const [state, setState] = React.useState({
        right: openSidebar,
    });

    type DrawerSide = 'top' | 'left' | 'bottom' | 'right';

    /**
     * Toggle Drawer
     *
     * @param {DrawerSide} side
     * @param {boolean} open
     * @return {(event: (React.KeyboardEvent | React.MouseEvent)) => void}
     */
    const toggleDrawer = (side: DrawerSide, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {

        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setState({ ...state, [side]: open });

        if (close) {

            close({}, false);

        }
    };

    /**
     * return sidebar list
     *
     * @param {DrawerSide} side
     * @return {JSX.Element}
     */
    const sideList = (side: DrawerSide) => (
        <div
            key={side}
            className={classes.fullList}
            role="presentation"
        >
            {children}
        </div>
    );

    useEffect(() => {
        setState({ ...state, right: openSidebar });

        if (openSidebar && schema) {
            dispatch(GraphActions.toggleHMI(true));
            dispatch(HmiPlayerActions.setSchema(null));
            dispatch(HmiPlayerActions.setRealTime(false));
        }
        // eslint-disable-next-line
    }, [openSidebar, schema]);

    return (
        <React.Fragment>
            <RightSideBarContext.Provider value={{ cancel: toggleDrawer('right', false) }}>
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={state.right}
                    classes={{ paper: classes.modal }}
                    ModalProps={{ hideBackdrop: true }}
                    className={`right-sidebar ${name ? name : ''}`}
                    transitionDuration={transitionDuration}
                    onMouseMove={event => event.stopPropagation()}
                >
                    {sideList('right')}
                </Drawer>
            </RightSideBarContext.Provider>
        </React.Fragment>
    );
};

export default RightSidebar;

export const RightSideBarContext = createContext<RightSideBarTContext>({});