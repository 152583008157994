import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { ReactComponent as SensorIcon } from '../../../../core/ui/assets/images/icons/parameter-v-2.svg';
import { ReactComponent as StateIcon } from '../../../../core/ui/assets/images/icons/state_icon.svg';
import { ReactComponent as KeyIcon } from '../../../../core/ui/assets/images/icons/key.svg';
import { EditMenu } from '../../../../core/ui/components';
import SensorFormList from '../../SensorFormList/SensorFormList';
import { ISensor, ISensorItemDragDropTreeProps, IUnit } from '../../../../core/interfaces';
import { isIE } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ConfigurationActions, FormActions } from '../../../../core/actions';


const SensorItem: React.FC<ISensorItemDragDropTreeProps> = (
    {
        sensor,
        unit,
        unitData,
        leftPadding,
        processRule,
        maxProductInUnit,
        ...props
    }: ISensorItemDragDropTreeProps,
) => {
    const { t } = useTranslation(),
        dispatch = useDispatch();
    /**
     *  Open form 'Edit sensor'
     *
     * @param {Object} data
     */
    const editSensor = useCallback((data: { sensor: ISensor, unit: IUnit }) => {

        dispatch(FormActions.toggle(false, 'sensor', { sensor: data.sensor, sensorParentUnit: data.unit }));

    }, [dispatch]);

    /**
     * Confirm remove sensor
     *
     * @param {Object} data
     */
    const removeConfirmation = useCallback((data: { sensor: { id: number, type: string }, unit: Record<string, unknown> }) => {

        dispatch(ConfigurationActions.remove('sensor', { id: data.sensor.id }));

    }, [dispatch]);

    const menuSensor = [
        {
            title: t('EDIT_CONFIG'),
            action: editSensor,
            color: '',
        },
        {
            title: t('DETACH_FROM_THE_UNIT'),
            action: removeConfirmation,
            icon: '',
            color: 'red',
        },
    ];

    const [editMenuActive, setEditMenuActive] = useState(false);

    const [elementContainer, setElementContainer] = useState<Element | null>(null);

    useEffect(() => {

        setElementContainer(document.getElementsByClassName('configure-draggable')[0]);

    }, [setElementContainer]);

    /**
     * callback  closing edit menu
     *
     * @type {(event) => void}
     */
    const editMenuCloseCallback = useCallback((event) => {

        setEditMenuActive(Boolean(event));

    }, [setEditMenuActive]);

    /**
     * callback  opening edit menu
     * @type {(event) => void}
     */
    const editMenuOpenCallback = useCallback((event) => {

        setEditMenuActive(Boolean(event));

    }, [setEditMenuActive]);

    const stateStyle: CSSProperties = {
            height: 24,
            width: 24,
            minWidth: 24,
            marginRight: 8,
        },
        sensorStyle: CSSProperties = {
            height: 24,
            width: 24,
            minWidth: 24,
            marginRight: 8,
        },
        keyStyle: CSSProperties = {
            height: 24,
            width: 24,
            position: 'absolute',
            left: 80,
            transform: 'translateY(-50%)',
            top: '50%',
        },
        sensorItemLeftPositionStyle: CSSProperties = {
            left: isIE ? props.scrollLeft : 0,
            position: isIE ? 'relative' : 'sticky',
        };

    return (
        <div
            className={editMenuActive ? 'sensor-item active' : 'sensor-item'}
        >
            <div
                className={'right-section ' + leftPadding}
                style={sensorItemLeftPositionStyle}
            >
                <div>
                    {sensor.isKeyParameter ?
                        <KeyIcon
                            className={'key-param'}
                            style={keyStyle}
                        />
                        : null
                    }
                    {(sensor.sensorType === 'state') || (sensor.type === 'state') ?
                        <StateIcon
                            style={stateStyle}
                        />
                        :
                        <SensorIcon
                            style={sensorStyle}
                        />
                    }
                    {/*Commented out for better performance*/}
                    {/*<Tooltip*/}
                    {/*    title={sensor.name ? ' ' + sensor.name : ' ' + sensor.id}*/}
                    {/*    leaveDelay={0}*/}
                    {/*    enterDelay={0}*/}
                    {/*>*/}
                    <div className="right-section-text">
                        {sensor.name ? ' ' + sensor.name : ' ' + sensor.id}
                    </div>
                    {/*</Tooltip>*/}
                    {sensor.isNew &&
                    <span className={'new-sensor'} />
                    }
                    {menuSensor.length > 0 ?
                        <span
                            className={'wrap-edit-menu'}
                        >
                            <EditMenu
                                classNameMenu={unit ? '' : 'unsorted-config'}
                                menuList={menuSensor}
                                dataItem={{
                                    sensor: sensor,
                                    unit: {
                                        unit: unit,
                                        data: unitData,
                                    },
                                }}
                                container={elementContainer}
                                onClose={editMenuCloseCallback}
                                onOpen={editMenuOpenCallback}
                            />
                        </span>
                        : null
                    }
                </div>
            </div>
            <div
                className="left-section"
            >
                <SensorFormList
                    sensor={{ ...sensor }}
                    processRule={processRule}
                    maxProductInUnit={maxProductInUnit}
                    currentUnit={unit}
                    products={props.products}
                />
            </div>
        </div>
    );
};

export default React.memo(SensorItem);