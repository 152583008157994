import { fabric } from 'fabric';
import { v4 } from 'uuid';
import { Handler } from '../handlers/Handler';
import { IFabricEvent, FabricObject, IEditorPoint, IEditorToolOptions } from '../../interfaces';
import PolygonLine from '../../objects/PolygonLine';
import PolygonCircle from '../../objects/PolygonCircle';

/**
 * Polygon editor
 *
 * @class PolygonEditor
 */
export class PolygonEditor {

    /**
     * Main handler instance
     *
     * @type {Handler}
     */
    handler: Handler;

    private options?: IEditorToolOptions;

    /**
     * Constructor
     *
     * @param {Handler} handler
     */
    constructor(handler: Handler) {

        this.handler = handler;
    }

    /**
     * Active polygon drawing mode
     *
     * @param {IEditorToolOptions} options
     */
    activate(options?: IEditorToolOptions): void {

        this.handler.interactionHandler.drawing('polygon');
        this.handler.pointArray = [];
        this.handler.lineArray = [];
        this.options = options;
    }

    /**
     * Add new polygon point
     *
     * @param {IFabricEvent} event
     */
    addPoint(event: IFabricEvent): void {

        const { e, absolutePointer } = event;

        if (absolutePointer) {

            const { x, y } = absolutePointer;

            const firstCircleColor = this.options && this.options.stroke ? '#ffffff' : '#ff0000',
                circleColor = this.options && this.options.stroke ? this.options.stroke : '#ffffff';

            const circleBorder = this.options && this.options.stroke ? this.options.stroke : '#333333';

            const circle = new PolygonCircle({
                radius: this.options && this.options.strokeWidth ? this.options.strokeWidth * 2 : 3,
                fill: !this.handler.pointArray || !this.handler.pointArray.length ? firstCircleColor : circleColor,
                stroke: circleBorder,
                strokeWidth: 1,
                left: x,
                top: y,
                selectable: false,
                hasBorders: false,
                hasControls: false,
                originX: 'center',
                originY: 'center',
                hoverCursor: 'pointer',
                ignoreZoom: true,
                zIndex: this.handler.canvas.getObjects().length,
            }) as FabricObject<fabric.Circle>;

            circle.set({
                id: v4(),
            });

            const points = [x, y, x, y];

            const lineStrokeWidth = this.options && this.options.strokeWidth ? this.options.strokeWidth : 2;

            const line = new PolygonLine(points, {
                strokeWidthInitial: lineStrokeWidth,
                strokeWidth: lineStrokeWidth / this.handler.canvas.getZoom(),
                fill: this.options && this.options.stroke ? this.options.stroke : '#999999',
                stroke: this.options && this.options.stroke ? this.options.stroke : '#999999',
                originX: 'center',
                originY: 'center',
                selectable: false,
                hasBorders: false,
                hasControls: false,
                evented: false,
                strokeUniform: true,
                ignoreZoom: true,
                zIndex: this.handler.canvas.getObjects().length,
            }) as FabricObject<fabric.Line>;

            if (this.handler.activeShape) {

                const position = this.handler.canvas.getPointer(e);
                const activeShapePoints = (this.handler.activeShape.get('points') as Array<IEditorPoint>).filter(Boolean);

                activeShapePoints.push({
                    x: position.x,
                    y: position.y,
                });

                const polygon = this.handler.fabricObjects.areaPolygon.create(activeShapePoints);

                polygon.zIndex = this.handler.canvas.getObjects().length;

                this.handler.canvas.remove(this.handler.activeShape);
                this.handler.canvas.add(polygon);
                this.handler.activeShape = polygon;
                this.handler.canvas.renderAll();

            } else {

                const polyPoint = [{ x, y }];

                const polygon = this.handler.fabricObjects.areaPolygon.create(polyPoint);

                polygon.zIndex = this.handler.canvas.getObjects().length;

                this.handler.activeShape = polygon;
                this.handler.canvas.add(polygon);
            }

            this.handler.activeLine = line;
            this.handler.pointArray.push(circle);
            this.handler.lineArray.push(line);
            this.handler.canvas.add(line);
            this.handler.canvas.add(circle);
        }
    }

    /**
     * @return boolean returns if last point was removed
     */
    removeLastPoint(): boolean {
        if (this.handler.pointArray.length <= 1) {
            this.cancel();
            return true;
        }

        const lastPoint = this.handler.pointArray.pop();
        const lastLine = this.handler.lineArray.pop();

        const linesCount = this.handler.lineArray.length;

        if (!lastLine || !lastPoint) {
            this.cancel();
            return true;
        }

        this.handler.activeLine = linesCount > 0 ? this.handler.lineArray[linesCount - 1] : null;
        this.handler.canvas.remove(lastPoint);
        this.handler.canvas.remove(lastLine);

        if (this.handler.activeShape) {
            this.handler.canvas.remove(this.handler.activeShape);
            this.handler.activeShape = null;

            const activeShapePoints = this.handler.pointArray.map(({ top, left }) => ({
                x: left,
                y: top,
            } as IEditorPoint));

            if (activeShapePoints.length > 0) {
                const polygon = this.handler.fabricObjects.areaPolygon.create(activeShapePoints);

                this.handler.canvas.add(polygon);
                this.handler.activeShape = polygon;
                this.handler.canvas.renderAll();
            }
        }

        return false;
    }

    /**
     * Finish polygon creation
     *
     * @param {FabricObject<Circle>[]} pointArray
     */
    finish(pointArray: FabricObject[]): void {

        const points: Record<string, unknown>[] = [];

        const id = v4();

        pointArray.forEach((point: FabricObject) => {

            points.push({
                x: point.left,
                y: point.top,
            });

            this.handler.canvas.remove(point);
        });

        this.handler.lineArray.forEach((line: FabricObject) => {

            this.handler.canvas.remove(line);
        });

        if (this.handler.activeShape) {

            this.handler.canvas.remove(this.handler.activeShape);
        }

        if (this.handler.activeLine) {

            this.handler.canvas.remove(this.handler.activeLine);
        }

        const lineStrokeWidth = this.options && this.options.strokeWidth ? this.options.strokeWidth : 2;

        const options = {
            id,
            points,
            type: 'polygon',
            stroke: this.options && this.options.stroke ? this.options.stroke : '#000000',
            strokeWidthInitial: lineStrokeWidth,
            strokeWidth: lineStrokeWidth / this.handler.canvas.getZoom(),
            fill: this.options && this.options.fill ? this.options.fill : 'rgba(0, 0, 0, 0.25)',
            opacity: this.options && this.options.opacity ? this.options.opacity : 1,
            objectCaching: !this.handler.editable,
            superType: 'drawing',
            zIndex: this.handler.getObjects().length + 1,
        };

        this.handler.add(options);
        this.handler.pointArray = [];
        this.handler.activeLine = null;
        this.handler.activeShape = null;
        this.handler.interactionHandler.selection();
    }

    /**
     * Cancel polygon creation
     */
    cancel(): void {

        this.handler.pointArray.forEach((point: FabricObject) => {

            this.handler.canvas.remove(point);
        });

        this.handler.lineArray.forEach((line: FabricObject) => {

            this.handler.canvas.remove(line);
        });

        if (this.handler.activeLine) {

            this.handler.canvas.remove(this.handler.activeLine);
        }

        if (this.handler.activeShape) {

            this.handler.canvas.remove(this.handler.activeShape);
        }

        this.handler.pointArray = [];
        this.handler.lineArray = [];
        this.handler.activeLine = null;
        this.handler.activeShape = null;

        this.handler.canvas.renderAll();

        this.handler.interactionHandler.selection();
    }
}
