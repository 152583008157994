import { notificationConstants } from '../../constants';
import {
    IErrors, INotification,
    INotificationAction,
} from '../../interfaces';


interface INotificationState {
    notifications: INotification[],
    errors: IErrors;
}

/**
 * Bar chart description visibility reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function notification(state: INotificationState = {
    notifications: [],
    errors: {},
}, action: INotificationAction): INotificationState {

    /**
     *
     * @param {INotification[]} state
     * @param {INotification} current
     * @return {INotification[]}
     */
    const markAsRead = (state: INotification[], current: INotification): INotification[] => {
        const newState = state.map(value => {

            if (value.id === current.id) {

                return { ...value, ...current };
            }

            return { ...value };
        });

        return newState;
    };

    switch (action.type) {

        case notificationConstants.LIST_NOTIFICATION_SUCCESS:
        case notificationConstants.MASK_ALL_AS_READ_NOTIFICATION_SUCCESS:

            return {
                notifications: action.notifications,
                errors: {},
            };

        case notificationConstants.LIST_NOTIFICATION_FAILURE:
        case notificationConstants.MASK_ALL_AS_READ_NOTIFICATION_FAILURE:

            return {
                ...state,
                errors: action.errors,
            };

        case notificationConstants.UPDATE_COMMENT_NOTIFICATION_SUCCESS:
        case notificationConstants.MASK_AS_READ_NOTIFICATION_SUCCESS:

            return {
                ...state,
                notifications: markAsRead(state.notifications, action.markAsRead),
            };

        default:

            return state;
    }
}
