import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    IMonitoringTreeKeySensorProps,
    ISensorTargetValueState,
} from '../../../../core/interfaces';
import { graphConstants } from '../../../../core/constants';
import { ReactComponent as FolderKeyIcon } from '../../../../core/ui/assets/images/icons/folder-key.svg';
import { Tooltip } from '../../../../core/ui/components';
import { HistogramChart } from '../../../../base/components';
import { isMobile } from 'react-device-detect';
import { selectHistogramHeight } from '../../../../core/selectors/graphHistogramHeight/histogramHeightSelector';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { selectGraphBarHeight } from '../../../../core/selectors/graphBarHeight/graphBarHeightSelector';

/**
 * Monitoring tree key sensor functional component
 *
 * @param {any} sensor
 * @param {any} unit
 * @param {number} histogramHeight
 * @param {number} maxWidthSideBar
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const KeySensor: React.FC<IMonitoringTreeKeySensorProps> = (
    {
        sensor,
        unit,
        visibleSideBar,
        maxWidthSideBar,
        activeProductData,
    }: IMonitoringTreeKeySensorProps,
) => {

    const [delay, setDelay] = useState(true);

    const [sensorTargetValue, setSensorTargetValue] = useState<ISensorTargetValueState | undefined>(undefined);

    const histogramHeight = useSelector(selectHistogramHeight);

    const graphHeight = useSelector(selectGraphBarHeight);

    const { targetValues } = sensor;
    /**
     * Stop click even propagation
     *
     * @param {React.MouseEvent} event
     */
    function stopClickPropagation(event: React.MouseEvent) {

        event.stopPropagation();
    }

    useEffect(() => {

        if (activeProductData && targetValues) {

            const activeProducts = activeProductData.activeProducts;


            const sensorTargetValueData = {
                activeProducts: activeProducts,
                targetValues: targetValues,
            };

            setSensorTargetValue(sensorTargetValueData);

        }
    }, [activeProductData, targetValues]);

    /**
     * Rendering delay
     */
    useEffect(() => {


        const delayTimeout = setTimeout(() => setDelay(false), 200);

        return ()=>clearTimeout(delayTimeout);

    }, []);

    /**
     * Rendering delay
     */
    if (delay) {

        const skeletonStyle = {
            lineHeight: 'inherit',
            borderBottom: '1px solid #e9ecef',
        };

        return (
            <div
                className={histogramHeight === graphConstants.histogramSmallHeight ? 'item-title no-pointer' : 'item-title no-pointer extended'}
            >
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                    style={{
                        maxWidth: maxWidthSideBar,
                        minWidth: maxWidthSideBar,
                        transition: 'max-width 0.2s linear',
                    }}
                >
                    <FolderKeyIcon style={{
                        height: 24,
                        width: 24,
                        marginRight: 8,
                        minWidth: 24,
                    }}
                    />
                        <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{unit.name + ' (' + sensor.name + ')'}</span>
                </div>
                <div className={'left-section with-chart'}>
                    <SkeletonTheme color={'#ffffff'} >
                        <Skeleton height={sensor.sensorType === 'state' ? Number(graphHeight) : histogramHeight} style={skeletonStyle} />
                    </SkeletonTheme>
                </div>
            </div>
        );
    }


    if (sensor && sensor.isKeyParameter && sensor.isVisible && unit.isMinimized) {

        return (
            <div
                className={histogramHeight === graphConstants.histogramSmallHeight ? 'item-title no-pointer' : 'item-title no-pointer extended'}
            >
                <div
                    className={visibleSideBar ? 'right-section sensor-name left-padding-2 limit-padding' : 'right-section sensor-name left-padding-2'}
                    style={{
                        maxWidth: maxWidthSideBar,
                        minWidth: maxWidthSideBar,
                        transition: 'max-width 0.2s linear',
                    }}
                    onClick={stopClickPropagation}
                >
                    <FolderKeyIcon style={{
                        height: 24,
                        width: 24,
                        marginRight: 8,
                        minWidth: 24,
                    }}
                    />
                    <Tooltip
                        title={`${unit.name} (${sensor.name})`}
                        enterDelay={500}
                        style={{ zIndex: 1200 }}
                        enterTouchDelay={200}
                        leaveTouchDelay={2000}
                    >
                        <span className={`sensor-name ${isMobile ? 'is-mobile' : ''}`}>{unit.name + ' (' + sensor.name + ')'}</span>
                    </Tooltip>
                </div>
                <div className={sensor.isVisible ? 'left-section with-chart' : 'left-section'}>
                    {sensor.isVisible && sensor.sensorType === 'graph' ?
                        <HistogramChart
                            sensor={sensor}
                            sensorTargetValue={sensorTargetValue}
                            hrMode={false}
                        />
                        :
                        null
                    }
                </div>
            </div>
        );
    }

    return null;
};


export default React.memo(KeySensor);
