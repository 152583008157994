import React, { useCallback } from 'react';
import { ProductsVerticalLineGraph } from '../../../../core/ui/components';
import { useTranslation} from 'react-i18next';
import { IProcessItemTreeProps } from '../../../../core/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { GraphActions } from '../../../../base/store/actions';
import { selectHmiPlayerMode } from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline } from '../../../../core/selectors/dashboard/dashboardSelector';


const ProcessItemTree: React.FC<IProcessItemTreeProps> = (
    {
        visibleSideBar,
        maxWidthSideBar,
        unitId,
        ...props
    }: IProcessItemTreeProps,
) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const dashboardRealTime = useSelector(selectDashboardOnline);
    const { body: { offsetWidth } } = document;

    /**
     * On mouse move callback
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>)=> {

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

                }

            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * On touch start callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchStartCallback = useCallback((event: React.TouchEvent<HTMLDivElement>)=> {

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }

            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * On touch move callback
     *
     * @type {(event: React.TouchEvent<HTMLDivElement>) => void}
     */
    const onTouchMoveCallback = useCallback((event: React.TouchEvent<HTMLDivElement>)=> {

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }

            }

        }

    }, [maxWidthSideBar, dispatch, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * On mouse leave callback
     *
     * @type {() => void}
     */
    const onMouseLeaveCallback = useCallback(()=> {

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());

        }

    }, [dispatch, HMIPlayerStatus]);

    const rightSectionStyle ={
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
    };

    return (
        <div className="item-title no-pointer">
            <div
                className={visibleSideBar ? 'right-section left-padding-3 limit-padding hidden' : 'right-section left-padding-3 product-scheme'}
                style={rightSectionStyle}
            >
                {!props.displayProductRule && <span className="product-scheme-title">{t('PRODUCT')}</span>}
            </div>
            <div
                className="left-section no-chart product-scheme"
                onMouseMove={onMouseMoveCallback}
                onTouchStart={onTouchStartCallback}
                onTouchMove={onTouchMoveCallback}
                onMouseLeave={onMouseLeaveCallback}
            >
                {!props.displayProductRule ?
                    <ProductsVerticalLineGraph
                        unitId={unitId}
                    />
                :
                    null
                }
            </div>
        </div>
    );
};

export default React.memo(ProcessItemTree);