import { IChartAlert, IChartData, IHistogramData, ISensor } from '../../core/interfaces';
import { useParamSelector } from '../useParamSelector';
import { selectSensorDataBySensorId } from '../../core/selectors/HistogramChart/sensorDataSelector';
import { selectSensorHrDataBySensorId } from '../../core/selectors/HistogramChartHr/sensorDataHrSelector';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAllNotification } from '../../core/selectors/notification/notificationSelector';

export interface IChartDataWithColor extends IChartData{
    color: string;
}

export const useDataHistogram = (sensor: ISensor, hrMode: boolean, mixMode: boolean, color: string):[any[], IChartDataWithColor[], IChartAlert[]] => {
    const sensorId = `${sensor.controllerId}.${sensor.sensorId}`;
    const [alertPointArr, setAlertPointArr] = useState<any[]>([]);
    const [dataWithAlertColor, setDataWithAlertColor] = useState<IChartDataWithColor[]>([]);
    const [poolingGapsData, setPoolingGapsData] = useState<IHistogramData | null>(null);

    const data: IHistogramData = useParamSelector(selectSensorDataBySensorId, sensorId),
        dataHr: IHistogramData = useParamSelector(selectSensorHrDataBySensorId, sensor.id),
        notifications = useSelector(selectAllNotification),
        alertData: IChartAlert[] = !hrMode ? data?.alerts || [] : [];

    useEffect(() => {

        if (!hrMode ? data?.values : dataHr?.values) {
            setPoolingGapsData(!hrMode ? data : dataHr);
        }

    }, [data, dataHr, setPoolingGapsData]);

    const updateData =(chartData: IChartDataWithColor[])=>{
        setAlertPointArr([]);

        return chartData.map((data, index) => {

            const alertPointArrNew = alertPointArr;
            let defaultColor = color;
            alertData && alertData.forEach((value) => {
                const notificationEndTime = value.endTime ? new Date(value.endTime) : new Date();

                if (new Date(data.timestamp).getTime() >= new Date(value.startTime).getTime() &&
                    new Date(data.timestamp).getTime() <= new Date(notificationEndTime).getTime() &&
                    !mixMode) {

                    defaultColor = '#e30c0c';
                }


                if (new Date(data.timestamp).getTime() >= new Date(value.startTime).getTime() &&
                    new Date(data.timestamp).getTime() <= new Date(notificationEndTime).getTime()) {



                    const alertPointIndex = alertPointArrNew.findIndex(alPoint => alPoint.id === value.notificationId);
                    const currentNotification = notifications && notifications.find(ntf => ntf.id === value.notificationId);

                    if (alertPointIndex === -1) {

                        const isNew = currentNotification ? currentNotification.isNew : value.isNew;
                        const comment = currentNotification ? currentNotification.comment : value.comment;

                        alertPointArrNew.push({
                            id: value.notificationId,
                            point: [index],
                            endTime: notificationEndTime,
                            startTime: value.startTime,
                            comment: comment || '',
                            um: value.um,
                            isNew: isNew,
                            value: value.value,
                        });

                    }
                }

            });
            data.color = defaultColor;

            setAlertPointArr(alertPointArrNew);
            return data;
        });
    };

    useEffect(() => {
        if (poolingGapsData?.values) {

            const colorData = updateData(poolingGapsData.values as IChartDataWithColor[]);

            setDataWithAlertColor(colorData);
        }
    }, [poolingGapsData, color]);

    return [
        alertPointArr,
        dataWithAlertColor,
        alertData,
    ];
};