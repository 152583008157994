import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import styled from 'reshadow';

import { IData, IGridMenu } from '../../../interfaces';

const styles = require('./ActionMenu.scss');

/**
 * A data table actions menu
 *
 * @param {IMenuItem[]} items A menu items
 * @param {boolean} visible Set menu visible or hidden
 * @param {IData} data A data row
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const ActionMenu: React.FC<IProps> = ({
	items,
	visible,
	data,
}) => {

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null),
		open = Boolean(anchorEl),
		handleClick = (event: React.MouseEvent<HTMLElement>) => {

            event.stopPropagation();

            setAnchorEl(event.currentTarget);
        },
        handleClose = () => {

			setAnchorEl(null);
		};

	const isVisible = visible ? 'visible' : '';

	return styled(styles)(
		<div className={'action-menu ' + isVisible}>
			<IconButton
				aria-controls="action-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			{anchorEl ?
				<Menu
					id="action-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					onMouseLeave={handleClose}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					{items.map((item, index) => (

						<MenuItem
							key={index}
							onClick={(e) => {

								e.stopPropagation();

								item.action(data);

								handleClose();
							}}
							style={{ color: item.color }}
							disabled={item.disabled ? item.disabled(data) : false}
						>{item.title}
      </MenuItem>
					))}
				</Menu> : null}

		</div>
	);
};

export default ActionMenu;

interface IProps {
	items: IGridMenu[];
	visible: boolean;
	data: IData;
}
