import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from '../../../../../core/ui/components';
import { ScrollContainer } from '../../../ui/components/ScrollContainer/ScrollContainer';
import { isMobile, withOrientationChange } from 'react-device-detect';
import { EmployeeThunks } from '../../../store/thunks/employeeThunks';
import { DepartmentThunks } from '../../../store/thunks/departmentThunks';
import { IHrState } from '../../../store/reducers';
import { selectEmployeesNoCategory } from '../../../store/selectors/employeeSelector';
import { IDepartment, IEmployee, IFormEmployee, IReorderHr } from '../../../interfaces';
import RightSidebar from '../../../ui/components/Sidebar/RightSidebar';
import { selectDepartmentState } from '../../../store/selectors/departmentSelector';
import DepartmentForm from './DepartmentForm/DepartmentForm';
import EmployeeForm from './EmployeeForm/EmployeeForm';
import { selectSettingPageState } from '../../../store/selectors/settingPageSelector';
import { SettingPageThunks } from '../../../store/thunks/settingPageThunks';
import DraggableListHr from '../../../ui/components/DraggableListHr/DragDropTreeHr';

import './Employee.scss';
import DeleteDialog from '../../../ui/components/Dialog/DeleteDialog';
import { IEmployeeProps, IEmployeeComponentState, IMoveHr } from '../../../../interfaces';


/**
 * Employee component
 *
 * @class Employee
 */
class Employee extends React.Component<IEmployeeProps & WithTranslation, IEmployeeComponentState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: IEmployeeProps & WithTranslation) {

        super(props);
        
        this.state = {
            option: {
                search: {
                    value: '',
                    handleSearch: this.handleSearch.bind(this),
                },
                menu: [
                    {
                        title: this.props.t('EDIT'),
                        action: this.editNode.bind(this),
                        color: '',
                    },
                    {
                        title: this.props.t('DELETE'),
                        action: this.removeConfirmation.bind(this),
                        color: 'red',
                    },
                ],
                colorField: false,
            },
            addInput: false,
            openSidebar: false,
            deleteValue: null,
            deleteValueName: null,
            modelType: null,
        };

        this.handleSearch = this.handleSearch.bind(this);

        this.onDragEndStates = this.onDragEndStates.bind(this);

        this.openSidebar = this.openSidebar.bind(this);

        this.onSidebarClose = this.onSidebarClose.bind(this);

        this.filterFunctionEmployee = this.filterFunctionEmployee.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

    }

    componentDidMount() {

        this.props.fetchEmployees('', {
            column: 'id',
            dir: 'asc',
        }, undefined, { field: ['department.id||$isnull'] });

        this.props.fetchDepartments('', {
            column: 'id',
            dir: 'asc',
        }, { table: ['employees', 'employees.beacon'] });

    }

    /**
     *  Edit current category
     *
     * @param dataItem
     * @param status
     * @param nodeName
     */
    editNode(dataItem: IEmployee | IDepartment, status: boolean, nodeName: string) {

        if (nodeName === 'unsorted' || nodeName === 'list') {

            this.props.toggleForm(true, 'employee', dataItem);
        }

        if (nodeName === 'category') {

            this.props.toggleForm(true, 'department', dataItem);
        }

    }

    /**
     *  Remove current category
     *
     * @param dataItem
     * @param status
     * @param nodeName
     */
    removeConfirmation(dataItem: IEmployee | IDepartment, status: boolean, nodeName: string) {

        if (nodeName === 'unsorted'|| nodeName === 'list') {

            const employee = dataItem as IEmployee;

            this.setState({ deleteValue: employee, modelType: 'employee', deleteValueName: `${employee.surname} ${employee.firstName} ${employee.middleName}` });
        }
        if (nodeName === 'category') {

            const department = dataItem as IDepartment;

            this.setState({ deleteValue: dataItem, modelType: 'department', deleteValueName: department.name });
        }

    }

    /**
     *  Handler for the search
     *
     * @param {ChangeEvent<HTMLInputElement>} event
     */
    handleSearch(event: ChangeEvent<HTMLInputElement>) {

        const { option } = this.state;

        option.search.value = event.target.value;

        this.setState({ option: option });



    }
    /**
     * 'Drag end' handler
     *
     * @param state
     * @param action
     */
    onDragEndStates(state: IMoveHr | IReorderHr, action: string) {

        if (action === 'move') {

            const move = state as IMoveHr;

            const updateData: IFormEmployee = {
                    id: move.moveCause.id,
                    surname: move.moveCause.surname,
                    additionalPhone: move.moveCause.additionalPhone,
                    beacon: move.moveCause.beacon? move.moveCause.beacon.id: null,
                    department: move.destinationCategory[0]? Number(move.destinationCategory[0].id) : null,
                    email: move.moveCause.email && move.moveCause.email.length === 0? null : move.moveCause.email,
                    firstName: move.moveCause.firstName,
                    middleName: move.moveCause.middleName,
                    phone: move.moveCause.phone,
                    orderInDepartment: move.droppableDestination.index,
                };

            this.props.updateEmployee(updateData);

        }

        if (action === 'reorder') {

            const move = state as IReorderHr;

            const updateData: IFormEmployee = {
                id: move.moved.id,
                surname: move.moved.surname,
                additionalPhone: move.moved.additionalPhone,
                beacon: move.moved.beacon? move.moved.beacon.id: null,
                department: parseInt(String(move.index))? move.index as number: null,
                email: move.moved.email && move.moved.email.length === 0? null: move.moved.email,
                firstName: move.moved.firstName,
                middleName: move.moved.middleName,
                phone: move.moved.phone,
                orderInDepartment: move.endIndex,
            };

            this.props.updateEmployee(updateData);

        }

        this.props.fetchEmployees('', {
            column: 'id',
            dir: 'asc',
        }, undefined, { field: ['department.id||$isnull'] });

    }

    /**
     *sidebar opening handler
     *
     * @param {React.MouseEvent<HTMLElement>} event
     */
    openSidebar(event: React.MouseEvent<HTMLElement>) {

        const { formOpening } = this.props,
        tableName = event.currentTarget.title;

        event.preventDefault();

        event.stopPropagation();

        if (formOpening) {

            this.props.toggleForm(false);

            setTimeout(() => this.props.toggleForm(true, tableName), 200);


        } else {
            this.props.toggleForm(true, tableName);

        }
    }

    onSidebarClose() {

        this.setState({ openSidebar: false });
        
    }

    /**
     *  Sort Employee by orderInCategory
     *
     * @param {IEmployee[]} value
     * @return {IEmployee[]}
     */
    sortOrderInCategory(value: IEmployee[]) {

        return value.sort((a, b) => {

            if (a.orderInDepartment === undefined) {

                a.orderInDepartment = 0;
            }

            if (b.orderInDepartment === undefined) {

                b.orderInDepartment = 0;
            }

            return a.orderInDepartment < b.orderInDepartment ? -1 : 1;
        });

    }

    /**
     *  Filter data for Item
     */
    filterFunctionEmployee(data: IEmployee[]): IEmployee[] {

        const { option } = this.state;

        if (option.search.value.trim().length > 0) {
            const result = this.sortOrderInCategory(data).filter(value => (
                value.firstName.toLowerCase().includes(option.search.value.toLowerCase()) ||
                value.surname.toLowerCase().includes(option.search.value.toLowerCase()) ||
                (value.middleName && value.middleName.toLowerCase().includes(option.search.value.toLowerCase()))
            ) && !value.deletedAt);

            return result? result : [];
        } else {

            return this.sortOrderInCategory(data).filter(value => !value.deletedAt);

        }
    }

    onConfirmDialog() {

        const { modelType, deleteValue } = this.state;

        if (modelType === 'employee') {

            this.props.deleteEmployees(deleteValue as IEmployee);

            this.setState({ deleteValue: null, modelType: null });
        }

        if (modelType === 'department') {

            this.props.deleteDepartment(deleteValue as IDepartment);

            this.setState({ deleteValue: null, modelType: null });
        }

    }

    handleCancel() {

        this.setState({ deleteValue: null, modelType: null });

    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, isLandscape, employees, departments, formOpening, formName } = this.props,
            { deleteValue, modelType, deleteValueName } = this.state;

        return (
            <React.Fragment>
            <div className="with-list-component employee">
                <ScrollContainer
                    maxHeightSlider={`calc(100vh - ${isMobile ? isLandscape ? 40 : 220 : 40}px)`}
                    bodyStyle={{
                        paddingTop: 0,
                    }}
                    headerScroll={
                        <React.Fragment>
                            <h2 className="content-title">{t('EMPLOYEES')}</h2>
                            <div className="btn-wrap states">
                                <Button
                                    id="add-employee"
                                    type="button"
                                    color={'primary'}
                                    title={'employee'}
                                    onClick={this.openSidebar}
                                >
                                    {this.props.t('ADD_EMPLOYEE')}
                                </Button>
                                <Button
                                    id="add-department"
                                    type="button"
                                    color={'primary'}
                                    title={'department'}
                                    onClick={this.openSidebar}
                                >{this.props.t('ADD_DEPARTMENT')}
                                </Button>
                            </div>
                        </React.Fragment>
                    }
                >
                    <DraggableListHr
                        droppable={[...departments]}
                        draggableUnsorted={[...this.filterFunctionEmployee(employees)]}
                        draggable={[...departments]}
                        addInput={this.state.addInput}
                        option={this.state.option}
                        onDragEnd={this.onDragEndStates}
                        filterFunction={this.filterFunctionEmployee}
                        table={'employees'}
                        draggableName={['surname', 'firstName', 'middleName']}
                        droppableName={['name']}
                        beacon
                        phone
                    />
                </ScrollContainer>
            </div>
                <RightSidebar openSidebar={formOpening} close={this.onSidebarClose}>
                    {formName === 'employee'?
                        <EmployeeForm />
                        :
                        null
                    }
                    {formName === 'department'?
                        <DepartmentForm />
                        :
                        null
                    }
                </RightSidebar>
                {deleteValue ?
                    <DeleteDialog
                        removeId={deleteValue.id}
                        heading={t(modelType === 'employee' ? 'DELETE_EMPLOYEE_Q' : 'DELETE_DEPARTMENT_Q', {
                            name: deleteValueName?.trim(),
                        })}
                        onAccept={this.onConfirmDialog}
                        onClose={this.handleCancel}
                        body={t(modelType !== 'employee' ? 'THIS_ACTION_WILL_DELETE_DEPARTMENT_AND_CANNOT_BE_UNDONE' : 'THIS_ACTION_WILL_DELETE_EMPLOYEE_AND_CANNOT_BE_UNDONE')}
                        open={Boolean(deleteValue)}
                    />
                    :
                    null
                }
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {
    const { formOpening, formName, model } = selectSettingPageState(state);

    const { departments } = selectDepartmentState(state);

    return {
        employees: selectEmployeesNoCategory(state),
        departments,
        formOpening,
        formName,
        model,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    fetchEmployees: EmployeeThunks.fetchEmployees,
    deleteEmployees: EmployeeThunks.deleteEmployee,
    fetchDepartments: DepartmentThunks.fetchDepartments,
    deleteDepartment: DepartmentThunks.deleteDepartment,
    toggleForm: SettingPageThunks.toggleForm,
    updateEmployee: EmployeeThunks.updateEmployee,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withOrientationChange(Employee)));

