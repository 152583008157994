import { createSelector } from 'reselect';
import { RootState } from '../../store';


const selectGraphMinimapBrush =  (state: RootState) => state.graphMinimapBrush;

export const selectGraphMinimapBrushRule = createSelector(
    selectGraphMinimapBrush,
    (graphMinimapBrush) => graphMinimapBrush.brushRule,
);

export const selectBrushSelection = createSelector(
    selectGraphMinimapBrush,
    (graphMinimapBrush) => graphMinimapBrush.selection,
);
