import { trackPromise } from 'react-promise-tracker';
import { HrApiProvider } from '../providers/apiProvider';
import { IFilter, IJoin, IOrder } from '../../../core/interfaces';
import { ILocationApiModel } from './locationService';
import { IGatewayApiModel } from './gatewayService';

/**
 * Service to work with building API resources
 *
 * @class BuildingService
 */
export class BuildingService extends HrApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/buildings(/:id)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [];
    }

    /**
     * Get list of buildings
     *
     * @return {Promise<Object>}
     */
    list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<IBuildingApiModel[]> {

        return trackPromise(
            this.http
                .get<IBuildingApiModel[]>(this.url(), {
                    params: this.prepareListParams(search, order, join, filter),
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
        );
    }

    /**
     * Create building
     *
     * @param {IBuildingApiModel} model
     *
     * @return {Promise<IBuildingApiModel>}
     */
    store(model: IBuildingApiModel): Promise<IBuildingApiModel> {
        const url = this.url();

        return trackPromise(
            this.http
                .post<IBuildingApiModel>(url, model, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
        );
    }

    /**
     * Update building
     *
     * @param {IBuildingApiModel} model
     *
     * @return {Promise<IBuildingApiModel>}
     */
    update(model: IBuildingApiModel): Promise<IBuildingApiModel> {
        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .patch<IBuildingApiModel>(url, model, {
                    headers: this.headers,
                })
                .then(this.getDataExtractor())
        );
    }

    /**
     * Delete building
     *
     * @param {IBuildingApiModel} model
     *
     * @return {Promise<boolean>}
     */
    delete(model: IBuildingApiModel): Promise<boolean> {
        const url = this.url({ id: model.id });

        return trackPromise(
            this.http
                .delete<IBuildingApiModel>(url, {
                    headers: this.headers,
                })
                .then(Boolean)
        );
    }

    /**
     * Save building
     *
     * @param {IBuildingApiModel} building
     *
     * @return {Promise<IBuildingApiModel>}
     */
    save(building: IBuildingApiModel): Promise<IBuildingApiModel> {

        if (building.id && building.id >= 1) {

            return this.update(building);
        }

        return this.store(building);
    }
}

export interface IBuildingApiModel {
    id?: number;
    name: string;
    points: Array<Array<number>>;
    plan: ILocationApiModel | number;
    zones?: [];
    gateways?: IGatewayApiModel[];
}
