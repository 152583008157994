import React, { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { history } from '../../helpers';
import { ActionMenu } from '../../core/ui/components';
import { IDashboard, IData } from '../../core/interfaces';

interface IDraggableItemProps {
    page: IDashboard;
    classes: Record<'itemIcon' | 'tooltip' | 'popper', string>;
    mouseEnterTabs: IMouseEnterTab;
    selectedDashboard?: IDashboard;
    mouseEnterHandler: (page: IDashboard) => void;
    mouseLeaveHandler: () => void;
    touchEnterHandler: (page: IDashboard) => void;
    selectPage: (page: IDashboard) => void;
    rbac: any;
    menu: ({ title: string, action: (data: IData) => void, color?: undefined } | { title: string, action: (data: IData) => void, color: string })[];
    index: number;
}


interface IMouseEnterTab {
    [key: number]: boolean;
}

const DraggableItem: React.FC<IDraggableItemProps> = (
    {
        page,
        classes,
        selectedDashboard,
        mouseEnterTabs,
        mouseEnterHandler,
        mouseLeaveHandler,
        touchEnterHandler,
        selectPage,
        rbac,
        index,
        menu,
    }: IDraggableItemProps,
) => {

    /**
     * Default style for dragged item
     *
     * @param isDragging { boolean }
     * @param draggableStyle - default style
     */
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        boxShadow: isDragging ? '-2px 0 4px 0 #e6eaed' : 'unset',

        // // styles we need to apply on draggables
        ...draggableStyle,
    });

    /**
     * On mouse enter callback
     *
     * @type {() => void}
     */
    const onMouseEnterCallback = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {

        event.preventDefault();

        mouseEnterHandler(page);

    }, [mouseEnterHandler, page]);

    /**
     * On mouse leave callback
     *
     * @type {() => void}
     */
    const onMouseLeaveCallback = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {

        event.preventDefault();

        mouseLeaveHandler();

    }, [mouseLeaveHandler]);

    /**
     * On touch start callback
     *
     * @type {() => void}
     */
    const onTouchStartCallback = useCallback((event: React.TouchEvent<HTMLLIElement>) => {

        event.preventDefault();

        touchEnterHandler(page);

        selectPage(page);

    }, [touchEnterHandler, selectPage, page]);

    /**
     * On click callback
     *
     * @type {() => void}
     */
    const onClickCallback = useCallback((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {

        event.preventDefault();

        selectPage(page);

    }, [selectPage, page]);

    const tooltipStyle = { zIndex: 1200 };

    return (
        <Draggable key={page.id} draggableId={page.id.toString()} index={index}>
            {(provided, snapshot) => (
                <Tooltip
                    title={page.name}
                    classes={{
                        tooltip: classes.tooltip,
                        popper: classes.popper,
                    }}
                    enterDelay={500}
                    style={tooltipStyle}
                    open={Object.prototype.hasOwnProperty.call(mouseEnterTabs, page.id)}
                >
                    <ListItem
                        className={'dashboard-page'}
                        selected={selectedDashboard ? selectedDashboard.id === page.id && history.location.pathname !== '/configuration' : false}
                        onMouseEnter={onMouseEnterCallback}
                        onMouseLeave={onMouseLeaveCallback}
                        onTouchStart={onTouchStartCallback}
                        onClick={onClickCallback}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                        )}
                    >
                        <ListItemText primary={page.name} />
                        {mouseEnterTabs[page.id] && rbac.can('dashboard:update') ?
                            <ActionMenu
                                items={menu}
                                visible={mouseEnterTabs[page.id]}
                                data={page}
                            />
                            : null}

                    </ListItem>
                </Tooltip>
            )}
        </Draggable>
    );
};

export default React.memo(DraggableItem);
