import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IErrors, IFilter, IJoin, IOrder, IStateCategory, ICause, IStateData } from '../interfaces';
import { stateConstants } from '../constants';
import { StatesService } from '../services';

/**
 * Unit related actions
 *
 * @type {Object}
 */
export const statesActions = {

    /**
     *  Get list States
     *
     * @param { String } search Search string
     * @param { Object } order Sort settings
     * @param { IJoin } join
     * @param { IFilter } filter
     *
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'desc' }, join?:IJoin, filter?: IFilter) => {

        //Action creators
        const success = (states: any[]) => {

            return {
                type: stateConstants.LIST_SUCCESS,
                states,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: stateConstants.LIST_FAILURE,
                errors,
            };
        }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {
            service.list(search, order, join, filter)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * store State category
     *
     * @param { IStateCategory } state
     *
     *  @return {Promise<Object>}
     */
    store: (state: IStateCategory) => {

        //Action creators
        const success = (state: IStateCategory) => {

            return {
                type: stateConstants.STORE_CATEGORY_SUCCESS,
                categories: state,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: stateConstants.STORE_CATEGORY_FAILURE,
                errors,
            };

        }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.store(state)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * store Edit data
     *
     * @param { any } data
     *
     *  @return {Promise<Object>}
     */
    storeEditData: (data: any) => {

        //Action creators
        const success = (data: any) => {

            return {
                type: stateConstants.STORE_STATES_ITEM_SUCCESS,
                state: data,
            };
            // eslint-disable-next-line
        }, failure = ({errors}: IErrors) => {

            return {
                type: stateConstants.STORE_STATES_ITEM_FAILURE,
                errors,
            };
            // eslint-disable-next-line
        }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(success(data));
        };
    },

    storeReason: (state: any) => {

        //Action creators
        const success = (state: ICause) => {

            return {
                type: stateConstants.STORE_REASON_SUCCESS,
                reason: state,
            };
            // eslint-disable-next-line
        }, failure = ({errors}: IErrors) => {

            return {
                type: stateConstants.STORE_REASON_FAILURE,
                errors,
            };
            // eslint-disable-next-line
        }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(success(state));
        };
    },


    /**
     * Update State category
     *
     * @param { IStateCategory } state
     *
     *  @return {Promise<Object>}
     */
    updateStates: (state: IStateCategory) => {

        //Action creators
        const success = (state: IStateCategory) => {

            return {
                type: stateConstants.UPDATE_STATES_SUCCESS,
                state,
            };

        }, failure = (errors: IErrors) => {

            return {
                type: stateConstants.UPDATE_STATES_FAILURE,
                errors,
            };

        }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.update(state)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },

    updateReason: (state: any) => {

        //Action creators
        const success = (state: ICause) => {

            return {
                type: stateConstants.UPDATE_REASON_SUCCESS,
                state,
            };
            // eslint-disable-next-line
        }, failure = ({errors}: IErrors) => {

            return {
                type: stateConstants.UPDATE_REASON_FAILURE,
                errors,
            };
            // eslint-disable-next-line
        }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(success(state));
        };
    },


    /**
     * Remove state category by ID
     *
     * @param { IStateCategory } state
     *
     *  @return {Promise<Object>}
     */
    delete: (state: IStateCategory) => {


        const success = (state: IStateCategory) => {

                return {
                    type: stateConstants.DELETE_STATES_SUCCESS,
                    state,
                };

            }, failure = ({ errors }: IErrors) => {

                return {
                    type: stateConstants.DELETE_REASON_FAILURE,
                    errors,
                };

            }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            service.remove(state)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });

        };
    },


    onDragEndStates: (state: { draggable: object[], draggableUnsorted: object[], droppable: object[] }) => {

        //Action creators
        const success = (state: { draggable: object[], draggableUnsorted: object[], droppable: object[] }) => {

            return {
                type: stateConstants.STORE_ON_DRAG_END_SUCCESS,
                state: {
                    categories: state.droppable,
                    products: state.draggable,
                    unsorted: state.draggableUnsorted? state.draggableUnsorted: [],
                },
            };
            // eslint-disable-next-line
        }, failure = ({errors}: IErrors) => {

            return {
                type: stateConstants.STORE_ON_DRAG_END_FAILURE,
                errors,
            };
            // eslint-disable-next-line
        }, service = new StatesService();

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(success(state));
        };
    },

    /**
     * Show/hide state details popover
     *
     * @param {boolean} visible
     * @param {Object} state
     *
     * @return {Promise<Object>}
     */
    toggleStateDetails: (visible: boolean, state?: any) => {

        const show = () => {

            return {
                type: stateConstants.SHOW_DETAILS,
                state: state,
            };

        }, hide = () => {

            return {
                type: stateConstants.HIDE_DETAILS,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(visible ? show() : hide());
        };
    },

    /**
     * Make a state selected
     *
     * @param {Object} state
     * @param {Object} position
     *
     * @param { IStateData } states
     * @param hrMode
     * @return {Promise<Object>}
     */
    selectState: (state: any, position: {left: number, width: number}, states: IStateData, hrMode?: boolean) => {

        const action = () => {

            return {
                type: stateConstants.SELECT,
                state: state,
                position: position,
                states: states,
                hrMode: hrMode,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Deselect all states
     *
     * @return {Promise<Object>}
     */
    deselectAllStates: () => {

        const action = () => {

            return {
                type: stateConstants.DESELECT_ALL,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Make a state hovered
     *
     * @param {Object} state
     * @param {Object} position
     * @param {string | number} sensorId
     *
     * @return {Promise<Object>}
     */
    hoveredState: (state: any, position: {left: number, width: number}, sensorId: string | number) => {

        const action = () => {

            return {
                type: stateConstants.HOVERED,
                state: state,
                position: position,
                sensorId: sensorId,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },

    /**
     * Deselect all states
     *
     * @return {Promise<Object>}
     */
    unHoveredStates: () => {

        const action = () => {

            return {
                type: stateConstants.UNHOVERED,
            };
        };

        return (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => {

            dispatch(action());
        };
    },
};
