import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Sensor from './Sensor';
import KeySensor from './KeySensor';
import ChangeProduct from '../ChangeProduct/ChangeProduct';
import { Tooltip, ProductsVerticalLineGraph } from '../../../../core/ui/components';
import { statesActions } from '../../../../core/actions';
import { GraphActions } from '../../../../base/store/actions';
import {
    IActiveProductData,
    ISensor,
    IMonitoringTreeUnitProps, IFlatTreeUnit,
} from '../../../../core/interfaces';
import Can from '../../../../rbac/Can';
import { isMobile } from 'react-device-detect';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import { selectActiveProductByUnitId } from '../../../../core/selectors/unitActiveProducts/activeProductDataSelector';
import { selectActiveProductLastUpdate } from '../../../../core/selectors/unitActiveProducts/activeProductlastUpdateSelector';
import { useParamSelector } from '../../../../hooks/useParamSelector';
import { selectFlatMonitoringTreeItemById } from '../../../../core/selectors/monitoringTree/monitoringTreeSelector';
import { selectHmiPlayerMode } from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline } from '../../../../core/selectors/dashboard/dashboardSelector';

/**
 * Monitoring tree unit functional component
 *
 * @param {any} unit
 * @param {any} process
 * @param {any} factory
 * @param {number} index
 * @param {number} processIndex
 * @param {number} factoryIndex
 * @param {number} maxWidthSideBar
 * @param {boolean} visibleSideBar
 * @param {string} searchField
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Unit: React.FC<IMonitoringTreeUnitProps> = (
    {
        unitID,
        process,
        factory,
        index,
        processIndex,
        factoryIndex,
        maxWidthSideBar,
        visibleSideBar,
        searchField,
    }: IMonitoringTreeUnitProps,
) => {

    const [activeProduct, setActiveProduct] = useState<IActiveProductData | undefined>(undefined);

    const dispatch = useDispatch();

    const selectedDashboard = useSelector(selectSelectedDashboard),
        activeProductData = useParamSelector(selectActiveProductByUnitId, unitID) as IActiveProductData | undefined,
        unit = useParamSelector(selectFlatMonitoringTreeItemById, unitID, 'unit') as IFlatTreeUnit,
        lastUpdateProduct = useSelector(selectActiveProductLastUpdate),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const dashboardRealTime = useSelector(selectDashboardOnline);
    const { body: { offsetWidth } } = document;

    const unitIsMinimized = !unit.isMinimized && unit.data.some((sensor: ISensor) => sensor.isVisible),
        sensorIsKeyParameter = unit.data.some((sensor: ISensor) => sensor.isKeyParameter && sensor.isVisible);

    useEffect(() => {

        if (activeProductData && (unitIsMinimized || sensorIsKeyParameter)) {

            setActiveProduct(activeProductData);

        }

    }, [unit, activeProductData, setActiveProduct, lastUpdateProduct]);

    /**
     * Apply filter and sorting to sensors array
     *
     * @param {ISensor[]} sensors
     *
     * @returns {ISensor[]}
     */
    const filter = (sensors: ISensor[])=> {

        return sensors.filter(sensor => sensor.name.toLowerCase().match(searchField) && sensor.isVisible);
    };


    /**
     * Apply filter and sorting to sensors array
     *
     * @param {ISensor[]} sensors
     *
     * @returns {ISensor[]}
     */
    const filterAndSorting = (sensors: ISensor[]): ISensor[] => {

        const filtered = filter(sensors);

        filtered.sort((a, b) => {

            if (a.position === undefined) {

                a.position = 0;
            }

            if (b.position === undefined) {

                b.position = 0;
            }

            return a.position < b.position ? -1 : 1;
        });

        return filtered;
    };

    /**
     * Mouse move handler
     *
     * @param {React.MouseEvent} event
     */
    const mouseMoveHandler = useCallback((event: React.MouseEvent) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            if (dashboardRealTime && offsetWidth - 36 >= event.pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
            }
            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * Touch start handler
     *
     * @param {React.TouchEvent} event
     */
    const touchStartHandler = useCallback((event: React.TouchEvent) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            if (dashboardRealTime && offsetWidth - 36>= event.touches[0].pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
            }

            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * Touch move handler
     *
     * @param {React.TouchEvent} event
     */
    const touchMoveHandler = useCallback((event: React.TouchEvent) => {

        event.preventDefault();

        if (HMIPlayerStatus === 'stop') {

            if (dashboardRealTime && offsetWidth - 36 >= event.touches[0].pageX) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
            }

            if (!dashboardRealTime) {

                dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * Key sensor
     */
    const keySensor = unit.data.find((sensor: ISensor) => sensor.isKeyParameter === true);

    const rightSectionStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s linear',
    }, visibleSideBarStyle: CSSProperties = {
        fontWeight: 'bold',
        direction: 'ltr',
        color: '#333333',
    };

    const deselectStates = useCallback(() => {

        dispatch(GraphActions.deselectAlertGraph());
        dispatch(statesActions.deselectAllStates());

    }, [dispatch]);

    const peakLeave = useCallback(() => {

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());

        }

    }, [dispatch, HMIPlayerStatus]);

    return (
        <div
            key={unit.id}
            className={index === 0 && processIndex === 0 && factoryIndex === 0 ? 'process-item first-item-on-tree' : 'process-item'}
        >
            <React.Fragment>
                {unitIsMinimized || sensorIsKeyParameter ?
                    <div className="item-title display-flex"
                         onClick={deselectStates}
                    >
                        <div
                            className="right-section breadcrumb small-height"
                            style={rightSectionStyle}
                        >
                            <Tooltip title={`${factory.name}/${process.name}/${unit.name}`}>
                                <div className={`breadcrumb-text ${isMobile ? 'is-mobile' : ''}`} >
                                    <div hidden={visibleSideBar}>
                                        {factory.name}/{process.name}/<span>{unit.name}</span>
                                    </div>
                                    {visibleSideBar ?
                                        <div
                                            style={visibleSideBarStyle}
                                        >
                                            {`/${unit.name}`}
                                        </div> : null}
                                </div>
                            </Tooltip>
                            {!visibleSideBar ? (
                                <Can action="product:change">
                                    <ChangeProduct unitId={unit.id} />
                                </Can>
                            ) : null}
                        </div>
                        <div
                            className="left-section product-line"
                            onMouseMove={mouseMoveHandler}
                            onTouchStart={touchStartHandler}
                            onTouchMove={touchMoveHandler}
                            onMouseLeave={peakLeave}
                        >
                            <ProductsVerticalLineGraph
                                unitId={unit.id}
                            />
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
            {unitIsMinimized ?
                filterAndSorting(unit.data).map((sensor, sensorIndex) => {

                    if (selectedDashboard && !selectedDashboard.graphs_switch && sensorIndex !== 0) {

                        return null;
                    }

                    return (
                        <Sensor
                            key={sensor.id}
                            sensor={sensor}
                            unit={unit}
                            process={process}
                            factory={factory}
                            keySensor={keySensor}
                            visibleSideBar={visibleSideBar}
                            maxWidthSideBar={maxWidthSideBar}
                            activeProductData={activeProduct}
                        />
                    );
                })
                :
                keySensor ? (
                    <KeySensor
                        sensor={keySensor}
                        unit={unit}
                        visibleSideBar={visibleSideBar}
                        activeProductData={activeProduct}
                    />
                ) : null
            }
        </div>
    );
};

export default Unit;
