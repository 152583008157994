import {
    FetchMonitoringTreeFailure,
    FetchMonitoringTreeRequest,
    FetchMonitoringTreeSuccess, ProcessHrData, ProcessHrDataRange, ProcessHrDataRangeUpdate, ProcessHrDataUpdate,
    UpdateMonitoringTreeFailure,
    UpdateMonitoringTreeSuccess,
} from '../actions/monitoringTreeActions';
import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { IEmployeeState } from '../reducers/employeeReducer';
import { IHRDashboardUpdateModel } from '../../interfaces';
import { OpeningForm } from '../actions/settingPageActions';
import { MonitoringTreeService } from '../../services/monitoringTreeService';

export const monitoringTreeDashboardThunks = {

    fetchDashboard: (id: number) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        const monitoringTreeService = new MonitoringTreeService();

        try {

            dispatch(new FetchMonitoringTreeRequest());

            const monitoringTree = await monitoringTreeService.list(id);

            dispatch(new FetchMonitoringTreeSuccess({ monitoringTree }));

        } catch (errors) {

            const error = monitoringTreeService.errorHandler(errors);

            dispatch(new FetchMonitoringTreeFailure({ error }));
        }
    },

    updateDashboard: (model: IHRDashboardUpdateModel) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        const monitoringTreeService = new MonitoringTreeService();

        try {

            const monitoringTree = await monitoringTreeService.update(model);


            monitoringTree.isMinimized = model.isMinimized;


            dispatch(new UpdateMonitoringTreeSuccess({ monitoringTree }));

            dispatch(new OpeningForm({ formOpening: false }));

        } catch (errors) {

            const error = monitoringTreeService.errorHandler(errors);

            dispatch(new UpdateMonitoringTreeFailure({ error }));
        }
    },

    processHrData: <T>(dataHr: T, override: boolean, range: boolean) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        dispatch(new ProcessHrData({ dataHr, override, range }));

    },

    processHrDataRange: <T>(dataHrRange: T, override: boolean, range: boolean) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        dispatch(new ProcessHrDataRange({ dataHrRange, override, range }));
    },

    processHrDataUpdate: <T>(dataHr: T) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        dispatch(new ProcessHrDataUpdate({ dataHr }));

    },

    processHrDataRangeUpdate: <T>(dataHrRange: T) => async(dispatch: Dispatch<IEmployeeState, void, AnyAction>): Promise<void> => {

        dispatch(new ProcessHrDataRangeUpdate({ dataHrRange }));
    },
};
