import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { List, ListItem } from '@material-ui/core';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';

// ICONS
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { ReactComponent as GraphicsTall } from '../../assets/images/icons/tall-graphics.svg';
import { ReactComponent as GraphicsTallActive } from '../../assets/images/icons/tall-graphics-active.svg';
import { ReactComponent as GraphicsNormal } from '../../assets/images/icons/low-off.svg';
import { ReactComponent as GraphicsNormalActive } from '../../assets/images/icons/low-off-active.svg';
import { ReactComponent as HistogramHigh } from '../../assets/images/icons/high-off.svg';
import { ReactComponent as HistogramHighActive } from '../../assets/images/icons/high-off-active.svg';
import { ReactComponent as HistogramLow } from '../../assets/images/icons/low-on.svg';
import { ReactComponent as HistogramLowActive } from '../../assets/images/icons/low-on-active.svg';
import { ReactComponent as ZoomIn } from '../../assets/images/icons/zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../assets/images/icons/zoom-out.svg';
import { ReactComponent as Linear } from '../../assets/images/icons/linear-off.svg';
import { ReactComponent as LinearActive } from '../../assets/images/icons/linear-on.svg';
import { ReactComponent as Logarithmic } from '../../assets/images/icons/logarithmic-off.svg';
import { ReactComponent as LogarithmicActive } from '../../assets/images/icons/logarithmic-on.svg';
import { ReactComponent as Cubism } from '../../assets/images/icons/cubism-off.svg';
import { ReactComponent as CubismActive } from '../../assets/images/icons/cubism-on.svg';
import { ReactComponent as Export } from '../../assets/images/icons/export.svg';
import { ReactComponent as DropdownArrowIcon } from '../../assets/images/icons/dropdown-arrow.svg';
import { Scrollbar } from 'react-scrollbars-custom';

import Player from '../../../../components/Dashboard/HMI/Player';

import { DashboardActions, ExportDataActions, FormActions, statesActions } from '../../../actions';
import { graphConstants } from '../../../constants';

import './styles/Panel.scss';
import DateRangePicker from './DateRangePicker';
import ButtonMenu from '../../../../components/Dashboard/HMI/ButtonMenu';
import { GraphActions } from '../../../../base/store/actions';
import { HmiPlayerActions } from '../../../actions';
import { IDashboard, IHmiSchema } from '../../../interfaces';

interface IPanelProps {
    histogramHeight: number;
    histogramMode: string;
    barHeight: number;
    barDescriptionVisible?: boolean;
    minimapVisible?: boolean;
    histogramToggleHeight: (status: boolean) => void;
    histogramSwitchMode: (name: string) => void;
    minimapToggle: (data: boolean) => void;
    togglePeriod: (name: string) => void;
    minimapPeriod: string;
    minimapRange: { startDate: Date; endDate: Date; key: string; };
    zoomSelection: (minimapRange: { startDate: Date; endDate: Date; key: string; }, zoom: string) => void;
    barToggleHeight: (statusHeight: boolean) => void;
    barToggleDescription: (data: boolean) => void;
    shiftSelection: (minimapRange: { startDate: Date; endDate: Date; key: string; }, position: string, minimapPeriod: string) => void;
    minimapScaleDates: Record<string, unknown>;
    deselectStates: () => void;
    dashboardOnline: boolean;
    screenWidth: number;
    selection: Date[];
    exportAllState: (from: string, to: string, fileName: string, dashboard: number)=> void;
    setOffCurrentTime: () => void;
    setOnCurrentTime: () => void;
    defaultMinimapPeriod: string;
    selectedDashboard: IDashboard;
    hmiVisible: boolean;
    toggleHMI: (isVisible: boolean) => void;
    realTimeHmiPlayer: (status: boolean) => void;
    setHmiSchema: (schema: IHmiSchema | null) => void;
    stopHmiPlayer: () => void;
    enableHmi: boolean;
}

/**
 * A dashboard toolbar to manage graphics
 *
 * @param histogramHeight
 * @param histogramMode
 * @param barHeight
 * @param barDescriptionVisible
 * @param minimapVisible
 * @param histogramToggleHeight
 * @param histogramSwitchMode
 * @param minimapToggle
 * @param togglePeriod
 * @param minimapPeriod
 * @param minimapRange
 * @param zoomSelection
 * @param barToggleHeight
 * @param barToggleDescription
 * @param shiftSelection
 * @param minimapScaleDates
 * @param deselectStates
 * @param dashboardOnline
 * @param screenWidth
 * @param selection
 * @param exportAllState
 * @param setOffCurrentTime
 * @param setOnCurrentTime
 * @param hmiVisible
 * @param toggleHMI
 * @param setHmiSchema
 * @param stopHmiPlayer
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Panel: React.FC<IPanelProps> = (
    {
        histogramHeight = graphConstants.histogramSmallHeight,
        histogramMode,
        barHeight = graphConstants.barSmallHeight,
        barDescriptionVisible = true,
        minimapVisible = true,
        histogramToggleHeight,
        histogramSwitchMode,
        minimapToggle,
        togglePeriod,
        minimapPeriod,
        minimapRange,
        zoomSelection,
        barToggleHeight,
        barToggleDescription,
        shiftSelection,
        deselectStates,
        dashboardOnline,
        screenWidth,
        selection,
        exportAllState,
        setOffCurrentTime,
        realTimeHmiPlayer,
        setOnCurrentTime,
        defaultMinimapPeriod,
        selectedDashboard,
        hmiVisible = false,
        toggleHMI,
        setHmiSchema,
        stopHmiPlayer,
        enableHmi,
    }: IPanelProps,
) => {

    const { t } = useTranslation();

    const histogramHeightSmall = histogramHeight === graphConstants.histogramSmallHeight,
        barHeightSmall = barHeight === graphConstants.barSmallHeight;
    const dispatch = useDispatch();

    const [hmiMenuAnchor, setHmiMenuAnchor] = useState<null | HTMLElement>(null);
    const hmiBtnRef = useRef<null | HTMLElement>(null);

    /**
     * Handle histogram height switch
     *
     */
    const histogramHeightSwitch = useCallback(() => {

        histogramToggleHeight(histogramHeightSmall);

    }, [histogramToggleHeight, histogramHeightSmall]);

    /**
     * Handle Minimap visibility switch
     */
    const minimapSwitch = useCallback(() => {

        minimapToggle(minimapVisible);

    }, [minimapToggle, minimapVisible]);

    /**
     * Handle HMI visibility switch
     */
    const hmiSwitch = useCallback(() => {

        toggleHMI(hmiVisible);

        setHmiMenuAnchor(!hmiVisible ? hmiBtnRef.current : null);

        if (hmiVisible) {

            setHmiSchema(null);
            stopHmiPlayer();
        }

    }, [toggleHMI, hmiVisible, setHmiSchema]);

    /**
     * Handle bar chart height switch
     */
    const barHeightSwitch = useCallback(() => {

        barToggleHeight(barHeightSmall);

    }, [barToggleHeight, barHeightSmall]);

    /**
     * Handle bar chart description switch
     */
    const barDescriptionSwitch = useCallback(() => {

        barToggleDescription(barDescriptionVisible);

    }, [barToggleDescription, barDescriptionVisible]);

    /**
     * Setting the default period
     */
    useEffect(() => {

        togglePeriod(defaultMinimapPeriod);

        setOnCurrentTime();

    }, [togglePeriod, setOnCurrentTime]);

    /**
     * Handler for loading a report for all states
     */
    const exportData = useCallback((event) => {

        event.preventDefault();

        event.stopPropagation();

        if (selection && selectedDashboard) {

            const { id } = selectedDashboard;

            exportAllState(moment(selection[0]).format(), moment(selection[1]).format(), `${moment().format('DD.MM.YY  HH-mm')} sensors report`, id);

        }

    }, [selection, exportAllState, selectedDashboard]);

    /**
     * State deselection handler
     */
    const deselectStatesAction = useCallback(() => {

        deselectStates();

    }, [deselectStates]);

    /**
     * Stop Propagation handler
     */
    const stopPropagationOnClick = useCallback((event) => {

        dispatch(FormActions.toggle(false));

        event.stopPropagation();

    }, [dispatch]);

    /**
     * Period picker by day
     */
    const periodSwitchCallback = useCallback((event)=>{

        event.preventDefault();

        event.stopPropagation();

        togglePeriod(event.currentTarget.getAttribute('itemId'));

        if (['day', 'week', 'month', 'year'].includes(event.currentTarget.getAttribute('itemId'))) {
            setOnCurrentTime();
        }

    }, [setOnCurrentTime, togglePeriod]);

    /**
     * Time shift handler for the selected period
     */
    const shiftSelectionCallback = useCallback((event)=>{
        event.preventDefault();

        event.stopPropagation();

        if (event.currentTarget.getAttribute('itemId') === 'right') {
            if (selection && moment(minimapRange.endDate as Date).isAfter(moment())) {

                setOnCurrentTime();

            } else {

                setOffCurrentTime();

                realTimeHmiPlayer(false);

            }
        }

        if (event.currentTarget.getAttribute('itemId') === 'left') {

            setOffCurrentTime();

            realTimeHmiPlayer(false);
        }

        shiftSelection(minimapRange, event.currentTarget.getAttribute('itemId'), minimapPeriod);

    }, [setOnCurrentTime, shiftSelection, minimapRange, minimapPeriod, dashboardOnline, screenWidth, setOffCurrentTime, realTimeHmiPlayer, selection]);

    /**
     * Increase decrease handler for selected period
     */
    const zoomSelectionCallback = useCallback((event)=>{
        event.preventDefault();

        event.stopPropagation();

        zoomSelection(minimapRange, event.currentTarget.getAttribute('itemId'));

        setOffCurrentTime();

        realTimeHmiPlayer(false);

    }, [setOffCurrentTime, zoomSelection, minimapRange, dashboardOnline, screenWidth, realTimeHmiPlayer]);

    /**
     * Handler for switching histogram view
     */
    const histogramSwitchModeCallback = useCallback((event)=>{

        event.stopPropagation();

        histogramSwitchMode(event.target.checked? event.target.value : 'default');

    }, [histogramSwitchMode]);

    /**
     * Close HMI menu handler
     */
    function closeHmiMenu() {

        setHmiMenuAnchor(null);

        toggleHMI(true);
    }

    return (

        <div className="panel">
            <Scrollbar
                permanentTrackY
                noScrollY
                removeTracksWhenNotUsed
                height={30}
                width={'100%'}
            >
                <List
                    style={{
                        display: 'flex',
                        minWidth: 966,
                    }}
                    disablePadding
                    onClick={deselectStatesAction}
                >
                    <ListItem component="li" className="grapics-item">
                        <FormControl onClick={stopPropagationOnClick}>
                            <RadioGroup defaultValue="tall" aria-label="graphics" name="graphics">
                                <Radio
                                    value="normal"
                                    name="graphics"
                                    checked={!barHeightSmall}
                                    onChange={barHeightSwitch}
                                    color="default"
                                    icon={<GraphicsTall />}
                                    checkedIcon={<GraphicsTallActive />}
                                />
                                <Radio
                                    value="small"
                                    name="graphics"
                                    checked={barHeightSmall}
                                    onChange={barHeightSwitch}
                                    color="default"
                                    icon={<GraphicsNormal />}
                                    checkedIcon={<GraphicsNormalActive />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </ListItem>
                    <ListItem component="li" className="histogram-item">
                        <FormControl onClick={stopPropagationOnClick}>
                            <RadioGroup defaultValue="high" aria-label="histogram" name="histogram">
                                <Radio
                                    value="normal"
                                    name="histogram"
                                    checked={!histogramHeightSmall}
                                    onChange={histogramHeightSwitch}
                                    color="default"
                                    icon={<HistogramHigh />}
                                    checkedIcon={<HistogramHighActive />}
                                />
                                <Radio
                                    value="small"
                                    name="histogram"
                                    checked={histogramHeightSmall}
                                    onChange={histogramHeightSwitch}
                                    color="default"
                                    icon={<HistogramLow />}
                                    checkedIcon={<HistogramLowActive />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </ListItem>
                    <ListItem component="li" className="desc-item">
                        <FormControlLabel
                            className="field-checkbox"
                            onClick={stopPropagationOnClick}
                            control={
                                <Checkbox
                                    value="desc"
                                    name="showDesc"
                                    checked={barDescriptionVisible}
                                    onChange={barDescriptionSwitch}
                                    color="primary"
                                    icon={<span className="checkbox-icon" />}
                                />
                            }
                            label={t('SHOW_DESCRIPTIONS')}
                        />
                    </ListItem>
                    <ListItem component="li" className="minimap-item">
                        <FormControlLabel
                            className="field-checkbox"
                            onClick={stopPropagationOnClick}
                            control={
                                <Checkbox
                                    value="minimap"
                                    name="showMinimap"
                                    checked={minimapVisible}
                                    onChange={minimapSwitch}
                                    color="primary"
                                    icon={<span className="checkbox-icon" />}
                                />
                            }
                            label={t('SHOW_MINIMAP')}
                        />
                    </ListItem>
                    {enableHmi ?
                        <ListItem component="li" className={`hmi-item ${hmiVisible ? 'check' : ''}`}>
                            <FormControlLabel
                                ref={hmiBtnRef}
                                className="field-checkbox"
                                onClick={stopPropagationOnClick}
                                control={
                                    <Checkbox
                                        value="hmi"
                                        name="showHmi"
                                        checked={hmiVisible}
                                        onChange={hmiSwitch}
                                        color="primary"
                                        icon={<span className="checkbox-icon" />}
                                    />
                                }
                                label={t('SHOW_HMI')}
                            />
                            <DropdownArrowIcon />
                            <ButtonMenu
                                anchorEl={hmiMenuAnchor}
                                open={Boolean(hmiMenuAnchor)}
                                handleClose={closeHmiMenu}
                                onClose={closeHmiMenu}
                            />
                        </ListItem>
                        : null}
                    <ListItem component="li" className="minimap-nav-item">
                        <List
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'row',
                            }}
                            disablePadding
                        >
                            <ListItem
                                onClick={stopPropagationOnClick}
                            >
                                <IconButton
                                    color="primary" component="span"
                                    itemID="left"
                                    onClick={shiftSelectionCallback}
                                >
                                    <NavigateBeforeIcon />
                                </IconButton>
                            </ListItem>
                            <ListItem
                                className={minimapPeriod === 'day' ? 'minimap-nav-range-item active' : 'minimap-nav-range-item'}
                                itemID="day"
                                onClick={periodSwitchCallback}
                            >
                                <span>{t('DAY')}</span>
                            </ListItem>
                            <ListItem
                                className={minimapPeriod === 'week' ? 'minimap-nav-range-item active' : 'minimap-nav-range-item'}
                                itemID="week"
                                onClick={periodSwitchCallback}
                            >
                                <span>{t('WEEK')}</span>
                            </ListItem>
                            <ListItem
                                className={minimapPeriod === 'month' ? 'minimap-nav-range-item active' : 'minimap-nav-range-item'}
                                itemID="month"
                                onClick={periodSwitchCallback}
                            >
                                <span>{t('MONTH')}</span>
                            </ListItem>
                            <ListItem
                                className={minimapPeriod === 'year' ? 'minimap-nav-range-item active' : 'minimap-nav-range-item'}
                                itemID="year"
                                onClick={periodSwitchCallback}
                            >
                                <span>{t('YEAR')}</span>
                            </ListItem>
                            <ListItem>
                                <IconButton color="primary" component="span"
                                            itemID="right"
                                            onClick={shiftSelectionCallback}
                                >
                                    <NavigateNextIcon />
                                </IconButton>
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem component="li" className="zoom-item">
                        <List
                            style={{
                                display: 'inline-flex',
                                flexDirection: 'row',
                            }}
                            disablePadding
                        >
                            <ListItem onClick={stopPropagationOnClick}>
                                <IconButton color="primary" component="span"
                                            itemID="zoomOut"
                                            onClick={zoomSelectionCallback}
                                >
                                    <ZoomOut />
                                </IconButton>
                            </ListItem>
                            <ListItem onClick={stopPropagationOnClick}>
                                <IconButton color="primary" component="span"
                                            itemID="zoomIn"
                                            onClick={zoomSelectionCallback}
                                >
                                    <ZoomIn />
                                </IconButton>
                            </ListItem>
                        </List>
                    </ListItem>
                    <ListItem component="li" className="minimap-item">
                        {dashboardOnline ?
                            <label className="dashboard-mode online"><i /><span>{t('REALTIME')}</span></label> :
                            <label className="dashboard-mode offline"><i /><span>{t('OFFLINE')}</span></label>}
                    </ListItem>
                    <ListItem component="li" className="histogram-type-item">
                        <FormControl onClick={stopPropagationOnClick}>
                            <RadioGroup defaultValue="linear" aria-label="histogramType" name="histogramType" >
                                <Checkbox
                                    value="linear"
                                    name="histogramType"
                                    checked={histogramMode === graphConstants.histogramModeLinear}
                                    onChange={histogramSwitchModeCallback}
                                    color="default"
                                    icon={<Linear />}
                                    checkedIcon={<LinearActive />}
                                />
                                <Checkbox
                                    value="logarithmic"
                                    name="histogramType"
                                    checked={histogramMode === graphConstants.histogramModeLogarithmic}
                                    onChange={histogramSwitchModeCallback}
                                    color="default"
                                    icon={<Logarithmic />}
                                    checkedIcon={<LogarithmicActive />}
                                />
                                <Checkbox
                                    value="overlapping"
                                    name="histogramType"
                                    checked={histogramMode === graphConstants.histogramModeOverlapping}
                                    onChange={histogramSwitchModeCallback}
                                    color="default"
                                    icon={<Cubism />}
                                    checkedIcon={<CubismActive />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </ListItem>
                    <ListItem component="li" className="export-item" onClick={exportData}>
                        <IconButton color="primary" aria-label="upload picture" component="span">
                            <Export />
                        </IconButton>
                    </ListItem>
                    <ListItem component="li" className="datebox-item">
                        <DateRangePicker />
                    </ListItem>
                </List>
            </Scrollbar>
            {enableHmi ?
                <Player />
                :
                null
            }
        </div>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: any) => {

    const {
        graphHistogramHeight,
        graphMinimapVisibility,
        graphPeriod,
        graphHistogramMode,
        graphBarHeight,
        graphBarDescriptionVisibility,
        graphHMIVisibility,
        dashboard,
        dashboardSelect,
        graphMinimapBrush,
        appSetting,
    } = state;

    return {
        histogramHeight: graphHistogramHeight.height,
        histogramMode: graphHistogramMode.mode,
        minimapVisible: graphMinimapVisibility.visible,
        minimapPeriod: graphPeriod.currentPeriod,
        minimapScaleDates: graphPeriod.scaleDates,
        minimapRange: graphPeriod.range,
        barHeight: graphBarHeight.height,
        barDescriptionVisible: graphBarDescriptionVisibility.visible,
        dashboardOnline: dashboard.online,
        screenWidth: dashboard.screenWidth,
        selection: graphMinimapBrush.selection,
        defaultMinimapPeriod: appSetting.settings.defaultMonitoringView,
        selectedDashboard: dashboardSelect.selectedDashboard,
        hmiVisible: graphHMIVisibility.visible,
        enableHmi: appSetting.settings.hmi.isEnabled,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    histogramToggleHeight: GraphActions.histogramToggleHeight,
    histogramSwitchMode: GraphActions.histogramSwitchMode,
    minimapToggle: GraphActions.minimapToggle,
    togglePeriod: GraphActions.periodSelect,
    zoomSelection: GraphActions.zoomSelect,
    barToggleHeight: GraphActions.barToggleHeight,
    barToggleDescription: GraphActions.barToggleDescription,
    shiftSelection: GraphActions.shiftSelection,
    deselectStates: statesActions.deselectAllStates,
    barToggleTableView: GraphActions.barToggleTableView,
    setOffCurrentTime: DashboardActions.setOffCurrentTime,
    setOnCurrentTime: DashboardActions.setOnCurrentTime,
    exportAllState: ExportDataActions.exportAllState,
    toggleHMI: GraphActions.toggleHMI,
    setHmiSchema: HmiPlayerActions.setSchema,
    stopHmiPlayer: HmiPlayerActions.stop,
    realTimeHmiPlayer: HmiPlayerActions.setRealTime,
});

export default connect(mapStateToProps, mapDispatchToProps)(Panel);
