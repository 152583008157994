import { monitoringTreeConstants } from '../../constants';
import { IMonitoringTreeCollectionState, IMonitoringTreeCollectionAction } from '../../interfaces';

/**
 * Monitoring tree collection reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function monitoringTreeCollection(state: IMonitoringTreeCollectionState = {
    trees: {},
}, action: IMonitoringTreeCollectionAction): IMonitoringTreeCollectionState {

    switch (action.type) {

        case monitoringTreeConstants.GET_MONITORING_TREES_PART_SUCCESS: {

            const trees: any = { ...state.trees };

            trees[action.dashboardId] = [ ...action.data ];

            return {
                trees: trees,
            };
        }

        default:

            return state;
    }
}
