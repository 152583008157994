import { createSelector } from 'reselect';
import { selectBrushSelection } from '../graphMinimapBrush/graphMinimapBrushSelector';
import { selectSensorDataBySensorId } from '../HistogramChart/sensorDataSelector';
import { selectStateDataBySensorId } from '../stateChart/stateDataSelector';
import { RootState } from '../../store';

const stateSelector = (state: RootState) => state.hmiPlayer;

export const selectHmiPlayerMode = createSelector(
    stateSelector,
    player => player.mode,
);

export const selectHmiPlayerSpeed = createSelector(
    stateSelector,
    player => player.speed,
);

export const selectHmiPlayerRealTimeStatus = createSelector(
    stateSelector,
    player => player.realTime,
);

export const selectHmiPlayerValue = createSelector(
    stateSelector,
    selectBrushSelection,
    (player, range) => {

        if (player.value > 0) {

            return player.value;
        }

        const [start] = range;

        return start.getTime();
    },
);

export const selectHmiPlayerSchema = createSelector(
    stateSelector,
    player => player.schema,
);

export const selectVisibilityObjectOnScheme = createSelector(
    stateSelector,
    player => player.showObjects,
);

export const selectVisibilitySchemeOnScheme = createSelector(
    stateSelector,
    player => player.showHmiMap,
);

export const selectOpacityScheme = createSelector(
    stateSelector,
    player => player.opacityHmiMap,
);

//TODO: create selector for sensor value using selectHmiPlayerValue, selectSensorDataBySensorId & selectStateDataBySensorId
