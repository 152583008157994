import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en_locale from '../../translations/en.json';
import ru_locale from '../../translations/ru.json';

import { modules } from '../../modules';

modules.forEach((module) => {

    const translations = module.translations();

    Object.assign(en_locale, translations.en);
    Object.assign(ru_locale, translations.ru);
});

/**
 * Initialize the i18n component
 */
i18n.use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en_locale,
            },
            ru: {
                translation: ru_locale,
            },
        },
        lng: localStorage.getItem('lang') || 'en',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
