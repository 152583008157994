import { createSelector } from 'reselect';
import { selectHrModuleState } from './index';

export const selectLocationState = createSelector(
    selectHrModuleState,
    state => state.locationsState,
);

export const selectLocations = createSelector(
    selectLocationState,
    state => state.locations,
);

export const selectLocationsErrors = createSelector(
    selectLocationState,
    state => state.error,
);

export const selectLocationsAsc = createSelector(
    selectLocations,
    locations => locations.map(location => ({
        ...location,
        layers: location.layers.sort((left, right) => left.id - right.id),
    }))
);
