import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { IHrState } from '../../../../store/reducers';
import { selectSettingPageState } from '../../../../store/selectors/settingPageSelector';
import { SettingPageThunks } from '../../../../store/thunks/settingPageThunks';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IDepartment, IEmployee } from '../../../../interfaces';
import { selectDepartmentState } from '../../../../store/selectors/departmentSelector';
import { IErrors } from '../../../../interfaces';
import { DepartmentThunks } from '../../../../store/thunks/departmentThunks';
import TextInput from '../../../../ui/components/Input/TextInput';
import ConfirmDialog from '../../../../ui/components/Dialog/ConfirmDialog';
import Button from '../../../../ui/components/Button/Button';
import { IGatewayApiModel } from '../../../../services/gatewayService';


interface IDepartmentFormProps extends WithTranslation {
    formOpening?: boolean;
    formName?: string;
    model?: IEmployee | IDepartment | IGatewayApiModel;
    toggleForm: (formOpening: boolean, formName?: string, model?: IEmployee | IDepartment) => void;
    beacons?: Record<string, unknown>[];
    error: IErrors;
    createDepartment: (model: IDepartment)=> void;
    updateDepartment: (model: IDepartment)=> void;
    clearDepartmentError: ()=> void;
}

interface IForm {
    id?: number;
    name: string;
    description: string;
}

const DepartmentForm: React.FC<IDepartmentFormProps> = (
    {
        t,
        model,
        toggleForm,
        error,
        createDepartment,
        updateDepartment,
        clearDepartmentError,
    }: IDepartmentFormProps
) => {

    const [initialValues, setInitialValues] = useState<IForm>({
        name: '',
        description: '',
    });

    const [formValue, setFormValue] = useState<IForm | IDepartment | null>(null);

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .trim()
            .max(30, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: '30' }))
            .required(t('DEPARTMENT_NAME_IS_REQUIRED')),
        description: yup
            .string()
            .max(300, t('MAX_COMMENT_COMMENT'))
            .nullable(true),
    });

    useEffect(() => {

        return () => {
            toggleForm(false);

            setFormValue(null);
        };

    }, [toggleForm]);

    /**
     *  Update initValue with model data
     */
    useEffect(() => {

        if (model) {

            setInitialValues(model as IForm);

        }

    }, [model]);
    /**
     * Handler the form submit
     *
     * @param value
     */
    const handleSubmit = useCallback((value: IForm) => {

        setFormValue(value);

    }, [setFormValue]);

    /**
     * Popup handler. Unset anchor element.
     */
    const handleClose = useCallback(() => {

        toggleForm(false);

        setFormValue(null);

        clearDepartmentError();

    }, [setFormValue, toggleForm, clearDepartmentError]);


    /**
     * Handler confirm dialog form
     *
     * @type {() => void}
     */
    const onConfirmDialog = useCallback(()=> {

        if (formValue) {
            if (formValue.id) {

                updateDepartment(formValue as IDepartment);

            } else {

                createDepartment(formValue as IDepartment);

            }
        }

    }, [createDepartment, updateDepartment, formValue]);

    /**
     * Handler cancel dialog form
     *
     * @type {() => void}
     */
    const handleCancel = useCallback(()=> {

        setFormValue(null);

    }, [setFormValue]);


    return (
        <div className="form-box form-box-space node-form">
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <form
                        noValidate
                        onSubmit={props.handleSubmit}
                    >
                        <div className="table-header">
                            <div
                                className={'title'}
                            >
                                {t(!model ? 'ADD_DEPARTMENT' : 'EDIT_DEPARTMENT')}
                            </div>
                        </div>
                        {error?.message ?
                            <div className="common-error">
                                {t('THE_DEPARTMENT_NAME_ALREADY_BEEN_TAKEN')}
                            </div>
                            : null
                        }
                        <div className="table-body">
                            <div className="form-group">
                                <TextInput
                                    className={
                                        'form-field '
                                        +
                                        (props.touched.name ? props.errors.name || (error.message && error.message[0]) ? 'error-field' : 'success-field' : '')
                                    }
                                    label={t('NAME')}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.name}
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder={t('NAME')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {props.touched.name && props.errors.name &&
                                    <div
                                        className="validation-massage"
                                    >{props.errors.name}
                                    </div>
                                    }
                                </TextInput>
                            </div>
                            <div className="form-group">
                                <TextInput
                                    className={
                                        'form-field '
                                        +
                                        (props.touched.description ? props.errors.description ? 'error-field' : 'success-field' : '')
                                    }
                                    label={t('DESCRIPTION')}
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    value={props.values.description?props.values.description: ''}
                                    id="description"
                                    name="description"
                                    type="text"
                                    placeholder={t('DESCRIPTION')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {props.touched.description && props.errors.description &&
                                    <div
                                        className="validation-massage"
                                    >{props.errors.description}
                                    </div>
                                    }
                                </TextInput>
                            </div>
                        <div className="form-group btn-group">
                            <Button
                                id="cancel"
                                type="reset"
                                color={'primary'}
                                onClick={handleClose}
                            >
                                {t('CANCEL')}
                            </Button>
                            <Button
                                id="add"
                                type="submit"
                                color={'secondary'}
                            >
                                {t(!model ? 'ADD' : 'SAVE_CHANGES')}
                            </Button>
                        </div>
                        </div>
                    </form>
                )}
            </Formik>
            {formValue ?
                <ConfirmDialog
                    heading={t(!model ? 'ADD_DEPARTMENT_Q' : 'CHANGE_DEPARTMENT_Q')}
                    onAccept={onConfirmDialog}
                    onClose={handleCancel}
                    open={Boolean(formValue)}
                />
                :
                null
            }
        </div>
    );
};

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {

    const { formOpening, formName, model } = selectSettingPageState(state);
    const { error } = selectDepartmentState(state);

    return {
        formOpening,
        formName,
        model: model as IDepartment,
        error,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    toggleForm: SettingPageThunks.toggleForm,
    createDepartment: DepartmentThunks.saveDepartment,
    updateDepartment: DepartmentThunks.updateDepartment,
    clearDepartmentError: DepartmentThunks.clearDepartmentError,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DepartmentForm));