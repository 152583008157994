import React, { useCallback, useState } from 'react';
import { isIE, isMobile } from 'react-device-detect';
import { ReactComponent as HRIcon } from '../../ui/assets/images/icons/hr.svg';
import { IcomoonPreloader } from '../../ui/components/IcomoonPreloader/IcomoonPreloader';
import { useTranslation } from 'react-i18next';
import './LayerWrapper.scss';
import { connect } from 'react-redux';
import { GraphActions } from '../../../../base/store/actions';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ReactComponent as DropdownArrow } from '../../../../core/ui/assets/images/icons/dropdown-arrow.svg';
import { monitoringTreeDashboardThunks } from '../../store/thunks/monitoringTreeDashboardThunks';
import { IHRDashboardUpdateModel } from '../../interfaces';


interface ILayerWrapperProps {
    children?: React.ReactNode
    visibleSideBar: boolean;
    editable: boolean;
    dashboard?: IHRDashboardUpdateModel;
    expandedDefault?: boolean;
    maxWidthSideBar: number;
    peakEnterEmpty: (x: number) => void;
    peakLeave: () => void;
    updateDashboard: (dashboard: IHRDashboardUpdateModel) =>void;
}

const LayerWrapper: React.FC<ILayerWrapperProps> = (
    {
        visibleSideBar,
        maxWidthSideBar,
        peakEnterEmpty,
        peakLeave,
        editable,
        updateDashboard,
        expandedDefault = true,
        dashboard,
        children,
    }: ILayerWrapperProps,
) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(expandedDefault);
    const onChangeCallback = useCallback((event: React.ChangeEvent<Record<string, unknown>>, expanded: boolean) => {

        event.preventDefault();

        if (editable && dashboard) {

            const sendData = {
                id: dashboard.id,
                isMinimized: !expanded,
            };

            updateDashboard(sendData);
        }

        setExpanded(expanded);

    }, [setExpanded, updateDashboard, editable, dashboard]);

    /**
     *
     *
     * @type {() => void}
     */
    const peakLeaveCallback = useCallback(() => {

        peakLeave();

    }, [peakLeave]);

    /**
     *
     *
     * @type {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void}
     */
    const onMouseMoveCallback = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

        const index = (event.pageX - maxWidthSideBar);
        peakEnterEmpty(index);

    }, [peakEnterEmpty, maxWidthSideBar]);

    return (
        <section className="wrap-hr monitoring-tree">
            <Accordion
                className={'expansion-panel hr-panel'}
                defaultExpanded={expanded}
                expanded={expanded}
                onChange={onChangeCallback}
                disabled={!editable}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id={'department'}
                >
                    <div
                        className="title-block-wrap"

                    >
                        {editable ? <DropdownArrow /> : null}
                        <div className={`title-block padding-1 ${isMobile ? 'is-mobile' : ''}`}
                             style={{
                                 position: isIE ? 'relative' : 'sticky',
                                 minHeight: 25,
                                 maxWidth: maxWidthSideBar,
                                 minWidth: maxWidthSideBar,
                                 borderRight: '1px solid #e9ecef',
                             }}
                             onMouseEnter={peakLeaveCallback}
                        >
                            <HRIcon style={{ height: 25, width: 25 }} />
                            {visibleSideBar ? t('HR_GRAPH') : null}
                            <IcomoonPreloader />
                        </div>
                        <div
                            className="left-section"
                            onMouseMove={onMouseMoveCallback}
                        />
                    </div>
                </AccordionSummary>
                <AccordionDetails className="hr-details">
                    {children || ''}
                </AccordionDetails>
            </Accordion>
        </section>
    );
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    peakEnterEmpty: GraphActions.peakEnterEmptyLine,
    peakLeave: GraphActions.peakLeave,
    updateDashboard: monitoringTreeDashboardThunks.updateDashboard,
});


export default connect(null, mapDispatchToProps)(React.memo(LayerWrapper));
