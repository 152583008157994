import { Action } from 'redux';
import { ILocation } from '../../interfaces';

export enum LocationActionTypes {
    SAVE_LOCATION = '[Location] Save Location',
    FETCH_LOCATIONS_REQUEST = '[Location] Fetch Locations Request',
    FETCH_LOCATIONS_SUCCESS = '[Location] Fetch Locations Success',
    FETCH_LOCATIONS_FAILURE = '[Location] Fetch Locations Failure',
    SET_LOCATION_PICTURE = '[Location] Set Location Picture',
    SET_LOCATION_PICTURE_ERROR = '[Location] Set Location Picture Error',
    DELETE_LOCATION_ACTION = '[Location] Delete Location Action',
}

export class FetchLocationsRequest implements Action {
    readonly type = LocationActionTypes.FETCH_LOCATIONS_REQUEST;
}

export class FetchLocationsSuccess implements Action {
    readonly type = LocationActionTypes.FETCH_LOCATIONS_SUCCESS;

    constructor(
        public payload: {
            locations: ILocation[]
        }
    ) {}
}

export class FetchLocationsFailure implements Action {
    readonly type = LocationActionTypes.FETCH_LOCATIONS_FAILURE;

    constructor(
        public payload: {
            error: any
        }
    ) {}
}

export class SaveLocationAction implements Action {
    readonly type = LocationActionTypes.SAVE_LOCATION;

    constructor(
        public payload: {
            location: ILocation,
        },
    ) {}
}

export class SetLocationPictureAction implements Action {
    readonly type = LocationActionTypes.SET_LOCATION_PICTURE;

    constructor(
        public payload: {
            locationId: number,
            pictureContent: string,
        }
    ) {
    }
}
export class SetLocationPictureErrorAction implements Action {
    readonly type = LocationActionTypes.SET_LOCATION_PICTURE_ERROR;

    constructor(
        public payload: {
            error: any,
        }
    ) {
    }
}

export class DeleteLocationAction implements Action {
    readonly type = LocationActionTypes.DELETE_LOCATION_ACTION;

    constructor(
        public payload: {
            location: ILocation
        }
    ) {
    }
}

export type LocationActions =
    FetchLocationsRequest |
    FetchLocationsSuccess |
    FetchLocationsFailure |
    SaveLocationAction |
    SetLocationPictureAction |
    DeleteLocationAction |
    SetLocationPictureErrorAction;
