import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import Header from '../Header/Header';
import StructuralTree from './StructuralTree/StructuralTree';
import SocketProvider from '../../core/providers/socketProvider';
import { DashboardActions, FormActions, ProductActions } from '../../core/actions';
import {
    IActivateProduct,
    IDashboardProps,
    IHistogramData,
    IUser,
} from '../../core/interfaces';

import { appConfig } from '../../config/appConfig';
import Notification from '../LoadingNotification/LoadingNotification';
import { history, poolingGaps } from '../../helpers';
import { AppRoles } from '../../rbac/roles';
import { RootState } from '../../core/store';
import { GraphActions } from '../../base/store/actions';
import { isEmptyArray } from '../../helpers/isEmptyArray';
import { selectUnitsForCurrentDashboard } from '../../core/selectors/monitoringTree/monitoringTreeSelector';
import moment from 'moment';
import DashboardTimer from '../DashboardTimer/DashboardTimer';

interface IDashboardState {
    socketId: undefined | string;
}

/**
 * Dashboard component
 *
 * @class Dashboard
 */
class Dashboard extends React.Component<IDashboardProps & WithTranslation, IDashboardState> {

    /**
     * Constructor
     *
     * @param {IProps & WithTranslation} props
     */
    constructor(props: IDashboardProps & WithTranslation) {

        super(props);

        this.socket = new SocketProvider();

        this.updateScreenWidth();

        this.sideBarLogic = JSON.parse(localStorage.getItem('sidebar') as string);

        window.addEventListener('resize', this.updateScreenWidth.bind(this));

        this.socketMessageId = 0;

        this.socketMessageIdProduct = 0;

        this.socketMessageIdForRange = 0;

        this.overrideSensorData = false;

        this.overrideProductData = false;

        this.state = {
            socketId: undefined,
        };

        this.rangePeriodUpdated = false;

        this.valueRepeatTimeout = this.props.settings.valueRepeatTimeout;

        this.getSocketId = this.getSocketId.bind(this);

        this.socket.listen('getGraphData', this.processSocketResponse.bind(this));
        this.socket.listen('graphDataUpdate', this.processSocketUpdates.bind(this));
        this.socket.listen('getGraphDataRange', this.processSocketResponse.bind(this));
        this.socket.listen('graphDataRangeUpdate', this.processSocketUpdates.bind(this));
        this.socket.listen('getActiveProductData', this.processProductSocketResponse.bind(this));
        this.socket.listen('activeProductUpdate', this.processProductSocketUpdates.bind(this));

    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {


        this.socket.getSocketId(this.getSocketId);

    }

    /**
     * Callback after component props update
     *
     * @param {IProps} prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps: IDashboardProps, prevState: IDashboardState) {

        const {
            brushSelection,
            sideBarMaxWidth,
            screenWidth,
            dashboardOnline,
            dashboardLastUpdate,
            arrOfUnit,
            dashboardLastUpdateProduct,
            user,
            settings,
            brushRule,
            timerRule,
            brushRange,
        } = this.props;


        if (!settings.isConfigured && !settings.hasDashboards && user && user.role === AppRoles.SUPER) {

            history.push('/configuration');

        }

        if (prevProps.settings.valueRepeatTimeout !== settings.valueRepeatTimeout) {

            this.valueRepeatTimeout = settings.valueRepeatTimeout;
        }

        if (prevState.socketId && brushSelection &&  prevState.socketId !== this.state.socketId) {

            const [from, to] = brushSelection;

            this.socket.call('reconnect', {

                access_token: localStorage.getItem('auth_token'),
                from: from.toISOString(),
                to: to.toISOString(),
                numberOfDataPoints: screenWidth,
                messageIdGraph: this.socketMessageId,
                messageIdGraphRange: this.socketMessageIdForRange,
                messageIdProduct: this.socketMessageIdProduct,
                realtime: dashboardOnline,
            });
        }

        let selectedFromTime = 0, selectedToTime = 0, prevFromTime = 0, prevToTime = 0;


        if (brushSelection) {

            selectedFromTime = brushSelection[0].getTime();
            selectedToTime = brushSelection[1].getTime();
        }

        if (prevProps.brushSelection) {

            prevFromTime = prevProps.brushSelection[0].getTime();
            prevToTime = prevProps.brushSelection[1].getTime();
        }
        
        if (brushSelection &&
            arrOfUnit &&
            brushRange &&
            brushRule &&
            (
                selectedFromTime !== prevFromTime ||
                selectedToTime !== prevToTime ||
                prevProps.brushRule !== brushRule ||
                prevProps.brushRange !== brushRange ||
                (
                    arrOfUnit.length !== prevProps.arrOfUnit.length ||
                    !arrOfUnit.every(unit => prevProps.arrOfUnit.includes(unit))
                ) ||
                (screenWidth && screenWidth !== prevProps.screenWidth)
            ) &&
            arrOfUnit.length > 0
        ) {
            const [from, to] = brushSelection;

            if (brushRange) {

                this.rangeRule = new Date(brushRange.startDate).getTime() === new Date(from).getTime();

            }

            if (brushSelection !== prevProps.brushSelection ||
                this.firstLoad ||
                (screenWidth && screenWidth !== prevProps.screenWidth)) {

                if (!timerRule){

                    this.props.processSensorDataLoaded();

                }

                this.callSocketGraphDataRange();

                this.firstLoad = false;
            }

            if ((prevProps.brushRange?.startDate &&
                    moment(brushRange.startDate).isAfter(prevProps.brushRange.startDate)) ||
                this.firstLoadRange ||
                (screenWidth && screenWidth !== prevProps.screenWidth)) {

                this.callSocketGraphData();
                
                this.firstLoadRange = false;

            }

           this.callSocketActiveProductData();

        }

        if (dashboardOnline !== prevProps.dashboardOnline) {

            if (!dashboardOnline) {

                this.socket.stop('getGraphData', this.processSocketResponse.bind(this));
                this.socket.stop('getGraphDataRange', this.processSocketResponse.bind(this));
                this.socket.stop('getActiveProductData', this.processProductSocketResponse.bind(this));

            } else {

                this.socket.listen('getGraphData', this.processSocketResponse.bind(this));
                this.socket.listen('getGraphDataRange', this.processSocketResponse.bind(this));
                this.socket.listen('getActiveProductData', this.processProductSocketResponse.bind(this));
            }
        }


        if (dashboardLastUpdate !== prevProps.dashboardLastUpdate || dashboardLastUpdateProduct !== prevProps.dashboardLastUpdateProduct) {

            // this.callSocketGraphData();
            // this.callSocketGraphDataRange();

        }
        
        if (sideBarMaxWidth && sideBarMaxWidth !== prevProps.sideBarMaxWidth) {

            this.updateScreenWidth();
        }
    }

    /**
     * Callback before the component will be removed from DOM
     */
    componentWillUnmount() {

        this.socket.disconnect();

        window.removeEventListener('resize', this.updateScreenWidth.bind(this));

        this.props.toggleForm(true);
    }

    /**
     * Set Positive Override
     */
    setPositiveOverride() {
        this.overrideSensorData = true;

        this.overrideProductData = true;
    }

    /**
     * Call Active Product Data
     */
    callSocketActiveProductData() {

        const { brushRange, arrOfUnit, dashboardOnline } = this.props;

        this.socketMessageIdProduct = Math.floor(100000000 + (Math.random() * 900000000));

        this.setPositiveOverride();

        if (brushRange) {
            const getActiveProductDataArgs = {
                messageId: this.socketMessageIdProduct,
                from: brushRange.startDate.toISOString(),
                to: brushRange.endDate.toISOString(),
                units: arrOfUnit ? arrOfUnit : [],
                access_token: localStorage.getItem('auth_token'),
                subscribe: dashboardOnline,
            };

            this.socket.call('getActiveProductData', getActiveProductDataArgs, this.processProductSocketResponse.bind(this));
        }
    }

    /**
     * Call Graph Data
     */
    callSocketGraphData() {

        const { screenWidth, brushRange, arrOfUnit, dashboardOnline } = this.props;

        this.socketMessageId = Math.floor(100000000 + (Math.random() * 900000000));

        this.setPositiveOverride();

        if (brushRange) {
            const getGraphDataArgs = {
                messageId: this.socketMessageId,
                numberOfDataPoints: screenWidth,
                from: brushRange.startDate.toISOString(),
                to: brushRange.endDate.toISOString(),
                units: arrOfUnit ? arrOfUnit : [],
                access_token: localStorage.getItem('auth_token'),
                subscribe: dashboardOnline,
            };

            this.socket.call('getGraphData', getGraphDataArgs, this.processSocketResponse.bind(this));
        }
    }

    /**
     * Call Graph Data Range
     */
    callSocketGraphDataRange() {

        const { screenWidth, brushSelection, arrOfUnit } = this.props;

        this.socketMessageIdForRange = Math.floor(100000000 + (Math.random() * 900000000));

        this.setPositiveOverride();

        if (brushSelection) {
            const [from, to] = brushSelection;

            const getGraphDataArgsRange = {
                messageId: this.socketMessageIdForRange,
                numberOfDataPoints: screenWidth,
                from: from.toISOString(),
                to: to.toISOString(),
                units: arrOfUnit ? arrOfUnit : [],
                access_token: localStorage.getItem('auth_token'),
                subscribe: false,
            };

            this.socket.call('getGraphDataRange', getGraphDataArgsRange, this.processSocketResponse.bind(this));
        }
    }

    /**
     * Get socket ID
     *
     * @param {string} id
     */
    getSocketId(id: string): void {

        const { socketId } = this.state;

        if (socketId !== id) {

            this.setState({ socketId: id });

        }
    }

    /**
     * Process response from sockets and call a callback
     *
     * @param {Object} response
     */
    processSocketResponse<T>(response: { messageId: number, data: T }) {

        if (this.socketMessageId === response.messageId && !isEmptyArray(response.data)) {

            poolingGaps(response.data as unknown as IHistogramData[], this.valueRepeatTimeout).then(value => {

                this.props.processSensorData(value);

                this.overrideSensorData = false;

            });
        }

        if (this.socketMessageIdForRange === response.messageId && !isEmptyArray(response.data)) {

            poolingGaps(response.data as unknown as IHistogramData[], this.valueRepeatTimeout).then(value => {

                this.props.processSensorDataRange(value);

            });
        }
    }

    /**
     * Process response from update event and call a callback
     *
     * @param {Object} response
     */
    processSocketUpdates<T>(response: { messageId: number, data: T })  {

        if (this.socketMessageId === response.messageId && !isEmptyArray(response.data)) {

            this.rangePeriodUpdated = true;

            poolingGaps(response.data as unknown as IHistogramData[], this.valueRepeatTimeout).then(value => {

                this.props.updateSensorData(value);
            });
        }

        if (this.socketMessageIdForRange === response.messageId && !isEmptyArray(response.data)) {

            poolingGaps(response.data as unknown as IHistogramData[], this.valueRepeatTimeout).then(value => {

                this.props.updateSensorDataRange(value);
            });
        }
    }

 /**
     * Process response from sockets and call a callback
     *
     * @param {Object} response
     */
    processProductSocketResponse(response: { messageId: number, data: IActivateProduct[] }) {

     if (this.socketMessageIdProduct === response.messageId && !isEmptyArray(response.data)) {

            this.props.processProductData(response.data, this.overrideSensorData, this.rangeRule);

            this.overrideProductData = false;
        }
    }

    /**
     * Process response from update event and call a callback
     *
     * @param {Object} response
     */
    processProductSocketUpdates(response: { messageId: number, data: IActivateProduct[] }) {

        if (this.socketMessageIdProduct === response.messageId && !isEmptyArray(response.data)) {

            this.props.updateProductData(response.data);
        }
    }

    /**
     * Update screen width available for charts
     */
    updateScreenWidth() {

        const sideBarWidth = this.props.sideBarMaxWidth ? this.props.sideBarMaxWidth : this.sideBarLogic ? appConfig.dashboardLeftOffsetLarge : appConfig.dashboardLeftOffsetSmall;

        this.props.updateScreenWidth(window.innerWidth < 960 ? 960 - sideBarWidth : window.innerWidth - sideBarWidth);
    }


    /**
     * Range rule. Display range data
     *
     * @type {boolean}
     * @private
     */
    private rangeRule = true;

    /**
     * Range Period Updated
     *
     * @type {boolean}
     * @private
     */
    private rangePeriodUpdated: boolean;

    /**
     * Socket provider instance
     *
     * @type {SocketProvider}
     */
    private socket: SocketProvider;

    /**
     * Socket message Id
     *
     * @type {number}
     */
    private socketMessageId: number;

    /**
     * Socket message Id for range
     *
     * @type {number}
     */
    private socketMessageIdForRange: number;

    /**
     * Socket message Id for product
     *
     * @type {number}
     */
    private socketMessageIdProduct: number;


    /**
     *
     * Override/append sensor data flag
     *
     * @type {boolean}
     */
    private overrideSensorData: boolean;

    /**
     *
     * Override/append sensor data flag
     *
     * @type {boolean}
     */
    private overrideProductData: boolean;

    /**
     * Current state for opening sidebar
     */
    private readonly sideBarLogic: boolean;

    /**
     * default value repeat timeout
     * @type {number}
     * @private
     */
    private valueRepeatTimeout: number;

    /**
     * First load
     * 
     * @type {Boolean}
     * @private
     */
    private firstLoad = true;

    /**
     * First load range
     * 
     * @type {Boolean}
     * @private
     */
    private firstLoadRange = true;


    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        return (
            <React.Fragment>
                <Notification />
                <Header />
                <DashboardTimer>
                    <StructuralTree />
                </DashboardTimer>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { selection, brushRule, timerRule } = state.graphMinimapBrush,
        { online, lastUpdate, screenWidth } = state.dashboard,
        { currentPeriod, range } = state.graphPeriod,
        { refresh } = state.stateOverride,
        { lastUpdateProduct } = state.unitActiveProducts,
        { settings } = state.appSetting,
        { user } = state.auth;

    const arrOfUnit = selectUnitsForCurrentDashboard(state);

    return {
        brushSelection: selection,
        screenWidth,
        dashboardOnline: online,
        dashboardLastUpdate: lastUpdate,
        dashboardLastUpdateProduct: lastUpdateProduct,
        selectedPeriod: currentPeriod,
        sideBarMaxWidth: state.graphStructuralTreeVisibility.maxWidth,
        brushRule,
        timerRule,
        arrOfUnit,
        refreshState: refresh,
        user: user as IUser,
        settings,
        brushRange: range,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    updateScreenWidth: DashboardActions.updateScreenWidth,
    processSensorData: DashboardActions.processSensorsData,
    processSensorDataRange: DashboardActions.processSensorsDataRange,
    processSensorDataLoaded: DashboardActions.processSensorsDataLoaded,
    clearRangeData: DashboardActions.clearRangeData,
    updateSensorData: DashboardActions.updateSensorData,
    updateSensorDataRange: DashboardActions.updateSensorDataRange,
    togglePeriod: GraphActions.periodSelect,
    processProductData: ProductActions.processProductData,
    updateProductData: ProductActions.updateProductData,
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));
