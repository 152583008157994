import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGraphPeriodRange } from '../../core/selectors/graphPeriod/graphPeriodSelector';
import { selectDashboardOnline, selectScreenWidth } from '../../core/selectors/dashboard/dashboardSelector';
import { selectMaxWidthSideBar } from '../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { GraphActions } from '../../base/store/actions';
import moment from 'moment';

// eslint-disable-next-line react/prop-types
const DashboardTimer: React.FC = ({ children }) => {

    const dispatch = useDispatch();

    const brashSelection = useSelector(selectGraphPeriodRange),
        screenWidth = useSelector(selectScreenWidth),
        sidebarWidth= useSelector(selectMaxWidthSideBar),
        isActive = useSelector(selectDashboardOnline);

    const [range, setRange] = useState<number|null>(null);
    const [newDate, setNewDate] = useState<number>(0);
    const [seconds, setSeconds] = useState(-1);

    useEffect(() => {

        if (brashSelection) {

            const { startDate, endDate } = brashSelection;
            const range = new Date(endDate).getTime() - new Date(startDate).getTime();

            setRange(range);

            const tick = ((range) / 1000) / (screenWidth - sidebarWidth);
            setNewDate(tick * 40);
            setSeconds(Math.round(tick));
        }

    }, [brashSelection, screenWidth]);

    useEffect(() => {

        let interval: number | NodeJS.Timeout | null | undefined = null;

        if (isActive  && seconds > 0) {

            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);

        } else if (!isActive && seconds !== 0) {

            clearInterval(interval as unknown as NodeJS.Timeout);

            dispatch(GraphActions.minimapTimerRule(false));
        }
        if (seconds === 0 && range) {

            if (isActive) {

                const sendData = {
                    startDate: moment(new Date(new Date().getTime() + newDate)).subtract({ d: Math.round(range / (1000 * 60 * 60 * 24)) }).toDate(),
                    endDate: new Date(new Date().getTime() + newDate),
                    key: 'selection',
                };

                dispatch(GraphActions.rangeSelect(sendData, true));
            }

            dispatch(GraphActions.minimapTimerRule(true));
        }

        return () => clearInterval(interval as NodeJS.Timeout);

    }, [dispatch, isActive, seconds]);

    return (
        <>
            {children}
        </>
    );
};

export default DashboardTimer;
