import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { IGraphPreferences } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with unit API graph-preferences
 *
 * @class DashboardService
 */
export class GraphPreferencesService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/graph-preferences(/:id)';
    }

    /**
     * Update graph preferences
     *
     * @param {IGraphPreferences} graphPreferences
     *
     * @return {Promise<Object>}
     */
    update(graphPreferences: IGraphPreferences): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: graphPreferences.id }), graphPreferences, {
                    headers: this.headers,
                })
        );
    }
}
