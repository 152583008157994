import { createSelector } from 'reselect';
import { RootState } from '../../store';

const selectNotification = (state: RootState) => state.notification;

export const selectNotificationCount= createSelector(
    selectNotification,
    (notification) => notification.notifications.filter(value => value.isNew).length,
);

export const selectAllNotification= createSelector(
    selectNotification,
    (notification) => notification.notifications,
);
