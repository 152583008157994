import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

import { FormDialog, Button, DeleteDialog } from '../../../core/ui/components';
import {
    ExportDataActions,
    FormActions,
    HmiPlayerActions,
    statesActions,
    statesOverridesActions
} from '../../../core/actions';
import {
    IColumn,
    IOrder,
    IData,
    IStateSelectionState,
    IStatesOverride,
    IUnit,
    ISensor,
    IAuthState, IStateItem, IStateData,
} from '../../../core/interfaces';
import { ObjectHelper } from '../../../core/helpers/objectHelper';

import filterIcon from '../../../core/ui/assets/images/icons/filter.svg';
import { ReactComponent as Export } from '../../../core/ui/assets/images/icons/export.svg';

import './style/StateTable.scss';
import { isIE, isMobile } from 'react-device-detect';
import { RootState } from '../../../core/store';
import { GraphActions } from '../../store/actions';
import { ListStickyHeader } from '../index';
import { selectStateDataBySensorId } from '../../../core/selectors/stateChart/stateDataSelector';

interface IProps {
    auth: IAuthState;
    title: string;
    data: IStateItem[];
    style: any;
    stateSelection: IStateSelectionState;
    barToggleTableView: (visible: boolean, top: number) => void;
    toggleForm: (opened: boolean, name?: string) => void;
    storeFormData: (data: any) => void;
    removeStateOverride: (stateOverride: IStatesOverride) => void;
    unit: IUnit;
    exportStateData: (from: string, to: string, controllerId: string, sensorId: string, name: string) => void;
    selection?: Date[];
    currentSensor: IStateData;
    toggleHMI: (arg: boolean) => void;
    setSchema: (arg: any) => void;
    setRealTime: (arg: boolean) => void;
}

interface IState {
    search: string | null
    order: IOrder | null;
    dialogOpened: boolean;
    filterKey: string | null;
    filterOrder: 'asc' | 'desc';
    selectedFilters: any;
    removeId: any | null;
    backToStart: boolean;
}


/**
 * Add format duration function
 */
momentDurationFormatSetup.bind(moment());

/**
 * A table view for a state chart
 *
 * @class StateTable
 */
class StateTable extends React.PureComponent<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: any) {

        super(props);

        const { t, auth } = this.props;

        const contextMenu = [];

        if (auth.rbac.can('state-chart:edit-state')) {

            contextMenu.push({
                title: t('EDIT'),
                action: this.editState.bind(this),
                color: '',
            });
        }

        if (auth.rbac.can('state-chart:delete-state')) {

            contextMenu.push({
                title: t('DELETE'),
                action: this.removeConfirmation.bind(this),
                color: 'red',
                disabled: (row: IData) => {

                    return !row.id;
                },
            });
        }

        this.columns = [
            {
                name: 'startTime',
                label: t('START'),
                sortable: true,
                width: '11.32%',
                mutate: (value: undefined, row: IData): any => {

                    return (<React.Fragment>{moment(this.checkStartTime(row.startTime)).format('HH:mm | DD.MM.YY')}
                        <div className="color-square" style={{ backgroundColor: row.color ? row.color : 'inherit' }} />
                            </React.Fragment>);
                },
                footerFieldName: `${t('TOTAL_DURATION')}:`,
            },
            {
                name: 'endTime',
                label: t('END'),
                sortable: true,
                width: '11.32%',
                mutate: (value: undefined, row: IData): any => {

                    return moment(this.checkEndTime(row.endTime)).format('HH:mm | DD.MM.YY');
                },
            },
            {
                name: 'duration',
                label: t('DURATION'),
                sortable: true,
                width: '8.11%',
                mutate: (value: undefined, row: IData): any => {

                    if (moment(row.endTime).diff(moment(this.checkStartTime(row.startTime)), 'seconds') < 60) {

                        return moment.duration(new Date(this.checkEndTime(row.endTime)).getTime() - new Date(this.checkStartTime(row.startTime)).getTime()).format('s[s]');
                    }

                    return moment.duration(new Date(this.checkEndTime(row.endTime)).getTime() - new Date(this.checkStartTime(row.startTime)).getTime()).format('h[h] m[m]');
                },
                footerMutate: (rows: IData[]): any => {
                    return moment.duration(this.totalDuration(rows)).format('d[d] h[h] m[m]');
                },
            },
            {
                name: 'stateCategoryName',
                label: t('CATEGORY'),
                sortable: true,
                action: {
                    icon: filterIcon,
                    handler: () => {

                        this.showFilter('stateCategoryName');
                    },
                },
                width: '18%',
            },
            {
                name: 'causeName',
                label: t('CAUSE'),
                sortable: true,
                action: {
                    icon: filterIcon,
                    handler: () => {

                        this.showFilter('causeName');
                    },
                },
                width: '18%',
            },
            {
                name: 'comment',
                label: t('COMMENT'),
                sortable: true,
                width: '33%',
                menu: contextMenu.length ? contextMenu : undefined,
            },
        ];

        this.state = {
            search: null,
            order: null,
            dialogOpened: false,
            filterKey: null,
            filterOrder: 'asc',
            selectedFilters: {
                stateCategoryName: [],
                causeName: [],
            },
            removeId: null,
            backToStart: false,
        };

        this.exportStateData = this.exportStateData.bind(this);
        this.setBackToStart = this.setBackToStart.bind(this);
    }

    componentDidMount() {

        const { setRealTime, setSchema, toggleHMI } = this.props;

        setRealTime(false);
        setSchema(null);
        toggleHMI(true);
    }


    /**
     * Data table columns
     *
     * @type {IColumn[]}
     */
    private readonly columns: IColumn[];


    /**
     * Calculated total duration
     *
     * @param {IData[]} rows
     * @return {number}
     */
    totalDuration(rows: IData[]) {

        let duration = 0;

        rows.forEach(value => {
            duration += value.duration;
        });

        return duration;
    }

    /**
     * Checking start time for range compliance
     *
     * @param {Date} startTime
     * @return {Date}
     */
    checkStartTime(startTime: Date): Date {

        const { selection } = this.props;

        if (selection) {

            if (new Date(startTime) < selection[0]) {
                return selection[0];
            }
        }

        return startTime;
    }

    /**
     * Checking end time for range compliance
     *
     * @param {Date} endTime
     * @return {Date}
     */
    checkEndTime(endTime: Date): Date {

        const { selection } = this.props;

        if (selection) {

            if (new Date(endTime) > selection[1]) {

                return selection[1];
            }

        }
        return endTime;
    }

    /**
     * Set search and order settings
     *
     * @param {string} search
     * @param {IOrder} order
     */
    setSearchOrder(search: string, order: IOrder) {

        this.setState({
            search: search,
            order: order,
        });
    }

    /**
     * Hide state table
     */
    hideTable() {

        this.props.barToggleTableView(true, 0);
    }

    /**
     * Show filter dialog
     *
     * @param {string} column
     */
    showFilter(column: string) {

        this.setState({
            filterKey: column,
            dialogOpened: true,
        });
    }

    /**
     * Hide filter dialog
     */
    hideFilter() {

        this.setState({
            dialogOpened: false,
        });
    }

    /**
     * Set order of the filter list
     *
     * @param {string} order
     */
    setFilterOrder(order: 'asc' | 'desc') {

        this.setState({
            filterOrder: order,
        });
    }

    /**
     * Set back to start condition
     * 
     * @param {boolean} value 
     */
    setBackToStart(value: boolean) {
        this.setState({
            backToStart: value,
        });
    }

    /**
     * Get filter items list
     *
     * @return {IData[]}
     */
    getFilterItems() {

        const { filterKey, filterOrder, selectedFilters } = this.state,
            { data } = this.props;

        if (filterKey) {

            const items = data.map((d: IData, index, array) => {

                return { name: d[filterKey], selected: selectedFilters[filterKey].indexOf(d[filterKey]) !== -1 };

            }).filter((thing, index, self) =>

                index === self.findIndex((t) => (
                    t.name === thing.name && thing.name
                ))
            );

            for (const item of items) {

                item.selected = selectedFilters[filterKey].findIndex((value: any) => value.name === item.name) !== -1;
            }

            items.sort((a: IData, b: IData) => {

                if (a.name < b.name) {

                    return filterOrder === 'asc' ? -1 : 1;
                }

                if (a.name > b.name) {

                    return filterOrder === 'asc' ? 1 : -1;
                }

                return 0;
            });

            return items;
        }

        return [];
    }

    /**
     * Check/uncheck filter item handler
     *
     * @param {IData} item
     */
    filterCheckHandler(item: IData) {

        const { filterKey, selectedFilters } = this.state;

        if (filterKey) {

            const index = selectedFilters[filterKey].findIndex((value: any) => value.name === item.name);

            if (index !== -1) {

                selectedFilters[filterKey].splice(index, 1);
            } else {

                selectedFilters[filterKey].push(item);
            }

            this.setState({
                selectedFilters: selectedFilters,
                backToStart: true,
            });
        }
    }

    /**
     * Apply filters and order settings to data
     *
     * @return {IData[]}
     */
    getFilteredData() {

        let data = [...this.props.data];

        const { search, order, selectedFilters } = this.state;

        data.map((value: any) => {

            value.duration = new Date(this.checkEndTime(value.endTime)).getTime() - new Date(this.checkStartTime(value.startTime)).getTime();

            if (new Date(value.startTime) !== new Date(value.endTime)) {
                return value;
            }
        });

        if (order && order.column) {

            const paths: any = {
                'startTime': 'startTime',
                'endTime': 'endTime',
                'duration': 'duration',
                'stateCategoryName': 'stateCategoryName',
                'causeName': 'causeName',
                'comment': 'comment',
            };

            data.sort((a: IData, b: IData) => {

                const valueA = ObjectHelper.getPathValue(paths[order.column], a),
                    valueB = ObjectHelper.getPathValue(paths[order.column], b);

                if (valueA < valueB || !valueA) {

                    return order.dir === 'asc' ? -1 : 1;
                }

                if (valueA > valueB || !valueB) {

                    return order.dir === 'asc' ? 1 : -1;
                }

                return 0;
            });
        }

        if (selectedFilters.stateCategoryName.length > 0) {

            data = data.filter((d: IData) => d.stateCategoryName && selectedFilters.stateCategoryName.findIndex((value: any) => value.name === d.stateCategoryName) !== -1);
        }

        if (selectedFilters.causeName.length > 0) {

            data = data.filter((d: IData) => d.causeName && selectedFilters.causeName.findIndex((value: any) => value.name === d.causeName) !== -1);
        }

        if (search) {

            data = data.filter((d: IData) => d.causeName && d.causeName.toLowerCase().indexOf(search.toLowerCase()) !== -1);
        }

        return data as IData[];
    }

    /**
     * Open edit state form and fill it with data
     *
     * @param {IData} state
     */
    editState(state: IData) {
        this.props.storeFormData({ data: state, unit: this.props.unit }); //TODO: do something about unit

        this.props.toggleForm(false, 'stateForm');
    }

    /**
     * Confirm remove state
     *
     * @param {IData} state
     */
    removeConfirmation(state: IData) {

        this.setState({
            removeId: state,
        });
    }

    /**
     * Remove state by ID
     *
     * @param {number} stateId
     */
    removeState() {
        const { removeId } = this.state;

        if (removeId && removeId.id) {

            this.props.removeStateOverride(removeId);
        }

    }

    /**
     * Clean up state after closing delete state confirmation dialog
     */
    onDeleteDialogClose() {

        this.setState({
            removeId: null,
        });
    }


    exportStateData() {

        const { currentSensor, selection, title, unit  } = this.props;

        if (currentSensor) {

            const sensorId = String(currentSensor.sensorId).split('.');
            const sensorData = unit.data?.find((sensor: ISensor) => sensor.controllerId === sensorId[0] && sensor.sensorId === sensorId[1]);

            if (sensorData && selection) {

                this.props.exportStateData(moment(selection[0]).format(), moment(selection[1]).format(), sensorData.controllerId, sensorData.sensorId, title + ` ${sensorData.name}`);
            }

        }
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { title, t, style, stateSelection, unit } = this.props,
            { dialogOpened, removeId, backToStart } = this.state;

        return (
            <React.Fragment>
                <div className="state-table-wrapper" style={style}
                     onClick={event => event.stopPropagation()}
                     onTouchStart={event => event.stopPropagation()}
                     onMouseMove={event => event.stopPropagation()}
                >
                    <div className="state-table"
                         style={{ height: isIE ? 'calc(100vh - 112px)' : `calc(100vh - ${style.top})px` }}
                    >
                        <div className="state-table-header-component">
                            <Button size={'small'} className="text-btn" onClick={this.hideTable.bind(this)}
                                    color={'primary'}
                            >{t('CLOSE_TABLE_X')}
                            </Button>
                            <h2 className="content-title">{title.length > 0 ? title : unit.name}</h2>
                            <Button color={'primary'} onClick={this.exportStateData}
                                    className="export-btn"
                            ><Export />
                            </Button>
                        </div>
                        <ListStickyHeader
                            columns={this.columns}
                            data={this.getFilteredData()}
                            refresh={this.setSearchOrder.bind(this)}
                            searchable
                            searchPlaceholder={'CAUSE_SEARCH'}
                            selectedParam={'startTime'}
                            searchableField={'causeName'}
                            maxHeightSlider={`calc(100vh - ${parseInt(style.top) + (isMobile ? 260 : 80)}px - 175px)`}
                            selected={stateSelection && stateSelection.state ? stateSelection.state : null}
                            paginationEnable
                            footerInfoField
                            labelRowsPerPage={`${t('STATE_PER_PAGE')}:`}
                            backToStart={backToStart}
                            setBackToStart={this.setBackToStart}
                        />
                    </div>
                </div>
                <FormDialog
                    open={dialogOpened}
                    onClose={this.hideFilter.bind(this)}
                    name="filter-dialog"
                    onClick={event => event.stopPropagation()}
                    onTouchStart={event => event.stopPropagation()}
                >
                    <div className="subheader">{t('SORT')}</div>
                    <div className="filter-buttons">
                        <Button size="small" variant="outlined"
                                onClick={() => this.setFilterOrder('asc')}
                        >{t('ASCENDING')}
                        </Button>
                        <Button size="small" variant="outlined"
                                onClick={() => this.setFilterOrder('asc')}
                        >{t('DESCENDING')}
                        </Button>
                    </div>
                    <div className="subheader">{t('FILTER')}</div>
                    <div className="filter-list">
                        <FormControl component="fieldset">
                            <FormGroup>
                                {this.getFilterItems().map((item: IData, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={
                                            <Checkbox
                                                checked={item.selected}
                                                onChange={() => this.filterCheckHandler(item)}
                                                color="primary"
                                                icon={<span className="icon" />}
                                                checkedIcon={<span className="icon checked" />}
                                            />
                                        }
                                        label={item.name}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </div>
                </FormDialog>

                <DeleteDialog
                    open={removeId !== null}
                    removeId={removeId}
                    heading={t('REMOVE_STATE_Q')}
                    body={t('THIS_ACTION_WILL_DELETE_STATE_AND_CANNOT_BE_UNDONE')}
                    onAccept={this.removeState.bind(this)}
                    onClose={this.onDeleteDialogClose.bind(this)}
                />
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { auth, stateSelection, graphMinimapBrush, graphBarTableVisibility } = state;

    const { sensorId } = graphBarTableVisibility;
    const { selection } = graphMinimapBrush;

    const stateData = selectStateDataBySensorId(state, String(sensorId));

    let tableState: IStateItem[] = [];

    if (stateData && selection) {

        const [from, to] = selection;

        const states = stateData.states.filter(value => new Date(value.endTime) > new Date(from) && (new Date(value.endTime) < new Date(to) || new Date(value.startTime) < new Date(to)));

        if (tableState !== states) {

            tableState = states;

        }
    }

    return {
        auth,
        stateSelection,
        selection: graphMinimapBrush.selection,
        data: tableState,
        currentSensor: stateData,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    barToggleTableView: GraphActions.barToggleTableView,
    toggleForm: FormActions.toggle,
    storeFormData: statesActions.storeEditData,
    removeStateOverride: statesOverridesActions.delete,
    exportStateData: ExportDataActions.exportState,
    toggleHMI: GraphActions.toggleHMI,
    setSchema: HmiPlayerActions.setSchema,
    setRealTime: HmiPlayerActions.setRealTime,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StateTable));