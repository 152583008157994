import React, { useCallback } from 'react';
import { Dialog as MuiDialog, DialogProps, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import styled from 'reshadow';

import Button from '../Button/Button';

const styles = require('./Dialog.scss');

/**
 * An action confirmation dialog
 *
 * @param {boolean} open Dialog open/close switcher
 * @param {string} heading Dialog heading text
 * @param {string} body Dialog body text
 * @param {function} onAccept Callback when user confirms an action
 * @param {function} onClose Callback when user denies an action
 * @param {object} props The rest props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const ConfirmDialog: React.FC<ConfirmDialogProps> = (
    {
        open,
        heading,
        body,
        onAccept,
        onClose,
        container ,
        ...props
    }
) => {

    const { t } = useTranslation();

    const handleClose = useCallback(
        e => {
            e.stopPropagation();
            onClose && onClose({}, 'backdropClick');
        },
        [onClose]
    );

    return styled(styles)(
        <MuiDialog
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
            open={open}
            onClose={handleClose}
            {...props}
            disableEnforceFocus
            container={container}
        >
            <div className="dialog-popup">
                <h2 id="dialog-title" dangerouslySetInnerHTML={{ __html: heading }} />
                <p id="dialog-description" dangerouslySetInnerHTML={{ __html: body ? body : '' }} />
                <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="confirm-popup-cancel-button"
                            type="button"
                            color={'primary'}
                            onClick={handleClose}
                            disableFocusRipple
                        >{t('CANCEL')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Button
                            id="confirm-popup-confirm-button"
                            type="button"
                            color={'primary'}
                            disableFocusRipple
                            onClick={(e) => {

                                onAccept();

                                handleClose(e);
                            }}
                            autoFocus
                        >{t('CONFIRM')}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </MuiDialog>
    );
};

interface IProps {
    heading: string;
    body?: string;
    onAccept: () => void;
    container?:  Element | React.Component<any, {}, any> | (() => (Element | React.Component<any, {}, any> | null)) | null | undefined
}

export type ConfirmDialogProps = IProps & DialogProps;

export default ConfirmDialog;