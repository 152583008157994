import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import { AppRoles } from '../../../rbac/roles';
import { UserActions, FormActions } from '../../../core/actions';
import { IUser, IData, IError } from '../../../core/interfaces';
import { Button, TextInput, Select, ConfirmDialog } from '../../../core/ui/components';
import { RootState } from '../../../core/store';

interface IFormValues {
    id?: number;
    email: string;
    username: string;
    role: string;
}

interface IProps {
    store: (user: IUser) => void;
    update: (user: IUser) => void;
    user: IUser | null;
    errors: IError;
    formOpened: boolean;
    toggleForm: (opened: boolean) => void;
    model: IData | null;
}

interface IState {
    initialValues: IFormValues;
    formValues: null | IFormValues;
}

/**
 * User add/edit form
 *
 * @class UserForm
 */
class UserForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t } = this.props;

        this.state = {
            initialValues: {
                email: '',
                username: '',
                role: AppRoles.STANDARD,
            },
            formValues: null,
        };

        this.validationSchema = yup.object().shape({
            email: yup
                .string()
                .trim()
                .email(t('EMAIL_MUST_BE_A_VALID_EMAIL'))
                .required(t('EMAIL_IS_REQUIRED')),
            username: yup
                .string()
                .trim()
                .required(t('USERNAME_IS_REQUIRED')),
            role: yup
                .string()
                .required(t('ROLE_IS_REQUIRED')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.onCancelDialog = this.onCancelDialog.bind(this);
    }

    /**
     * Component props update handler
     *
     * @param {IProps} prevProps Updated component properties
     */
    componentDidUpdate(prevProps: IProps) {

        const { model, user } = this.props;

        if (prevProps.model !== model && model) {

            this.setState({
                initialValues: {
                    id: parseInt(model.id.toString()),
                    email: model.email.toString() || '',
                    username: model.username.toString() || '',
                    role: model.role.toString() || AppRoles.STANDARD,
                },
            });
        }

        if (prevProps.user !== user && user) {

            this.handleCancel();
        }
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IFormValues>>;


    /**
     * User form submit handler
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        if (!values.id) {

            this.props.store({
                username: values.username,
                email: values.email,
                role: values.role,
            });

        } else {

            this.setState({
                formValues: {
                    id: values.id,
                    username: values.username,
                    email: values.email,
                    role: values.role,
                },
            });

        }
    }

    /**
     * On confirming a popup
     */
    onConfirmDialog() {

        const { formValues } = this.state;

        if (formValues) {

            this.props.update(formValues);

            this.setState({ formValues: null });
        }
    }

    /**
     * On canceling a popup
     */
    onCancelDialog() {

        this.setState({ formValues: null });

    }

    /**
     * User form cancel handler
     */
    handleCancel() {

        this.props.toggleForm(true);

        this.setState({
            initialValues: {
                email: '',
                username: '',
                role: AppRoles.STANDARD,
            },
        });
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, errors = {} } = this.props,
            { initialValues } = this.state;

        const rolesSelectValues = [
            { value: AppRoles.SUPER, label: t('SUPER_USER') },
            { value: AppRoles.ADVANCED, label: t('ADVANCED_USER') },
            { value: AppRoles.STANDARD, label: t('USER') },
        ];

        return (
            <div className="user-form">
                <h3>{t(initialValues.id ? 'EDIT_USER' : 'ADD_USER')}</h3>

                {errors && errors.field === 'email' && <div className="common-error">{t('THE_EMAIL_HAS_ALREADY_BEEN_TAKEN')}</div> }

                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form onSubmit={props.handleSubmit} noValidate style={{ paddingBottom: 80 }}>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.username ? (props.errors.username || errors.field === 'username') ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                label={t('USERNAME')}
                                value={props.values.username}
                                name="username"
                                type="text"
                                placeholder="John Doe"
                                inputProps={{ maxLength: 50 }}
                            >
                                {((props.touched.username && props.errors.username) || errors.username) &&
                                <div className="validation-massage">{props.errors.username || errors.username}</div>
                                }
                            </TextInput>
                            <TextInput
                                className={
                                    'form-field ' +
                                    (props.touched.email ? (props.errors.email || errors.field === 'email' ) ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                label={t('EMAIL')}
                                value={props.values.email}
                                name="email"
                                type="email"
                                placeholder="example@email.com"
                                inputProps={{ maxLength: 50 }}
                            >
                                {((props.touched.email && props.errors.email) || errors.field === 'email') &&
                                <div className="validation-massage">{props.errors.email || errors.field === 'email'}</div>
                                }
                            </TextInput>
                            <Select
                                className={
                                    'form-field ' +
                                    (props.touched.role ? (props.errors.role || errors.role) ? 'error-field' : 'success-field' : '')
                                }
                                onChange={props.handleChange}
                                label={t('ROLE')}
                                value={props.values.role}
                                options={rolesSelectValues}
                                name="role"
                            >
                                {((props.touched.role && props.errors.role) || errors.role) &&
                                <div className="validation-massage">{props.errors.role || errors.role}</div>
                                }
                            </Select>
                            <div className="button-row">
                                <Button type="button" color="primary" onClick={this.handleCancel} style={{ marginRight: 30 }}>
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="secondary">
                                    {t(initialValues.id ? 'SAVE_CHANGES' : 'ADD')}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
                {this.state.formValues ?
                    <ConfirmDialog
                        heading={t('EDIT_USER_Q')}
                        onAccept={this.onConfirmDialog}
                        onClose={this.onCancelDialog}
                        open={Boolean(this.state.formValues)}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { user, errors } = state.userChange,
        { formOpened } = state.form;

    return {
        user,
        errors,
        formOpened,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    store: UserActions.store,
    update: UserActions.update,
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserForm));