import React, { Component } from 'react';
import { ISensor, IUnit } from '../../../core/interfaces';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';
import * as yup from 'yup';
import { ListItemText, Tooltip as TooltipNoStyle, withStyles } from '@material-ui/core';
import ActionFieldForForm from './ActionFieldForForm';
import { Formik } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';

interface IFormListProps {
    sensor: ISensor;
    initialValues: ISensor;
    currentUnit?: IUnit;
    enableInput: {
        um: boolean;
        comment: boolean;
        pIdCode: boolean;
        nameTargetValue: string | null;
    };
    handleOnKeyPress: (event: React.KeyboardEvent<HTMLDivElement>) => void;
    handleChangeClickInput: (nameInput: string, status: boolean, id?: number) => void;
    handleSubmit: (values: ISensor) => void;
}

interface IFormListState {
    initialValues: ISensor;
    validationSchema: ObjectSchema<Shape<ValidateOptions, { name: string }>>;
    enableReinitialize: boolean;
    changedData: ISensor | null;
}

/**
 * Base styles for the Components
 *
 * @type {StylesHook<Styles<Theme, {}, string>>}
 */
const Tooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: 300,
        zIndex: 1,
    },
    popper: {
        zIndex: 1200,
    },
}))(TooltipNoStyle);

class FormList extends Component<IFormListProps & WithTranslation, IFormListState> {

    constructor(props: IFormListProps & WithTranslation) {
        super(props);

        const { initialValues } = this.props;

        this.state = {
            initialValues: initialValues,
            validationSchema: yup.object().shape({
                id: yup.number(),
                um: yup.string(),
                pitCode: yup.string()
                    .max(15),
                comment: yup.string(),
                latestValue: yup.string().transform(value => (value === null ? '' : value)),
                name: yup.string()
                    .trim()
                    .min(1)
                    .max(150),
            }),
            enableReinitialize: true,
            changedData: null,
        };
    }

    /**
     * Handler for the double click on the input
     *
     * @param { React.MouseEvent<HTMLDivElement, MouseEvent> } event
     */
    handleOnDoubleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {

        event.preventDefault();

        this.props.handleChangeClickInput(event.currentTarget.id, false);

    }

    /**
     * Handler for the touchstart
     *
     * @param { React.MouseEvent<HTMLDivElement, MouseEvent> } event
     */
    handleOnTouchStart(event: React.TouchEvent<HTMLDivElement>) {

        this.props.handleChangeClickInput(event.currentTarget.title, false);

    }

    /**
     * Keypress handler for confirmation input
     *
     * @param {String} nameInput
     * @param {React.KeyboardEvent<Element>} event
     */
    handleChangeKeyPressInput(nameInput: 'um' | 'comment', event: React.KeyboardEvent<HTMLElement>) {

        if (event.key === 'Enter') {

            const currentEnableInput = this.props.enableInput;

            currentEnableInput[nameInput] = true;

            // this.setState({ enableInput: currentEnableInput });
        }
    }


    render() {
        const { t } = this.props;

        const { initialValues, validationSchema } = this.state;

        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={this.props.handleSubmit}
            >{props => (
                <form onSubmit={props.handleSubmit} noValidate>

                    <ListItemText className={'description__id'}>
                        {props.values.controllerId.slice(props.values.controllerId.length - 3)}.{props.values.sensorId}
                    </ListItemText>
                    <ListItemText
                        className={'description__um'}
                        id="um"
                        onDoubleClick={this.handleOnDoubleClick}
                        onKeyPress={this.props.handleOnKeyPress}
                    >
                        {this.props.enableInput.pIdCode ?
                            <Tooltip
                                title={props.values.pIdCode ? props.values.pIdCode : ''}
                                enterDelay={500}
                                style={{ zIndex: 1200 }}
                                enterTouchDelay={200}
                                leaveTouchDelay={2000}
                            >
                                <span className={'value'}>{props.values.pIdCode}</span>
                            </Tooltip> :
                            <ActionFieldForForm
                                formProps={props}
                                enableInput={this.props.enableInput.pIdCode}
                                name={'pIdCode'}
                                placeholder={t('PID_CODE')}
                                handleChangeClickInput={this.props.handleChangeClickInput}
                                handleSubmit={this.props.handleSubmit}
                                maxWidth={86}
                            />
                        }
                    </ListItemText>
                    <ListItemText
                        className={'description__um'}
                        id="um"
                        onDoubleClick={this.handleOnDoubleClick}
                        onKeyPress={this.props.handleOnKeyPress}
                    >
                        {this.props.enableInput.um ?
                            <Tooltip
                                title={props.values.um ? props.values.um : ''}
                                enterDelay={500}
                                style={{ zIndex: 1200 }}
                                enterTouchDelay={200}
                                leaveTouchDelay={2000}
                            >
                                <span className={'value'}>{props.values.um}</span>
                            </Tooltip> :
                            <ActionFieldForForm
                                formProps={props}
                                enableInput={this.props.enableInput.um}
                                name={'um'}
                                placeholder={'UM'}
                                handleChangeClickInput={this.props.handleChangeClickInput}
                                handleSubmit={this.props.handleSubmit}
                                maxWidth={86}
                            />
                        }
                    </ListItemText>
                    <Tooltip
                        title={props.values.comment ? props.values.comment : ''}
                        enterDelay={500}
                        enterTouchDelay={200}
                        leaveTouchDelay={2000}
                    >
                        <ListItemText className={'description__comment'}
                                      id="comment"
                                      onDoubleClick={this.handleOnDoubleClick}
                                      onTouchStart={this.handleOnTouchStart}
                                      onKeyPress={this.props.handleOnKeyPress}
                        >
                            {this.props.enableInput.comment ?

                                <span className={'value'}>{props.values.comment}</span>
                                :
                                <ActionFieldForForm
                                    formProps={props}
                                    enableInput={this.props.enableInput.comment}
                                    name={'comment'}
                                    placeholder={t('COMMENT')}
                                    handleChangeClickInput={this.props.handleChangeClickInput}
                                    handleSubmit={this.props.handleSubmit}
                                />
                            }
                        </ListItemText>
                    </Tooltip>
                    <ListItemText className={'description__value'}>
                        {props.values.latestValue}
                    </ListItemText>

                </form>
            )}
            </Formik>
        );
    }
}

export default withTranslation()(FormList);