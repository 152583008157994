import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import {dashboardConstants, monitoringTreeConstants} from '../constants';
import {
    IDashboard,
    IDashboardDuplicate,
    IErrors,
    IFilter, IHistogramData,
    IJoin, IMonitoringTree,
    IOrder,
    IUserTabBar,
} from '../interfaces';
import { DashboardService, UserTabBarService, GetMonitoringTreeService } from '../services';
import { history } from '../../helpers';
import { ObjectHelper } from '../helpers/objectHelper';
import store from '../store';
import { selectMonitoringTreeCollection } from '../selectors/monitoringTree/monitoringTreeCollectionSelector';
import { selectMonitoringTree } from '../selectors/monitoringTree/monitoringTreeSelector';
import { selectMonitoringTreeState } from '../../modules/Hr/store/selectors/monitoringTreeSelector';
import {
    selectAllSensorDataForHistogram,
    selectAllSensorDataForHistogramRange,
    selectOverrideSensors,
    selectOverrideSensorsRange,
    selectDashboardOnline,
} from '../selectors/dashboard/dashboardSelector';

/**
 * Dashboard related actions
 *
 * @type {Object}
 */
export const DashboardActions = {

    /**
     *
     * @param { string} search
     * @param { IOrder } order
     * @param { IJoin } join
     * @param { IFilter } filter
     *
     * @param trackerLogic
     * @return {Promise<Object>}
     */
    list: (search = '', order: IOrder = { column: 'id', dir: 'asc' }, join?: IJoin, filter?: IFilter, trackerLogic?: boolean):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const success = (dashboards: IDashboard[]) => {
                return {
                    type: dashboardConstants.LIST_SUCCESS,
                    dashboards,
                };
            },
            failure = ({ errors }: IErrors) => {

                return {
                    type: dashboardConstants.LIST_FAILURE,
                    errors,
                };
            },
            service = new UserTabBarService();

        return (dispatch) => {

            service.list(search, order, join, filter, trackerLogic).then(({ data }: AxiosResponse) => {

                dispatch(success(data));

            }).catch((error) => {

                dispatch(failure(service.errorHandler(error)));
            });
        };

    },

    /**
     * Create new dashboard
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<Object>}
     */
    store: (dashboard: IDashboard):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (dashboard: IDashboard) => {

            return {
                type: dashboardConstants.STORE_SUCCESS,
                dashboard,
            };
// eslint-disable-next-line
        }, failure = ( {errors}: IErrors ) => {

            return {
                type: dashboardConstants.STORE_FAILURE,
                errors,
            };

        }, service = new DashboardService();

        return (dispatch) => {

            service.create(dashboard).then(({ data }: AxiosResponse) => {

                history.push('/');

                dispatch(success(data));

            }).catch(error => {

                dispatch(failure(service.errorHandler(error)));

            });

        };
    },

    /**
     * Duplacate new dashboard
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<Object>}
     */
    duplicate: (dashboard: IDashboardDuplicate):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (dashboard: IDashboardDuplicate) => {

            return {
                type: dashboardConstants.DUPLICATE_SUCCESS,
                dashboard,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: dashboardConstants.DUPLICATE_FAILURE,
                errors,
            };

        }, service = new DashboardService();

        return (dispatch) => {

            service.duplicate(dashboard).then(({ data }: AxiosResponse) => {

                dispatch(success(data));

            }).catch(error => {

                dispatch(failure(service.errorHandler(error)));

            });

        };
    },

    /**
     * Dragged UserTabBar
     *
     * @param { IUserTabBar }  userTabBar
     *
     * @return {Promise<Object>}
     */
    dragged: (userTabBar: IUserTabBar):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (userTabBar: IUserTabBar) => {

            return {
                type: dashboardConstants.DRAGGED_UPDATE_SUCCESS,
                userTabBar,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: dashboardConstants.DRAGGED_UPDATE_FAILURE,
                errors,
            };

        }, service = new UserTabBarService();

        return (dispatch) => {

            service.update(userTabBar).then(({ data }: AxiosResponse) => {

                dispatch(success(data));

            }).catch(error => {

                dispatch(failure(service.errorHandler(error)));

            });

        };
    },

    /**
     * Update user account
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<Object>}
     */
    update: (dashboard: IDashboard):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void =>{

        //Action creators
        const success = (dashboard: IDashboard) => {

            return {
                type: dashboardConstants.UPDATE_SUCCESS,
                dashboard,
            };

        }, failure = ({ errors }: IErrors) => {

            return {
                type: dashboardConstants.UPDATE_FAILURE,
                errors,
            };

        }, service = new DashboardService();

        return (dispatch) => {

            service.update(dashboard).then(({ data }: AxiosResponse) => {

                dispatch(success(data));

            }).catch(error => {

                dispatch(failure(service.errorHandler(error)));

            }).finally(() => service.list('', { column: 'id', dir: 'asc' }));
        };
    },

    /**
     * Select a dashboard page
     *
     * @param {IDashboard} dashboard
     *
     * @return {Promise<Object>}
     */
    select: (dashboard: IDashboard):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (dashboard: IDashboard) => {

            return {
                type: dashboardConstants.SELECT,
                dashboard,
            };

        }, loadMonitoringTree = (data: IMonitoringTree) => {

            return {
                type: monitoringTreeConstants.UPDATE_LOCAL_MONITORING_TREE_SUCCESS,
                data,
            };

        }, loadMonitoringTreeFailure = ({ errors }: IErrors) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREE_FAILURE,
                errors,
            };

        }, loadMonitoringTreePart = (data: IMonitoringTree, dashboardId: number) => {

            return {
                type: monitoringTreeConstants.GET_MONITORING_TREES_PART_SUCCESS,
                data,
                dashboardId,
            };

        };

        return (dispatch) => {

            dispatch(success(dashboard));

            if (dashboard.id !== 0) {

                //Try to find dashboard data in the storage
                const dashboardId = dashboard.id.toString();

                const monitoringTrees = selectMonitoringTreeCollection(store.getState());

                const monitoringTree = (monitoringTrees && Object.prototype.hasOwnProperty.call(monitoringTrees, dashboardId)) ?
                    ObjectHelper.getKeyValue(monitoringTrees, dashboardId) : null;

                if (monitoringTree) {

                    //dashboard data has been found in the storage. Load it to the selected tab
                    dispatch(loadMonitoringTree(monitoringTree));

                } else {

                    //no dashboard data found in the storage. Request it from server
                    const monitoringTreeService = new GetMonitoringTreeService();

                    monitoringTreeService.get(dashboard.id)
                        .then(({ data }: AxiosResponse) => {

                            dispatch(loadMonitoringTree(data));
                            dispatch(loadMonitoringTreePart(data, dashboard.id));

                        })
                        .catch((error) => {

                            dispatch(loadMonitoringTreeFailure(monitoringTreeService.errorHandler(error)));
                        });
                }
            }
        };
    },

    /**
     *
     */
    remove: (dashboard: IDashboard):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        //Action creators
        const success = (dashboard: IDashboard) => {

            return {
                type: dashboardConstants.DELETE_SUCCESS,
                dashboard,
            };
        }, failure = ({ errors }: IErrors) => {

            return {
                type: dashboardConstants.DELETE_FAILURE,
                errors,
            };

        }, service = new DashboardService();

        return (dispatch) => {

            service.remove(dashboard)
                .then(({ data }: any) => {

                    dispatch(success(data));

                })
                .catch((error) => {

                    dispatch(failure(service.errorHandler(error)));
                });
        };
    },

    /**
     * Receive data from sensors and put it into storage
     *
     * @param {array} data
     *
     * @return {Promise<Object>}
     */
    processSensorsData: (data: IHistogramData[]):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const receiveData = (data: IHistogramData[], overrideSensors: number[]) => {

            return {
                type: dashboardConstants.RECEIVE_SENSORS_DATA,
                sensorsData: data,
                overrideSensors,
            };
        }, service = new DashboardService();

        return (dispatch) => {

            const currentData = selectAllSensorDataForHistogram(store.getState());

            const realTime = selectDashboardOnline(store.getState());

            const overrideSensors = realTime ? selectOverrideSensors(store.getState()) : [];

            const [state] = data;

            service.updateLocalSensorData(data, currentData, overrideSensors).then(value => {

                const index = overrideSensors.findIndex(id => id === state.id);

                if (index !== -1) {
                        
                    overrideSensors.splice(index, 1);
                }

                dispatch(receiveData(value, overrideSensors));
            });
        };
    },

    /**
     * Receive data range from sensors and put it into storage
     *
     * @param {array} data
     *
     * @return {Promise<Object>}
     */
    processSensorsDataRange: (data: IHistogramData[]):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const receiveData = (data: IHistogramData[], overrideSensorsRange: number[]) => {

            return {
                type: dashboardConstants.RECEIVE_SENSORS_DATA_RANGE,
                sensorsData: data,
                overrideSensorsRange,
            };
        }, service = new DashboardService();

        return (dispatch) => {

            const currentData = selectAllSensorDataForHistogramRange(store.getState());

            const realTime = selectDashboardOnline(store.getState());

            const overrideSensorsRange = realTime ? selectOverrideSensorsRange(store.getState()) : [];

            const [state] = data;

            service.updateLocalSensorData(data, currentData, overrideSensorsRange).then(value => {

                const index = overrideSensorsRange.findIndex(id => id === state.id);

                if (index !== -1) {
                    overrideSensorsRange.splice(index, 1);
                }

                dispatch(receiveData(value, overrideSensorsRange));

            });
        };
    },

    /**
     * Loaded data from sensors
     *
     * @return {Promise<Object>}
     */
    processSensorsDataLoaded: ():
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const loaded = () => {

            return {
                type: dashboardConstants.RECEIVE_SENSORS_DATA_LOADED,
            };
        };

        return (dispatch) => {

            dispatch(loaded());
        };
    },

    /**
     * Loaded data from sensors
     *
     * @return {Promise<Object>}
     */
    clearRangeData: ():
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const loaded = () => {

            return {
                type: dashboardConstants.CLEAR_RANGE_DATA,
            };
        };

        return (dispatch) => {

            dispatch(loaded());
        };
    },

    /**
     * Receive only new data from sensors and update data in storage
     *
     * @param {array} data
     *
     * @return {Promise<Object>}
     */
    updateSensorData: (data: any):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const updateData = (data: any) => {

            return {
                type: dashboardConstants.UPDATE_SENSORS_DATA,
                sensorsData: data,
            };
        }, service = new DashboardService();

        return (dispatch) => {

            const currentData = selectAllSensorDataForHistogram(store.getState());

            service.updateLocalSensorData(data, currentData).then(value => {

                dispatch(updateData(value));

            });
        };
    },

    /**
     * Receive only new data range from sensors and update data in storage
     *
     * @param {array} data
     *
     * @return {Promise<Object>}
     */
    updateSensorDataRange: (data: any):
        (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const updateData = (data: any) => {

            return {
                type: dashboardConstants.UPDATE_SENSORS_DATA_RANGE,
                sensorsData: data,
            };
        }, service = new DashboardService();

        return (dispatch) => {

            const currentData = selectAllSensorDataForHistogramRange(store.getState());

            service.updateLocalSensorData(data, currentData).then(value => {


                dispatch(updateData(value));
            });
        };
    },

    /**
     * Set dashboard to online mode
     *
     * @return {Promise<Object>}
     */
    setOnline: (): (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void =>{

        const action = () => {

            return {
                type: dashboardConstants.SET_ONLINE,
            };
        };

        return (dispatch) => {

            dispatch(action());
        };
    },

    /**
     * Set dashboard to offline mode
     *
     * @return {Promise<Object>}
     */
    setOffline: (): (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const action = () => {

            return {
                type: dashboardConstants.SET_OFFLINE,
            };
        };

        return (dispatch) => {

            dispatch(action());
        };
    },

    /**
     * Set the dashboard to online if the current time matches the end date.
     *
     * @return {Promise<Object>}
     */
    setOnCurrentTime: (): (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void =>{

        const action = () => {

            return {
                type: dashboardConstants.SET_ON_CURRENT_TIME,
            };
        };

        return (dispatch) => {

            dispatch(action());
        };
    },

    /**
     * Set the dashboard to offline mode if the current time is not the end date or less
     *
     * @return {Promise<Object>}
     */
    setOffCurrentTime: (): (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void => {

        const action = () => {

            return {
                type: dashboardConstants.SET_OFF_CURRENT_TIME,
            };
        };

        return (dispatch) => {

            dispatch(action());
        };
    },

    /**
     * Update screen width available for charts
     *
     * @param {number} width
     *
     * @return {Promise<Object>}
     */
    updateScreenWidth: (width: number): (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>) => void =>{

        const action = () => {

            return {
                type: dashboardConstants.UPDATE_SCREEN_WIDTH,
                width: width,
            };
        };

        return (dispatch) => {

            dispatch(action());
        };
    },
};
