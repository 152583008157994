import React from 'react';
import { connect, useSelector } from 'react-redux';

import './styles/Rule.scss';
import { RootState } from '../../../store';
import { ISensor, IChartData } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { selectScreenWidth } from '../../../selectors/dashboard/dashboardSelector';
import { selectGraphRule } from '../../../selectors/graphRule/graphRuleSelector';
import { selectHistogramHeight } from '../../../selectors/graphHistogramHeight/histogramHeightSelector';
import { selectBrushSelection } from '../../../selectors/graphMinimapBrush/graphMinimapBrushSelector';

interface IRuleTextProps {
    data:  IChartData[];
    sensor: ISensor;
    hrMode: boolean;
}

/**
 * A rule text component to display graph values
 *
 * @param {React.PropsWithChildren<any>} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const RuleText: React.FC<IRuleTextProps> = (
    {
        data,
        sensor,
        hrMode,
    }: IRuleTextProps,
) => {

    const { t } = useTranslation();

    const width = useSelector(selectScreenWidth),
        graphRule = useSelector(selectGraphRule),
        selection: Date[] | undefined = useSelector(selectBrushSelection),
        graphHeight = useSelector(selectHistogramHeight);

    if (graphRule && graphRule.display && data && data.length) {

        const x = Math.round(graphRule.x);

        const styles = {
            right: (width - x) + 'px',
            top: ((graphHeight / 2) - 15) + 'px',
        };

        let minText: string | number = 'auto',
            maxText: string | number = 'auto';

        if (sensor.graphPreferences && sensor.graphPreferences[0]) {

            minText = sensor.graphPreferences[0].min || sensor.graphPreferences[0].min === 0 ? sensor.graphPreferences[0].min : 'auto';
            maxText = sensor.graphPreferences[0].max || sensor.graphPreferences[0].max === 0 ? sensor.graphPreferences[0].max : 'auto';
        }

        const index = data.length < width ? Math.round(x * (data.length / (width - (selection && new Date(selection[1]) > (new Date()) ? 40 : 0)))) : x;

        return (
            <div className="rule-text" style={styles}>
                <span>{data[index] && parseFloat(String(data[index].value || 0)).toFixed(2)}</span>
                {data[index] ?
                    <span className="description" >
                        {sensor.um ? sensor.um : ''} ({sensor.graphPreferences && sensor.graphPreferences[0] ? `${minText}, ${maxText}` : hrMode? t('CM') :''})
                    </span> : null
                }
            </div>
        );
    }

    return null;
};

export default RuleText;
