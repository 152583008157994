export const stateOverrideConstants = {

    STORE_SUCCESS: 'STATE_OVERRIDE_STORE_SUCCESS',
    STORE_FAILURE: 'STATE_OVERRIDE_STORE_FAILURE',

    UPDATE_SUCCESS: 'STATE_OVERRIDE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'STATE_OVERRIDE_UPDATE_FAILURE',

    DELETE_SUCCESS: 'STATE_OVERRIDE_DELETE_SUCCESS',
    DELETE_FAILURE: 'STATE_OVERRIDE_DELETE_FAILURE',

};
