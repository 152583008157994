import React, { FC, useEffect} from 'react';
import { connect, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { DrawerProps } from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import CodeIcon from '@material-ui/icons/Code';
import { makeStyles } from '@material-ui/core/styles';
import { LayoutActions } from '../../../actions/layout';
import {
    selectDrawerHeight,
    selectDrawerIsResize,
    selectDrawerWidth, selectIsFullScreenDrawer,
    selectPositionDrawer,
} from '../../../selectors/layout/responsiveDrawerSelector';

import './styles/ResponsiveDrawer.scss';
import { selectMaxWidthSideBar } from '../../../selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';

interface IProps {
    defaultWidth: number;
    startResize: () => void;
    stopResize: (event: MouseEvent | TouchEvent) => void;
    currentRef: (instance: HTMLElement | null) => void;
}

const useStyles = makeStyles(() => ({
    drawerPaper: {
        marginTop: '70px',
        padding: '10px',
        overflow: 'visible',
    },
    fullWidth: {
        width: 'auto',
        margin: '0 -10px',
        overflow: 'hidden',
    },
}));

/**
 * Responsive drawer component
 *
 * @param {number} defaultWidth
 * @param {string} anchor
 * @param {boolean} open
 * @param {function} onClose
 * @param {any} children
 * @param {function} startResize
 * @param {function} stopResize
 *
 * @param currentRef
 * @return {JSX.Element}
 *
 * @constructor
 */
const ResponsiveDrawer: FC<IProps & DrawerProps> = (
    {
        defaultWidth,
        anchor,
        open,
        onClose,
        children,
        startResize,
        stopResize,
        currentRef,
    }: IProps & DrawerProps,
) => {

    //TODO: Add height calculation, if anchor is 'top' or 'bottom'
    //TODO: Adjust width/height calculations to screen limits
    const isResize = useSelector(selectDrawerIsResize);
    const width = useSelector(selectDrawerWidth) || defaultWidth;
    const drawerParamHeight = useSelector(selectDrawerHeight);
    const isFullScreen = useSelector(selectIsFullScreenDrawer);
    const positionDrawer = useSelector(selectPositionDrawer);
    const barWidth = useSelector(selectMaxWidthSideBar);

    /**
     * Handle mouse down event on resize button
     */
    function handleMouseDown() {

        startResize();
    }

    useEffect(() => {

        /**
         * Handle mouse up event on resize button
         */
        function handleMouseUp(event: MouseEvent) {

            stopResize(event);
        }

        /**
         * Handle mouse up event on resize button
         */
        function handleTouchEnd(event: TouchEvent) {

            stopResize(event);
        }

        if (isResize) {

            window.addEventListener('mouseup', handleMouseUp);

            if ('ontouchstart' in window) {

                window.addEventListener('touchmove', handleTouchEnd);
            }

        } else {

            window.removeEventListener('mouseup', handleMouseUp);

            if ('ontouchstart' in window) {

                window.removeEventListener('touchmove', handleTouchEnd);
            }
        }

        return () => {

            window.removeEventListener('mouseup', handleMouseUp);

            if ('ontouchstart' in window) {

                window.removeEventListener('touchmove', handleTouchEnd);
            }
        };

    }, [isResize, stopResize]);

    const classes = useStyles();
    const { body: { offsetHeight } } = document;
    const paperClass = { paper: classes.drawerPaper };

    const positionDrawerRightStyle: React.CSSProperties = { width, pointerEvents: 'all', maxWidth: `calc(100% - ${barWidth}px)` },
        positionDrawerBottomStyle: React.CSSProperties = {
            height: drawerParamHeight.height,
            marginTop: drawerParamHeight.margin,
            width: `calc(100% - ${barWidth}px)`,
            pointerEvents: 'all',
        },
        paperPropsFullScreenStyle: React.CSSProperties = {
            height: offsetHeight || '100%',
            width: '100%',
            pointerEvents: 'all',
            marginTop: 0,
        };

    const paperPropsStyle: React.CSSProperties = positionDrawer === 'right' ?
            positionDrawerRightStyle : positionDrawerBottomStyle;

    return (
        <Drawer
            variant="persistent"
            anchor={anchor}
            open={open}
            onClose={onClose}
            className={`hmi-drawer-${positionDrawer}`}
            classes={paperClass}
            BackdropProps={
                { invisible: true }
            }
            ModalProps={
                { keepMounted: true, style: { pointerEvents: 'none' } }
            }
            PaperProps={
                { style: isFullScreen? paperPropsFullScreenStyle:paperPropsStyle }
            }
            ref={instance => currentRef(instance as HTMLElement | null)}
        >
            {!isFullScreen ?
                <Fab
                    onMouseDown={handleMouseDown}
                    onTouchMove={handleMouseDown}
                    size="small"
                    className={`drawer ${positionDrawer}`}
                    disableRipple
                >
                    <CodeIcon />
                </Fab>
                : null
            }
            <div role="presentation" className={`${classes.fullWidth} ${isFullScreen ? 'full-screen-drawer' : ''}`}>
                {children}
            </div>
        </Drawer>
    );
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    startResize: LayoutActions.drawerStartResize,
    stopResize: LayoutActions.drawerStopResize,
});

export default connect(null, mapDispatchToProps)(ResponsiveDrawer);
