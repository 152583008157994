import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { IObject } from '../interfaces';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';

import './../styles/LayersList.scss';
import { Popover } from '@material-ui/core';

/**
 * Layers list component
 */
class LayersList extends React.Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    state = {
        anchorEl: null,
        open: false,
    };

    /**
     * Handle toggle method
     */
    handleToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ open: !this.state.open, anchorEl: event.currentTarget });
    };

    private onClose() {
        this.setState({ open: false });
    }

    render() {

        const { anchorEl, open } = this.state;

        const { objects, t } = this.props;

        return (
            <div className="layer-list-menu">
                <Button
                    ref={anchorEl}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                >
                    {t('SHOW_LAYERS')}
                </Button>
                {objects?.length > 0 &&
                <Popover
                    id="layer-list-popover"
                    open={open}
                    onClose={this.onClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className="layer-list-popover"
                >
                    <Paper>
                        <MenuList autoFocusItem={open} id="menu-list-grow">
                            {objects.map((object, index) =>
                                (
                                    <MenuItem
                                        key={index}
                                        onClick={() => this.props.onToggleObject(object)}
                                    >
                                        <VisibilityIcon
                                            color={object.active ? 'primary' : 'secondary'}
                                            style={{
                                                marginRight: 10,
                                                color: object.active ? '#1976d2' : '#c0c0c0',
                                            }}
                                        />
                                        {object.type ? `${t(object.type.toUpperCase())}: ` : null}{object.name}
                                    </MenuItem>
                                )
                            )}
                        </MenuList>
                    </Paper>
                </Popover>
                }
            </div>
        );
    }
}

export default withTranslation()(LayersList);

interface IProps {
    objects: IObject[];
    onToggleObject: (object: IObject) => void;
}
interface IState {
    anchorEl: HTMLButtonElement | null;
    open: boolean;
}
