import React, { Component } from 'react';
import { Button, TextInput, ConfirmDialog } from '../../../core/ui/components';
import { Formik } from 'formik';
import './FormNode.scss';
import {
    IData,
    IError,
    IOrder,
    IProcess,
    IProduct,
    IUnit,
} from '../../../core/interfaces';
import * as yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ReactComponent as UnitIcon } from '../../../core/ui/assets/images/icons/folder-unit.svg';
import { FormActions, UnitActions } from '../../../core/actions';
import FormSelect from './FormSelect/FormSelect';
import { RootState } from '../../../core/store';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';


/**
 * default props and state
 */

interface IOptions {
    label: string;
    value: string;
}

interface IFormValues {
    id?: number;
    units: number | string;
    process: number | string;
    name: string;
    comment: string;
}

interface IProps {
    label?: string;
    loadProcess?: (search?: string, order?: IOrder) => void;
    loadUnit?: (search?: string, order?: IOrder) => void;
    units: IData[];
    unitsData: IData | null | undefined;

    errors?: IError;
    i18n?: WithTranslation;
    tReady?: WithTranslation;
    t?: WithTranslation;
    openSidebar: boolean;
    toggleForm: (opened: boolean, name?: string) => void;
    updateUnit?: (data: IUnit, type: string, product: IProduct | null, refreshTree?: boolean) => void;
    elementInTree?: {
        process: IProcess[];
        unit: IUnit[];
    };
}

interface IState {
    initialValues: IFormValues;
    enableReinitialize: boolean;
    selectedValue: string;
    optionsUnits: IOptions[];
    optionsProcess: IOptions[];
    confirm: boolean;
}

/**
 * Form Units Component
 *
 * @class FormUnits
 */
class FormUnits extends Component<IProps & WithTranslation, IState> {

    constructor(props: IProps & WithTranslation) {

        super(props);

        this.validationSchema = yup.object().shape({
            units: yup.string().required(this.props.t('UNITS_IS_REQUIRED')),
            process: yup.string().required(this.props.t('PROCESS_IS_REQUIRED')),
            name: yup.string()
                .trim()
                .max(30, this.props.t('MAX_ITEM_LENGTH', { name: this.props.t('NAME'), length: '30' }))
                .required(this.props.t('UNITS_NAME_IS_REQUIRED')),
            comment: yup.string()
                .max(300, this.props.t('MAX_COMMENT_COMMENT')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.onConfirmDialog = this.onConfirmDialog.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.handleChangeSelect = this.handleChangeSelect.bind(this);

        this.chooseProcess = this.chooseProcess.bind(this);

        this.closeSidebar = this.closeSidebar.bind(this);
    }

    /**
     * Current state
     */
    readonly state: IState = {
        initialValues: {
            units: this.props.unitsData?.current.id || '',
            process: this.props.unitsData?.parent.id || '',
            comment: this.props.unitsData?.current.comment || '',
            name: this.props.unitsData?.current.name || '',
        },
        enableReinitialize: true,
        selectedValue: '',
        optionsUnits: [],
        optionsProcess: [],
        confirm: false,

    };


    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        const { loadUnit } = this.props;

        if (loadUnit) {

            loadUnit();

        }

        this.updateOptions();

    }

    /**
     * Component props update handler
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps: Readonly<IProps & WithTranslation>) {

        if (prevProps.units !== this.props.units) {

            this.updateOptions();
        }
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, { process: string; name: string; units: string; comment: string }>>;


    /**
     *  Handler submit form
     *
     * @param {IFormValues} values
     */
    handleSubmit(values: IFormValues) {

        if (values.process && values.name && values.units) {

            this.setState({ confirm: true, initialValues: values });
        }
    }

    /**
     * Handler for the cancel form dialog
     * Reset field form
     */
    handleCancel() {


        this.setState({ confirm: false });
    }

    /**
     * Handler cancel dialog form
     */
    onConfirmDialog() {

        const { initialValues } = this.state,
            { updateUnit, toggleForm, unitsData } = this.props;


        if (updateUnit) {

            const updateData = {
                id: parseInt(String(initialValues.units)),
                comment: initialValues.comment,
                name: initialValues.name,
                process: parseInt(String(initialValues.process)),
            };

            updateUnit(
                updateData as IUnit,
                'tree_needs_to_be_updated',
                null,
                unitsData?.parent.id !== parseInt(String(initialValues.process),
                ));

            toggleForm(true, '');
        }
    }

    /**
     * function for the update the 'Options'
     */
    updateOptions() {
        const optionsUnitsProps: IOptions[] = [],
            optionsProcessProps: IOptions[] = [],
            { elementInTree } = this.props;

        if (this.props.units && elementInTree) {
            this.props.units.forEach((value) => {

                if (!elementInTree.unit.find(unit => unit.id === value.id)) {
                    optionsUnitsProps.push({
                        label: value.name.toString(),
                        value: value.id,
                    });
                } else if (this.props.unitsData) {
                    optionsUnitsProps.push({
                        label: value.name.toString(),
                        value: value.id,
                    });
                }
            });
        }

        elementInTree && elementInTree.process.forEach((value) => {
            if (value.id) {
                optionsProcessProps.push({
                    label: value.name.toString(),
                    value: value.id.toString(),
                });
            }
        });

        this.setState({ optionsProcess: optionsProcessProps, optionsUnits: optionsUnitsProps });
    }

    /**
     *  Handler Change select
     *  re-init  initialValues with choose param
     *
     * @param {React.ChangeEvent} event
     */
    handleChangeSelect(event: React.ChangeEvent<{ name?: string; value: unknown }>) {

        const { initialValues } = this.state;

        const fieldData = this.props.units.filter(value => value.id === parseInt(String(event.target.value)));

        initialValues['units'] = event.target.value as number;
        initialValues['name'] = String(fieldData[0].name.toString());
        initialValues['comment'] = String((fieldData[0].comment || '').toString());
        initialValues['id'] = event.target.value as number;

        this.setState({ initialValues: initialValues });

    }

    /**
     *
     * @param {React.ChangeEvent<{name?: string, value: unknown}>} event
     */
    chooseProcess(event: React.ChangeEvent<{ name?: string; value: unknown }>) {

        const { initialValues } = this.state;

        initialValues.process = event.target.value as number;

        this.setState({ initialValues: initialValues });
    }

    /**
     * Sidebar close handler
     */
    closeSidebar(): void {

        this.props.toggleForm(this.props.openSidebar);

    }


    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { errors = {}, t } = this.props;

        return (
            <React.Fragment>
                <div className="form-box form-box-space node-form">
                    <Formik
                        initialValues={this.state.initialValues}
                        enableReinitialize
                        validationSchema={this.validationSchema}
                        onSubmit={this.handleSubmit}
                    >{props => (
                        <form onSubmit={props.handleSubmit} noValidate>
                            <div className="section wrap-form-node">
                                <div className="table-header">
                                    <div
                                        className={'title'}
                                    >{this.props.unitsData ? this.props.t('CHANGE_UNITS') : this.props.t('ADD_UNITS')}
                                    </div>
                                    <UnitIcon className={'title-icon'} />
                                </div>
                                {errors && errors.field === 'name' &&
                                <div className="common-error">{t('THE_UNIT_HAS_ALREADY_BEEN_TAKEN')}</div>}
                                <div className="table-body">
                                    <div className="form-group select-unit select">
                                        <FormSelect
                                            formProps={props}
                                            handleChangeSelect={this.handleChangeSelect}
                                            options={this.state.optionsUnits}
                                            disabledSelect={Boolean(this.props.unitsData)}
                                            name={'units'}
                                            placeholder={this.props.t('SELECT_UNITS')}
                                            label={this.props.t('SELECT_AN_AVAILABLE_UNITS')}
                                            reset
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.name || errors.field === 'name' ? props.errors.name || errors.field === 'name' ? 'error-field' : 'success-field' : '')
                                            }
                                            label={this.props.t('CHANGE_NAME')}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.name}
                                            name="name"
                                            type="text"
                                            placeholder={this.props.t('ENTER_NAME')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {((props.touched.name && props.errors.name)) &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.name}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group">
                                        <TextInput
                                            className={
                                                'form-field '
                                                +
                                                (props.touched.comment ? props.errors.comment ? 'error-field' : 'success-field' : '')
                                            }
                                            label={this.props.t('COMMENT')}
                                            onChange={props.handleChange}
                                            onBlur={props.handleBlur}
                                            value={props.values.comment}
                                            name="comment"
                                            type="text"
                                            placeholder={this.props.t('ENTER_COMMENT')}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            {((props.touched.comment && props.errors.comment)) &&
                                            <div
                                                className="validation-massage"
                                            >{props.errors.comment}
                                            </div>
                                            }
                                        </TextInput>
                                    </div>
                                    <div className="form-group select-unit select">
                                        <FormSelect
                                            formProps={props}
                                            handleChangeSelect={this.chooseProcess}
                                            options={this.state.optionsProcess}
                                            disabledSelect={false}
                                            name={'process'}
                                            placeholder={this.props.t('SELECT_PROCESS')}
                                            label={this.props.t('SELECT_PARENT_PROCESS')}
                                            reset={false}
                                        />
                                    </div>
                                    <div className="form-group btn-group">

                                        <Button
                                            type="reset"
                                            color={'primary'}
                                            onClick={this.closeSidebar}
                                        >{this.props.t('CANCEL')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            color={'secondary'}
                                        >
                                            {this.props.unitsData ? this.props.t('SAVE_CHANGES') : this.props.t('ADD')}
                                        </Button>
                                    </div>

                                </div>
                            </div>
                        </form>
                    )}
                    </Formik>
                </div>
                <ConfirmDialog
                    heading={this.props.unitsData ? this.props.t('CHANGE_UNITS_Q') : this.props.t('ADD_UNITS_Q')}
                    onAccept={this.onConfirmDialog}
                    onClose={this.handleCancel}
                    open={this.state.confirm}
                />
            </React.Fragment>
        );
    }
}


/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */

const mapStateToProps = (state: RootState) => {

    const { units } = state.unit,
        { formModel } = state.form;

    return {
        units,
        errors: state.unitChange.errors,
        errorsProcess: state.process.errors,
        unitsData: formModel || null,
    };
};


/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    loadUnit: UnitActions.list,
    updateUnit: UnitActions.update,
    toggleForm: FormActions.toggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FormUnits));
