import { Action } from 'redux';
import { IBarrierApiModel, IBarrierIndentOnly } from '../../services/barrierService';

export enum BarrierActionTypes {
    SAVE_BARRIER_ACTION = '[Barrier] Save Barrier',
    FETCH_BARRIERS_REQUEST = '[Barrier] Fetch Barriers Request',
    FETCH_BARRIERS_SUCCESS = '[Barrier] Fetch Barriers Success',
    FETCH_BARRIERS_FAILURE = '[Barrier] Fetch Barriers Failure',
    DELETE_BARRIER_ACTION = '[Barrier] Delete Barrier',
    CLEAR_BARRIERS_ACTION = '[Barrier] Clear Barriers',
}

/**
 * Fetch barrier request action
 *
 * @class FetchBarriersRequest
 */
export class FetchBarriersRequest implements Action {
    readonly type = BarrierActionTypes.FETCH_BARRIERS_REQUEST;
}

/**
 * Fetch barrier success action
 *
 * @class FetchBarriersSuccess
 */
export class FetchBarriersSuccess implements Action {
    readonly type = BarrierActionTypes.FETCH_BARRIERS_SUCCESS;

    constructor(
        public payload: {
            barriers: IBarrierApiModel[]
        }
    ) { }
}

/**
 * Fetch barrier failure action
 *
 * @class FetchBarriersFailure
 */
export class FetchBarriersFailure implements Action {
    readonly type = BarrierActionTypes.FETCH_BARRIERS_FAILURE;

    constructor(
        public payload: {
            error: any
        }
    ) { }
}

/**
 * Save barrier action
 *
 * @class SaveBarrierAction
 */
export class SaveBarrierAction implements Action {
    readonly type = BarrierActionTypes.SAVE_BARRIER_ACTION;

    constructor(
        public payload: {
            barrier: IBarrierApiModel,
        },
    ) { }
}

/**
 * Delete barrier action
 *
 * @class DeleteBarrierAction
 */
export class DeleteBarrierAction implements Action {
    readonly type = BarrierActionTypes.DELETE_BARRIER_ACTION;

    constructor(
        public payload: {
            barrier: IBarrierIndentOnly
        }
    ) { }
}

/**
 * Clear barriers action
 * 
 * @class ClearBarrierAction
 */
export class ClearBarriersAction implements Action {
    readonly type = BarrierActionTypes.CLEAR_BARRIERS_ACTION;
}

export type BarrierActions =
    FetchBarriersRequest |
    FetchBarriersSuccess |
    FetchBarriersFailure |
    SaveBarrierAction |
    DeleteBarrierAction |
    ClearBarriersAction;
