export const hmiSchemaConstants = {

    LIST_HMI_SCHEMA_SUCCESS: 'HMI_SCHEMA_LIST_SUCCESS',
    LIST_HMI_SCHEMA_FAILURE: 'HMI_SCHEMA_LIST_FAILURE',

    STORE_HMI_SCHEMA_SUCCESS: 'HMI_SCHEMA_STORE_SUCCESS',
    STORE_HMI_SCHEMA_FAILURE: 'HMI_SCHEMA_STORE_FAILURE',

    UPDATE_HMI_SCHEMA_SUCCESS: 'HMI_SCHEMA_UPDATE_SUCCESS',
    UPDATE_HMI_SCHEMA_FAILURE: 'HMI_SCHEMA_UPDATE_FAILURE',

    DELETE_HMI_SCHEMA_SUCCESS: 'HMI_SCHEMA_DELETE_SUCCESS',
    DELETE_HMI_SCHEMA_FAILURE: 'HMI_SCHEMA_DELETE_FAILURE',

    GET_PICTURE_HMI_SCHEMA_SUCCESS: 'GET_PICTURE_HMI_SCHEMA_SUCCESS',
    GET_PICTURE_HMI_SCHEMA_FAILURE: 'GET_PICTURE_HMI_SCHEMA_FAILURE',

    EDIT_HMI_SCHEMA_SUCCESS: 'EDIT_HMI_SCHEMA_SUCCESS',
    EDIT_HMI_LOAD_PICTURE_SCHEMA_SUCCESS: 'EDIT_HMI_LOAD_PICTURE_SCHEMA_SUCCESS',

    CLEAR_HMI_SCHEMA_STATE: 'CLEAR_HMI_SCHEMA_STATE',

    UPDATE_LOCAL_HMI_SCHEMA_SUCCESS: 'UPDATE_LOCAL_HMI_SCHEMA_SUCCESS',

    UPDATE_LOCAL_OPEN_HMI_SCHEMA_SUCCESS: 'UPDATE_LOCAL_OPEN_HMI_SCHEMA_SUCCESS',

    UPDATE_HMI_SCHEMA_FROM_SUCCESS: 'UPDATE_HMI_SCHEMA_FROM_SUCCESS',
};
