import { Action } from 'redux';
import { IBuildingApiModel } from '../../services/buildingService';

export enum BuildingActionTypes {
    SAVE_BUILDING_ACTION = '[Building] Save Building',
    FETCH_BUILDINGS_REQUEST = '[Building] Fetch Buildings Request',
    FETCH_BUILDINGS_SUCCESS = '[Building] Fetch Buildings Success',
    FETCH_BUILDINGS_FAILURE = '[Building] Fetch Buildings Failure',
    DELETE_BUILDING_ACTION = '[Building] Delete Building',
    CLEAR_BUILDINGS_ACTION = '[Building] Clear Buildings',
}

/**
 * Fetch building request action
 *
 * @class FetchBuildingsRequest
 */
export class FetchBuildingsRequest implements Action {
    readonly type = BuildingActionTypes.FETCH_BUILDINGS_REQUEST;
}

/**
 * Fetch building success action
 *
 * @class FetchBuildingsSuccess
 */
export class FetchBuildingsSuccess implements Action {
    readonly type = BuildingActionTypes.FETCH_BUILDINGS_SUCCESS;

    constructor(
        public payload: {
            buildings: IBuildingApiModel[]
        }
    ) {}
}

/**
 * Fetch building failure action
 *
 * @class FetchBuildingsFailure
 */
export class FetchBuildingsFailure implements Action {
    readonly type = BuildingActionTypes.FETCH_BUILDINGS_FAILURE;

    constructor(
        public payload: {
            error: any
        }
    ) {}
}

/**
 * Save building action
 *
 * @class SaveBuildingAction
 */
export class SaveBuildingAction implements Action {
    readonly type = BuildingActionTypes.SAVE_BUILDING_ACTION;

    constructor(
        public payload: {
            building: IBuildingApiModel,
        },
    ) {}
}

/**
 * Delete building action
 *
 * @class DeleteBuildingAction
 */
export class DeleteBuildingAction implements Action {
    readonly type = BuildingActionTypes.DELETE_BUILDING_ACTION;

    constructor(
        public payload: {
            building: IBuildingApiModel
        }
    ) {}
}

export class ClearBuildingsAction implements Action {
    readonly type = BuildingActionTypes.CLEAR_BUILDINGS_ACTION;
}

export type BuildingActions =
    FetchBuildingsRequest |
    FetchBuildingsSuccess |
    FetchBuildingsFailure |
    SaveBuildingAction |
    DeleteBuildingAction |
    ClearBuildingsAction;