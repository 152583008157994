import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { ObjectSchema, Shape, ValidateOptions } from 'yup';

import Button from '../../../../ui/components/Button/Button';
import TextInput from '../../../../ui/components/Input/TextInput';
import { IGateway, IZone, IObject } from '../../interfaces';
import { InputLabel, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import Select from '../../../../ui/components/Select/Select';
import CreatableSelect from 'react-select/creatable';
import './../../styles/GatewayForm.scss';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';
import PhotoIcon from '@material-ui/icons/Photo';
import { ModalSelectOption } from '../../../../../../base/components/Editor/interfaces';
import CircularProgress from '@material-ui/core/CircularProgress';
import { appConfig } from '../../../../../../config/appConfig';
import { OptionsType, ValueType } from 'react-select';

interface IProps {
    onSave?: (model: IGateway) => void;
    onCancel?: () => void;
    object?: any;
    zones: ModalSelectOption[];
    gateways: ModalSelectOption[];
    gatewaysList: IObject[];
    objects: IObject[];
}

interface IState {
    initialValues: IGateway;
    dialog: boolean;
    previewUrl: string | null;
    previewImg: File | null;
    previewLoaded: boolean;
    edit: boolean;
}

interface IFormValues {
    id?: string;
    name: any;
    comment?: string | null;
    latitude: number;
    longitude: number;
    zone?: IZone | string;
    pictures?: any[];
    value?: string;
    model_id?: number;
}


/**
 * Gateway form
 *
 * @class GatewayForm
 */
class GatewayForm extends React.Component<IProps & WithTranslation, IState> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);

        const { t, object, gatewaysList, gateways } = this.props;

        this.state = {
            initialValues: {
                ...object,
                name: object?.value ? this.props.gateways.find(item => item.value === object.value) : null,
                comment: object?.comment ? object.comment : '',
                latitude: object?.latitude ? object.latitude : 0,
                longitude: object?.longitude ? object.longitude : 0,
                zone: object?.zone ? this.getZoneFromObjects(object.zone) : '',
                pictures: object?.pictures ? object.pictures : [],
            },
            dialog: false,
            previewUrl: null,
            previewImg: null,
            previewLoaded: false,
            edit: !!(object && object.id),
        };

        this.validationSchema = yup.object().shape({
            name: yup
                .mixed()
                .test('uniq',
                    t('THE_GATEWAY_NAME_HAS_ALREADY_BEEN_TAKEN'),
                    (data: ModalSelectOption) => {
                        return data && data.label ? [
                            ...gateways.filter(value => value.label.trim().toLowerCase() === data.label.trim().toLowerCase()),
                            ...gatewaysList.filter(value => value.name.trim().toLowerCase() === data.label.trim().toLowerCase() &&
                                value.name.trim().toLowerCase() !== object.name.trim().toLowerCase()),
                        ].length === 1 : true;
                    })
                .required(t('NAME_IS_REQUIRED')),
            comment: yup
                .string()
                .trim()
                .max(1500, t('MAX_ITEM_LENGTH', { name: t('NAME'), length: 1500 })),
            latitude: yup
                .number()
                .typeError(t('LATITUDE_RESTRICTION_NUMBER_TYPE'))
                .min(-90, t('LATITUDE_RESTRICTION_MIN'))
                .max(90, t('LATITUDE_RESTRICTION_MAX')),
            longitude: yup
                .number()
                .typeError(t('LONGITUDE_RESTRICTION_NUMBER_TYPE'))
                .min(-180, t('LONGITUDE_RESTRICTION_MIN'))
                .max(180, t('LONGITUDE_RESTRICTION_MAX')),
            pictures: yup
                .array()
                .test(
                    'is-correct-file',
                    t('ERROR_FILES_MUST_NOT_EXCEED_SIZE_MB', { size: 15 }),
                    this.checkIfFilesAreTooBig
                )
                .test(
                    'is-big-file',
                    t('ERROR_ACCEPTABLE_FORMATS_FOR_UPLOADING_FORMAT', { format: 'jpeg, jpg, png' }),
                    this.checkIfFilesAreCorrectType
                )
                .max(10, t('PICTURES_RESTRICTION_MAX')),
        });

        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleCancel = this.handleCancel.bind(this);

        this.noOptions = this.noOptions.bind(this);
    }

    /**
     * Form validation schema
     *
     * @type {ObjectSchema}
     */
    private readonly validationSchema: ObjectSchema<Shape<ValidateOptions, IGateway>>;

    /**
     * Checking if files are too big
     *
     * @param {[File]} files
     *
     * @return {boolean}
     */
    checkIfFilesAreTooBig(files?: [File]): boolean {

        let valid = true;

        if (files) {

            let size = 0;

            files.map(file => {

                if (file instanceof File) {

                    size += file.size / 1024 / 1024;

                }
            });

            if (size > 15) {

                valid = false;
            }
        }
        return valid;
    }

    /**
     * Checking the correct file type
     *
     * @param {[File]} files
     *
     * @return {boolean}
     */
    checkIfFilesAreCorrectType(files?: [File]): boolean {

        let valid = true;

        if (files) {

            files.map(file => {

                if (file instanceof File && !['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {

                    valid = false;

                }
            });
        }
        return valid;
    }


    /**
     * v4 id from objects array.
     *
     * @param {IZone} data
     *
     * @return {string}
     */
    getZoneFromObjects(data: IZone | string): string {

        const { objects } = this.props;

        let zone;

        if (typeof data !== 'string') {

            zone = objects.find(obj => obj.model_id === data.id || obj.zone === data.id);

        } else {

            zone = objects.find(obj => obj.id === data);

        }

        return zone ? zone.id : '';
    }

    /**
     * Gateway form submit handler
     *
     * @param {IGateway} values
     */
    handleSubmit(values: IGateway) {

        const { onSave } = this.props;

        const dataToSend = {
            ...values,
            value: values.name.value,
            name: values.name.label.trim(),
            comment: values.comment?.trim() || '',
            deleteModel: values.name.value !== values.value ? values.value : null,
        };

        if (onSave) {
            onSave(dataToSend);
        }
    }

    /**
     * Gateway form cancel handler
     */
    handleCancel() {

        const { onCancel } = this.props;

        if (onCancel) {

            onCancel();
        }
    }

    /**
     * Styles for react-select options
     *
     * @param props
     */
    private stylesSetting(props: FormikProps<IFormValues>) {
        return {
            control: (base: any, state: any) => ({
                ...base,
                borderRadius: 0,
                borderColor: props.touched.name && props.errors.name ? '#ff3b30' : base.borderColor,
                boxShadow: 'none',
                '&:hover': {
                    borderColor: state.isFocused && props.touched.name && props.errors.name ? '#ff3b30' : base.borderColor,
                },
            }),
            menu: (base: any) => ({ ...base, zIndex: 10 }),
            option: (base: any) => ({
                ...base,
                wordWrap: 'break-word'
            })
        };
    }


    /**
     * Handler responsible to update an option label
     *
     * @param val
     * @param props
     */
    private onCreateOption(val: string, props: FormikProps<IFormValues>) {

        props.setFieldTouched('name', true);

        if (val.length > 63) val.substring(0, 63);

        if (props.values.name && val.trim()) {

            const index = this.props.gateways.findIndex((el) => props.values.name.value === el.value);

            // set max length restriction to new label name
            const label = val;

            if (index !== -1) {

                this.props.gateways[index] = { ...this.props.gateways[index], label };

                props.setFieldValue('name', this.props.gateways[index], true);
            }
        }

        return val;
    }

    /**
     * On chnage file field
     *
     * @param e
     * @param props
     */
    onChangeFile(e: React.ChangeEvent<any>, props: FormikProps<IFormValues>) {
        props.setFieldTouched('pictures');

        const uploadingFiles = Array.prototype.slice.call(e.currentTarget.files);

        const files = props.values.pictures?.length ? [...props.values.pictures, ...uploadingFiles] : uploadingFiles;

        if (files.length > 10) {
            props.setErrors({ pictures: this.props.t('PICTURES_RESTRICTION_MAX') });
        }

        props.setFieldValue('pictures', files);
    }

    /**
     * Open preview image
     *
     * @param image
     */
    onPreviewImage(image: File | { link: string }) {

        if (image instanceof File) {

            const url = URL.createObjectURL(image);

            this.setState({ dialog: true, previewUrl: url, previewImg: image });

        } else {

            this.setState({
                dialog: true,
                previewUrl: appConfig.hrApiEndpoint + `${image.link}`,
                previewImg: image as any
            });
        }
    }

    /**
     * On delete imgae method responsible to delete image in preview mode
     *
     * @param props
     */
    onDeleteImage(props: FormikProps<IFormValues>) {

        const { previewImg } = this.state;

        if (props.values?.pictures && previewImg) {

            const pictures = [...props.values.pictures];

            const index = pictures.findIndex((pic: File | { id: number }) => {
                if (pic instanceof File) {

                    return pic.name === previewImg.name
                        && pic.lastModified === previewImg.lastModified;

                } else {

                    const prevImg = previewImg as any;

                    return pic.id === prevImg.id;
                }
            });

            if (index !== -1) {

                pictures.splice(index, 1);

                props.setFieldValue('pictures', pictures);

                props.setErrors({});

                props.setTouched({});
            }
        }

        this.handleClose();

    }

    handleClose = () => {

        if (this.state.previewUrl) {

            URL.revokeObjectURL(this.state.previewUrl);

        }

        this.setState({ dialog: false, previewUrl: null, previewImg: null, previewLoaded: false });
    };

    /**
     * OnLoadPreview method use to load image preview
     */
    onLoadPreview = () => {

        const { previewImg } = this.state;

        previewImg && previewImg.size > 1048576 ? setTimeout(() => this.setState({ previewLoaded: true }), 500) : this.setState({ previewLoaded: true });
    };

    noOptions(obj: { inputValue: string }) {

        return obj.inputValue = this.props.t('NO_OPTIONS');
    }

    /**
     * Check valid new option method
     */
    checkValidNewOption(inputValue: string, value: ValueType<any>, options: OptionsType<any>): boolean {

        let returnValue = true;

        options.forEach((option) => {
            if (!inputValue.trim().length || (inputValue.toLowerCase() === option.label.toLowerCase())) {
                returnValue = false;
            }
        });

        return returnValue && value.length;
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, gateways } = this.props,
            { initialValues, dialog, previewUrl, edit, previewLoaded } = this.state;

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleSubmit}
                >
                    {props => (
                        <form
                            onSubmit={props.handleSubmit}
                            noValidate
                            encType="multipart/form-data"
                            className="gateway-edit-modal"
                        >
                            <div className="form-field">
                                <CreatableSelect
                                    isClearable
                                    name="name"
                                    styles={this.stylesSetting(props)}
                                    onChange={(data) => {

                                        props.setFieldValue('pictures', data ? data.pictures : [], true);

                                        props.setFieldValue('name', data ? data : '', true);
                                    }}
                                    placeholder={`${t('NAME')}*`}
                                    options={gateways}
                                    noOptionsMessage={this.noOptions}
                                    value={props.values.name}
                                    onInputChange={val => this.onCreateOption(val.substring(0, 63), props)}
                                    formatCreateLabel={(val: string) => val}
                                    isValidNewOption={this.checkValidNewOption}
                                />
                                {props.touched.name && props.errors.name &&
                                    <div className="validation-massage">{props.errors.name}</div>
                                }
                            </div>

                            <div className="MuiTextArea">
                                <TextInput
                                    onChange={props.handleChange}
                                    onBlur={props.handleBlur}
                                    label={t('COMMENT')}
                                    className={
                                        'form-field comment-input'
                                        +
                                        (props.touched.comment ? props.errors.comment ? 'error-field' : 'success-field' : '')
                                    }
                                    placeholder={`${t('ADD_COMMENT')}`}
                                    name="comment"
                                    rowsMax="4"
                                    multiline
                                    aria-label="comment"
                                    value={props.values.comment ? props.values.comment : ''}
                                >
                                    {props.touched.comment && props.errors.comment &&
                                        <div
                                            className="validation-massage"
                                        >{props.errors.comment}
                                        </div>
                                    }
                                </TextInput>
                            </div>

                            <div className="form-field gateway-file-field">
                                <div className="gateway-file-wrapper">
                                    <div>
                                        <input
                                            accept="image/*"
                                            className="gateway-file-input"
                                            id="icon-button-file"
                                            type="file"
                                            onChange={(e) => this.onChangeFile(e, props)}
                                            onBlur={props.handleBlur}
                                            multiple
                                        />
                                        <label htmlFor="icon-button-file">
                                            <IconButton color="primary" aria-label="upload picture" component="span">
                                                <AttachFileIcon />
                                            </IconButton>
                                        </label>
                                    </div>
                                    <div className="gateway-file-list">
                                        {props.values.pictures?.map((picture, index) => <PhotoIcon key={index} onClick={() => this.onPreviewImage(picture)} />)}
                                    </div>
                                </div>
                                {(props.touched.pictures && props.errors.pictures) &&
                                    <div className="validation-massage">{props.errors.pictures}</div>
                                }
                            </div>

                            <TextInput
                                className={
                                    `form-field label-inline ${props.touched.latitude ? (props.errors.latitude) ? 'error-field' : 'success-field' : ''}`
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                placeholder={t('LATITUDE')}
                                value={props.values.latitude}
                                label={t('LATITUDE')}
                                name="latitude"
                                inputProps={{ maxLength: 10 }}
                            >
                                {(props.touched.latitude && props.errors.latitude) &&
                                    <div className="validation-massage">{props.errors.latitude}</div>
                                }
                            </TextInput>
                            <TextInput
                                className={
                                    `form-field label-inline ${props.touched.longitude ? (props.errors.longitude) ? 'error-field' : 'success-field' : ''}`
                                }
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                placeholder={t('LONGITUDE')}
                                value={props.values.longitude}
                                label={t('LONGITUDE')}
                                name="longitude"
                                inputProps={{ maxLength: 10 }}
                            >
                                {(props.touched.longitude && props.errors.longitude) &&
                                    <div className="validation-massage">{props.errors.longitude}</div>
                                }
                            </TextInput>
                            <Select
                                className="form-field"
                                value={props.values.zone}
                                name="zone"
                                displayEmpty
                                placeholder={t('SELECT_OPTIONAL_ZONE')}
                                onChange={props.handleChange}
                                options={this.props.zones}
                                MenuProps={{
                                    disableScrollLock: true,
                                    style: {
                                        maxHeight: 300,
                                    },
                                }}
                            />
                            <div className="button-row">
                                <Button type="button" color="primary" onClick={this.handleCancel} style={{ marginRight: 30 }}>
                                    {t('CANCEL')}
                                </Button>
                                <Button type="submit" color="primary">
                                    {t(edit ? 'EDIT' : 'ADD')}
                                </Button>
                            </div>

                            <div>
                                <Dialog
                                    className="preview-gateway-dialog"
                                    onClose={this.handleClose}
                                    maxWidth="xl"
                                    open={dialog}
                                    scroll={'body'}
                                    closeAfterTransition
                                >
                                    <DialogContent>
                                        {previewUrl && !previewLoaded &&
                                            <div>
                                                <CircularProgress color="primary" />
                                            </div>
                                        }
                                        {previewUrl &&
                                            <>
                                                <img
                                                    src={previewUrl}
                                                    style={{
                                                        maxHeight: '82vh',
                                                        margin: '0 auto',
                                                        display: previewLoaded ? 'block' : 'none',
                                                    }}
                                                    onLoad={this.onLoadPreview}
                                                />
                                            </>
                                        }
                                    </DialogContent>
                                    <DialogActions style={{ padding: '8px 24px 20px' }}>
                                        <Button onClick={() => this.onDeleteImage(props)} style={{ color: 'red' }} color="primary" autoFocus>
                                            {t('DELETE')}
                                        </Button>
                                        <Button onClick={this.handleClose} color="primary" autoFocus>
                                            {t('CLOSE')}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                        </form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

export default withTranslation()(GatewayForm);