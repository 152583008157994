
import { IBeacon } from '../../interfaces';
import { IErrors } from '../../../../core/interfaces';
import { BeaconActions, BeaconActionTypes } from '../actions/beaconActions';
import { GatewayActionTypes } from "../actions/gatewayActions";

export interface IBeaconState {
    isLoading: boolean;
    beacons: IBeacon[];
    error: IErrors;
}

export const initialState: IBeaconState = {
    isLoading: false,
    beacons: [],
    error: { },
};

export function beaconReducer(state = initialState, action: BeaconActions): IBeaconState {

    switch (action.type) {

        case BeaconActionTypes.FETCH_BEACONS_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: {},
            };
        }

        case BeaconActionTypes.FETCH_BEACONS_SUCCESS: {

            const { beacons } = action.payload;

            return {
                ...state,
                isLoading: false,
                beacons,
            };
        }

        case BeaconActionTypes.FETCH_BEACONS_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case BeaconActionTypes.DELETE_BEACON_ACTION: {

            const beacons = state.beacons.filter(beacon => beacon.id !== action.payload.beacon.id);

            return {
                ...state,
                beacons,
            };
        }
        case BeaconActionTypes.CLEAR_BEACON_ERROR: {

            return {
                ...state,
                error: {},
            };
        }

        case BeaconActionTypes.SAVE_BEACON_ACTION: {

            const payloadBeacon = action.payload.beacon;

            const beacons = state.beacons.map(beacon => beacon.id === payloadBeacon.id ? payloadBeacon : beacon);

            if (beacons.findIndex(zone => zone.id === payloadBeacon.id) === -1) {
                beacons.push(payloadBeacon);
            }

            return {
                ...state,
                beacons,
            };
        }

        default: {
            return state;
        }
    }
}
