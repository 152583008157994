import { ThunkDispatch as Dispatch } from 'redux-thunk';
import { ActivateConfigurationBlock, GetAppSettingModule } from '../actions/hrModuleActions';
import { AnyAction } from 'redux';
import { AppSettingsService } from '../../services/appSettingsService';

export const hrModuleThunks = {
    activateConfigurationTree: () => (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): void => {
        dispatch(new ActivateConfigurationBlock());
    },

    getAppSetting: () => async (dispatch: Dispatch<Record<string, unknown>, void, AnyAction>): Promise<void> => {

        const appSettingsService = new AppSettingsService();

        try {
            const moduleStatus = await appSettingsService.getConfig();

            dispatch(new GetAppSettingModule({ moduleStatus }));

        } catch (errors) {

            const moduleStatus = { moduleStatus: false };

            dispatch(new GetAppSettingModule({ moduleStatus }));

            throw errors;
        }
    },
};
