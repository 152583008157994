import { hmiPlayerConstants } from '../../constants';
import { IHMIPlayerAction, IHMIPlayerState, PlayerMode } from '../../interfaces';

const initialState = {
    mode: PlayerMode.MODE_STOP,
    speed: 1,
    value: 0,
    schema: null,
    showObjects: true,
    showHmiMap: true,
    opacityHmiMap: null,
    realTime: true,
};

/**
 * HMI Player reducer
 *
 * @param {IHMIPlayerState} state Current application state
 * @param {IHMIPlayerAction} action Current action
 *
 * @returns {IHMIPlayerState}
 */
export function hmiPlayer(state: IHMIPlayerState = initialState, action: IHMIPlayerAction): IHMIPlayerState {

    switch (action.type) {

        case hmiPlayerConstants.PLAY:

            return {
                ...state,
                mode: PlayerMode.MODE_PLAY,
            };

        case hmiPlayerConstants.PAUSE:

            return {
                ...state,
                mode: PlayerMode.MODE_PAUSE,
            };

        case hmiPlayerConstants.REALTIME:

            return {
                ...state,
                mode: action.realTime? PlayerMode.MODE_REALTIME: PlayerMode.MODE_STOP,
                realTime: action.realTime,
            };

        case hmiPlayerConstants.STOP:

            return {
                ...state,
                mode: PlayerMode.MODE_STOP,
                value: 0,
            };

        case hmiPlayerConstants.SET_VALUE:

            return {
                ...state,
                value: action.value,
            };

        case hmiPlayerConstants.SET_SPEED:

            return {
                ...state,
                speed: action.speed,
            };

        case hmiPlayerConstants.SET_SCHEMA:

            return {
                ...state,
                schema: action.schema,
            };

        case hmiPlayerConstants.SHOW_HMI_SCHEMA:

            return {
                ...state,
                showHmiMap: !state.showHmiMap,
            };

        case hmiPlayerConstants.SHOW_OBJECT:

            return {
                ...state,
                showObjects: !state.showObjects,
            };

        case hmiPlayerConstants.SET_OPACITY:

            return {
                ...state,
                opacityHmiMap: action.opacityHmiMap,
            };

        default:

            return state;
    }
}
