export function getHeightFromMousePosition(position: { y: number } | null): { height: number, margin: number } {

    const { body: { offsetHeight, offsetTop } } = document;

    const structureTree = document.getElementsByClassName('structure-tree')[0];
    const structureTreeTop: number | undefined = structureTree?.getBoundingClientRect().top || 0;

    if (!position) {
        return { height: (offsetHeight / 2), margin: offsetHeight / 2 };

    }

    const minHeight = 450;

    const newHeight = offsetHeight - (position.y - offsetTop);
    const newMargin = newHeight > minHeight ?
        position.y > (structureTreeTop || 0) ?
            position.y : (structureTreeTop || 0)
        : offsetHeight - minHeight;
    const sendHeight = newHeight > offsetHeight - (structureTreeTop || 0) ?
        offsetHeight - (structureTreeTop || 0) : newHeight;

    return {
        height: newHeight > minHeight ? sendHeight : minHeight,
        margin: newMargin,
    };
}
