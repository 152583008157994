import io from 'socket.io-client';
import { appConfig } from '../../config/appConfig';

/**
 * Socket provider. Provides socket connection with server
 *
 * @class SocketProvider
 */
export default class SocketProvider {

    /**
     * Socket.IO client
     *
     * @type {SocketIOClient.Socket}
     */
    private socket: SocketIOClient.Socket;

    /**
     * Constructor
     */
    constructor() {

        this.socket = io.connect(appConfig.socketEndpoint, {
            reconnection: true,
        });
    }


    /**
     *  Listen to an event and send socket ID
     *
     * @param {(data: string) => void} handler
     */
    getSocketId(handler: (data: string) => void): void {

        this.socket.on('reconnect', () => {

            return handler(this.socket.id);

        });

        this.socket.on('connect', () => {

            return handler(this.socket.id);

        });

    }

    /**
     * Listen to an event and process a response
     *
     * @param {string} event
     * @param {function} handler
     */
    listen(event: string, handler: (data: any) => void): void {

        this.socket.on(event, handler);
    }

    /**
     * Stop listen to an event
     *
     * @param {string} event
     * @param {function} handler
     */
    stop(event: string, handler: (data: any) => void): void {

        this.socket.off(event, handler);
    }

    /**
     * Call an event with a given params
     *
     * @param {string} event
     * @param {mixed} args
     */
    call(event: string, ...args: unknown[]): void {

        this.socket.emit(event, ...args);
    }

    /**
     * Disconnect from a server
     */
    disconnect(): void {

        this.socket.disconnect();
    }
}
