import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { isMobile, withOrientationChange } from 'react-device-detect';
import { ScrollContainer } from '../../../ui/components/ScrollContainer/ScrollContainer';
import { IHrState } from '../../../store/reducers';
import { gatewayThunks } from '../../../store/thunks/gatewayThunks';
import { selectEditorGateways } from '../../../store/selectors/gatewaySelector';
import GatewayTable from './GatewayTable/GatewayTable';
import { zoneThunks } from '../../../store/thunks/zoneThunks';
import { IFilter, IOrder } from '../../../../../core/interfaces';
import { IJoin } from '../../../interfaces';
import { IGatewayApiModel } from '../../../services/gatewayService';


interface IProps {
    gateways: IGatewayApiModel[];
    isLandscape: boolean;
    fetchGateways: (search: string, order: IOrder, join?: IJoin, filter?: IFilter)=>void;
    fetchZones: ()=>void;
    clearZones: () => void;
    clearGateways: () => void;
}

/**
 * Gateway component
 *
 * @class Gateway
 */
class Gateway extends React.Component<IProps & WithTranslation> {

    /**
     * Constructor
     *
     * @param {Object} props
     */
    constructor(props: IProps & WithTranslation) {

        super(props);
    }

    componentDidMount() {

        this.props.fetchGateways('', {
            column: 'id',
            dir: 'asc',
        }, { table: ['zone', 'plan'] });

        this.props.fetchZones();
    }

    componentWillUnmount() {
        this.props.clearZones();
        this.props.clearGateways();
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, isLandscape } = this.props;

        return (
            <React.Fragment>
                <div className="wrapper with-list-component">
                    <ScrollContainer
                        maxHeightSlider={`calc(100vh - ${isMobile ? isLandscape ? 40 : 220 : 40}px)`}
                        bodyStyle={{
                            paddingTop: 10,
                        }}
                        headerScroll={
                            <React.Fragment>
                                <h2 className={'content-title factories'}>{t('GATEWAYS')}</h2>
                            </React.Fragment>
                        }
                    >
                        <GatewayTable />
                    </ScrollContainer>
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: IHrState) => {

    const gateways = selectEditorGateways(state);

    return {
        gateways,
    };
};

/**
 * Map dispatch to component props
 *
 * @param dispatch
 *
 * @return {Object}
 */
const mapDispatchToProps = ({
    fetchGateways: gatewayThunks.fetchAllGateways,
    fetchZones: zoneThunks.fetchAllZones,
    clearZones: zoneThunks.clearZones,
    clearGateways: gatewayThunks.clearGateways,
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withOrientationChange(Gateway)));
