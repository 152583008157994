import { createSelector } from 'reselect';

const selectMonitoringTreeCollectionState = (state: any) => state.monitoringTreeCollection;

const getDashboardId = (state: any, dashboardId?: number) => dashboardId;

export const selectMonitoringTreeCollection = createSelector(
    selectMonitoringTreeCollectionState,
    monitoringTreeCollection => monitoringTreeCollection && monitoringTreeCollection.trees,
);


export const selectMonitoringTreeCollectionByDashboardId = createSelector(
    selectMonitoringTreeCollection,
    getDashboardId,
    (trees, dashboardId) => trees && dashboardId && trees[dashboardId],
);