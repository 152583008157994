import React, { useCallback } from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { Accordion, AccordionDetails } from '@material-ui/core';
import DroppableItemTitle from './DroppableItemTitle';
import DroppableItemBody from './DroppableItemBody';
import { IPlaceholderProps } from '../../../../../interfaces';


interface IDroppableItemProps {
    droppableItem: any;
    editItem: any;
    draggableName: string[];
    droppableName: string[];
    phone: boolean;
    beacon: boolean;
    placeholderProps: IPlaceholderProps;
    activeDraggable: (id: number, name: string) => void;
    getItem: (id: number) => Record<string, unknown>;
    option: Record<string, unknown>;
    editD: number[];
    activeItemWrap: boolean;
    activeDroppable: (id: number) => void;
    handleClick: (index: number | string, isOpen: boolean | undefined) => void;
    filterFunction: (data: any) => any;
    table: string;
}

const DroppableItem: React.FC<IDroppableItemProps> = ({
                                                          droppableItem,
                                                          editItem,
                                                          draggableName,
                                                          phone,
                                                          beacon,
                                                          getItem,
                                                          placeholderProps,
                                                          activeDraggable,
                                                          droppableName,
                                                          option,
                                                          editD,
                                                          activeItemWrap,
                                                          activeDroppable,
                                                          handleClick,
                                                          filterFunction,
                                                          table,
                                                      }: IDroppableItemProps) => {


    /**
     * Default style for dragged item
     *
     * @param isDragging { boolean }
     * @param draggableStyle - default style
     */
    const getItemStyle = useCallback((isDragging: boolean, draggableStyle: any) => {
        return { // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            width: '100%',
            height: 40,
            // change background colour if dragging
            background: '#ffffff',
            // background: isDragging ? 'lightgreen' : '#ffffff',

            // styles we need to apply on draggables
            ...draggableStyle,
        };
    }, []);

    return (
        <div
            key={droppableItem.id}
            className={'list-draggable '}
        >
            <Droppable
                droppableId={String(droppableItem.id.toString())}
                key={droppableItem.id}
            >
                {(provided: DroppableProvided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        <Accordion
                            className={'expansion-panel'}
                            defaultExpanded={false}
                        >
                            <DroppableItemTitle
                                droppableItem={droppableItem}
                                editItem={editItem}
                                droppableName={droppableName}
                                activeItemWrap={activeItemWrap}
                                getItem={getItem}
                                option={option}
                                activeDroppable={activeDroppable}
                                handleClick={handleClick}
                            />
                            <AccordionDetails style={{ width: '100%' }}>
                                <div
                                    className={'wrapper-draged-element'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minHeight: 1,
                                    }}
                                >
                                    {getItem(droppableItem.id) ? filterFunction(getItem(droppableItem.id)[table]).map((draggableValue: Record<string, unknown>, index: number) =>

                                        (
                                            <DroppableItemBody
                                                key={draggableValue.id as number}
                                                draggableValue={draggableValue}
                                                editD={editD}
                                                editItem={editItem}
                                                getItemStyle={getItemStyle}
                                                option={option}
                                                draggableName={draggableName}
                                                phone={phone}
                                                beacon={beacon}
                                                activeDraggable={activeDraggable}
                                                index={index}
                                                droppableItem={droppableItem}
                                            />
                                        )
                                    ) : null}

                                    {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                    {provided.placeholder}

                                    {placeholderProps && placeholderProps.id === droppableItem.id.toString() ?
                                        <div
                                            className={'placeholder-props'}
                                            style={{
                                                position: 'absolute',
                                                top: placeholderProps.clientY! + 40,
                                                left: placeholderProps.clientX,
                                                height: placeholderProps.clientHeight,
                                                width: placeholderProps.clientWidth,
                                            }}
                                        />
                                        :
                                        null
                                    }

                                    {/* <CustomPlaceholder snapshot={snapshot} /> */}

                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                )}
            </Droppable>


        </div>
    );
};

export default DroppableItem;
