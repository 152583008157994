
/**
 * Fitting string
 *
 * @param {string} str
 * @param {number} maxlength
 */
export const maxString = (str: string, maxlength: number): string => {



    const ellipsis = '…';

    if (str.length <= maxlength) {

        return str;

    } else {

        return str.substr(0, maxlength) + ellipsis;
    }
}