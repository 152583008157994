import React, { useCallback, useEffect, useState } from 'react';
import { graphConstants } from '../../../../core/constants';
import { ReactComponent as DropdownArrowIcon } from '../../../../core/ui/assets/images/icons/dropdown-arrow.svg';
import { Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { MonitoringActions } from '../../../../core/actions';
import {
    IActiveProductData,
    ISensorTargetValueState,
    IStructuralTreeUnitTitleProps,
} from '../../../../core/interfaces';
import { Tooltip } from '../../../../core/ui/components';
import { GraphActions } from '../../../../base/store/actions';
import { HistogramChart } from '../../../../base/components';
import { selectSelectedDashboard } from '../../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import {
    selectMaxWidthSideBar,
    selectVisibleSideBar,
} from '../../../../core/selectors/graphStructuralTreeVisibility/graphStructuralTreeVisibilitySelector';
import { selectHmiPlayerMode } from '../../../../core/selectors/hmi/playerSelector';
import { selectDashboardOnline } from '../../../../core/selectors/dashboard/dashboardSelector';

const StructuralTreeUnitTitle: React.FC<IStructuralTreeUnitTitleProps> = (
    {
        GetMinimizedUnit,
        unit,
        GetSensorWithKeyParam,
        histogramHeight,
        handleClick,
        checkedIcon,
        icon,
        histogramData,
        activeProductData,
    }: IStructuralTreeUnitTitleProps,
) => {

    const dispatch = useDispatch();

    const histogram = histogramData.filter((value) => value.isKeyParameter === true)[0];

    const visibleSideBar = useSelector(selectVisibleSideBar),
        maxWidthSideBar = useSelector(selectMaxWidthSideBar),
        selectedDashboard = useSelector(selectSelectedDashboard),
        HMIPlayerStatus = useSelector(selectHmiPlayerMode);

    const dashboardRealTime = useSelector(selectDashboardOnline);
    const { body: { offsetWidth } } = document;


    const [sensorTargetValue, setSensorTargetValue] = useState<ISensorTargetValueState | undefined>(undefined);

    /**
     * CollapseUnit Callback. Updates a component in the monitoring tree.
     */
    const collapseUnitCallback = useCallback(() => {

        handleClick(unit.id, 'unit');

        if (selectedDashboard) {

            dispatch(MonitoringActions.update(selectedDashboard.id, 'unit', unit.id, { isMinimized: !unit.isMinimized }));

        }

    }, [handleClick, unit, dispatch, selectedDashboard]);

    /**
     * MouseMove Callback. A peak mouse enter the event handler for an empty line.
     */
    const mouseMoveCallback = useCallback((event) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));
                }
                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.pageX - maxWidthSideBar));

                }
            }

        }

    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * TouchStart Callback. A peak mouse enter the event handler for an empty line.
     */
    const touchStartCallback = useCallback((event) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }
    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * TouchMove Callback. A peak mouse enter the event handler for an empty line.
     */
    const touchMoveCallback = useCallback((event) => {

        event.preventDefault();

        if (maxWidthSideBar) {

            if (HMIPlayerStatus === 'stop') {

                if (dashboardRealTime && offsetWidth - 36 >= event.touches[0].pageX) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));
                }

                if (!dashboardRealTime) {

                    dispatch(GraphActions.peakEnterEmptyLine(event.touches[0].pageX - maxWidthSideBar));

                }
            }

        }
    }, [dispatch, maxWidthSideBar, HMIPlayerStatus, dashboardRealTime, offsetWidth]);

    /**
     * MouseLeave Callback. A peak mouse enter the event handler for an empty line.
     */
    const mouseLeaveCallback = useCallback(() => {

        if (HMIPlayerStatus === 'stop') {

            dispatch(GraphActions.peakLeave());
        }

    }, [dispatch, HMIPlayerStatus]);

    useEffect(() => {

        if (activeProductData && GetSensorWithKeyParam) {

            const activeProduct = activeProductData as IActiveProductData,
                activeProducts = activeProduct.activeProducts;

            const sensorTargetValueData = {
                activeProducts: activeProducts,
                targetValues: GetSensorWithKeyParam.targetValues!,
            };

            setSensorTargetValue(sensorTargetValueData);

        }
    }, [activeProductData]);

    const rightSectionStyle = {
        maxWidth: maxWidthSideBar,
        minWidth: maxWidthSideBar,
        transition: 'max-width 0.2s',
        transitionTimingFunction: 'cubic-bezier(0.1, 0.1, 0.1, 0.1)',
    };

    const sensorName = `${unit.name}${!GetMinimizedUnit && GetSensorWithKeyParam ? ' (' + GetSensorWithKeyParam.name + ')' : ''}`;

    return (
        <div
            className={!GetMinimizedUnit && GetSensorWithKeyParam && histogramHeight !== graphConstants.histogramSmallHeight ? 'item-title extended' : 'item-title '}
        >
            <div className="wrap-section">
                <div
                    className={'right-section left-padding-3 ' +
                    (GetMinimizedUnit ? 'active' : '') +
                    (visibleSideBar ? ' limit-padding hidden' : '') +
                    (!GetMinimizedUnit && GetSensorWithKeyParam && histogram ? 'sensor-name' : '')}
                    style={rightSectionStyle}
                    onClick={collapseUnitCallback}
                >
                    <DropdownArrowIcon />
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        value="factory"
                        checked={!unit.isMinimized}
                        className={''}
                        disabled
                    />
                    <Tooltip
                        title={sensorName}
                        enterDelay={500}
                        style={{ zIndex: 1200 }}
                        enterTouchDelay={200}
                        leaveTouchDelay={2000}
                    >
                        <span className={'sensor-name edit'}>
                            {sensorName}
                        </span>
                    </Tooltip>
                </div>
                <div
                    className={!GetMinimizedUnit && GetSensorWithKeyParam ? 'left-section with-chart' : 'left-section no-chart'}
                    onMouseMove={mouseMoveCallback}
                    onTouchStart={touchStartCallback}
                    onTouchMove={touchMoveCallback}
                    onMouseLeave={mouseLeaveCallback}
                >
                    {
                        !GetMinimizedUnit && GetSensorWithKeyParam && histogram ?
                            <HistogramChart
                                sensor={histogram}
                                sensorTargetValue={sensorTargetValue}
                                hrMode={false}
                            />
                            : null
                    }

                </div>
            </div>
        </div>

    );
};

export default React.memo(StructuralTreeUnitTitle);