import { v4 } from 'uuid';
import { EventHandler as BaseEventHandler } from '../../../../../../base/components/Editor/canvas/handlers/EventHandler';
import { FabricObject, IFabricEvent } from '../../../../../../base/components/Editor/interfaces';
import { editorConstants } from '../../constants';
import { fabric } from 'fabric';

export class EventHandler extends BaseEventHandler {

    /**
     * Mouse down event handler
     *
     * @param {IFabricEvent} evt
     */
    onMouseDown(evt: IFabricEvent): void {

        if (this.handler.interactionMode === editorConstants.interaction.modeGrab) {

            this.panning = true;

            return;
        }

        const event = evt as IFabricEvent<MouseEvent>;

        const { editable } = this.handler;

        const { target } = event;

        if (editable) {

            if (this.handler.interactionMode === editorConstants.interaction.modePolygon) {

                this.handler.canvas.discardActiveObject();

                if (target && this.handler.pointArray.length && target.id === this.handler.pointArray[0].id) {

                    if (this.handler.pointArray.length < 3) {

                        return;
                    }

                    this.handler.drawingHandler.polygon.finish(this.handler.pointArray);

                } else {

                    this.handler.drawingHandler.polygon.addPoint(event);
                }

            } else {

                const { absolutePointer } = event;

                if (absolutePointer) {

                    const { x, y } = absolutePointer;

                    const objectsMapping = {
                        [editorConstants.interaction.modeEngine]: editorConstants.objects.engine,
                        [editorConstants.interaction.modeSensor]: editorConstants.objects.sensor,
                        [editorConstants.interaction.modeValve]: editorConstants.objects.valve,
                        [editorConstants.interaction.modeIndicator]: editorConstants.objects.indicator,
                        [editorConstants.interaction.modeRectangle]: editorConstants.objects.rectangle,
                        [editorConstants.interaction.modeOval]: editorConstants.objects.oval,
                        [editorConstants.interaction.modeTriangle]: editorConstants.objects.triangle,
                        [editorConstants.interaction.modeStar]: editorConstants.objects.star,
                    };

                    const optionsMapping = {
                        [editorConstants.interaction.modeRectangle]: {
                            strokeWidth: 1,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                            width: 18,
                            height: 18,
                            left: x - 9,
                            top: y - 9,
                        },
                        [editorConstants.interaction.modeOval]: {
                            strokeWidth: 1,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                            radius: 9,
                            left: x - 9,
                            top: y - 9,
                        },
                        [editorConstants.interaction.modeTriangle]: {
                            strokeWidth: 1,
                            stroke: '#74797d',
                            fill: '#cdd0d4',
                            opacity: 1,
                            width: 18,
                            height: 18,
                            left: x - 9,
                            top: y - 9,
                        },
                    };

                    const defaultOptions = {
                        id: v4(),
                        type: objectsMapping[this.handler.interactionMode],
                        left: x,
                        top: y,
                        zIndex: this.handler.canvas.getObjects().length + 10,
                    };

                    this.handler.add({
                        ...defaultOptions,
                        ...optionsMapping[this.handler.interactionMode],
                    });

                    this.handler.interactionHandler.selection();
                }
            }
        }
    }

}
