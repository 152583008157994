import React from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ProcessActions } from '../../core/actions';
import { Message, ScrollContainer } from '../../core/ui/components';
import { IColumn, IData, IProcessPageProps } from '../../core/interfaces';
import { RootState } from '../../core/store';
import { isMobile, withOrientationChange } from 'react-device-detect';
import { ListStickyHeader } from '../../base/components';

/**
 * Process component
 *
 * @class Process
 */
class Process extends React.Component<IProcessPageProps & WithTranslation> {

    /**
     * Constructor
     *
     * @param {IProps & WithTranslation} props
     */
    constructor(props: IProcessPageProps & WithTranslation) {

        super(props);

        const { t } = this.props;

        this.columns = [
            {
                name: 'id',
                label: '#ID',
                sortable: false,
                width: '10%',
            },
            {
                name: 'name',
                label: t('NAME'),
                sortable: false,
                editable: true,
                validation: {
                    required: true,
                    maxLength: 30,
                },
                width: '30%',
            },
            {
                name: 'comment',
                label: t('COMMENT'),
                sortable: false,
                editable: true,
                validation: {
                    required: false,
                    maxLength: 250,
                },
                width: '60%',
            },
        ];

        this.bulkUpdate = this.bulkUpdate.bind(this);
    }

    /**
     * Callback after render the component to the DOM
     */
    componentDidMount() {

        this.props.load();
    }

    /**
     * Component props update handler
     *
     * @param {IProps} prevProps Updated component properties
     */
    componentDidUpdate(prevProps: IProcessPageProps) {

        if (this.props.refresh && prevProps.refresh !== this.props.refresh) {

            this.props.load();
        }
    }

    /**
     * Data table columns
     *
     * @type {IColumn[]}
     */
    private readonly columns: IColumn[];

    /**
     * Bulk update processes after edit
     *
     * @param {IData[]} models Changed models
     */
    bulkUpdate(models: IData[]) {

        this.props.bulkUpdate(models);
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const { t, load, isLandscape } = this.props;

        return (
            <div className="with-list-component page-setting">
                <ScrollContainer
                    maxHeightSlider={`calc(100vh - ${isMobile ? isLandscape ? 40 : 220 : 40}px)`}
                    headerScroll={
                        <div className="content-title-wrap">
                            <h2 className={'content-title'}>{t('PROCESSES')}</h2>
                            <Message
                                message={this.props.t('PLEASE_ENTER_A_UNIQUE_NAME')}
                                hidden
                            />
                        </div>
                    }
                    bodyStyle={{
                        paddingTop: 15,
                    }}
                >
                    <ListStickyHeader
                        columns={this.columns}
                        data={this.props.processes}
                        selectedParam={'id'}
                        refresh={load}
                        onSaveChanges={this.bulkUpdate}
                        maxHeightSlider={'calc(100vh - 251px)'}
                        minHeightSlider={'400px'}
                    />
                </ScrollContainer>
            </div>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { processes, errors } = state.process,
        { refresh } = state.processChange;

    return {
        processes,
        errors,
        refresh,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    load: ProcessActions.list,
    bulkUpdate: ProcessActions.bulkUpdate,
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withOrientationChange(Process)));
