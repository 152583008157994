import ApiProvider from '../providers/apiProvider';
import { AxiosResponse } from 'axios';

/**
 * Service to work with user API resources
 *
 * @class ProductService
 */
export class ProductTargetValueService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/product-target-values(/:productId-:sensorId)';
    }

    /**
     * A searchable columns
     *
     * @return {string[]}
     */
    get searchable(): string[] {

        return [''];
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    async get(productId: number, sensorId: number): Promise<AxiosResponse> {

        return await this.http
            .get(this.url({ productId: productId, sensorId: sensorId }), {
                headers: this.headers,
            });
    }

    /**
     * Get list of products
     *
     * @params {string} search A search string
     * @params {IOrder} order An order params
     *
     * @return {Promise<Object>}
     */
    async update(productId: number, sensorId: number, data: { minTargetValue: number | null, maxTargetValue: number | null }): Promise<AxiosResponse> {

        return await this.http
                .patch(this.url({ productId: productId, sensorId: sensorId }), data, {
                    headers: this.headers,
                });
    }

}