import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isAndroid, isChrome, isIOS, isSafari } from 'react-device-detect';
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import { Slider } from '../../../../../base/components/Controls/Slider/Slider';

import '../styles/FooterPanel.scss';

interface IProps {
    onSlide: (value: number) => void;
    onShowMap: (value: boolean) => void;
    onShowObjects: (value: boolean) => void;
    onClose: () => void;
    panelSetting: {
        showMap: boolean;
        showObjects: boolean;
        opacity: number;
    }
}

/**
 * Editor footer panel
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
export const FooterPanel: React.FC<IProps> = (
    {
        onSlide,
        onClose,
        onShowMap,
        onShowObjects,
        panelSetting,
    }: IProps,
) => {

    const [showMap, setShowMap] = useState<boolean>(panelSetting.showMap);
    const [showObjects, setShowObjects] = useState<boolean>(panelSetting.showObjects);

    const handleMapChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        onShowMap(!showMap);

        setShowMap(!showMap);
    };

    const handleObjectsChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        onShowObjects(!showObjects);

        setShowObjects(!showObjects);
    };

    const { t } = useTranslation();

    return (
        <div
            className={`map-editor-footer-panel 
                ${isIOS && isSafari ? 'ios-style-safari' : isIOS && isChrome ? 'ios-style-chrome' : ''}
                ${isAndroid ? 'android-style' : ''}`
            }
        >
            <Slider label={t('SCHEME_OPACITY')} value={panelSetting.opacity} onChangeValue={onSlide} />
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showMap}
                            onChange={handleMapChange}
                            name="showMap"
                            color="primary"
                        />
                    }
                    label={t('SHOW_SCHEME')}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showObjects}
                            onChange={handleObjectsChange}
                            name="showObjects"
                            color="primary"
                        />
                    }
                    label={t('SHOW_OBJECTS')}
                />
            </FormGroup>
            <Button
                variant="outlined"
                onClick={onClose}
            >
                {t('CLOSE')}
            </Button>
        </div>
    );
};
