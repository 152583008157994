import { BuildingActions, BuildingActionTypes } from '../actions/buildingActions';
import { IBuildingApiModel } from '../../services/buildingService';

export interface IBuildingState {
    isLoading: boolean;
    buildings: IBuildingApiModel[];
    error: any;
}

export const initialState: IBuildingState = {
    isLoading: false,
    buildings: [],
    error: null,
};

export function buildingReducer(state = initialState, action: BuildingActions): IBuildingState {

    switch (action.type) {

        case BuildingActionTypes.FETCH_BUILDINGS_REQUEST: {

            return {
                ...state,
                isLoading: true,
                error: null,
            };
        }

        case BuildingActionTypes.FETCH_BUILDINGS_SUCCESS: {

            const { buildings } = action.payload;

            return {
                ...state,
                isLoading: false,
                buildings,
            };
        }

        case BuildingActionTypes.FETCH_BUILDINGS_FAILURE: {

            const { error } = action.payload;

            return {
                ...state,
                isLoading: false,
                error,
            };
        }

        case BuildingActionTypes.DELETE_BUILDING_ACTION: {

            const modifyBuildings= state.buildings.filter(building => building.id !== action.payload.building.id);

            return {
                ...state,
                buildings: modifyBuildings,
            };
        }

        case BuildingActionTypes.SAVE_BUILDING_ACTION: {

            const modifyBuildings = state.buildings.map((building) => building.id === action.payload.building.id ? action.payload.building : building);

            if (modifyBuildings.findIndex(building => building.id === action.payload.building.id) === -1) modifyBuildings.push(action.payload.building);
            return {
                ...state,
                buildings: modifyBuildings,
            };
        }

        case BuildingActionTypes.CLEAR_BUILDINGS_ACTION: {
            return {
                ...state,
                buildings: [],
            }
        }

        default: {

            return state;
        }
    }
}