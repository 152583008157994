import React from 'react';
import { connect, useSelector } from 'react-redux';
import {
    IEditStructuralTreeProps, IEditStructuralTreeState, IFlatTreeFactory, IFlatTreeUnit,
    ISensor, IUser,
} from '../../../core/interfaces';
import { ReactComponent as FactoryIcon } from '../../../core/ui/assets/images/icons/folder-factory.svg';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { graphConstants } from '../../../core/constants';
import { DragUpdate, DropResult } from 'react-beautiful-dnd';
import { FormActions, MonitoringActions, statesActions } from '../../../core/actions';
import StructuralTreeTitle from './Items/StructuralTreeTitle';
import Process from './EditStructuralTree/Process';

import './EditStructuralTree.scss';
import { Message } from '../../../core/ui/components';
import { withTranslation, WithTranslation } from 'react-i18next';
import { AppRoles } from '../../../rbac/roles';
import { RootState } from '../../../core/store';
import { modules } from '../../../modules';
import { GraphActions } from '../../../base/store/actions';
import { selectSelectedDashboard } from '../../../core/selectors/dashboardSelect/selectedDashboardSelector';
import { selectHmiPlayerMode } from '../../../core/selectors/hmi/playerSelector';


/**
 *  Component Edit Structural tree
 *
 *  @class EditStructuralTree
 */
class EditStructuralTree extends React.PureComponent<IEditStructuralTreeProps & WithTranslation, IEditStructuralTreeState> {

    constructor(props: IEditStructuralTreeProps & WithTranslation) {

        super(props);

        this.getOpenStatus = this.getOpenStatus.bind(this);

        this.handleClick = this.handleClick.bind(this);

        this.onDragEnd = this.onDragEnd.bind(this);

        this.onDragUpdate = this.onDragUpdate.bind(this);

        this.onMouseDown = this.onMouseDown.bind(this);

        this.deselectAllStatesAlert = this.deselectAllStatesAlert.bind(this);

    }

    /**
     *  Default Component State
     */
    readonly state: IEditStructuralTreeState = {
        treeData: this.props.treeData,
        collapse: {
            process: [],
            factory: [],
            unit: [],
        },
        placeholderProps: {},
    };

    /**
     * Callback after component props update
     *
     * @param {IProps} prevProps
     */
    componentDidUpdate(prevProps: Readonly<IEditStructuralTreeProps>) {

        if (this.props.treeData && this.props.dashboardSelect !== prevProps.dashboardSelect) {

            this.setState({ treeData: this.props.treeData });

        }
    }

    /**
     * Current dragging sensor
     *
     * @type {ISensor | null}
     */
    sensorOnDrag: ISensor | null = null;

    /**
     *  Get active sensor data chart
     *
     * @param sensorData
     *
     * @return {Object[]}
     */
    getSensorWithIsVisibleStatus(sensorData: ISensor[]): {
        active: boolean;
        keyActive: boolean;
    } {
        const rules = (sensor: ISensor) => sensor.isVisible === true;
        const rulesKeyActive = (sensor: ISensor) => sensor.isVisible === true && sensor.isKeyParameter === true;
        return {
            active: sensorData.some(rules),
            keyActive: sensorData.some(rulesKeyActive),
        };
    }

    /**
     * Tree element open status checker
     *
     * @param {number} id
     * @param {String} paramArr
     */
    getOpenStatus(id: number, paramArr: 'process' | 'factory' | 'unit'): boolean {

        return this.state.collapse[paramArr].indexOf(id) < 0;
    }

    /**
     *  handle for open draggable element
     *
     * @param {number} id
     * @param {string} paramArr
     */
    handleClick(id: number, paramArr: string) {

        const { collapse } = this.state;

        switch (paramArr) {

            case 'factory':

                if (collapse[paramArr].indexOf(id) < 0) {

                    collapse[paramArr].push(id);

                } else {

                    collapse[paramArr].splice(collapse[paramArr].indexOf(id), 1);
                }

                break;

            case 'process':

                if (collapse[paramArr].indexOf(id) < 0) {

                    collapse[paramArr].push(id);

                } else {

                    collapse[paramArr].splice(collapse[paramArr].indexOf(id), 1);
                }

                break;

            case 'unit':

                if (collapse[paramArr].indexOf(id) < 0) {

                    collapse[paramArr].push(id);

                } else {

                    collapse[paramArr].splice(collapse[paramArr].indexOf(id), 1);
                }

                break;
        }

        this.setState({ collapse: collapse });

        this.props.toggleForm(false);
    }

    /**
     *  A little function to help us with Reordering the result
     *
     * @param {ISensor} list - Category Item list
     * @param {number} startIndex - index begin dragging
     * @param {number} endIndex - index end dragging
     * @param {string| number} index - droppable index (category )
     */
    reorder(list: ISensor, startIndex: number, endIndex: number, index: string | number) {

        const { treeData } = this.state;

        const newTreeData = treeData.map(treeItem => {

            if (treeItem.type === 'unit') {
                if ((treeItem as IFlatTreeUnit).data.indexOf(list) > -1) {

                    (treeItem as IFlatTreeUnit).data[endIndex].position = endIndex;

                    const [removed] = (treeItem as IFlatTreeUnit).data.splice(startIndex, 1);

                    removed.position = endIndex;

                    (treeItem as IFlatTreeUnit).data.splice(endIndex, 0, removed);
                }
            }

            return treeItem;
        });

        this.setState({ treeData: newTreeData });

        if (this.sensorOnDrag && this.props.dashboardSelect) {

            this.props.updateMonitoringTree(this.props.dashboardSelect.id, 'sensor', this.sensorOnDrag.id,
                {
                    sensorChange: {
                        isVisible: this.sensorOnDrag.isVisible,
                        position: endIndex,
                    },
                });

        }
    }


    /**
     *  Result after dragged element
     *
     * @param {DropResult} result
     */
    onDragEnd(result: DropResult) {

        const { source, destination } = result;
        this.setState({ placeholderProps: {} });

        // dropped outside the list
        if (!destination) {

            return;
        }

        if (source.droppableId === destination.droppableId) {

            const index = parseInt(source.droppableId.replace(/\D+/g, ''));

            if (this.sensorOnDrag) {

                this.reorder(
                    this.sensorOnDrag,
                    source.index,
                    destination.index,
                    index,
                );

            }


        }
    }

    /**
     * Update placeholder when item is dragged
     *
     * @param {DragUpdate} update
     * */
    onDragUpdate(update: DragUpdate) {

        if (!update.destination) {

            return;

        }

        const insertItem = <T, >(arr: T[], item: T, index: number) => [
            ...arr.slice(0, index),
            item,
            ...arr.slice(index),
        ];

        const removeItem = <T, >(arr: T[], index: number) => [
            ...arr.slice(0, index),
            ...arr.slice(index + 1),
        ];

        const swapElements = <T, >(arr: T[], source: number, destination: number) =>
                insertItem(removeItem(arr, source), arr[source], destination),
            draggableAttr = 'data-rbd-drag-handle-draggable-id',
            droppableAttr = 'data-rbd-droppable-id',
            getAttr = (key: string, value: string) => `[${key}="${value}"]`,
            { source, destination } = update,
            draggableQuery = getAttr(draggableAttr, update.draggableId),
            droppableQuery = getAttr(droppableAttr, destination.droppableId),
            draggable = document.querySelector(draggableQuery) as HTMLElement,
            droppable = document.querySelector(droppableQuery) as HTMLElement;


        if (!draggable || !droppable) {

            return;
        }

        const { clientHeight, clientWidth } = draggable;

        const reorderedChildren = source.droppableId === destination.droppableId
            ? swapElements(Array.from(droppable.children), source.index, destination.index)
            : insertItem(Array.from(droppable.children), draggable, destination.index);

        const clientY =
            parseFloat(window.getComputedStyle(droppable).paddingTop) +
            Array.from(reorderedChildren)
                .slice(0, destination.index)
                .reduce((total, curr) => {

                    const style = window.getComputedStyle(curr);
                    const marginBottom = parseFloat(style.marginBottom);

                    return total + curr.clientHeight + marginBottom;
                }, 0);

        this.setState({
            placeholderProps: {
                id: parseInt(destination.droppableId),
                clientHeight,
                clientWidth,
                clientY,
                clientX: parseFloat(window.getComputedStyle(droppable).paddingLeft),
            },
        });

        this.props.barToggleTableView(true, 0);
    }

    /**
     * Sensor mouse down even handler
     *
     * @param {ISensor} sensor
     */
    onMouseDown(sensor: ISensor) {

        this.sensorOnDrag = sensor;
    }

    /**
     * Deselect all states and alert
     */
    deselectAllStatesAlert() {

        const { modeAction, mode } = this.props;

        if (modeAction) modeAction(!mode);

        this.props.barToggleTableView(true, 0);

        this.props.deselectStates();

        this.props.deselectAlertGraph();
    }

    /**
     * Render the component
     *
     * @return {JSX.Element}
     */
    render() {

        const {
                histogramHeight = graphConstants.histogramSmallHeight,
                visibleSideBar = true,
                searchField,
                appSettings,
                user,
                dashboardSelect,
                dashboards,
                rbac,
            } = this.props,
            { placeholderProps } = this.state;

        const structureTreeStyle = {
            display: this.props.visibility ? 'block' : 'none',
            height: this.props.scrollHeight,
        };

        return (
            <React.Fragment>
                <div
                    ref={this.props.structureTreeRef}
                    className={histogramHeight === graphConstants.histogramSmallHeight ? 'structure-tree' : 'structure-tree extended'}
                    style={structureTreeStyle}
                >
                    {!appSettings.isConfigured ?
                        <Message
                            message={this.props.t('THE_SYSTEM_IS_NOT_CONFIGURED')}
                            hidden
                        />
                        : null
                    }
                    {appSettings.isConfigured && dashboards.length === 0 && !appSettings?.hasDashboards && user?.role !== AppRoles.STANDARD ?
                        <Message
                            message={this.props.t('CREATE_DASHBOARD')}
                            hidden
                        />
                        : null
                    }
                    {this.state.treeData ? this.state.treeData
                        .filter(treeItem => treeItem.type === 'factory')
                        .map((factory) => (
                            <div
                                className={visibleSideBar ? 'factory' : 'factory open'}
                                key={'factory-key-' + factory.id}
                            >
                                <Accordion
                                    className={'expansion-panel'}
                                    defaultExpanded={!factory.isMinimized}
                                    expanded={this.getOpenStatus(factory.id, 'factory')}
                                    onChange={this.deselectAllStatesAlert}
                                >
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id={'factory' + factory.id.toString()}
                                    >
                                        <StructuralTreeTitle
                                            rightSectionClass={this.props.visibleSideBar ? 'firs-item hidden' : 'firs-item'}
                                            node={factory}
                                            getOpenStatus={this.getOpenStatus}
                                            handleClick={this.handleClick}
                                            icon={
                                                <FactoryIcon className="default-icon-size" />
                                            }
                                            checkedIcon={
                                                <FactoryIcon className="default-icon-size" />
                                            }
                                            checkBoxValue={'factory'}
                                            checkboxDisabled
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails className={'factory-details'}>
                                        <div className={'display-flex-row'}>
                                            {factory.data ? (factory as IFlatTreeFactory).data.map(process => (
                                                <Process
                                                    key={'process-key-' + process}
                                                    processID={process}
                                                    factory={factory as IFlatTreeFactory}
                                                    collapse={{ ...this.state.collapse }}
                                                    searchField={searchField}
                                                    placeholderProps={placeholderProps}
                                                    getOpenStatus={this.getOpenStatus}
                                                    onDragEnd={this.onDragEnd}
                                                    onDragUpdate={this.onDragUpdate}
                                                    onChange={this.deselectAllStatesAlert}
                                                    onMouseDown={this.onMouseDown}
                                                    handleClick={this.handleClick}
                                                />
                                            )) : null}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        )) : null
                    }
                    {
                        modules.map((module, index) => {

                            if (rbac.can('hr:monitoring-tree:manage')) {

                                return module.renderMonitoringTree(
                                    'editMode',
                                    index,
                                    searchField,
                                );

                            }
                        })
                    }
                </div>
            </React.Fragment>
        );
    }
}

/**
 * Map global state to component props
 *
 * @param {Object} state
 *
 * @return {Object}
 */
const mapStateToProps = (state: RootState) => {

    const { graphHistogramHeight, graphMinimapVisibility, auth, appSetting, dashboard } = state;
    const { dashboards } = dashboard;

    const dashboardSelect = selectSelectedDashboard(state),
        HMIPlayerStatus = selectHmiPlayerMode(state);

    return {
        histogramHeight: graphHistogramHeight.height,
        minimapVisible: graphMinimapVisibility.visible,
        mode: graphHistogramHeight.mode,
        dashboardSelect,
        appSettings: appSetting.settings,
        user: auth.user as IUser,
        dashboards,
        rbac: auth.rbac,
        HMIPlayerStatus,
    };
};

/**
 * Map dispatch to component props
 *
 * @type {object}
 */
const mapDispatchToProps = ({
    barToggleTableView: GraphActions.barToggleTableView,
    modeAction: GraphActions.histogramToggleMode,
    deselectStates: statesActions.deselectAllStates,
    deselectAlertGraph: GraphActions.deselectAlertGraph,
    updateMonitoringTree: MonitoringActions.update,
    toggleForm: FormActions.toggle,
});


export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditStructuralTree));