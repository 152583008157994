import React from 'react';

import './styles/Selector.scss';

/**
 * A graph elements selector functional component
 *
 * @param {React.PropsWithChildren<IProps>} props
 *
 * @return {JSX.Element}
 *
 * @constructor
 */
const Selector: React.FC<IProps> = (props: IProps) => {

    return (
        <div className={`graph-selector ${props.typeName? props.typeName: ''}`} style={{ left: props.left, width: props.width }}>
            <div className="start">{props.start}</div>
            <div className="end">{props.end}</div>
            <div className={`duration ${props.width < 53? 'bottom' :''}`}><span >{props.duration}</span></div>
        </div>
    );
};

export default Selector;

interface IProps {
    width: number;
    left: number;
    start?: string;
    end?: string;
    duration?: string;
    typeName?: string;
}
