import { trackPromise } from 'react-promise-tracker';
import ApiProvider from '../providers/apiProvider';
import { ICause, IData, IFilter, IJoin, IOrder } from '../interfaces';
import { AxiosResponse } from 'axios';

/**
 * Service to work with unit API resources
 *
 * @class StatesService
 */
export class CausesService extends ApiProvider {

    /**
     * The API resource URL pattern
     *
     * @return {string}
     */
    get urlPattern(): string {

        return '/causes(/:id)';
    }

    /**
     * Get list of causes
     *
     * @params {object} params Query params
     *
     * @return {Promise<Object>}
     */
    async list(search: string, order: IOrder, join?: IJoin, filter?: IFilter): Promise<AxiosResponse> {

        return await this.http
            .get(this.url(), {
                params: this.prepareListParams(search, order, join, filter),
                headers: { ...this.headers, 'Pragma': 'no-cache' },
            });
    }

    /**
     * Bulk causes create
     *
     * @param {IData[]} causes
     *
     * @return {Promise<AxiosResponse<T>>}
     */
    bulkStore(causes: IData[]): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url() + '/bulk', causes, {
                    headers: this.headers,
                })
        );
    }

    store(causes: ICause): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .post(this.url(), causes, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Update causes
     *
     * @param {ICause} causes
     *
     * @return {Promise<Object>}
     */
    update(causes: ICause): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .patch(this.url({ id: causes.id }), causes, {
                    headers: this.headers,
                })
        );
    }

    /**
     * Remove causes by ID
     *
     * @param {number} causes
     *
     * @return {Promise<Object>}
     */
    remove(causes: ICause): Promise<AxiosResponse> {

        return trackPromise(
            this.http
                .delete(this.url({ id: causes.id }), {
                    headers: this.headers,
                })
        );
    }
}
