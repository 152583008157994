import { formConstants, unitConstants } from '../../constants';
import { IUnitChangeAction, IUnitChangeState } from '../../interfaces';

/**
 * Unit add/update/delete reducer. Process actions and change application global state
 *
 * @param {Object} state Current application state
 * @param {Object} action Current action
 *
 * @return {Object}
 */
export function unitChange(state: IUnitChangeState = {
    refresh: null,
    errors: {},
}, action: IUnitChangeAction): IUnitChangeState {

    switch (action.type) {

        case unitConstants.BULK_UPDATE_SUCCESS:

            return {
                refresh: Date.now(),
                errors: {},
            };

        case unitConstants.BULK_UPDATE_FAILURE:
        case unitConstants.UPDATE_FAILURE:

            return {
                refresh: Date.now(),
                errors: action.errors ? action.errors : {},
            };

        case formConstants.CLOSE:
            return {
                ...state,
                errors: {},
            };

        default:

            return state;
    }
}
